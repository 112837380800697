import fetch from 'isomorphic-fetch';

export type FetchVideoOptions = {
  search: string;
  sort: string;
  sortd: string;
  count: string;
  page: string;
  labels: string;
  excludeAnyWithLabels: string;
};

const ALLOWED_QUERY_PARAMS: Array<keyof FetchVideoOptions> = [
  'search',
  'sort',
  'sortd',
  'count',
  'page',
  'labels',
  'excludeAnyWithLabels',
];

export const createFetchVideoRequest = (options: FetchVideoOptions) => {
  const searchParams = new URLSearchParams();

  ALLOWED_QUERY_PARAMS.forEach((param) => {
    if (options[param]) {
      searchParams.append(param, options[param]);
    }
  });

  return fetch(
    `${process.env.REACT_APP_NEW_API_HOST}/videos?` + searchParams.toString(),
    {
      credentials: 'include',
      headers: {
        pragma: 'no-cache',
        'cache-control': 'no-cache',
      },
    }
  ).then(async (response) => {
    if (!response.ok) {
      const error = await response.json();
      throw Error(error.message);
    }
    return response.json();
  });
};
