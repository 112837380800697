import { createAction } from '@reduxjs/toolkit';
import type {
  PrivacySettings,
  PrivacyVisibility,
} from 'src/common/types/privacy';

export const settingsActions = {
  playerAppearance: {
    watermarkLink: {
      keyPressed: createAction<{ watermark_link: string }>(
        '[Settings Player Watermark Link] key pressed'
      ),
      linkSaved: createAction('[Settings Player Watermark Link] link saved'),
    },
  },
  privacy: {
    visibility: {
      changed: createAction<PrivacyVisibility>(
        '[Settings Privacy and Security] visibility changed'
      ),
      errored: createAction<Partial<PrivacySettings>>(
        '[Settings Privacy and Security] visibility errored'
      ),
      initiated: createAction<PrivacyVisibility>(
        '[Settings Privacy and Security] visibility initiated'
      ),
    },
    restrictions: {
      domainRestriction: {
        toggled: createAction(
          '[Settings Privacy and Security] domain restrictions toggled'
        ),
        errored: createAction(
          '[Settings Privacy and Security] domain restrictions errored'
        ),
      },
      allowedDomains: {
        input: {
          changed: createAction<string>(
            '[Settings Privacy and Security] allowed domain input changed'
          ),
        },
        initiated: createAction<string>(
          '[Settings Privacy and Security] allowed domain update initiated'
        ),
        errored: createAction<string>(
          '[Settings Privacy and Security] allowed domain update errored'
        ),
        saved: createAction(
          '[Settings Privacy and Security] allowed domain saved'
        ),
      },
    },
    preferences: {
      allowDownload: {
        errored: createAction<boolean>(
          '[Settings Privacy and Security] allow downloading errored'
        ),
        toggled: createAction(
          '[Settings Privacy and Security] allow downloading toggled'
        ),
      },
      allowSharing: {
        errored: createAction<boolean>(
          '[Settings Privacy and Security] allow sharing errored'
        ),
        toggled: createAction(
          '[Settings Privacy and Security] allow sharing toggled'
        ),
      },
      hideViewCount: {
        errored: createAction<boolean>(
          '[Settings Privacy and Security] hide view count errored'
        ),
        toggled: createAction(
          '[Settings Privacy and Security] hide view count toggled'
        ),
      },
    },
    advanced: {
      resetSettingsClicked: createAction(
        '[Settings Privacy and Security] reset settings clicked'
      ),
    },
  },
  resetModal: {
    cancelClicked: createAction(
      '[Settings Privacy and Security Reset Modal] cancel clicked'
    ),
    confirmClicked: createAction(
      '[Settings Privacy and Security Reset Modal] confirm clicked'
    ),
    resetting: createAction(
      '[Settings Privacy and Security Reset Modal] resetting privacy settings'
    ),
    resetSuccess: createAction(
      '[Settings Privacy and Security Reset Modal] privacy settings successfully reset'
    ),
    resetFail: createAction(
      '[Settings Privacy and Security Reset Modal] privacy settings reset failed'
    ),
  },
};
