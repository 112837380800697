type ObjectValues<T> = T[keyof T];

// The following types come from the API -- src/dal/media/media.model.ts
export const MEDIA_FLAGGING = {
  NONE: 0,
  TOS_VIOLATION: 1,
  DMCA_INFRINGEMENT: 2,
  NSFW: 3,
} as const;
type MediaFlagging = ObjectValues<typeof MEDIA_FLAGGING>;

export const MEDIA_STATUS = {
  UPLOADING: 0,
  PROCESSING: 1,
  READY: 2,
  ERROR: 3,
  CANCELED: 4,
  DELETED: 5,
  LEGACY_READY: 6,
} as const;

export type MediaAttributes = {
  shortcode: string;
  file_id?: string;
  user_id?: number | null;
  flagged?: MediaFlagging | null;
  width?: number;
  height?: number;
  duration?: number;
  audio_channels?: number;
  ext?: string;
  original_name?: string;
  original_size?: number;
  original_width?: number;
  original_height?: number;
  original_bitrate?: number;
  original_framerate?: number;
  session_id?: string;
  status?: ObjectValues<typeof MEDIA_STATUS>;
  percent?: number;
  title?: string;
  description?: string;
  source?: string;
  error?: string;
  upload_source?: string;
  gif?: number;
  reddit_url?: string;
  reddit_title?: string;
  source_title?: string;
  subreddit?: string;
  thumb_gif?: number;
  client_ip?: string;
  hot?: number;
  version?: number;
  privacy?: number | null;
  tags?: string[];
  trending?: string | null;
  autotags?: object;
  client_geo?: string;
  queued?: number;
  extractor?: string;
  extract_id?: string;
  screenshot_offset?: number;
};
