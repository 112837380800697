import type { PropsWithChildren } from 'react';
import { connect } from 'react-redux';

import { checkoutActions } from 'src/checkout/state/checkout-actions';
import { selectIntentType } from 'src/checkout/state/checkout-selectors';
import { selectNoTrial } from 'src/common/state/me/me-selectors';
import type { RootState } from 'src/redux';

import { PaymentCollectionComponent } from './payment-collection-component';

const mapStateToProps = (state: RootState) => ({
  intentType: selectIntentType(state),
  noTrial: selectNoTrial(state),
});

const mapDispatchToProps = {
  transitionToFailed: checkoutActions.billingDetails.next.paymentStatus.failed,
};

const PaymentCollectionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentCollectionComponent);

export default PaymentCollectionContainer as unknown as (
  p: PropsWithChildren<{}>
) => JSX.Element;
