import { createAction } from '@reduxjs/toolkit';

import type {
  PlanFields,
  ChangePlanPreviewInvoice,
  PlanCadence,
} from '../types';

export const pricingActions = {
  planColumn: {
    changePlanButton: {
      clicked: createAction<string>(
        '[Pricing Page Plan Column Change Plan Button] clicked'
      ),
    },
  },
  cadenceSwitch: {
    changeTargetCadenceToggle: {
      clicked: createAction<PlanCadence>(
        '[Pricing Table Change Cadence Toggle] clicked'
      ),
    },
  },
  changePlanModal: {
    busy: createAction('[Pricing Page Change Plan Modal] busy / loading'),
    close: createAction('[Pricing Page Change Plan Modal] close'),
    previewInvoiceFetched: createAction<{
      invoice: ChangePlanPreviewInvoice;
    }>('[Pricing Page Change Plan Modal] preview invoice fetched'),
    previewInvoiceFetchFailed: createAction<string>(
      '[Pricing Page Change Plan Modal] preview invoice fetch failed'
    ),
    changeFulfilled: createAction<PlanFields>(
      '[Pricing Page Change Plan Modal] change completed'
    ),
    changeFailed: createAction<string>(
      '[Pricing Page Change Plan Modal] change failed'
    ),
    confirmButton: {
      clicked: createAction(
        '[Pricing Page Change Plan Modal] confirm button clicked'
      ),
    },
  },
};
