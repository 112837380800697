import type { PayloadAction } from '@reduxjs/toolkit';
import { fetchCurrentUserAction, loginAction } from 'src/actions';
import type { FetchingState } from 'src/common/types/fetching-state';

import type { ExtendedPrivacySetting } from '../types';
import { calculateNewPrivacySettings } from '../utils/calculate-new-privacy-settings';
import { settingsActions } from './settings-actions';

export type SettingsState = {
  resetModal: {
    isShowing: boolean;
    isResetting: boolean;
  };
  player: {
    watermarkLink: Pick<FetchingState, 'isLoading' | 'isDone'>;
  };
  privacy: ExtendedPrivacySetting;
};

const initialState: SettingsState = {
  resetModal: {
    isShowing: false,
    isResetting: false,
  },
  player: {
    watermarkLink: {
      isLoading: false,
      isDone: false,
    },
  },
  privacy: {
    allow_download: false,
    allow_sharing: false,
    allowed_domain: {
      value: '',
      isLoading: false,
      isDone: false,
    },
    domain_restrictions: 'off',
    hide_view_count: false,
    visibility: 'public',
  },
};

export const settingsReducer = (
  state = initialState,
  action: PayloadAction<any>
): SettingsState => {
  const { type, payload } = action;

  switch (type) {
    case loginAction.type:
    case fetchCurrentUserAction.type: {
      if (payload.privacy_settings) {
        return {
          ...state,
          privacy: {
            ...state.privacy,
            ...{
              ...payload.privacy_settings,
              allow_download: !!payload.privacy_settings.allow_download,
              allow_sharing: !!payload.privacy_settings.allow_sharing,
              allowed_domain: {
                value: payload.privacy_settings.allowed_domain || '',
                isLoading: false,
                isDone: false,
              },
            },
          },
        };
      }

      return state;
    }

    case settingsActions.privacy.advanced.resetSettingsClicked.type:
      return {
        ...state,
        resetModal: {
          ...state.resetModal,
          isShowing: true,
        },
      };

    case settingsActions.resetModal.cancelClicked.type:
      return {
        ...state,
        resetModal: {
          ...state.resetModal,
          isShowing: false,
        },
      };
    case settingsActions.resetModal.resetting.type:
      return {
        ...state,
        resetModal: {
          ...state.resetModal,
          isResetting: true,
        },
      };
    case settingsActions.resetModal.resetSuccess.type:
      return {
        ...state,
        resetModal: {
          isResetting: false,
          isShowing: false,
        },
      };
    case settingsActions.resetModal.resetFail.type:
      return {
        ...state,
        resetModal: {
          ...state.resetModal,
          isResetting: false,
        },
      };
    case settingsActions.playerAppearance.watermarkLink.keyPressed.type:
      return {
        ...state,
        player: {
          watermarkLink: {
            isLoading: true,
            isDone: false,
          },
        },
      };
    case settingsActions.playerAppearance.watermarkLink.linkSaved.type:
      return {
        ...state,
        player: {
          watermarkLink: {
            isLoading: false,
            isDone: true,
          },
        },
      };

    case settingsActions.privacy.visibility.initiated.type:
      return {
        ...state,
        privacy: {
          ...state.privacy,
          ...calculateNewPrivacySettings({ visibility: payload }),
        },
      };

    case settingsActions.privacy.visibility.errored.type:
      return {
        ...state,
        privacy: {
          ...state.privacy,
          ...payload,
          allowed_domain: {
            ...state.privacy.allowed_domain,
            value: payload.allowed_domain || state.privacy.allowed_domain.value,
          },
        },
      };

    case settingsActions.privacy.restrictions.allowedDomains.input.changed.type:
      return {
        ...state,
        privacy: {
          ...state.privacy,
          allowed_domain: {
            ...state.privacy.allowed_domain,
            isLoading: true,
            isDone: false,
          },
        },
      };

    case settingsActions.privacy.restrictions.allowedDomains.initiated.type:
      return {
        ...state,
        privacy: {
          ...state.privacy,
          allowed_domain: {
            value: payload,
            isLoading: true,
            isDone: false,
          },
        },
      };

    case settingsActions.privacy.restrictions.allowedDomains.saved.type:
      return {
        ...state,
        privacy: {
          ...state.privacy,
          allowed_domain: {
            ...state.privacy.allowed_domain,
            isLoading: false,
            isDone: true,
          },
        },
      };

    case settingsActions.privacy.restrictions.allowedDomains.errored.type:
      return {
        ...state,
        privacy: {
          ...state.privacy,
          allowed_domain: {
            value: payload,
            isLoading: false,
            isDone: false,
          },
        },
      };

    case settingsActions.privacy.preferences.allowDownload.toggled.type:
    case settingsActions.privacy.preferences.allowDownload.errored.type:
      return {
        ...state,
        privacy: {
          ...state.privacy,
          allow_download: !state.privacy.allow_download,
        },
      };

    case settingsActions.privacy.restrictions.domainRestriction.toggled.type:
    case settingsActions.privacy.restrictions.domainRestriction.errored.type:
      return {
        ...state,
        privacy: {
          ...state.privacy,
          domain_restrictions:
            state.privacy.domain_restrictions === 'off' ? 'allowlist' : 'off',
        },
      };

    case settingsActions.privacy.preferences.allowSharing.toggled.type:
    case settingsActions.privacy.preferences.allowSharing.errored.type:
      return {
        ...state,
        privacy: {
          ...state.privacy,
          allow_sharing: !state.privacy.allow_sharing,
        },
      };

    case settingsActions.privacy.preferences.hideViewCount.toggled.type:
    case settingsActions.privacy.preferences.hideViewCount.errored.type:
      return {
        ...state,
        privacy: {
          ...state.privacy,
          hide_view_count: !state.privacy.hide_view_count,
        },
      };

    default:
      return state;
  }
};
