import { createAction } from '@reduxjs/toolkit';
import type { BillingInfoResponse } from 'src/app/subscription/subscription-types';
import { meActions } from 'src/common/state/me/me-actions';
import type { MeState } from 'src/common/state/me/me-types';
import type { PrivacySettings } from 'src/common/types/privacy';
import type { UserOutput } from 'src/common/types/user';
import type { CreateVideoPayload } from 'src/dashboard/state/videos/videos-actions';
import type { VideoRepresentation } from 'src/dashboard/types';

import type { UploadFinishedData } from '../api/video';

export const fetchVideoAction = createAction<Error | {}>('FETCH_VIDEO');
export const clearVideosAction = createAction('CLEAR_VIDEOS');
export const clearVideoAction = createAction('CLEAR_VIDEO');

export const pollingUpdateReceivedAction = createAction<
  Array<VideoRepresentation>
>('POLLING_UPDATE_RECEIVED');

export type UploadActionPayload = {
  shortcode: string;
  upload_metadata: {};
  transcode_options: {};
  transcode_data?: UploadFinishedData;
  upload_time: number;
  upload_percent: number;
  percent: number;
  waitingToTranscode: boolean;
};
export const uploadAction = createAction<UploadActionPayload>('UPLOAD');

// TODO: Refactor to use UploadProgressData
export const uploadProgressAction = createAction<{
  shortcode: string;
  upload_percent: number;
  upload_speed: number;
  retries?: number;
}>('UPLOAD_PROGRESS');

export const retranscodeAction = createAction<{}>('RETRANSCODE');

export const shortcodeReceivedAction =
  createAction<CreateVideoPayload>('SHORTCODE_RECEIVED');

// TODO: Use a more complete type instead of UserOutput (MeState?)
export type FetchCurrentUserActionPayload =
  | Error
  | Partial<
      UserOutput & {
        isFetching: boolean;
        isFetchingBillingInfo?: boolean;
        fetchedBillingInfo?: boolean;
      } & Pick<MeState, 'flags' | 'no_trial' | 'version'>
    >
  // TODO: This is also strange (check subscription-saga.ts)
  | BillingInfoResponse;
export const fetchCurrentUserAction =
  createAction<FetchCurrentUserActionPayload>('FETCH_CURRENT_USER');

export const loginAction = createAction<
  | Error
  | {
      user_name: string;
      pro?: null | 'mlb';
      privacy_settings?: PrivacySettings;
    }
>('LOGIN');

export const editUserAction = meActions.editUser;

export type EditVideoActionPayload = {
  shortcode?: string;
  waitingToUpload?: boolean;
  status?: number;
  error?: string;
  thumbnail_url?: string | null;
  poster_url?: string | null;
  upload_percent?: number;
  percent?: number;
  isSaving?: boolean;
  title?: string;
  queued?: number;
  waitingToTranscode?: boolean;
  original_size?: number;
  original_name?: string;
  upload_source?: string;
};
export const editVideoAction =
  createAction<EditVideoActionPayload>('EDIT_VIDEO');

export type FetchStatsPayload = {
  live?: null | { count: number };
  hot?: null;
  plays?: null;
  pages?: null;
  articles?: never;
  interval?: never;
  domain?: string;
  shortcode?: string;
  trending?: never;
  videos?: null;
  vtrs?: null;
};
export const fetchStatsAction = createAction<Error | FetchStatsPayload>(
  'FETCH_STATS'
);

export const selectAction = createAction<
  Partial<VideoRepresentation> & Pick<VideoRepresentation, 'shortcode'>
>('SELECT');
export const deselectAction = createAction<{ shortcode: string }>('DESELECT');
export const deselectAllAction = createAction('DESELECT_ALL');

export const zoomAction = createAction('ZOOM');

export const extractUrlAction = createAction<
  | Error
  | {
      isFetching: boolean;
    }
  | { playback_url: string; mime: string; file: File }
>('EXTRACT_URL');
export const clearClipperAction = createAction('CLEAR_CLIPPER');

export const messageAction = createAction('MESSAGE');

export const subscribeAction = createAction<
  | Error
  | {
      purchasing: boolean;
      error?: boolean;
      message?: string | null;
    }
>('SUBSCRIBE');

export const updateCardAction = createAction<
  | Error
  | {
      purchasing: boolean;
      error?: string;
      message?: null | string;
    }
>('UPDATE_CARD');
