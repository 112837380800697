import React from 'react';
import {
  Route,
  Redirect,
  type RouterState,
  type RouteComponent,
} from 'react-router';

import { CATEGORY_SLUGS } from 'src/landing-new/slugs';

import { Pricing } from '../pricing/pricing';
import App from './app';
import Checkout from './checkout';
import Report from './report';

const getComponentAtPath =
  (importPromise: Promise<any>, componentName = 'default') =>
  (
    nextState: RouterState<any>,
    callback: (error: any, component: RouteComponent) => any
  ) =>
    importPromise.then((C) => callback(null, C[componentName]));

export const routes = [
  <Route path="/" component={(props) => <App {...props} />} />,
  <Route
    path="/blog"
    getComponent={getComponentAtPath(import('../blog/blog'), 'Blog')}
  />,
  <Route
    path="/blog/(:categorySlug)/(:postSlug)"
    getComponent={getComponentAtPath(
      import('../blog-item/blog-item'),
      'BlogItem'
    )}
  />,
  <Redirect from="/blog/(:categorySlug)" to="/blog" />,
  <Route path="/login" getComponent={getComponentAtPath(import('./login'))} />,
  <Route
    path="/signup"
    getComponent={getComponentAtPath(import('./signup'))}
  />,
  <Route
    path="/recover"
    getComponent={getComponentAtPath(import('./recover'))}
  />,
  <Redirect from="/upgrade" to="/checkout" />,
  <Route path="/checkout" component={Checkout} />,
  <Route path="/pricing" component={Pricing} />,
  <Route path="/report/(:shortcode)" component={Report} />,
  <Route
    path="/edit/(:shortcode)"
    getComponent={getComponentAtPath(import('./editor'))}
  />,
  <Route
    path="/thumbnail/(:shortcode)"
    getComponent={getComponentAtPath(import('./thumbnail'))}
  />,
  <Route
    path="/videos/(:shortcode)/details"
    getComponent={getComponentAtPath(import('./video-details'))}
  />,
  <Route
    path="/videos/(:shortcode)/analytics"
    getComponent={getComponentAtPath(import('./video-analytics'))}
  />,
  <Route
    path="/clipper"
    getComponent={getComponentAtPath(import('./clipper'))}
  />,
  <Route
    path="/clipper/*"
    getComponent={getComponentAtPath(import('./clipper'))}
  />,

  ...CATEGORY_SLUGS.map((category) => (
    <Route path={`/${category}/(:view)`} component={App} />
  )),

  <Route path="/(:view)" component={App} />,
  <Route path="/(:view)/(:shortcode)" component={App} />,
  <Route path="/(:view)/(:shortcode)/(:version)" component={App} />,
];
