import React from 'react';

import { Testimonials as TestimonialsOld } from 'src/landing/components/testimonials/testimonials';

import type { PageTestimonial } from '../../state/landing-types';
import './testimonials.css';

type TestimonialsProps = {
  testimonials: PageTestimonial[];
};

export const Testimonials = ({ testimonials }: TestimonialsProps) =>
  testimonials.length === 0 ? null : (
    <div className="testimonials_v2">
      <TestimonialsOld
        heading="Over 50 million users love Streamable worldwide"
        testimonials={testimonials}
      />
    </div>
  );
