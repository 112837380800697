import React from 'react';

export const SeparatorLine = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="537"
    height="446"
    className={className}
  >
    <path
      d="M 96.293 2 C 79.626 37.667 32.493 119 5.293 143 C -28.707 173 209.793 -12.944 297.293 61 C 368.293 121 228.626 293 154.293 368 C 249.959 323 459.893 275.2 534.293 444"
      fill="transparent"
      strokeWidth="4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray=""
    ></path>
  </svg>
);
