import React from 'react';

import { Flex } from 'src/common/components/flex/flex';
import { LogoMark } from 'src/common/components/logo-mark/logo-mark';
import { useEffectOnce } from 'src/common/hooks/use-effect-once';
import { Plan } from 'src/pricing/components/plan';
import { PLANS } from 'src/pricing/constants';
import { triggerGoogleAnalyticsEvent } from 'src/utils/trigger-google-analytics-event';

import { PricingLink } from './suggested-plan-pricing-link';
import './suggested-plan.css';

export type SuggestedPlanFreeProps = {
  callToActionLabel: string;
  withPricingLink?: boolean;
  triggerGtmEventOnMount?: boolean;
  onClick: () => void;
};

export const SuggestedPlanFree = ({
  callToActionLabel,
  onClick,
  withPricingLink = false,
  triggerGtmEventOnMount = true,
}: SuggestedPlanFreeProps) => {
  useEffectOnce(() => {
    if (triggerGtmEventOnMount) {
      triggerGoogleAnalyticsEvent('upsell_card_for_anonym_customers_rendered');
    }
  });

  return (
    <Flex as="article" className="suggested-plan">
      <Flex
        direction="row"
        alignItems="center"
        className="suggested-plan__title-container"
      >
        <LogoMark />
        <Plan.Title>{`Streamable ${PLANS.free.title}`}</Plan.Title>
      </Flex>
      <Plan.Price value={PLANS.free.cost} />
      <Plan.Description>{PLANS.free.description}</Plan.Description>
      <Plan.FeaturesList>
        {PLANS.free.items.map((item, i) => (
          <Plan.FeatureItem key={`label_${i}`}>{item.name}</Plan.FeatureItem>
        ))}
      </Plan.FeaturesList>
      <Plan.CallToAction onClick={onClick}>
        {callToActionLabel}
      </Plan.CallToAction>
      {withPricingLink && <PricingLink />}
    </Flex>
  );
};
