import React, { useEffect, useRef } from 'react';

import { useIsElementInViewport } from '../../../common/hooks/use-is-element-in-viewport';
import { CustomerLogosLineExpression } from '../customer-logos-line-expression/customer-logos-line-expression';
import { LOGOS } from './contants';
import './customer-logos.css';

export const CustomerLogos = ({ withLineGraphic = true }) => {
  const logosContainerRef = useRef(null);
  const isLogosContainerInViewport = useIsElementInViewport(logosContainerRef);

  useEffect(() => {
    if (logosContainerRef.current && isLogosContainerInViewport) {
      logosContainerRef.current.classList.add(
        'customer-logos-v2__logos-container--appear'
      );
    }
  }, [isLogosContainerInViewport]);

  return (
    <section className="customer-logos-v2">
      <div
        className="customer-logos-v2__logos-container"
        ref={logosContainerRef}
      >
        {LOGOS.map((logo, i) => (
          <img key={`logo-${i}`} src={logo.src} alt={logo.label} />
        ))}
      </div>

      {withLineGraphic && (
        <CustomerLogosLineExpression className="customer-logos-v2__line-expression" />
      )}
    </section>
  );
};
