import { createSelector } from '@reduxjs/toolkit';
import { pipe, prop } from 'ramda';
import { VideoRepresentation } from 'src/dashboard/types';
import { getSelectedVideos } from 'src/misc/selectors';
import type { RootState } from 'src/redux';

import { selectLabelsAsArray } from '../labels/labels-selectors';
import { getVideosForActiveLabel } from '../videos/videos-selectors';

const selectAllAssignLabelsState = (state: RootState) => state.assignLabels;

const selectAllAssignLabelsModalState = pipe(
  selectAllAssignLabelsState,
  prop('assignLabelsModal')
);

const selectAllDragAndDropState = pipe(
  selectAllAssignLabelsState,
  prop('dragAndDrop')
);

export const selectIsAssignLabelsModalShowing = pipe(
  selectAllAssignLabelsModalState,
  prop('isShowing')
);

export const selectIsBulkAssignmentToLabels = pipe(
  selectAllAssignLabelsModalState,
  prop('isBulk')
);

export const selectShortcodesToAssignLabelsTo = pipe(
  selectAllAssignLabelsModalState,
  prop('shortcodes')
);

const selectCurrentPageOrSelectedVideos = createSelector(
  [
    selectShortcodesToAssignLabelsTo,
    getVideosForActiveLabel,
    getSelectedVideos,
    selectIsBulkAssignmentToLabels,
  ],
  (shortcodes, currentPageVideos, selectedVideos, isBulk) => {
    let videos: VideoRepresentation[] = [];

    if (isBulk) {
      videos = selectedVideos;
    } else {
      videos = currentPageVideos.filter(
        ({ shortcode }) => shortcode && shortcodes.includes(shortcode)
      );
    }
    return videos;
  }
);

const selectCurrentPageOrSelectedVideosLength = createSelector(
  selectCurrentPageOrSelectedVideos,
  (videos) => videos.length
);

const selectVideoLabelCounts = createSelector(
  selectCurrentPageOrSelectedVideos,
  (videos) =>
    videos
      .flatMap((video) => video.labels)
      .reduce(
        (result, label) => {
          if (label) {
            if (result[label?.id]) {
              result[label.id]++;
            } else {
              result[label.id] = 1;
            }
          }
          return result;
        },
        {} as { [key: number]: number }
      )
);

export const buildLabelSelectionState = createSelector(
  [
    selectCurrentPageOrSelectedVideosLength,
    selectVideoLabelCounts,
    selectLabelsAsArray,
  ],
  (videosLength, counts, labels) =>
    labels.map((label) => {
      const count = counts[label.id] || 0;
      return {
        ...label,
        checked: count === videosLength,
        indeterminate: count > 0 && count < videosLength,
      };
    }),
  {
    devModeChecks: {
      // Investigate why this still polutes the tests output
      inputStabilityCheck: 'never',
    },
  }
);

export const selectSaveLabelAssignmentErrorMessage = pipe(
  selectAllAssignLabelsModalState,
  prop('errorMessage')
);

export const selectIsSavingLabelAssignmentLoading = pipe(
  selectAllAssignLabelsModalState,
  prop('isLoading')
);

export const selectIsCreatingLabel = pipe(
  selectAllAssignLabelsModalState,
  prop('isCreatingLabel')
);

export const selectIsDragging = pipe(
  selectAllDragAndDropState,
  prop('isDragging')
);
export const selectDragAndDropErrorAlert = pipe(
  selectAllDragAndDropState,
  prop('errorAlert')
);
