import React, { useRef, useState } from 'react';

import { BACKGROUND_VIDEOS, TICKER_CONFIG } from '../../constants';
import { useAnimateSlider } from '../../hooks/use-animate-slider';
import { useLoadVideos } from '../../hooks/use-load-videos';
import './hero-video-ticker.css';

export const HeroVideoTicker = () => {
  const [speed, setSpeed] = useState(TICKER_CONFIG.speed.fast);
  const [isPlaying, setIsPlaying] = useState(false);
  const sliderRef = useRef(null);

  const { areVideosLoaded } = useLoadVideos(sliderRef, () => {
    setIsPlaying(true);
  });
  useAnimateSlider({ sliderRef, areVideosLoaded, speed, isPlaying });

  const onMouseEnter = () => {
    setSpeed(TICKER_CONFIG.speed.slow);
  };

  const onMouseLeave = () => {
    setSpeed(TICKER_CONFIG.speed.fast);
  };

  return (
    <div
      className="hero-video-ticker"
      ref={sliderRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {[0, 1].map((i) =>
        BACKGROUND_VIDEOS.map((video, j) => (
          <video
            key={`video-ticker-${i}-${j}`}
            className="hero-video-ticker__video"
            role="img"
            autoPlay
            loop
            muted
            playsInline
            aria-hidden="true"
            data-src={video}
          />
        ))
      )}
    </div>
  );
};
