import { PageLongTextSection } from 'src/landing-new/state/landing-types';
import { Markdown } from 'src/misc/markdown';

import './long-text-section-component.css';

export const LongTextSection = ({
  longTextSection,
}: {
  longTextSection?: PageLongTextSection;
}) => {
  if (!longTextSection) {
    return null;
  }

  const { title, content } = longTextSection;

  return (
    <div className="long-text-section-container">
      <section className="long-text-section">
        {title && title !== '' && (
          <h2 className="long-text-section__title">{title}</h2>
        )}
        <div className="long-text-section__content">
          <Markdown withRemark>{content}</Markdown>
        </div>
      </section>
    </div>
  );
};
