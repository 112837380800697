import React, { useState, useEffect, type ReactNode } from 'react';
import { connect } from 'react-redux';

import type { MaterialIcon } from 'material-icons';
import moment from 'moment';
import { Button } from 'src/common/components/button/button';
import { Tooltip } from 'src/common/components/tooltip/tooltip';
import { dashboardUpgradeActions } from 'src/dashboard/state/upgrade/upgrade-actions';
import { VideoRepresentation } from 'src/dashboard/types';
import { RootState } from 'src/redux';
import { getSelectFlag } from 'src/utils/selectors/select-flags';

import { ANONYMOUS_USER } from '../../../selectors/users';
import { useShouldRestrictVideoPlaybackByOwnerPlan } from '../hooks/should-restrict-playback-by-owner-plan';
import { willExpireSoon, EXPIRATION_TIME_BY_PLAN } from '../utils';
import './video-notifications.scss';

const REFRESH_TIMESTAMPS_IN_MS = 30 * 1000;

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: '1 minute',
    mm: '%d minutes',
    h: '1 hour',
    hh: '%d hours',
    d: '1 day',
    dd: '%d days',
    w: '1 week',
    ww: '%d weeks',
    M: '1 month',
    MM: '%d months',
    y: '1 year',
    yy: '%d years',
  },
});

type VideoNotificationProps = {
  type: 'warn' | 'danger';
  message: string;
  icon?: Extract<MaterialIcon, 'warning' | 'block'>;
  tooltip?: ReactNode;
};

const VideoNotification = ({
  type,
  message,
  icon,
  tooltip,
}: VideoNotificationProps) => {
  const notification = (
    <div className={`video-notification video-notification--${type}`}>
      {icon && <span className="material-icons icon">{icon}</span>}
      {message}
    </div>
  );

  if (tooltip) {
    return (
      <Tooltip trigger={notification} interactive={true}>
        {tooltip}
      </Tooltip>
    );
  }

  return notification;
};

type OwnProps = {
  userTier: string;
  video: VideoRepresentation;
};

type Props = OwnProps & {
  shouldRespectRestrictedPlaybackFlag: string | boolean;
  onTriggerUpgrade: () => void;
};

const VideoNotificationsContainer = ({
  userTier,
  video,
  shouldRespectRestrictedPlaybackFlag,
  onTriggerUpgrade,
}: Props) => {
  const shouldRespectRestrictedPlaybackPlanLimit =
    useShouldRestrictVideoPlaybackByOwnerPlan(
      video.date_added!,
      shouldRespectRestrictedPlaybackFlag
    );
  const { date_added } = video;
  // @ts-ignore
  const expirationPeriod: null | number = EXPIRATION_TIME_BY_PLAN[userTier];

  const [, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, REFRESH_TIMESTAMPS_IN_MS);

    return () => clearInterval(interval);
  }, [date_added, userTier]);

  const momentDateAdded = moment(new Date(date_added! * 1000));
  const isDateInFuture = momentDateAdded.isAfter();

  if (video.stale) {
    return <VideoNotification type="danger" message="Pending Deletion" />;
  }

  const notifications: VideoNotificationProps[] = [];

  if (
    video.plan_limits?.should_restrict_playback &&
    shouldRespectRestrictedPlaybackPlanLimit
  ) {
    const exceedsSize = video.plan_limits.is_exceeding_free_plan_size_limit;
    const tooltipLimitText = exceedsSize
      ? `Your video exceeds the 250MB max 
    size limit for your plan. `
      : `Your video exceeds the 10 minute max 
    duration limit for your plan. `;

    const tooltip = (
      <p className="video-notification-tooltip-content">
        {tooltipLimitText}
        <Button
          variant="link"
          className="video-notification-tooltip-content__upgrade-btn"
          onClick={onTriggerUpgrade}
        >
          Upgrade
        </Button>{' '}
        to unlock playback.
      </p>
    );

    notifications.push({
      type: 'warn',
      message: 'Restricted',
      icon: 'block',
      tooltip,
    });
  }

  if (
    userTier === ANONYMOUS_USER &&
    !isDateInFuture &&
    willExpireSoon(date_added, userTier)
  ) {
    const timeToExpire = momentDateAdded.add(expirationPeriod, 'h').fromNow();
    notifications.push({
      type: 'warn',
      message: `Expires ${timeToExpire}`,
      icon: 'warning',
    });
  }

  return (
    <div className="video-notifications-container">
      {notifications.map((notification, index) => (
        <VideoNotification key={index} {...notification} />
      ))}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  shouldRespectRestrictedPlaybackFlag: getSelectFlag(
    'restrict-free-videos-playback-by-owner-plan'
  )(state),
});

const mapDispatchToProps = {
  onTriggerUpgrade: dashboardUpgradeActions.upgradeModal.triggered,
};

export const VideoNotifications = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoNotificationsContainer);
