import { apiRequest } from 'src/utils/api-request';

import { ChangePlanPreviewInvoice, PlanFields } from '../types';

export const fetchChangePlanPreviewInvoice = (targetPlanId: string) =>
  apiRequest<ChangePlanPreviewInvoice>({
    path: `/me/subscription/change_preview?planId=${targetPlanId}`,
  });

export const confirmPlanChange = (targetPlanId: string) =>
  apiRequest<{ plan: PlanFields }>({
    path: `/me/subscription`,
    method: 'POST',
    body: { planId: targetPlanId },
  });
