import { useState } from 'react';

import type { MeState } from 'src/common/state/me/me-types';
import type { FilePreset, FileRepresentation } from 'src/common/types/file';
import type { VideoRepresentation } from 'src/dashboard/types';

import { getExperiment } from '../../../utils/get-experiment';
import UpgradeModal from '../UpgradeModal';
import { FileListItem } from './FileListItem';
import './fileList.css';

const ORDER: Array<FilePreset> = ['mp4-high', 'mp4', 'mp4-mobile'];

type FileListProps = {
  video: VideoRepresentation;
  handleUpsellClick: (source: string) => void;
  me: MeState;
};
export const FileList = ({ video, me, handleUpsellClick }: FileListProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const noTrial = getExperiment('noFreeTrials') ? true : me.no_trial;

  const availableFiles = ORDER.filter((preset) => video.files[preset])
    .map((preset) => ({
      ...video.files[preset],
      preset,
    }))
    .filter(Boolean) as FileRepresentation[];

  const disabledResolutions = video.available_file_resolutions.filter(
    (preset) => !availableFiles.some((file) => file.preset === preset)
  );

  return (
    <div>
      <div className="file-list">
        {disabledResolutions.map((preset) => (
          <FileListItem
            key={preset}
            hasPlan={!!me.plan}
            disabled={true}
            file={{ preset, height: 2160 }}
            shortcode={video.shortcode!}
            showUpgradeModal={() => setModalOpen(true)}
            handleUpsellClick={handleUpsellClick}
          />
        ))}
        {availableFiles.map((file) => (
          <FileListItem
            key={file.preset}
            hasPlan={!!me.plan}
            file={file}
            shortcode={video.shortcode!}
            showUpgradeModal={() => setModalOpen(true)}
            handleUpsellClick={handleUpsellClick}
          />
        ))}
      </div>

      <UpgradeModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        darkMode={me.dark_mode}
        noTrial={noTrial}
        header="Streamable Basic Required"
        subheader="Upgrade now to access MP4 video URLs."
        srcInternal="mp4url"
      />
    </div>
  );
};
