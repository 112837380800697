import { ActiveLabelId } from './labels-reducer';

export const isStaticLabel = (label: ActiveLabelId) => {
  const staticLabels: ActiveLabelId[] = ['all-videos', 'unlabelled-videos'];
  return staticLabels.includes(label);
};

export const getQueryParamsForActiveLabel = (activeLabelId: ActiveLabelId) => {
  const isViewingUnlabelledVideos = activeLabelId === 'unlabelled-videos';
  const isViewingAllVideos = activeLabelId === 'all-videos';

  if (isViewingAllVideos) {
    return '';
  }

  if (isViewingUnlabelledVideos) {
    return new URLSearchParams({ excludeAnyWithLabels: 'true' }).toString();
  }

  return new URLSearchParams({ labels: activeLabelId.toString() }).toString();
};
