import { useState } from 'react';

import {
  faClose,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'src/common/components/button/button';
import { getCookie, setCookie } from 'src/misc/cookies';

import './top-banner-component.css';

type TopBannerComponentProps = {
  banner?:
    | { enabled: false }
    | {
        enabled: true;
        message: string;
        type: 'info' | 'warning';
        icon: 'exclamation-triangle' | null;
        dismissableKey: string | null;
      };
};

export const TopBannerComponent = ({ banner }: TopBannerComponentProps) => {
  const [isDismissed, setIsDismissed] = useState(false);

  if (!banner || !banner.enabled) {
    return null;
  }

  if (banner.dismissableKey && getCookie(banner.dismissableKey) === 'true') {
    return null;
  }

  if (isDismissed) {
    return null;
  }

  return (
    <div className={`banner-bar banner-bar--${banner.type}`}>
      <div className="banner-bar__icon">
        {banner.icon === 'exclamation-triangle' && (
          <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
        )}
      </div>
      <div>{banner.message}</div>
      {banner.dismissableKey && (
        <Button
          onClick={() => {
            setCookie(banner.dismissableKey!, 'true', 365);
            setIsDismissed(true);
          }}
          variant="icon"
          className="top_banner__close-btn"
        >
          <FontAwesomeIcon icon={faClose} size="lg" title="Dismiss" />
        </Button>
      )}
    </div>
  );
};
