import type { PayloadAction } from '@reduxjs/toolkit';
import { fetchCurrentUserAction } from 'src/actions';
import { subscriptionActions } from 'src/app/subscription/subscription-actions';
import { checkoutActions } from 'src/checkout/state/checkout-actions';
import { dashboardUpsellActions } from 'src/dashboard/state/upsell/upsell-actions';
import { videoPrivacyActions } from 'src/dashboard/state/video-privacy/video-privacy-actions';

import { ANNUAL_CADENCE, MONTHLY_CADENCE, DEFAULT_CADENCE } from '../constants';
import type {
  AvailablePlan,
  ChangePlanPreviewInvoice,
  PlanCadence,
} from '../types';
import { pricingActions } from './pricing-actions';

export type PricingState = {
  availablePlans: AvailablePlan[] | null;
  targetCadence: PlanCadence;
  changePlanModal: {
    isError: boolean;
    isLoading: boolean;
    isShowing: boolean;
    errorMessage?: string;
    targetPlanId: string | null;
    previewInvoice: ChangePlanPreviewInvoice | null;
  };
};

const getChangeModalModalState = () => ({
  isError: false,
  isLoading: false,
  isShowing: false,
  errorMessage: undefined,
  targetPlanId: null,
  previewInvoice: null,
});

const initialState: PricingState = {
  availablePlans: null,
  targetCadence: DEFAULT_CADENCE,
  changePlanModal: getChangeModalModalState(),
};

export const pricingReducer = (
  state = initialState,
  action: PayloadAction<any>
): PricingState => {
  switch (action.type) {
    case subscriptionActions.subscriptionInfoFetched.type: {
      return {
        ...state,
        availablePlans: action.payload.availablePlans,
      };
    }
    case pricingActions.cadenceSwitch.changeTargetCadenceToggle.clicked.type:
    case checkoutActions.billingDetails.cadenceSwitch.clicked.type: {
      return {
        ...state,
        targetCadence: action.payload,
      };
    }
    case fetchCurrentUserAction.type: {
      if (typeof action.payload.plan_annual === 'boolean') {
        return {
          ...state,
          targetCadence: action.payload.plan_annual
            ? ANNUAL_CADENCE
            : MONTHLY_CADENCE,
        };
      }
      return state;
    }

    case dashboardUpsellActions.storageUpsellModal.triggered.type: {
      return {
        ...state,
        changePlanModal: {
          ...state.changePlanModal,
          targetPlanId: action.payload,
        },
      };
    }

    case dashboardUpsellActions.storageUpsellModal.upgradeButton.clicked.type: {
      return {
        ...state,
        changePlanModal: {
          ...state.changePlanModal,
          targetPlanId: action.payload,
          isShowing: true,
        },
      };
    }

    case videoPrivacyActions.videoPrivacyModal.restrictionSection
      .domainRestrictions.upsellClicked.type:
    case pricingActions.planColumn.changePlanButton.clicked.type: {
      return {
        ...state,
        changePlanModal: {
          ...state.changePlanModal,
          targetPlanId: action.payload,
          isShowing: true,
        },
      };
    }
    case pricingActions.changePlanModal.busy.type: {
      return {
        ...state,
        changePlanModal: {
          ...state.changePlanModal,
          errorMessage: undefined,
          isError: false,
          isLoading: true,
        },
      };
    }
    case pricingActions.changePlanModal.close.type:
    case pricingActions.changePlanModal.changeFulfilled.type: {
      return {
        ...state,
        changePlanModal: {
          ...state.changePlanModal,
          errorMessage: undefined,
          targetPlanId: null,
          previewInvoice: null,
          isLoading: false,
          isShowing: false,
          isError: false,
        },
      };
    }
    case pricingActions.changePlanModal.previewInvoiceFetched.type: {
      return {
        ...state,
        changePlanModal: {
          ...state.changePlanModal,
          previewInvoice: action.payload.invoice,
          errorMessage: undefined,
          isLoading: false,
          isError: false,
        },
      };
    }
    case pricingActions.changePlanModal.previewInvoiceFetchFailed.type:
    case pricingActions.changePlanModal.changeFailed.type: {
      return {
        ...state,
        changePlanModal: {
          ...state.changePlanModal,
          errorMessage: action.payload,
          isLoading: false,
          isError: true,
        },
      };
    }
    default:
      return state;
  }
};
