import { createReducer } from '@reduxjs/toolkit';

import { embedPreviewActions } from './embed-preview-actions';
import type {
  EmbedContainerOptions,
  EmbedPlayerOptions,
} from './embed-preview-types';

export type EmbedPreviewState = {
  embedPreviewModal: {
    isOpen: boolean;
    playerOptions: EmbedPlayerOptions;
    codeCopied: boolean;
    containerOptions: EmbedContainerOptions;
    video: {
      height: number;
      width: number;
      shortcode: string;
    } | null;
  };
};

const initialState: EmbedPreviewState = {
  embedPreviewModal: {
    isOpen: false,
    playerOptions: {
      autoplay: false,
      muted: false,
      controls: true,
      loop: true,
    },
    codeCopied: false,
    containerOptions: {
      size: 'responsive',
      height: null,
      width: null,
    },
    video: null,
  },
};

export const embedPreviewReducer = createReducer(initialState, (builder) => {
  builder.addCase(
    embedPreviewActions.embedPreviewModal.opened,
    (state, { payload: video }) => {
      state.embedPreviewModal.isOpen = true;

      state.embedPreviewModal.codeCopied = false;
      state.embedPreviewModal.video = video;
      state.embedPreviewModal.playerOptions = {
        autoplay: false,
        muted: false,
        controls: true,
        loop: true,
      };
      state.embedPreviewModal.containerOptions = {
        size: 'responsive',
        height: video.height,
        width: video.width,
      };
    }
  );

  builder.addCase(
    embedPreviewActions.embedPreviewModal.changeContainerSizeType,
    (state, { payload: sizeType }) => {
      state.embedPreviewModal.containerOptions.size = sizeType;
    }
  );

  builder.addCase(
    embedPreviewActions.embedPreviewModal.changeContainerHeight,
    (state, { payload: height }) => {
      if (state.embedPreviewModal.containerOptions.size === 'responsive') {
        return;
      }

      if (isNaN(height)) {
        state.embedPreviewModal.containerOptions.height =
          state.embedPreviewModal.video!.height;
      } else {
        state.embedPreviewModal.containerOptions.height = height;
      }
    }
  );

  builder.addCase(
    embedPreviewActions.embedPreviewModal.changeContainerWidth,
    (state, { payload: width }) => {
      if (state.embedPreviewModal.containerOptions.size === 'responsive') {
        return;
      }

      if (isNaN(width)) {
        state.embedPreviewModal.containerOptions.width =
          state.embedPreviewModal.video!.width;
      } else {
        state.embedPreviewModal.containerOptions.width = width;
      }
    }
  );

  builder.addCase(embedPreviewActions.embedPreviewModal.codeCopied, (state) => {
    state.embedPreviewModal.codeCopied = true;
  });

  builder.addCase(
    embedPreviewActions.embedPreviewModal.enableCodeCopy,
    (state) => {
      state.embedPreviewModal.codeCopied = false;
    }
  );

  builder.addCase(
    embedPreviewActions.embedPreviewModal.togglePlayerOption,
    (state, { payload: option }) => {
      const newValue = !state.embedPreviewModal.playerOptions[option];
      state.embedPreviewModal.playerOptions[option] = newValue;
    }
  );

  builder.addCase(embedPreviewActions.embedPreviewModal.closed, (state) => {
    state.embedPreviewModal.isOpen = false;
    state.embedPreviewModal.video = null;
  });
});
