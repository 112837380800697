import React, { type PropsWithChildren, useState } from 'react';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  StripePaymentElement,
  type StripePaymentElementOptions,
} from '@stripe/stripe-js';
import type { IntentType } from 'src/checkout/state/checkout-types';
import { Button } from 'src/common/components/button/button';
import { Flex } from 'src/common/components/flex/flex';

import PaymentCollectionSkeleton from '../payment-collection-skeleton';
import './payment-collection.css';

type PaymentCollectionComponentProps = PropsWithChildren<{
  intentType: IntentType | null;
  noTrial: boolean;
  transitionToFailed: (message: string) => any;
}>;

export const PaymentCollectionComponent = ({
  children,
  intentType,
  noTrial,
  transitionToFailed,
}: PaymentCollectionComponentProps) => {
  const [isInitializing, setIsInitializing] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const paymentElementOptions = {
    layout: 'tabs',
    fields: {
      billingDetails: 'auto',
    },
    terms: {
      card: 'never',
    },
  } as StripePaymentElementOptions;

  const handleElementReady = (element: StripePaymentElement) => {
    setIsInitializing(false);
    element.focus();
  };

  const handleClick = async () => {
    if (!stripe || !elements) {
      transitionToFailed(
        'The payment form is not ready yet. Please try again shortly.'
      );
      return;
    }

    setIsSubmitting(true);

    const confirmationFunction =
      intentType === 'setup' ? stripe.confirmSetup : stripe.confirmPayment;
    const { error } = await confirmationFunction({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
      redirect: 'always',
    });

    /**
     * From https://stripe.com/docs/js/setup_intents/confirm_setup:
     * When the error type is card_error or validation_error,
     * you can display the error message in error.message directly to your user.
     * An error type of invalid_request_error could be due to an invalid request
     * or 3DS authentication failures
     */
    if (error) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        transitionToFailed(
          `${error.message} Please try a different payment method.`
        );
      } else {
        transitionToFailed(
          `We could not process your payment. Please try a different payment method.`
        );
      }

      setIsSubmitting(false);
    } else {
      /**
       * If there is no error, the redirect will happen automatically
       * so there is no need to do anything here, including `setIsSubmitting(false)`
       */
    }
  };

  return (
    <Flex className="payment-collection">
      <PaymentElement
        options={paymentElementOptions}
        onReady={handleElementReady}
      />
      {isInitializing ? (
        <PaymentCollectionSkeleton />
      ) : (
        <>
          {children}
          <Button
            color="primary"
            onClick={handleClick}
            disabled={isSubmitting || isInitializing}
            className="payment-collection__submit-button"
          >
            {isSubmitting && (
              <Flex alignItems="center" className="payment-collection__loader">
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  className="payment-collection__loader__icon"
                  width={16}
                />
              </Flex>
            )}
            {noTrial ? 'Upgrade Now' : 'Start Free Trial'}
          </Button>
        </>
      )}
    </Flex>
  );
};
