import React from 'react';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'src/common/components/flex/flex';
import { Text } from 'src/common/components/text/text';

import './labels-loader.css';

type LabelsLoaderProps = {
  isLoading: boolean;
};

export const LabelsLoader = ({ isLoading }: LabelsLoaderProps) => {
  if (!isLoading) {
    return null;
  }

  return (
    <Flex direction="row" alignItems="center" className="labels-loader">
      <Flex
        direction="row"
        justifyContent="center"
        className="labels-loader__icon-container"
      >
        <FontAwesomeIcon
          icon={faCircleNotch}
          className="labels-loader__icon"
          width={16}
        />
      </Flex>
      <Text className="labels-loader__text">Loading...</Text>
    </Flex>
  );
};
