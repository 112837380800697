import { createAction } from '@reduxjs/toolkit';

export const dashboardCollectionActions = {
  createCollection: {
    fulfilled: createAction('[Create Collection] Fulfilled'),
    failed: createAction('[Create Collection] Failed'),
  },
  videoListHeader: {
    shareButton: {
      clicked: createAction<Array<string>>(
        '[Video List Header] Share Button Clicked'
      ),
    },
  },
};
