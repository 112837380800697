import React from 'react';

import { Flex } from 'src/common/components/flex/flex';
import { Text } from 'src/common/components/text/text';
import { NavigationSection } from 'src/landing-new/types';

import { FooterLink } from '../footer-link';
import './footer-section.css';

type FooterSectionProps = {
  sections: NavigationSection[];
};

export const FooterSection = ({ sections }: FooterSectionProps) => (
  <>
    {sections.map((section) => (
      <section
        key={`footer-section-${section.title}`}
        className="footer-v3-section"
      >
        <Text as="h3" className="footer-v3-section__title">
          {section.title}
        </Text>
        <Flex className="footer-v3-section__navigation-list">
          {section.links.map((link, i) => (
            <li
              key={`footer-link-${link.href || i}`}
              className="footer-v3-section__navigation-list__item"
            >
              <FooterLink {...link} />
            </li>
          ))}
        </Flex>
      </section>
    ))}
  </>
);
