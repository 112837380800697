import { connect } from 'react-redux';

import {
  selectCurrentPlan,
  selectNextPlan,
  selectWillRenewOnDifferentPlan,
  selectIsStripeUser,
} from 'src/app/subscription/subscription-selectors';
import {
  selectIsGuest,
  selectHasPaidPlan,
  selectNoTrial,
} from 'src/common/state/me/me-selectors';
import { pricingActions } from 'src/pricing/state/pricing-actions';
import { selectTargetCadence } from 'src/pricing/state/pricing-selectors';
import type { RootState } from 'src/redux';
import { getExperiment } from 'src/utils/get-experiment';

import {
  SelfServePlanComponent,
  SelfServePlanOwnProps,
} from './self-serve-plan-component';

const mapStateToProps = (state: RootState, _: SelfServePlanOwnProps) => ({
  currentPlan: selectCurrentPlan(state),
  targetCadence: selectTargetCadence(state),
  subscriptionOnRenew: selectNextPlan(state),
  hasPaidPlan: selectHasPaidPlan(state),
  isGuest: selectIsGuest(state),
  offerTrial: getExperiment('noFreeTrial') ? false : !selectNoTrial(state),
  willRenewOnDifferentPlan: selectWillRenewOnDifferentPlan(state),
  isPayPalUser: !selectIsStripeUser(state),
});

const mapDispatchToProps = {
  enterChangeFlow: pricingActions.planColumn.changePlanButton.clicked,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelfServePlanComponent);
