import React from 'react';

import { Switch, SwitchOption } from 'src/common/components/switch/switch';
import { ANNUAL_CADENCE, MONTHLY_CADENCE } from 'src/pricing/constants';
import { PlanCadence } from 'src/pricing/types';

export type CadenceSwitchComponentProps = {
  changeCadence: (cadence: PlanCadence) => void;
  targetCadence: PlanCadence;
  options?: SwitchOption[];
};

export const CadenceSwitchComponent = ({
  targetCadence,
  changeCadence,
  options = [
    {
      label: 'Monthly',
      value: MONTHLY_CADENCE,
    },
    {
      label: 'Yearly',
      value: ANNUAL_CADENCE,
    },
  ],
}: CadenceSwitchComponentProps) => {
  const handleChange = () =>
    changeCadence(
      targetCadence === MONTHLY_CADENCE ? ANNUAL_CADENCE : MONTHLY_CADENCE
    );

  return (
    <Switch
      name="cadenceSwitch"
      options={options}
      onChange={handleChange}
      value={targetCadence}
    />
  );
};
