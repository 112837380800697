import React, { Component } from 'react';
import FaExclamationTriangle from 'react-icons/lib/fa/exclamation-triangle';

import classNames from 'classnames';

import LoadingImage from '../../assets/logo-white-transparent.gif';
import { getPlaybackUrl, getFileList } from '../utilities';
import './player.css';

// NOTE: Please not that this is NOT the player used on video pages,
//       for that navigate to streamable-app repository

export default class Player extends Component {
  constructor() {
    super();

    this.state = {
      activity: false,
    };
  }

  userActivity() {
    this.setState({ activity: true });
    if (this.activityTimeout) {
      clearTimeout(this.activityTimeout);
    }
    this.activityTimeout = setTimeout(() => {
      this.setState({ activity: false });
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.refVideo &&
      getPlaybackUrl(prevProps.video) !== getPlaybackUrl(this.props.video)
    ) {
      this.refVideo.load();
      this.refVideo.play();
    }
  }

  componentDidMount() {
    this.userActivity();

    if (!window.VideoPlayer || !this.props.video || !this.refVideo) {
      this.waitingTimeout = setTimeout(this.initPlayer, 500);
      return;
    }

    this.playerObject = new window.VideoPlayer(this.props.video, this.refVideo);
    this.playerObject.initialize();
  }

  componentWillUnmount() {
    if (this.activityTimeout) {
      clearTimeout(this.activityTimeout);
    }
    if (this.waitingTimeout) {
      clearTimeout(this.waitingTimeout);
    }

    if (this.playerObject) {
      this.playerObject.destroy();
    }
  }

  togglePlay() {
    if (this.refVideo.paused) {
      this.refVideo.play();
    } else {
      this.refVideo.pause();
    }
  }

  render() {
    const source = getPlaybackUrl(this.props.video);
    if (!source || this.props.video.status < 2) {
      return (
        <div
          className="player"
          style={
            this.props.video.thumbnail_url
              ? {}
              : {
                  height: '300px',
                  width: '480px',
                  backgroundColor: 'black',
                }
          }
          onClick={(e) => {
            if (this.props.onClick) {
              this.props.onClick(e);
            }
          }}
        >
          {this.props.video.thumbnail_url ? (
            <img
              alt="Progress"
              role="presentation"
              className="progress-image"
              src={this.props.video.thumbnail_url}
            />
          ) : (
            ''
          )}
          <div id="progress-overlay">
            <div className="progress-spinner">
              <img src={LoadingImage} role="presentation" alt="Loading" />
            </div>
            <div id="progress-bar-track">
              <div
                id="progress-bar-value"
                style={{ width: `${this.props.video.percent || 0}%` }}
              />
            </div>
            <div className="error-indicator">
              <FaExclamationTriangle />
              <div className="error-message">{this.props.video.error}</div>
            </div>
          </div>
        </div>
      );
    }

    const extraVideoAttrs = {};
    if (this.props.muted) {
      extraVideoAttrs.muted = true;
    }
    if (!this.props.noautoplay) {
      extraVideoAttrs.autoPlay = true;
    }
    if (this.props.mobile) {
      extraVideoAttrs.controls = true;
    }
    if (!this.props.video.allow_download) {
      extraVideoAttrs.controlsList = 'nodownload';
    }

    let fileList = getFileList(this.props.video);
    const isFullscreen = window.screenfull && window.screenfull.isFullscreen;

    return (
      <div
        className={classNames('player', {
          fullscreen: isFullscreen,
          activity: this.state.activity,
          'hide-audio': this.props.video.audio_channels === 0,
        })}
        onMouseOver={() => this.userActivity()}
        onMouseDown={() => this.userActivity()}
        onClick={(e) => {
          if (this.props.onClick) {
            this.props.onClick(e);
          }
        }}
      >
        <video
          className="video-player-tag"
          onContextMenu={(e) => {
            e.preventDefault();
            return false;
          }}
          playsInline
          poster={this.props.video._poster_url || this.props.video.poster_url}
          loop
          ref={(c) => {
            this.refVideo = c;
          }}
          {...extraVideoAttrs}
        >
          <source className="mp4-source" src={source} />
        </video>
        {this.props.me && this.props.me.watermark_url ? (
          <a
            className="watermark"
            href={this.props.me.watermark_link}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              pointerEvents: this.props.me.watermark_link ? 'auto' : 'none',
            }}
          >
            <img alt="Watermark" src={this.props.me.watermark_url} />
          </a>
        ) : (
          ''
        )}
        <div
          id="spinner"
          className="player-spinner"
          style={{
            background: this.props.video.color,
            color: this.props.video.color,
          }}
        ></div>
        {this.props.mobile ? (
          ''
        ) : (
          <div className="player-controls" id="player-controls-footer">
            <div className="player-controls-left">
              <div id="player-play-pause">
                <label>
                  <input
                    type="checkbox"
                    id="player-play-pause-button-input"
                    className="player-play-pause-button-input"
                  ></input>
                  <span
                    id="player-play-pause-button"
                    style={{
                      borderLeft: `16px double ${this.props.video.color}`,
                    }}
                  ></span>
                </label>
              </div>
              <div id="player-volume" className="player-volume">
                <div
                  id="player-volume-control"
                  className="player-volume-control"
                >
                  <div id="player-volume-track" className="player-volume-track">
                    <svg viewBox="0 0 60 60" height="40px" width="40px">
                      <path
                        transform="translate(52.588 20.551)"
                        d="m1.7857 20.104h-48.748l48.748-21.311-0.0003 21.311z"
                        fill={this.props.video.color}
                      />
                    </svg>
                  </div>
                  <div id="player-volume-value" className="player-volume-value">
                    <svg viewBox="0 0 60 60" height="40px" width="40px">
                      <path
                        transform="translate(52.588 20.551)"
                        d="m1.7857 20.104h-48.748l48.748-21.311-0.0003 21.311z"
                        fill={this.props.video.color}
                      />
                    </svg>
                  </div>
                </div>
                <div
                  role="button"
                  id="player-mute-button"
                  className="player-mute-button"
                >
                  <svg
                    className="volume-mute"
                    width="30"
                    height="28"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1280 352v1088q0 26-19 45t-45 19-45-19l-333-333h-262q-26 0-45-19t-19-45v-384q0-26 19-45t45-19h262l333-333q19-19 45-19t45 19 19 45z"
                      fill={this.props.video.color}
                    />
                  </svg>
                  <svg
                    className="volume-down"
                    width="30"
                    height="28"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1088 352v1088q0 26-19 45t-45 19-45-19l-333-333h-262q-26 0-45-19t-19-45v-384q0-26 19-45t45-19h262l333-333q19-19 45-19t45 19 19 45zm384 544q0 76-42.5 141.5t-112.5 93.5q-10 5-25 5-26 0-45-18.5t-19-45.5q0-21 12-35.5t29-25 34-23 29-35.5 12-57-12-57-29-35.5-34-23-29-25-12-35.5q0-27 19-45.5t45-18.5q15 0 25 5 70 27 112.5 93t42.5 142z"
                      fill={this.props.video.color}
                    />
                  </svg>
                  <svg
                    className="volume-up"
                    width="30"
                    height="28"
                    viewBox="0 0 1792 1792"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M832 352v1088q0 26-19 45t-45 19-45-19l-333-333h-262q-26 0-45-19t-19-45v-384q0-26 19-45t45-19h262l333-333q19-19 45-19t45 19 19 45zm384 544q0 76-42.5 141.5t-112.5 93.5q-10 5-25 5-26 0-45-18.5t-19-45.5q0-21 12-35.5t29-25 34-23 29-35.5 12-57-12-57-29-35.5-34-23-29-25-12-35.5q0-27 19-45.5t45-18.5q15 0 25 5 70 27 112.5 93t42.5 142zm256 0q0 153-85 282.5t-225 188.5q-13 5-25 5-27 0-46-19t-19-45q0-39 39-59 56-29 76-44 74-54 115.5-135.5t41.5-173.5-41.5-173.5-115.5-135.5q-20-15-76-44-39-20-39-59 0-26 19-45t45-19q13 0 26 5 140 59 225 188.5t85 282.5z"
                      fill={this.props.video.color}
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div id="player-progress" className="player-progress">
              <div
                id="player-progress-hover"
                className="player-progress-hover"
                style={{ color: this.props.video.color }}
              ></div>
              <div
                id="player-progress-track"
                className="player-progress-track"
                style={{ backgroundColor: this.props.video.color }}
              ></div>
              <div
                id="player-progress-value-hover"
                className="player-progress-value-hover"
                style={{ backgroundColor: this.props.video.color }}
              ></div>
              <div
                id="player-progress-value"
                className="player-progress-value"
                style={{ backgroundColor: this.props.video.color }}
              ></div>
            </div>
            <div className="player-controls-right">
              {!this.props.mobile &&
              fileList.length > 1 &&
              fileList[0].height >= 480 ? (
                <div className="player-settings">
                  <div
                    id="player-settings-overlay"
                    className="player-settings-overlay"
                  >
                    <div id="player-settings-quality-header">Quality</div>
                    {fileList.map((f) => (
                      <div
                        data-quality={f.preset}
                        className={`player-settings-quality-option ${
                          f.url === source ? 'checked' : ''
                        }`}
                      >
                        <svg
                          version="1.1"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill={this.props.video.color}
                            d="M9 16.172l10.594-10.594 1.406 1.406-12 12-5.578-5.578 1.406-1.406z"
                          ></path>
                        </svg>
                        {f.height}p
                        {f.height > 700 ? (
                          <sup
                            className="quality-hd-icon"
                            style={{ color: this.props.video.color }}
                          >
                            HD
                          </sup>
                        ) : (
                          ''
                        )}
                      </div>
                    ))}
                  </div>
                  <div
                    role="button"
                    id="player-settings-button"
                    className="player-settings-button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      width="21"
                      height="21"
                    >
                      <path
                        d="M42.3,26.4c0-.72.25-1.68.25-2.4a8,8,0,0,0-.25-2.4L47.48,18a1.1,1.1,0,0,0,.25-1.68l-4.94-8.4a1.11,1.11,0,0,0-1.48-.72L35.39,9.84a15.85,15.85,0,0,0-4.44-2.4L30.21,1.2A1.15,1.15,0,0,0,29,0H19.1a1.15,1.15,0,0,0-1.23,1.2l-.74,6a19.11,19.11,0,0,0-4.44,2.4L6.76,7.2a1.2,1.2,0,0,0-1.48.48L.34,16.08c-.49.72-.49,1.44.25,1.68L5.77,21.6c0,.72-.25,1.68-.25,2.4a8,8,0,0,0,.25,2.4L.59,30a1.1,1.1,0,0,0-.25,1.68l4.94,8.4a1.11,1.11,0,0,0,1.48.72l5.92-2.64a15.85,15.85,0,0,0,4.44,2.4l.74,6.24A1.15,1.15,0,0,0,19.1,48H29a1.15,1.15,0,0,0,1.23-1.2l.74-6.24a19.11,19.11,0,0,0,4.44-2.4l5.92,2.64a1.2,1.2,0,0,0,1.48-.48l4.94-8.4a1.45,1.45,0,0,0-.25-1.68ZM24,34A10,10,0,1,1,34,24,10,10,0,0,1,24,34Z"
                        fill={this.props.video.color}
                      />
                    </svg>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div
                role="button"
                id="player-fullscreen-button"
                className="player-fullscreen-button"
              >
                <svg
                  className="compact"
                  width="23"
                  height="23"
                  viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M896 960v448q0 26-19 45t-45 19-45-19l-144-144-332 332q-10 10-23 10t-23-10l-114-114q-10-10-10-23t10-23l332-332-144-144q-19-19-19-45t19-45 45-19h448q26 0 45 19t19 45zm755-672q0 13-10 23l-332 332 144 144q19 19 19 45t-19 45-45 19h-448q-26 0-45-19t-19-45v-448q0-26 19-45t45-19 45 19l144 144 332-332q10-10 23-10t23 10l114 114q10 10 10 23z"
                    fill={this.props.video.color}
                  />
                </svg>
                <svg
                  className="expand"
                  width="23"
                  height="23"
                  viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M883 1056q0 13-10 23l-332 332 144 144q19 19 19 45t-19 45-45 19h-448q-26 0-45-19t-19-45v-448q0-26 19-45t45-19 45 19l144 144 332-332q10-10 23-10t23 10l114 114q10 10 10 23zm781-864v448q0 26-19 45t-45 19-45-19l-144-144-332 332q-10 10-23 10t-23-10l-114-114q-10-10-10-23t10-23l332-332-144-144q-19-19-19-45t19-45 45-19h448q26 0 45 19t19 45z"
                    fill={this.props.video.color}
                  />
                </svg>
              </div>
            </div>
          </div>
        )}

        <div id="context-menu">
          <div className="context-menu-item" id="speed">
            Speed
            <span className="speed-button" data-speed="2">
              2
            </span>
            <span className="speed-button" data-speed="1.5">
              1.5
            </span>
            <span className="speed-button active" data-speed="1">
              1
            </span>
            <span className="speed-button" data-speed=".5">
              ½
            </span>
            <span className="speed-button" data-speed=".25">
              ¼
            </span>
          </div>
          {this.props.video.allow_download
            ? fileList.map((file) => (
                <a
                  href={file.url}
                  className="context-menu-item"
                  id="download"
                  download
                >
                  Download video
                  {fileList.length > 1 ? ` in ${file.height}p` : ''}{' '}
                </a>
              ))
            : ''}
          {this.props.video.hide_sharing ? (
            ''
          ) : (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a href="#" className="context-menu-item" id="copyurl">
              Copy video URL
            </a>
          )}
          {this.props.video.hide_sharing ? (
            ''
          ) : (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a href="#" className="context-menu-item" id="copyembed">
              Copy embed code
            </a>
          )}
          <a
            href={`/report/${this.props.video.shortcode}`}
            target="_blank"
            rel="noopener noreferrer"
            className="context-menu-item"
          >
            Report issue
          </a>
          {this.props.video.remove_branding ? (
            ''
          ) : (
            <a
              href="https://streamable.com"
              target="_blank"
              rel="noopener noreferrer"
              className="context-menu-item context-menu-footer"
            >
              <img
                src="https://statics.streamable.com/static/favicon.ico"
                alt="Logo"
                height="16"
                width="16"
              />
              <b>Powered by Streamable</b>
            </a>
          )}
        </div>
        {this.props.children}
      </div>
    );
  }
}
