import React, { useState } from 'react';
import { connect } from 'react-redux';
import Textarea from 'react-textarea-autosize';

import classnames from 'classnames';
import { renameVideo } from 'src/actions';
import { dashboardAssignLabelsActions } from 'src/dashboard/state/assign-labels/assign-labels-actions';
import {
  selectLabelsAsArray,
  selectLabelsAlphabeticallyByIds,
} from 'src/dashboard/state/labels/labels-selectors';
import type { LabelWithCount, VideoRepresentation } from 'src/dashboard/types';
import type { RootState, AppDispatch } from 'src/redux';

import VideoUrl from '../../../app/videos/videoItem/subComponents/VideoUrl';
import { VideoLabels } from '../video-labels/video-labels';

type OwnProps = {
  expired: boolean;
  video: VideoRepresentation;
};

type VideoInfoProps = OwnProps & {
  userLabels: LabelWithCount[];
  videoLabels: LabelWithCount[];
  handleLabelsClick: () => void;
  renameVideo: (title: string, debounced: boolean) => void;
};

export const VideoInfoComponent = ({
  video,
  expired,
  userLabels,
  videoLabels,
  handleLabelsClick,
  renameVideo,
}: VideoInfoProps) => {
  const [title, setTitle] = useState(video.title);

  const handleChange = (newTitle: string, forceSave?: boolean) => {
    setTitle(newTitle);
    renameVideo(newTitle, !forceSave);
  };

  return (
    <div
      className={classnames('card-block', { 'cursor-not-allowed': expired })}
    >
      <div
        className={classnames('card-metadata', {
          'cursor-not-allowed': expired,
        })}
      >
        <Textarea
          className="form-control edit-title-input"
          maxLength={200}
          placeholder="Add a title"
          value={title}
          onChange={(e) => {
            // check for line break
            const match = /\r|\n/.exec(e.target.value);
            if (match) {
              handleChange(e.target.value.replace(/(\r\n|\n|\r)/gm, ''), true);
              e.target.blur();
              return false;
            }
            handleChange(e.target.value);
          }}
        />
        <VideoUrl {...video} />
        {userLabels.length > 0 && (
          <VideoLabels
            labels={videoLabels}
            handleLabelsClick={handleLabelsClick}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  userLabels: selectLabelsAsArray(state),
  videoLabels: selectLabelsAlphabeticallyByIds(
    state,
    ownProps.video.labels ? ownProps.video.labels.map((label) => label.id) : []
  ) as LabelWithCount[],
});

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps) => ({
  handleLabelsClick: () => {
    if (ownProps.video.shortcode) {
      dispatch(
        dashboardAssignLabelsActions.videoCard.videoLabelsButton.clicked(
          ownProps.video.shortcode
        )
      );
    }
  },
  renameVideo: (title: string, debounced: boolean) => {
    if (ownProps.video.shortcode) {
      dispatch(renameVideo(ownProps.video.shortcode, title, debounced));
    }
  },
});

export const VideoInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoInfoComponent);
