import { createAction } from '@reduxjs/toolkit';
import fetch from 'isomorphic-fetch';
import { log } from 'src/actions/log';
import type { AppDispatch } from 'src/redux';

import type { PostWithLoadingState } from '../types';

export const blogItemPageActions = {
  blogItemFetched: createAction<{ slug: string }>(
    '[Blog Item Page] blog item fetched'
  ),
  blogItemFulfilled: createAction<PostWithLoadingState>(
    '[Blog Item Page] blog item fulfilled'
  ),
  blogItemRejected: createAction<{ slug: string }>(
    '[Blog Item Page] blog item rejected'
  ),
};

export const fetchBlogPostItem = (slug: string) => (dispatch: AppDispatch) => {
  dispatch(blogItemPageActions.blogItemFetched({ slug }));

  return fetch(`${process.env.REACT_APP_NEW_API_HOST}/blog/posts/${slug}`, {
    credentials: 'include',
  })
    .then(async (response) => {
      if (!response.ok) {
        throw Error(await response.json()).message;
      }
      return response.json();
    })
    .then(({ post }) => {
      dispatch(blogItemPageActions.blogItemFulfilled(post));
    })
    .catch((e) => {
      dispatch(blogItemPageActions.blogItemRejected({ slug }));
      log(e);
    });
};
