import React, { useMemo } from 'react';
import { browserHistory } from 'react-router';

import {
  faCode,
  faCut,
  faEllipsisH,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { MEDIA_STATUS } from 'src/common/types/media';
import type { VideoRepresentation } from 'src/dashboard/types';
import DropDown, { type DropDownItem } from 'src/misc/dropdown/DropDown';

import './video-footer-component.css';

export type VideoFooterComponentOwnProps = {
  expired: boolean;
  video: VideoRepresentation;
  togglePrivacyModal: () => void;
  handleEditLabelsClick: (shortcode: string) => void;
  toggleDeleteModal: () => void;
  toggleEmbedModal: (video: any) => void;
  toggleFileListModal: () => void;
};

type VideoFooterComponentProps = VideoFooterComponentOwnProps & {
  shouldExposeNewAnalyticsSummaryPage: boolean;
  shouldExposeVideoManageCaptions: boolean;
  openCaptionsModal: () => void;
  handleReplaceClick: (shortcode: string) => void;
  handlePrivacyClick: () => void;
  cancelVideo: () => void;
};

export const VideoFooterComponent = ({
  cancelVideo,
  expired,
  handleEditLabelsClick,
  handlePrivacyClick,
  handleReplaceClick,
  shouldExposeNewAnalyticsSummaryPage,
  shouldExposeVideoManageCaptions,
  openCaptionsModal,
  toggleDeleteModal,
  toggleEmbedModal,
  toggleFileListModal,
  video,
}: VideoFooterComponentProps) => {
  const dropdownItems = useMemo(() => {
    const items: Array<DropDownItem> = [
      {
        onClick: () => {
          if (shouldExposeNewAnalyticsSummaryPage) {
            browserHistory.push(`/videos/${video.shortcode}/analytics`);
          } else {
            browserHistory.push(`/stats/${video.shortcode}`);
          }
        },
        icon: <span className="material-symbols-outlined">insert_chart</span>,
        title: 'Analytics',
      },
      {
        onClick: () => browserHistory.push(`/thumbnail/${video.shortcode}`),
        icon: <span className="material-symbols-outlined">imagesmode</span>,
        title: 'Edit thumbnail',
      },
      ...(handleEditLabelsClick
        ? [
            {
              onClick: () => handleEditLabelsClick(video.shortcode!),
              icon: <span className="material-symbols-outlined">label</span>,
              title: 'Edit labels',
            },
          ]
        : []),
      ...(shouldExposeVideoManageCaptions
        ? [
            {
              onClick: () => openCaptionsModal(),
              icon: (
                <span className="material-symbols-outlined">
                  closed_caption
                </span>
              ),
              title: 'Captions',
            },
          ]
        : []),
      {
        onClick: () => handlePrivacyClick(),
        icon: <span className="material-symbols-outlined">lock</span>,
        title: 'Privacy',
      },
      {
        onClick: () => handleReplaceClick(video.shortcode!),
        icon: <span className="material-symbols-outlined">sync_alt</span>,
        title: 'Replace video',
      },
      {
        onClick: () => toggleFileListModal(),
        icon: <span className="material-symbols-outlined">download</span>,
        title: 'Download links',
      },
      {
        separator: true,
      },
      {
        onClick: () => toggleDeleteModal(),
        icon: <span className="material-symbols-outlined">delete</span>,
        title: 'Delete',
      },
    ];
    return items;
  }, [
    video.shortcode,
    handleReplaceClick,
    handleEditLabelsClick,
    handlePrivacyClick,
    toggleDeleteModal,
    toggleFileListModal,
    openCaptionsModal,
    shouldExposeVideoManageCaptions,
    shouldExposeNewAnalyticsSummaryPage,
  ]);

  return (
    <div
      className={classnames('video-footer', {
        'cursor-not-allowed': expired,
      })}
    >
      <div
        className={classnames('video-footer__actions-container', {
          'video-footer__disabled': expired || !video.shortcode,
        })}
      >
        {video.status === MEDIA_STATUS.READY && (
          <button
            className="action-button"
            onClick={() => toggleEmbedModal(video)}
          >
            <FontAwesomeIcon icon={faCode} /> Embed
          </button>
        )}

        {!video.status || video.status < MEDIA_STATUS.READY ? (
          <button className="action-button rightmost" onClick={cancelVideo}>
            <FontAwesomeIcon icon={faTrash} /> Cancel
          </button>
        ) : (
          <button
            className="action-button"
            onClick={() => {
              browserHistory.push(`/edit/${video.shortcode}`);
            }}
          >
            <FontAwesomeIcon icon={faCut} fontVariant="light" /> Edit Video
          </button>
        )}
        {video.status === MEDIA_STATUS.READY && (
          <DropDown className="action-button rightmost" items={dropdownItems}>
            <FontAwesomeIcon icon={faEllipsisH} /> More
          </DropDown>
        )}
      </div>
    </div>
  );
};
