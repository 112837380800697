import React from 'react';

import { Flex } from 'src/common/components/flex/flex';
import { Text } from 'src/common/components/text/text';

import './plan-price.css';

type PlanPriceProps = {
  duration?: string;
  frequencyLabel?: string;
  secondaryLabel?: string;
  value: string;
  originalValue?: string | null;
};

export const PlanPrice = ({
  duration = '',
  frequencyLabel = 'Per month',
  secondaryLabel,
  value,
  originalValue,
}: PlanPriceProps) => (
  <Flex className="plan-price-container">
    <Flex className="plan-price-container__row">
      {originalValue && (
        <Text className="plan-price-container__price plan-price-container__price--original">
          {originalValue}
        </Text>
      )}
      <Text className="plan-price-container__price">{value}</Text>
    </Flex>
    <Text className="plan-price-container__frequency">
      {frequencyLabel} {duration && `/ ${duration}`}
    </Text>
    {secondaryLabel && (
      <Text className="plan-price-container__secondary-label">
        {secondaryLabel}
      </Text>
    )}
  </Flex>
);
