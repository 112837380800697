import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { CardElement } from '@stripe/react-stripe-js';
import { PaymentMethod } from '@stripe/stripe-js';
import classnames from 'classnames';
import moment from 'moment';
import { calculateAnnualDiscount } from 'src/checkout/utils/calculate-annual-discount';
import { calculateAnnualPrice } from 'src/checkout/utils/calculate-annual-price';
import { calculateMonthlyPrice } from 'src/checkout/utils/calculate-monthly-price';
import { formatPrice } from 'src/checkout/utils/format-price';
import { Button } from 'src/common/components/button/button';
import { Flex } from 'src/common/components/flex/flex';
import { LogoMark } from 'src/common/components/logo-mark/logo-mark';
import { Skeleton } from 'src/common/components/skeleton/skeleton';
import { Text } from 'src/common/components/text/text';
import { useEffectOnce } from 'src/common/hooks/use-effect-once';
import { ANNUAL_CADENCE, MONTHLY_CADENCE } from 'src/pricing/constants';
import { AvailablePlan, PlanCadence } from 'src/pricing/types';
import { triggerGoogleAnalyticsEvent } from 'src/utils/trigger-google-analytics-event';

import { SelectablePlan } from '../selectable-plan/selectable-plan';
import { StripeFormWrapper } from '../stripe-form-wrapper/stripe-form-wrapper';
import './billing-details-component.css';

type BillingDetailsComponentOwnProps = {
  chosenPlan: AvailablePlan | null;
  handleSelectCadence: (plan: PlanCadence) => void;
  selectedCadence: PlanCadence | null;
};

type BillingDetailsComponentStateProps = {
  checkout: {
    error: boolean;
    message: string | null;
    purchasing: boolean;
  };
  hasPaidPlan: boolean;
  noTrial: boolean;
  trialPeriod: number;
};

type BillingDetailsComponentDispatchProps = {
  handleAlreadySubscribed: () => void;
  handleCardElementReady: () => void;
  handleSubscriptionError: (errorMessage: string) => void;
  handleSubscriptionInProgress: () => void;
  handleSubscribeToPlan: (subscriptionDetails: {
    paymentMethodId: string;
    plan?: string | null;
    token: string;
    noTrial: boolean;
    cadence: PlanCadence;
  }) => void;
  log: (message: string) => void;
};

type BillingDetailsComponentProps = BillingDetailsComponentOwnProps &
  BillingDetailsComponentStateProps &
  BillingDetailsComponentDispatchProps;

export const BillingDetailsComponent = ({
  chosenPlan,
  checkout: {
    error: isError,
    message: errorMessage,
    purchasing: isPurchasingInProgress,
  },
  hasPaidPlan,
  noTrial,
  selectedCadence,
  trialPeriod,
  handleAlreadySubscribed,
  handleCardElementReady,
  handleSelectCadence,
  handleSubscriptionError,
  handleSubscriptionInProgress,
  handleSubscribeToPlan,
  log,
}: BillingDetailsComponentProps) => {
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const trialEndDate = moment().add(trialPeriod, 'days').format('MMM Do');

  useEffectOnce(() =>
    triggerGoogleAnalyticsEvent('displayed_legacy_checkout_form')
  );

  const handleSuccess = async (paymentMethod: PaymentMethod) => {
    if (reCaptchaRef.current === null) {
      // TODO: handle captcha error
      return;
    }

    // This case should never happen because we do not allow the user to submit
    if (!chosenPlan || !selectedCadence) {
      return;
    }

    log('CHECKOUT RECAPTCHA SHOWN');
    const start = Date.now();
    const token = await reCaptchaRef.current.executeAsync();
    const delta = Date.now() - start;

    log(`CHECKOUT RECAPTCHA TOKEN RETURNED IN ${delta.toString()}ms`);
    reCaptchaRef.current.reset();

    handleSubscribeToPlan({
      paymentMethodId: paymentMethod.id,
      plan: chosenPlan[selectedCadence].id,
      token: token || '',
      noTrial,
      cadence: selectedCadence,
    });
  };

  const validateOwnPlan = () => {
    if (hasPaidPlan) {
      handleAlreadySubscribed();
      return false;
    }
    return true;
  };

  return (
    <Flex className="billing-details">
      <Text as="h3">Plan</Text>
      <Flex
        direction="row"
        alignItems="center"
        className="billing-details__plan-details"
      >
        <LogoMark className="billing-details__plan-details__logo-mark" />
        <Text className="billing-details__plan-details__plan-name">
          {chosenPlan && selectedCadence ? (
            `Streamable ${chosenPlan[selectedCadence].name}`
          ) : (
            <Skeleton height="1.25rem" animation="wave" width="75%" />
          )}
        </Text>
      </Flex>

      <Flex className="billing-details__plan-container">
        {chosenPlan ? (
          <>
            {chosenPlan.monthly && (
              <SelectablePlan
                handleSelect={() => handleSelectCadence(MONTHLY_CADENCE)}
                isSelected={selectedCadence === MONTHLY_CADENCE}
                value={MONTHLY_CADENCE}
                name="cadence"
                title="Monthly"
                description="Cancel anytime no fee"
                displayPrice={`$${calculateMonthlyPrice(chosenPlan)}/month`}
              />
            )}
            {chosenPlan.annual && (
              <SelectablePlan
                handleSelect={() => handleSelectCadence(ANNUAL_CADENCE)}
                isSelected={selectedCadence === ANNUAL_CADENCE}
                value={ANNUAL_CADENCE}
                name="cadence"
                title="Yearly"
                label="Most popular"
                displayPrice={`$${calculateAnnualPrice(chosenPlan)}/month`}
                description={`Pay yearly and save ${calculateAnnualDiscount(
                  chosenPlan
                )}%`}
              />
            )}
          </>
        ) : (
          <>
            <Skeleton height="5.3125rem" animation="wave" width="100%" />
            <Skeleton height="5.3125rem" animation="wave" width="100%" />
          </>
        )}
      </Flex>

      <StripeFormWrapper
        handleError={handleSubscriptionError}
        handleLoading={handleSubscriptionInProgress}
        handleSuccess={handleSuccess}
        log={log}
        validateOwnPlan={validateOwnPlan}
      >
        {({ elements, stripe }) => (
          <>
            <Text as="h3" className="billing-details__title">
              Billing details
            </Text>

            <CardElement
              onReady={handleCardElementReady}
              options={{
                style: {
                  base: {
                    fontSize: '14px',
                    fontFamily: "'Manrope', sans-serif",
                    color: '#1C1C1E',
                  },
                  invalid: {
                    color: '#D70015',
                  },
                },
              }}
            />

            {isError && errorMessage && (
              <Text className="billing-details__error-message">
                {errorMessage}
              </Text>
            )}

            <Flex
              alignItems="center"
              direction="row"
              className="billing-details__due-today-container"
            >
              {chosenPlan ? (
                <>
                  <Text>Due today:</Text>
                  <Text
                    className={classnames(
                      'billing-details__due-today-container__price',
                      {
                        'billing-details__due-today-container__price--trial':
                          !noTrial,
                      }
                    )}
                  >
                    $
                    {formatPrice(
                      selectedCadence === MONTHLY_CADENCE
                        ? chosenPlan.monthly.price
                        : chosenPlan.annual.price
                    )}
                  </Text>
                  {!noTrial && <Text>$0</Text>}
                </>
              ) : (
                <Skeleton height={30} animation="wave" />
              )}
            </Flex>

            {!noTrial && (
              <Text className="billing-details__trial-copy">
                You'll only be charged on {trialEndDate}, after your{' '}
                {trialPeriod}-day free trial ends.
              </Text>
            )}

            <Button
              color="primary"
              type="submit"
              className="billing-details__cta"
              disabled={
                !stripe ||
                !elements ||
                !chosenPlan ||
                !selectedCadence ||
                isPurchasingInProgress ||
                hasPaidPlan
              }
            >
              {noTrial ? 'Upgrade Now' : 'Start Free Trial'}
            </Button>
          </>
        )}
      </StripeFormWrapper>

      <Text className="billing-details__agreement-copy">
        <a
          target="_blank"
          href="https://terms.streamable.com/"
          rel="noreferrer"
        >
          Terms of Service
        </a>{' '}
        apply and you acknowledge the{' '}
        <a
          target="_blank"
          href="https://privacy.streamable.com/"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </Text>

      <ReCAPTCHA
        ref={reCaptchaRef}
        size="invisible"
        sitekey="6LefNMAZAAAAANHI6PRR6Vh9yMBsCmipPTw8jPOP"
      />
    </Flex>
  );
};
