import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import { subscriptionActions } from 'src/app/subscription/subscription-actions';
import {
  selectCurrentPlan,
  selectCurrentPlanId,
  selectCurrentPlansCadence,
  selectNextAvailablePlan,
} from 'src/app/subscription/subscription-selectors';
import type {
  SubscriptionInfo,
  ActivatedSubscriptionInfo,
} from 'src/app/subscription/subscription-types';
import { selectUserId } from 'src/common/state/me/me-selectors';
import { ENTERPRISE_PLAN_NAME } from 'src/dashboard/components/upsell/upsell-constants';
import { onBusinessClick } from 'src/pricing/components/plan/utils/onBusinessClick';
import { pricingActions } from 'src/pricing/state/pricing-actions';
import { AvailablePlan, PlanCadence } from 'src/pricing/types';
import { triggerGoogleAnalyticsEvent } from 'src/utils/trigger-google-analytics-event';

import { dashboardSuggestionsActions } from '../suggestions-actions';
import type { ShowUpsellCardParams } from '../suggestions-types';

const UPSELL_STORAGE_USED_PERCENTAGE_CUTOFF = 70;
const STORAGE_UPSELL_DISMISSED_LOCAL_STORAGE_KEY = (
  userId: string,
  planId: string
) => `storage-upsell-dismissed-for-${planId}-${userId}`;
const STORAGE_UPSELL_DISMISSED_LOCAL_STORAGE_VALUE = 'true';

export const storage = {
  getItem: localStorage.getItem.bind(localStorage),
  setItem: localStorage.setItem.bind(localStorage),
};

export function* getIsDismissedStorageUpsell(planId: string) {
  const userId: string = yield select(selectUserId);
  const isDismissedString: string = yield call(
    storage.getItem,
    STORAGE_UPSELL_DISMISSED_LOCAL_STORAGE_KEY(userId, planId)
  );
  return isDismissedString === STORAGE_UPSELL_DISMISSED_LOCAL_STORAGE_VALUE;
}

export function* checkStorageUpsell() {
  const currentPlan: ActivatedSubscriptionInfo =
    yield select(selectCurrentPlan);

  if (!currentPlan || !currentPlan.id) {
    // No upsell for anonymous users
    return;
  }

  const isDismissed: boolean = yield call(
    getIsDismissedStorageUpsell,
    currentPlan.id
  );
  if (isDismissed) {
    // No upsell if the user already dismissed the upsell for this plan
    return;
  }

  const storageLimit = currentPlan?.limits.storage;
  if (!storageLimit.limit) {
    // No storage limit
    return;
  }

  const percentUsed = Math.floor(
    (storageLimit.current / storageLimit.limit) * 100
  );

  if (percentUsed < UPSELL_STORAGE_USED_PERCENTAGE_CUTOFF) {
    // Storage used below the cutoff
    return;
  }

  yield call(triggerGoogleAnalyticsEvent, 'storage_upsell_was_shown');

  const nextPlan: SubscriptionInfo | null = yield select(
    selectNextAvailablePlan
  );

  const isEnterprise =
    !nextPlan?.name || nextPlan?.name === ENTERPRISE_PLAN_NAME;

  const upsellPayload: ShowUpsellCardParams = {
    title: 'Unlock more video storage!',
    callToActionLabel: isEnterprise
      ? 'Contact sales'
      : 'Unlock more video storage!',
    text: `You’re using ${percentUsed}% of your plan’s storage. Upgrade to get more space for your videos and to access premium features.`,
    dispatchActionTypeOnClick:
      dashboardSuggestionsActions.suggestionCard.accepted.type,
    planName: isEnterprise ? ENTERPRISE_PLAN_NAME : nextPlan?.name,
  };

  yield put(dashboardSuggestionsActions.suggestionCard.shown(upsellPayload));
}

export function* dismissStorageUpsell() {
  const currentPlanId: string = yield select(selectCurrentPlanId);
  const userId: string = yield select(selectUserId);
  yield call(
    storage.setItem,
    STORAGE_UPSELL_DISMISSED_LOCAL_STORAGE_KEY(userId, currentPlanId),
    STORAGE_UPSELL_DISMISSED_LOCAL_STORAGE_VALUE
  );
}

export function* acceptStorageUpsell() {
  const currentPlanCadence: PlanCadence = yield select(
    selectCurrentPlansCadence
  );
  const nextPlanSubscription: AvailablePlan = yield select(
    selectNextAvailablePlan
  );

  if (nextPlanSubscription) {
    const nextPlan = nextPlanSubscription[currentPlanCadence];
    if (!nextPlan) {
      return;
    }
    yield put(pricingActions.planColumn.changePlanButton.clicked(nextPlan.id));
  } else {
    yield call(
      onBusinessClick,
      'Enterprise plan request because of video storage limit'
    );
    yield call(
      triggerGoogleAnalyticsEvent,
      'storage_upsell_contact_sales_for_enterprise_clicked'
    );
    yield call(dismissStorageUpsell);
    yield put(dashboardSuggestionsActions.suggestionCard.closed());
  }
}

export function* acceptStorageUpsellFulfilled() {
  const currentPlanId: string = yield select(selectCurrentPlanId);
  const isDismissed: boolean = yield call(
    getIsDismissedStorageUpsell,
    currentPlanId
  );
  if (!isDismissed) {
    yield call(triggerGoogleAnalyticsEvent, 'storage_upsell_accepted');
    yield put(dashboardSuggestionsActions.suggestionCard.closed());
  }
}

export function* watchStorageUpsellSagas() {
  yield all([
    takeEvery(subscriptionActions.subscriptionInfoFetched, checkStorageUpsell),
    takeEvery(
      dashboardSuggestionsActions.suggestionCard.closed,
      dismissStorageUpsell
    ),
    takeEvery(
      dashboardSuggestionsActions.suggestionCard.accepted,
      acceptStorageUpsell
    ),
    takeEvery(
      pricingActions.changePlanModal.changeFulfilled,
      acceptStorageUpsellFulfilled
    ),
  ]);
}
