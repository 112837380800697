import { useEffect, useMemo, useState, useRef } from 'react';

import { isElementInViewport } from '../../utils/is-element-in-viewport';

export const useIsElementInViewport = (elementRef) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const isElementInViewportRef = useRef(false);

  const canIUseIntersectionObserver =
    'IntersectionObserver' in window && 'IntersectionObserverEntry' in window;

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    if (canIUseIntersectionObserver) {
      observer.observe(elementRef.current);

      return () => {
        observer.disconnect();
      };
    } else {
      // fallback if `IntersectionObserver` is not present in browser
      if (elementRef && elementRef.current) {
        const onScroll = () => {
          const isCurrentElementInViewport = isElementInViewport(
            elementRef.current
          );
          if (isCurrentElementInViewport !== isElementInViewportRef.current) {
            setIsIntersecting(isCurrentElementInViewport);
            isElementInViewportRef.current = isCurrentElementInViewport;
          }
        };

        window.addEventListener('scroll', onScroll, { passive: true });

        return () => {
          window.removeEventListener('scroll', onScroll);
        };
      }
    }
  }, [
    canIUseIntersectionObserver,
    elementRef,
    observer,
    setIsIntersecting,
    isElementInViewportRef,
  ]);

  return isIntersecting;
};
