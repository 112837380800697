import React from 'react';

import {
  CardElement,
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import type {
  PaymentMethod,
  Stripe,
  StripeElements,
  StripeElementsOptions,
} from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);

type StripeFormWrapperProps = {
  className?: string;
  children:
    | React.ReactNode
    | React.FunctionComponent<{
        elements: StripeElements | null;
        stripe: Stripe | null;
      }>;
  handleError?: (errorMessage: string) => void;
  handleLoading?: () => void;
  handleSuccess: (paymentMethod: PaymentMethod) => void;
  log: (message: string) => void;
  validateOwnPlan: () => boolean;
};

export const StripeFormWrapper = ({
  className,
  children,
  handleError,
  handleLoading,
  handleSuccess,
  log,
  validateOwnPlan,
}: StripeFormWrapperProps) => {
  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    elements: StripeElements | null,
    stripe: Stripe | null
  ) => {
    event.preventDefault();

    if (!validateOwnPlan() || !stripe || !elements) {
      log(
        `[StripeFormWrapper]: invalid own data, stripe: ${stripe} OR elements: ${elements}`
      );
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      log(`[StripeFormWrapper]: No card elemenet: ${cardElement}`);
      return;
    }

    if (handleLoading) {
      handleLoading();
    }

    try {
      log(
        `[StripeFromWrapper#attempting create payment method]: card element: ${cardElement}`
      );
      const stripeResult = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (stripeResult.error) {
        const { type, code, decline_code, message, param, payment_intent } =
          stripeResult.error;
        log(
          `[StripeFromWrapper#stripeResult.error]: type: ${type}, code: ${code}, decline_code: ${decline_code}, message: ${message}, param: ${param}, payment_intent: ${payment_intent}`
        );
        throw new Error(message);
      }

      handleSuccess(stripeResult.paymentMethod);
    } catch (error: any) {
      if (handleError) {
        log(`[StripeFormWrapper#catch]: ${error.message}`);
        handleError(error.message);
      }
    }
  };

  const stripeElementsOptions = {
    fonts: [
      {
        family: 'Manrope',
        cssSrc:
          'https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap',
      },
    ],
    locale: 'en',
  } as StripeElementsOptions;

  return (
    <Elements stripe={stripePromise} options={stripeElementsOptions}>
      <ElementsConsumer>
        {({ elements, stripe }) => (
          <form
            onSubmit={(e) => handleSubmit(e, elements, stripe)}
            className={className}
          >
            {typeof children === 'function'
              ? children({ elements, stripe })
              : children}
          </form>
        )}
      </ElementsConsumer>
    </Elements>
  );
};
