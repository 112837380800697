import React, { useState } from 'react';
import { Link } from 'react-router';

import classNames from 'classnames';
import { Button } from 'src/common/components/button/button';
import { Flex } from 'src/common/components/flex/flex';
import { Skeleton } from 'src/common/components/skeleton/skeleton';
import { Text } from 'src/common/components/text/text';

import {
  CHECKOUT_TRIAL_FEATURES,
  CHECKOUT_NOTRIAL_FEATURES,
} from '../../constants';
import { LeftArrow } from '../left-arrow/left-arrow';
import './left-panel.css';

export type LeftPanelProps = {
  features: string[];
  isLoading?: boolean;
  title: string;
  comparePlansLink?: string;
  noTrial?: boolean;
  trialPeriod: number;
};

type FeatureListProps = Pick<
  LeftPanelProps,
  'comparePlansLink' | 'features' | 'noTrial' | 'isLoading' | 'title'
> & {
  variant: 'mobile' | 'desktop';
  withToggle?: boolean;
};

const FeatureList = ({
  comparePlansLink,
  features,
  noTrial,
  isLoading,
  title,
  variant,
  withToggle,
}: FeatureListProps) => {
  const [showPlanDetails, setShowPlanDetails] = useState(false);
  const togglePlanDetails = () => setShowPlanDetails((shown) => !shown);

  return (
    <>
      {withToggle && (
        <Button
          className="left-panel__plan-details-toggle"
          color="primary"
          onClick={togglePlanDetails}
          variant="link"
        >
          {showPlanDetails ? 'Hide plan details' : 'Show plan details'}
        </Button>
      )}
      <div
        className={classNames(
          'left-panel__features-container',
          `left-panel__features-container--${variant}`,
          {
            'left-panel__features-container--hidden':
              variant === 'mobile' && !showPlanDetails,
          }
        )}
      >
        <div className="left-panel__features">
          <div className="left-panel__features__item">
            {isLoading ? (
              <>
                <Skeleton
                  height={12}
                  width="50%"
                  marginBottom="0.375rem"
                  animation="wave"
                />
                <Skeleton height={12} animation="wave" />
              </>
            ) : (
              <>
                <Text className="left-panel__features__item__title">
                  What's included in {title}?
                </Text>
                <Text>{features.join(', ')}</Text>
              </>
            )}
          </div>

          {(noTrial ? CHECKOUT_NOTRIAL_FEATURES : CHECKOUT_TRIAL_FEATURES).map(
            ({ id, title, description }) => (
              <div className="left-panel__features__item" key={id}>
                <Text className="left-panel__features__item__title">
                  {title}
                </Text>
                <Text>{description}</Text>
              </div>
            )
          )}
        </div>

        {!!comparePlansLink && (
          <div className="left-panel__compare-container">
            <Link to={comparePlansLink}>
              Compare plans and pricing
              <LeftArrow className="left-panel__compare-container__right-arrow" />
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export const LeftPanel = ({
  features,
  isLoading = false,
  noTrial = true,
  title,
  trialPeriod,
  comparePlansLink,
}: LeftPanelProps) => (
  <Flex direction="column" className="left-panel">
    <Flex direction="column" className="left-panel__heading-container">
      {!noTrial && (
        <Text className="left-panel__trial-copy">
          Start your {trialPeriod}-day free trial!
        </Text>
      )}

      <Text as="h1" className="left-panel__title">
        {isLoading ? (
          <Skeleton height={39} animation="wave" />
        ) : (
          `Streamable ${title}`
        )}
      </Text>
    </Flex>

    <FeatureList
      comparePlansLink={comparePlansLink}
      isLoading={isLoading}
      features={features}
      noTrial={noTrial}
      title={title}
      variant="mobile"
      withToggle
    />

    <FeatureList
      comparePlansLink={comparePlansLink}
      isLoading={isLoading}
      features={features}
      noTrial={noTrial}
      variant="desktop"
      title={title}
    />
  </Flex>
);
