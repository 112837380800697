import React from 'react';
import { Helmet } from 'react-helmet';

import { PublicPageLayout } from 'src/common/components/public-page-layout/public-page-layout';
import { withProviders } from 'src/common/hocs/with-providers';
import ExceedsLimitsModal from 'src/misc/modals/ExceedsLimitsModal';

import { CustomerLogos } from './components/customer-logos/customer-logos';
import { Features } from './components/features/features';
import { Hero } from './components/hero/hero';
import { Testimonials } from './components/testimonials/testimonials';

const LandingComponent = () => (
  <PublicPageLayout>
    <Helmet>
      <link rel="canonical" href="https://streamable.com"></link>
      <title>{window._STM_PREDATA.default.title}</title>
      <meta
        name="description"
        content={window._STM_PREDATA.default.description}
      ></meta>
    </Helmet>

    <Hero />

    <CustomerLogos />

    <Features />

    <Testimonials />

    <ExceedsLimitsModal />
  </PublicPageLayout>
);

export const Landing = withProviders({
  isDarkModeSupported: false,
})(LandingComponent);
