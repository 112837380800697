const CONVERSION_FACTOR = 1024;

export const humanizeStorageUsage = (usage: number, reference: number) => {
  // if the limit is less than 1TB, we don't need to convert and can just return the GB value
  if (reference < CONVERSION_FACTOR) {
    return `${Math.floor(usage).toLocaleString(undefined, {
      maximumSignificantDigits: 2,
    })}GB`;
  }

  // if the limit is greater than 1TB but the usage is less than 1TB, we need to convert to TB
  // but without converting to integer because it would always result in zero.
  if (reference > CONVERSION_FACTOR && usage < CONVERSION_FACTOR) {
    return `${(usage / CONVERSION_FACTOR).toLocaleString(undefined, {
      maximumSignificantDigits: 2,
    })}TB`;
  }

  // if we reach this point both the reference and the usage are over a TB, so we can convert to TB
  // so that we are comparing apples to apples
  return `${(usage / CONVERSION_FACTOR).toLocaleString(undefined, {
    maximumSignificantDigits: 2,
  })}TB`;
};
