import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { withProviders } from 'src/common/hocs/with-providers';
import { env } from 'src/env';

import './report.css';

type ReportCategory =
  | 'error'
  | 'violence'
  | 'xxx'
  | 'dmca'
  | 'quality'
  | 'other';

const REPORTING_CATEGORIES: Array<{ category: ReportCategory; label: string }> =
  [
    {
      category: 'error',
      label: "Video won't play on my device",
    },
    {
      category: 'violence',
      label: 'Promotes violence or terrorism',
    },
    {
      category: 'xxx',
      label: 'Pornography or graphic content',
    },
    {
      category: 'dmca',
      label: 'Copyright infringement',
    },
    {
      category: 'quality',
      label: 'Quality problems',
    },
    {
      category: 'other',
      label: 'Something else',
    },
  ];

type ReportCategoryRadioProps = {
  category: ReportCategory;
  label: string;
  selectedCategory: ReportCategory;
  setSelectedCategory: (category: ReportCategory) => void;
};

const ReportCategoryRadio = ({
  category,
  label,
  selectedCategory,
  setSelectedCategory,
}: ReportCategoryRadioProps) => (
  <div className="form-group">
    <label>
      <input
        type="radio"
        name="category"
        value={category}
        id={category}
        checked={category === selectedCategory}
        onChange={() => setSelectedCategory(category)}
      ></input>
      {label}
    </label>
  </div>
);

type ReportProps = {
  shortcode: string;
  query?: { completed: boolean; url?: string };
};

const Report = ({ shortcode, query }: ReportProps) => {
  const [selectedCategory, setSelectedCategory] =
    useState<ReportCategory>('error');

  useEffect(() => {
    // TODO: Do the redirect on Fastly
    window.location.href = `https://report.streamable.checkstep.com/?content_id=${shortcode}`;
  }, [shortcode]);

  return (
    <div className="card report">
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {query?.completed ? (
        <div className="complete" style={{ textAlign: 'center' }}>
          Your report has been received. We appreciate your help in making
          Streamable a lawful, positive community.
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <a href="https://streamable.com">Go Home</a>
          </div>
        </div>
      ) : (
        <form
          className="form"
          name="report"
          action={`${env.OLD_API_HOST}/${shortcode}/report`}
          method="POST"
        >
          <h3>What's wrong with this video?</h3>
          <div>
            {REPORTING_CATEGORIES.map(({ category, label }) => (
              <ReportCategoryRadio
                key={category}
                category={category}
                label={label}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />
            ))}
          </div>

          <div className="form-group" style={{ marginTop: '20px' }}>
            {selectedCategory === 'dmca' ? (
              <div id="dmca-alert">
                Please file a DMCA notification as detailed here:{' '}
                <a href="https://terms.streamable.com/#dmca">
                  https://terms.streamable.com/#dmca
                </a>
              </div>
            ) : (
              <textarea
                className="form-control"
                rows={5}
                id="comment"
                name="comment"
                placeholder="additional details"
              ></textarea>
            )}
          </div>

          <div
            className="form-group"
            style={{ marginTop: '20px', textAlign: 'center' }}
          >
            <input
              type="submit"
              className="btn btn-primary btn-sm"
              style={{ padding: '6px 14px' }}
            ></input>
          </div>
        </form>
      )}
    </div>
  );
};

export default withProviders({ design: 'v1' })(Report);
