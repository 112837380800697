import React from 'react';

import { PageTestimonial } from 'src/landing-new/state/landing-types';

import './testimonial-item.css';

export const TestimonialItem = ({
  testimonial,
  image,
  name,
  title,
}: PageTestimonial) => (
  <article className="testimonial-item">
    <h3 className="testimonial-item__content">{testimonial}</h3>
    <div className="testimonial-item__details">
      <img
        src={image?.url}
        alt={image?.title}
        className="testimonial-item__details__image"
      />
      <div>
        <p className="testimonial-item__details__name">{name}</p>
        <p className="testimonial-item__details__title">{title}</p>
      </div>
    </div>
  </article>
);
