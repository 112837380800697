import React from 'react';

import { Flex } from 'src/common/components/flex/flex';

import type { PageLink } from '../../state/landing-types';
import './links.css';

type LinksProps = {
  links: PageLink[];
};

export const Links = ({ links }: LinksProps) => {
  return (
    <Flex className="links" alignItems="center">
      <h2 className="links-header">Explore more video tools</h2>
      <Flex className="links-container" direction="row" justifyContent="center">
        {links.map((link) => (
          <a className="link" key={link.link} href={link.link}>
            {link.label}
          </a>
        ))}
      </Flex>
    </Flex>
  );
};
