import { pipe, prop } from 'ramda';
import type { RootState } from 'src/redux';

const selectAllUpgradeState = (state: RootState) => state.upgrade;

const selectUpgradeModal = pipe(selectAllUpgradeState, prop('upgradeModal'));

export const selectUpgradeModalIsOpen = pipe(
  selectUpgradeModal,
  prop('isOpen')
);
