import { connect } from 'react-redux';

import { meActions } from 'src/common/state/me/me-actions';
import type { AppDispatch } from 'src/redux';

import { UserAuthenticationComponent } from './user-authentication-component';

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  resetUser: () => dispatch(meActions.editUser({ error: null, message: null })),
});

export const UserAuthenticationContainer = connect(
  undefined,
  mapDispatchToProps
)(UserAuthenticationComponent);
