import { connect } from 'react-redux';

import {
  selectCurrentPlan,
  selectCurrentPlansLimits,
} from 'src/app/subscription/subscription-selectors';
import { dashboardUpsellActions } from 'src/dashboard/state/upsell/upsell-actions';
import { selectStorageUpsellModalIsOpen } from 'src/dashboard/state/upsell/upsell-selectors';
import {
  selectTargetPlanId,
  selectTargetProduct,
} from 'src/pricing/state/pricing-selectors';
import type { RootState, AppDispatch } from 'src/redux';

import { UpsellModalComponent } from './upsell-modal-component';

const mapStateToProps = (state: RootState) => ({
  limits: selectCurrentPlansLimits(state),
  isShowing: selectStorageUpsellModalIsOpen(state),
  targetPlanId: selectTargetPlanId(state),
  targetProduct: selectTargetProduct(state),
  currentPlan: selectCurrentPlan(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  onClose: () => {
    dispatch(dashboardUpsellActions.storageUpsellModal.closed());
  },
  onClickUpgrade: (productId: string) => {
    dispatch(
      dashboardUpsellActions.storageUpsellModal.upgradeButton.clicked(productId)
    );
  },
});

export const UpsellModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpsellModalComponent);
