import React from 'react';
import { connect } from 'react-redux';

import { compose } from 'ramda';
import { withProviders } from 'src/common/hocs/with-providers';
import {
  selectFreeTrialDuration,
  selectHasPaidPlan,
  selectIsLoggedIn,
  selectIsMeFetching,
  selectIsVerificationRequired,
  selectNoTrial,
} from 'src/common/state/me/me-selectors';
import {
  selectChosenPlan,
  selectTargetCadence,
} from 'src/pricing/state/pricing-selectors';
import { PlanCadence } from 'src/pricing/types';
import type { RootState, AppDispatch } from 'src/redux';
import { selectPaymentCollectionType } from 'src/utils/selectors/select-flags';

import {
  CheckoutComponent,
  type CheckoutComponentProps,
} from './checkout-component';
import { useRedirectIfUserInitiallyHasPaidPlan } from './hooks/use-redirect-if-user-initially-has-paid-plan';
import { checkoutActions } from './state/checkout-actions';

type CheckoutContainerOwnProps = {
  isMeFetching: boolean;
  viewParams: {
    query: {
      cadence?: string;
      plan?: string;
      src_internal?: string;
    };
  };
};

type CheckoutContainerProps = CheckoutContainerOwnProps &
  Pick<
    CheckoutComponentProps,
    | 'chosenPlan'
    | 'isLoggedIn'
    | 'isVerificationRequired'
    | 'noTrial'
    | 'paymentCollectionType'
  > & {
    changeCadence: (cadence: PlanCadence) => void;
    hasPaidPlan: boolean;
    targetCadence: PlanCadence;
    trialPeriod: number;
  };

const CheckoutContainerBase = ({
  changeCadence,
  chosenPlan,
  hasPaidPlan,
  isLoggedIn,
  isMeFetching,
  isVerificationRequired,
  noTrial,
  paymentCollectionType,
  targetCadence,
  trialPeriod,
  viewParams: { query },
}: CheckoutContainerProps) => {
  useRedirectIfUserInitiallyHasPaidPlan({ hasPaidPlan, isMeFetching });

  return (
    <CheckoutComponent
      chosenPlan={chosenPlan}
      handleSelectCadence={changeCadence}
      isLoggedIn={isLoggedIn}
      isVerificationRequired={isVerificationRequired}
      noTrial={noTrial}
      paymentCollectionType={paymentCollectionType}
      trialPeriod={trialPeriod}
      selectedCadence={targetCadence}
      srcInternal={query.src_internal}
    />
  );
};

const mapStateToProps = (
  state: RootState,
  {
    viewParams: {
      query: { plan, cadence },
    },
  }: CheckoutContainerOwnProps
) => ({
  chosenPlan: selectChosenPlan(plan, cadence as PlanCadence)(state),
  hasPaidPlan: selectHasPaidPlan(state),
  isLoggedIn: selectIsLoggedIn(state),
  isMeFetching: selectIsMeFetching(state),
  isVerificationRequired: selectIsVerificationRequired(state),
  noTrial: plan === 'elements' ? true : selectNoTrial(state),
  paymentCollectionType: selectPaymentCollectionType(state),
  targetCadence: plan === 'elements' ? 'monthly' : selectTargetCadence(state),
  trialPeriod: selectFreeTrialDuration(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  changeCadence: (cadence: PlanCadence) =>
    dispatch(checkoutActions.billingDetails.cadenceSwitch.clicked(cadence)),
});

export const CheckoutContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withProviders({ design: 'v1', isDarkModeSupported: false })
)(CheckoutContainerBase);
