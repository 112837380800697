import { PrivacyVisibility } from 'src/common/types/privacy';

export const SUB_MENU = [
  {
    icon: 'account_circle',
    slug: 'profile',
    title: 'My Profile',
  },
  {
    icon: 'palette',
    slug: 'appearance',
    title: 'Player Appearance',
  },
  {
    icon: 'lock',
    slug: 'settings',
    title: 'Privacy & Security',
  },
  {
    icon: 'paid',
    slug: 'billing',
    title: 'Plan & Billing',
  },
];

type VisibilityOption = {
  description: string;
  label: string;
  value: PrivacyVisibility;
};

export const VISIBILITY_OPTIONS: VisibilityOption[] = [
  {
    description: 'Anyone with a link can view.',
    label: 'Public',
    value: 'public',
  },
  {
    description: 'Private on your account, but embeddable anywhere.',
    label: 'Hide on Streamable',
    value: 'hidden_on_streamable',
  },
  {
    description: 'Only you will be able to view.',
    label: 'Private',
    value: 'private',
  },
];

export const PRIVACY_SETTING = {
  BY_LINK: 0,
  ONLY_ME: 1,
  PASSWORD_PROTECTED: 2,
};
