import React from 'react';

export const PricingLink = () => (
  <a
    href="pricing?src_internal=upsell-pricing-link"
    target="_blank"
    rel="noopener noreferrer"
    className="pricing-link"
  >
    See all plans & pricing details
  </a>
);
