import { apiRequest } from 'src/utils/api-request';

export const fetchVideoApi = (shortcode: string) =>
  apiRequest<null>({
    path: `/videos/${shortcode}`,
  });

export const fetchVideosApi = () =>
  apiRequest<null>({
    path: `/videos`,
  });
