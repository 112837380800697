import { toast } from 'react-hot-toast';

import type { PayloadAction } from '@reduxjs/toolkit';
import {
  all,
  call,
  debounce,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { logMesssage } from 'src/common/logging/logging-actions';
import { PrivacyVisibility } from 'src/common/types/privacy';
import { videosActions } from 'src/dashboard/state/videos/videos-actions';
import {
  selectVideosIsLoading,
  selectVideoForPrivacyModal,
  selectVideoFromCacheByShortcode,
} from 'src/dashboard/state/videos/videos-selectors';
import type { VideoRepresentation } from 'src/dashboard/types';
import { replaceSearchParamsInUrl } from 'src/dashboard/utils/replace-search-params-in-url';

import { fetchVideo } from '../videos/videos-saga';
import { videoPrivacyActions } from './video-privacy-actions';
import {
  resetVideoPrivacySettings,
  updateVideoSettings,
} from './video-privacy-api';

export function* handleVisibilityChange(
  action: PayloadAction<{ shortcode: string; visibility: PrivacyVisibility }>
) {
  const { shortcode, visibility } = action.payload;

  const video: VideoRepresentation | null = yield select(
    selectVideoForPrivacyModal
  );

  if (!video || !video.shortcode) {
    yield call(toast.error, 'Something unexpected occurred');
    yield put(
      logMesssage(
        `Attempt to change visibility for ${shortcode} failed because there is no local video record for it`
      )
    );
    return;
  }

  try {
    yield call(updateVideoSettings, shortcode, {
      visibility,
    });
    yield call(fetchVideo, shortcode);
  } catch (error) {
    const { message } = error as Error;

    yield call(toast.error, message);

    yield put(
      videoPrivacyActions.videoPrivacyModal.privacySection.visibility.errored({
        shortcode,
        visibility: video.privacy_settings?.visibility || 'public',
      })
    );

    yield put(logMesssage(message));
  }
}

export function* handleAllowDownloadToggle(action: PayloadAction<string>) {
  const shortcode = action.payload;

  try {
    const video: VideoRepresentation | null = yield select(
      selectVideoForPrivacyModal
    );

    if (!video || !video.shortcode) {
      yield call(toast.error, 'Something unexpected occurred');
      yield put(
        logMesssage(
          `Attempt to toggle allow_download for ${shortcode} failed because there is no local video record for it`
        )
      );
      return;
    }

    yield call(updateVideoSettings, shortcode, {
      allow_download: video.privacy_settings!.allow_download,
    });
    yield call(fetchVideo, shortcode);
  } catch (error) {
    const { message } = error as Error;

    yield call(toast.error, message);

    yield put(
      videoPrivacyActions.videoPrivacyModal.playerPreferencesSection.allowDownload.errored(
        shortcode
      )
    );

    yield put(logMesssage(message));
  }
}

export function* handleAllowSharingToggle(action: PayloadAction<string>) {
  const shortcode = action.payload;

  try {
    const video: VideoRepresentation | null = yield select(
      selectVideoForPrivacyModal
    );

    if (!video || !video.shortcode) {
      yield call(toast.error, 'Something unexpected occurred');
      yield put(
        logMesssage(
          `Attempt to toggle allow_sharing for ${shortcode} failed because there is no local video record for it`
        )
      );
      return;
    }

    yield call(updateVideoSettings, video.shortcode, {
      allow_sharing: video.privacy_settings!.allow_sharing,
    });
    yield call(fetchVideo, shortcode);
  } catch (error) {
    const { message } = error as Error;

    yield call(toast.error, message);

    yield put(
      videoPrivacyActions.videoPrivacyModal.playerPreferencesSection.allowSharing.errored(
        shortcode
      )
    );

    yield put(logMesssage(message));
  }
}

export function* handleHideViewCountToggle(action: PayloadAction<string>) {
  const shortcode = action.payload;

  try {
    const video: VideoRepresentation | null = yield select(
      selectVideoForPrivacyModal
    );

    if (!video || !video.shortcode) {
      yield call(toast.error, 'Something unexpected occurred');
      yield put(
        logMesssage(
          `Attempt to toggle hide_view_count for ${shortcode} failed because there is no local video record for it`
        )
      );
      return;
    }

    yield call(updateVideoSettings, video.shortcode, {
      hide_view_count: video.privacy_settings!.hide_view_count,
    });
    yield call(fetchVideo, shortcode);
  } catch (error) {
    const { message } = error as Error;

    yield call(toast.error, message);

    yield put(
      videoPrivacyActions.videoPrivacyModal.playerPreferencesSection.hideViewCount.errored(
        shortcode
      )
    );

    yield put(logMesssage(message));
  }
}

export function* handleDomainRestrictionsToggle(action: PayloadAction<string>) {
  const shortcode = action.payload;

  try {
    const video: VideoRepresentation | null = yield select(
      selectVideoForPrivacyModal
    );

    if (!video || !video.shortcode) {
      yield call(toast.error, 'Something unexpected occurred');
      yield put(
        logMesssage(
          `Attempt to toggle domain_restrictions for ${shortcode} failed because there is no local video record for it`
        )
      );
      return;
    }

    yield call(updateVideoSettings, video.shortcode, {
      domain_restrictions: video.privacy_settings!.domain_restrictions,
    });
    yield call(fetchVideo, shortcode);
  } catch (error) {
    const { message } = error as Error;

    yield call(toast.error, message);

    yield put(
      videoPrivacyActions.videoPrivacyModal.restrictionSection.domainRestrictions.errored(
        shortcode
      )
    );
    yield put(logMesssage(message));
  }
}

export function* handleAllowedDomainChange(
  action: PayloadAction<{ shortcode: string; allowedDomain: string }>
) {
  const { shortcode, allowedDomain } = action.payload;

  try {
    yield call(updateVideoSettings, shortcode, {
      allowed_domain: allowedDomain,
    });
    yield call(fetchVideo, shortcode);
    yield put(
      videoPrivacyActions.videoPrivacyModal.restrictionSection.allowedDomainInput.saved()
    );
  } catch (error) {
    const { message } = error as Error;

    yield call(toast.error, message);

    yield put(logMesssage(message));
  }
}

export function* handlePasswordChange(
  action: PayloadAction<{ shortcode: string; password: string }>
) {
  const { shortcode, password } = action.payload;

  try {
    yield call(updateVideoSettings, shortcode, {
      password,
    });
    yield call(fetchVideo, shortcode);
    yield put(
      videoPrivacyActions.videoPrivacyModal.restrictionSection.passwordProtection.turnedOn(
        shortcode
      )
    );
  } catch (error) {
    const { message } = error as Error;

    yield call(toast.error, message);

    yield put(logMesssage(message));
  }
}

export function* handlePasswordTurnedOff(action: PayloadAction<string>) {
  try {
    const shortcode = action.payload;
    yield call(updateVideoSettings, shortcode, {
      password: null,
    });
    yield call(fetchVideo, shortcode);
  } catch (error) {
    const { message } = error as Error;

    yield call(toast.error, message);

    yield put(logMesssage(message));
  }
}

function* handleResetModalConfirmClick(action: PayloadAction<string>) {
  try {
    const shortcode = action.payload;
    yield put(videoPrivacyActions.videoPrivacyModal.resetModal.resetting());
    yield call(resetVideoPrivacySettings, shortcode);
    yield call(fetchVideo, shortcode);
    yield put(videoPrivacyActions.videoPrivacyModal.resetModal.resetSuccess());
  } catch (error) {
    const { message } = error as Error;

    yield call(toast.error, message);

    yield put(videoPrivacyActions.videoPrivacyModal.resetModal.resetFail());
    yield put(logMesssage(message));
  }
}

export function* handleOpenVideoPrivacyModal(action: PayloadAction<string>) {
  const { payload: shortcode } = action;

  const newParams = new URLSearchParams(window.location.search);
  newParams.set('modal', 'video-privacy');
  newParams.set('shortcode', shortcode);

  yield call(
    [window.history, 'pushState'],
    null,
    '',
    replaceSearchParamsInUrl(newParams)
  );
}

export function* handleCloseVideoPrivacyModal() {
  const newParams = new URLSearchParams(window.location.search);
  newParams.delete('modal');
  newParams.delete('shortcode');

  yield call(
    [window.history, 'pushState'],
    null,
    '',
    replaceSearchParamsInUrl(newParams)
  );
}

export function* handleVideoPrivacyModalRendered() {
  const areVideosFetching: boolean = yield select(selectVideosIsLoading);

  // Wait for videos to be fetched before checking if we need to trigger the modal
  if (areVideosFetching) {
    yield take(videosActions.dashboard.fetchVideos.fulfilled);
  }

  const searchParams = new URLSearchParams(window.location.search);

  if (
    searchParams.get('modal') === 'video-privacy' &&
    searchParams.get('shortcode') !== null
  ) {
    const video: VideoRepresentation | null = yield select(
      selectVideoFromCacheByShortcode,
      searchParams.get('shortcode')!
    );

    /**
     * If the video is not currently in cache we don't want to trigger the modal
     * because it'll show up once the video gets into cache (i.e. after navigation)
     * causing a bug like opening of the modal
     */
    if (video) {
      yield put(
        videoPrivacyActions.videoPrivacyModal.triggeredBySearchParams(
          searchParams.get('shortcode')!
        )
      );
    }
  }
}

export function* watchVideoPrivacySaga() {
  yield all([
    takeEvery(
      videoPrivacyActions.videoPrivacyModal.privacySection.visibility.changed,
      handleVisibilityChange
    ),
    takeEvery(
      videoPrivacyActions.videoPrivacyModal.playerPreferencesSection
        .allowDownload.toggled,
      handleAllowDownloadToggle
    ),
    takeEvery(
      videoPrivacyActions.videoPrivacyModal.playerPreferencesSection
        .allowSharing.toggled,
      handleAllowSharingToggle
    ),
    takeEvery(
      videoPrivacyActions.videoPrivacyModal.playerPreferencesSection
        .hideViewCount.toggled,
      handleHideViewCountToggle
    ),
    takeEvery(
      videoPrivacyActions.videoPrivacyModal.restrictionSection
        .domainRestrictions.toggled,
      handleDomainRestrictionsToggle
    ),
    debounce(
      1000,
      videoPrivacyActions.videoPrivacyModal.restrictionSection
        .allowedDomainInput.changed,
      handleAllowedDomainChange
    ),
    takeEvery(
      videoPrivacyActions.videoPrivacyModal.restrictionSection.setPasswordButton
        .clicked,
      handlePasswordChange
    ),
    takeEvery(
      videoPrivacyActions.videoPrivacyModal.restrictionSection
        .passwordProtection.turnedOff,
      handlePasswordTurnedOff
    ),
    takeEvery(
      videoPrivacyActions.videoPrivacyModal.resetModal.confirmClicked,
      handleResetModalConfirmClick
    ),
    takeEvery(
      videoPrivacyActions.videoFooter.privacyItem.clicked,
      handleOpenVideoPrivacyModal
    ),
    takeEvery(
      videoPrivacyActions.videoPrivacyModal.closed,
      handleCloseVideoPrivacyModal
    ),
    takeEvery(
      videoPrivacyActions.videoPrivacyModal.firstRendered,
      handleVideoPrivacyModalRendered
    ),
  ]);
}
