import { browserHistory } from 'react-router';

import { ONE_GB_IN_BYTES } from 'src/common/constants';
import type { VideoRepresentation } from 'src/dashboard/types';
import type { AppDispatch } from 'src/redux';

import { createVideoFromFiles, editUserAction } from '../actions';
import { setCookie } from './cookies';

export function processUploadedFiles(
  dispatch: AppDispatch,
  files: File[],
  group?: null,
  maxLength?: number,
  maxSize?: number
): Promise<void> {
  return new Promise((resolve, reject) => {
    let filesArray = Array.from(files);

    const promises = filesArray.map(
      (file) =>
        new Promise<void>((r) => {
          if (maxSize && file.size > maxSize * ONE_GB_IN_BYTES) {
            dispatch(editUserAction({ videoExceedsLimits: 'video-toolarge' }));
            reject();
            return;
          }

          if (!maxLength) {
            r();
            return;
          }

          getLengthOfVideoFile(file, (duration) => {
            if (duration && duration > maxLength) {
              dispatch(editUserAction({ videoExceedsLimits: 'video-toolong' }));
              reject();
              return;
            }
            r();
          });
        })
    );

    Promise.all(promises).then((_) => {
      setCookie('dashboard', 'true', 90000);
      browserHistory.push(
        browserHistory.createPath(browserHistory.getCurrentLocation())
      );

      const dispatched = createVideoFromFiles(filesArray, {
        upload_source: 'web',
      })(dispatch);

      dispatched.then(() => resolve());
    });
  });
}

export function canPlayVideoFile(file: File) {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      resolve(false);
    }, 1000);
    let v = document.createElement('video');
    v.src = window.URL.createObjectURL(file);
    v.muted = true;
    v.style.visibility = 'hidden';
    v.addEventListener(
      'error',
      function (ev) {
        resolve(false);
        clearTimeout(timeout);
      },
      false
    );
    v.addEventListener(
      'canplay',
      function (ev) {
        resolve(true);
        clearTimeout(timeout);
      },
      false
    );
  });
}

export function getVideoFileMetadata(
  file: File,
  callback: (cbParams?: {
    duration: number;
    height: number;
    width: number;
  }) => void
) {
  const video = document.createElement('video');
  video.preload = 'metadata';
  video.onloadedmetadata = () => {
    callback({
      duration: video.duration,
      height: video.videoHeight,
      width: video.videoWidth,
    });
  };
  video.onerror = () => {
    callback();
  };
  if (typeof file === 'string') {
    video.src = file;
  } else {
    video.src = window.URL.createObjectURL(file);
  }
}

export function getLengthOfVideoFile(
  file: File,
  callback: (duration?: number) => void
) {
  getVideoFileMetadata(file, (data) => {
    if (!data) {
      callback();
      return;
    }
    callback(data.duration);
  });
}

export function secondsToString(seconds: number) {
  const numhours = Math.floor(seconds / 3600);
  seconds -= numhours * 3600;
  const numminutes = Math.floor(seconds / 60);
  let numseconds = (seconds - numminutes * 60).toFixed(2);
  if (+numseconds < 10) {
    numseconds = `0${numseconds}`;
  }
  if (numhours === 0) {
    if (numminutes > 0) {
      return `${numminutes}:${numseconds}`;
    }
    return `0${numseconds}`;
  }
  let string = `${numminutes}:${numseconds}`;
  if (numminutes < 10) {
    string = `0${string}`;
  }
  return `${numhours}:${string}`;
}

export function getBestFile(video: VideoRepresentation) {
  const files = getFileList(video);
  if (!files.length) {
    return null;
  }
  return files[0];
}

export function getBestSourceUrl(video: VideoRepresentation) {
  const file = getBestFile(video);
  if (!file) return null;
  return file.url;
}

export function getPlaybackUrl(video: VideoRepresentation) {
  const files = getFileList(video);
  if (!files.length) {
    return null;
  }
  if (files.length < 3) {
    return files[0].url;
  }
  return files[1].url;
}

export function getFileList(video: VideoRepresentation) {
  const fileList = [];
  for (const preset of Object.keys(video.files || {})) {
    const f = video.files[preset as keyof typeof video.files];
    if (f && f.height && f.url) {
      fileList.push({
        ...f,
        preset: preset,
      });
    }
  }
  return fileList.sort((a, b) => (b.height ?? 0) - (a.height ?? 0));
}

export function getFirstFile(video: VideoRepresentation) {
  if (!video.files) {
    return null;
  }
  let firstFile = null;
  for (const preset of Object.keys(video.files || {})) {
    const file = video.files[preset as keyof typeof video.files];
    if (!firstFile) {
      firstFile = file;
    } else if ((firstFile.date_added ?? 0) > (file?.date_added ?? 0)) {
      firstFile = file;
    }
  }
  return firstFile;
}

export function getEmbedCode(video: VideoRepresentation, options: any) {
  const {
    autoplay,
    responsive,
    height,
    width,
    muted,
    tag,
    src,
    poster,
    branding,
    loop,
    skipdelay,
    timeout,
    type,
    noControls,
  } = options;
  let code = '<iframe src="https://streamable.com/';
  let aspectRatio = width ? height / width : 0.5625;

  const { shortcode, height: videoHeight = 0, width: videoWidth = 0 } = video;

  aspectRatio = videoHeight / videoWidth;
  if (src) {
    code += 'customplayer';
  } else {
    code += `e/${shortcode}`;
  }
  if (options.id) {
    code += `/${options.id}`;
  }

  const params = [];
  if (autoplay) {
    params.push('autoplay=1');
  }
  if (noControls) {
    params.push('nocontrols=1');
  }
  if (muted) {
    params.push('muted=1');
  }
  if (src) {
    params.push('src=' + encodeURIComponent(src));
    params.push('height=' + videoHeight.toString());
    params.push('width=' + videoWidth.toString());
  }
  if (poster) {
    params.push('poster=' + encodeURIComponent(poster));
  }
  if (tag) {
    params.push('tag=' + encodeURIComponent(tag));
  }
  if (branding) {
    params.push('logo=0');
    params.push('rightsholder=' + branding);
  }
  if (loop === false) {
    params.push('loop=0');
  }
  if (type) {
    params.push('type=' + type);
  }
  if (skipdelay) {
    params.push('skipdelay=' + skipdelay);
  }
  if (timeout) {
    params.push('timeout=' + timeout * 1000);
  }

  let first = true;
  for (const param of params) {
    if (first) {
      code += '?';
    } else {
      code += '&';
    }
    code += param;
    first = false;
  }
  code += '"';

  let allow = autoplay ? ' allow="autoplay"' : '';

  if (height && width && !responsive) {
    code = `${code} width="${width}" height="${height}" frameborder="0" allowfullscreen${allow}></iframe>`;
  } else {
    code = `<div style="width:100%;height:0px;position:relative;padding-bottom:${(
      aspectRatio * 100
    ).toFixed(
      3
    )}%;">${code} frameborder="0" width="100%" height="100%" allowfullscreen${allow} style="width:100%;height:100%;position:absolute;left:0px;top:0px;overflow:hidden;"></iframe></div>`;
  }
  return code;
}
