import React from 'react';

import { Dialog } from '@headlessui/react';
import { ProcessingStep } from 'src/checkout/state/checkout-types';
import { Flex } from 'src/common/components/flex/flex';
import ShimmeringLogo from 'src/common/components/logo/shimmering';
import { Text } from 'src/common/components/text/text';

import './processing-payment-overlay.css';

type ProcessingPaymentOverlayComponentProps = {
  isShowing: boolean;
  step: ProcessingStep | null;
};

const messages: Record<ProcessingStep, string> = {
  normal: 'Processing payment.',
  degraded:
    'Hmm, this is taking longer than expected. We’ll keep trying, don’t click away!',
  failed: 'There was a problem processing your payment.',
};

const noop = () => {};

export const ProcessingPaymentOverlayComponent = ({
  isShowing,
  step,
}: ProcessingPaymentOverlayComponentProps) => {
  if (!step) {
    console.error('ProcessingPaymentOverlayComponent: step is null');
    return null;
  }
  return (
    <Dialog onClose={noop} open={isShowing}>
      <Flex
        className="processing-payment-overlay"
        alignItems="center"
        justifyContent="center"
      >
        <ShimmeringLogo
          className="processing-payment-overlay__logo"
          animated={step !== 'failed'}
        />
        {step === 'failed' ? (
          <Text className="processing-payment-overlay__text">
            {messages[step]} Please{' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className="processing-payment-overlay__link"
              href=""
              onClick={(ev) => {
                ev.preventDefault();

                if (window.zE) {
                  window.zE('webWidget', 'open');
                } else if (window.Beacon) {
                  window.Beacon('open');
                } else {
                  window.location.href = 'mailto:support@streamable.com';
                }
              }}
            >
              contact support
            </a>
          </Text>
        ) : (
          <Text className="processing-payment-overlay__text">
            {messages[step]}
          </Text>
        )}
      </Flex>
    </Dialog>
  );
};
