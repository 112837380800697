import fetch from 'isomorphic-fetch';
import { apiRequest } from 'src/utils/api-request';

import {
  BillingInfoResponse,
  CancelSubscriptionResponse,
  InvoicesResponse,
  ReactivateSubscriptionResponse,
  SubscriptionInfoResponse,
} from './subscription-types';

export const fetchSubscriptionInfoRequest = () =>
  apiRequest<SubscriptionInfoResponse>({ path: '/me/subscription/info' });

export const fetchBillingInfoRequest = () =>
  apiRequest<BillingInfoResponse>({ path: '/payments/billing_info' });

export const fetchInvoicesRequest = () =>
  apiRequest<InvoicesResponse>({ path: '/payments/invoices' });

export const cancelSubscriptionRequest = (
  reason: string,
  reason_category?: string
) =>
  apiRequest<CancelSubscriptionResponse>({
    path: '/me/plan',
    method: 'DELETE',
    body: JSON.stringify({ reason, reason_category }),
  });

export const reactivateSubscriptionRequest = () =>
  apiRequest<ReactivateSubscriptionResponse>({
    path: '/me/subscription/reactivate',
    method: 'POST',
  });

export const sendCollectedFeedbackRequest = async (
  feedback: string,
  email: string
) => {
  if (process.env.REACT_APP_COLLECT_FEEDBACK_ZAP_URL === undefined) {
    return;
  }

  return fetch(process.env.REACT_APP_COLLECT_FEEDBACK_ZAP_URL, {
    method: 'POST',
    body: JSON.stringify({
      text: feedback,
      email,
      subject: 'Feedback from cancellation flow',
    }),
    headers: {
      'Content-Type': 'application/json',
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
    },
    mode: 'no-cors',
  });
};
