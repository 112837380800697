import React from 'react';

import { CustomerLogos } from 'src/landing/components/customer-logos/customer-logos';

import { Hero } from '../hero';
import { FeaturesSkeleton } from './features-skeleton';

export const LoadingSkeleton = () => (
  <>
    <Hero page={null} isLoading />
    <CustomerLogos withLineGraphic={false} />
    <FeaturesSkeleton />
  </>
);
