import toast from 'react-hot-toast';

import { loadStripe } from '@stripe/stripe-js';
import type {
  PaymentIntent,
  PaymentIntentResult,
  SetupIntent,
  SetupIntentResult,
  Stripe,
} from '@stripe/stripe-js';
import { EventChannel, eventChannel } from 'redux-saga';
import {
  all,
  call,
  debounce,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { subscriptionActions } from 'src/app/subscription/subscription-actions';
import { fetchSubscriptionInfoRequest } from 'src/app/subscription/subscription-api';
import { SubscriptionInfoResponse } from 'src/app/subscription/subscription-types';
import { logMesssage } from 'src/common/logging/logging-actions';
import { getErrorMessage } from 'src/utils/get-error-message';

import { checkoutActions } from './checkout-actions';
import { fetchIntent } from './checkout-api';
import {
  selectClientSecret,
  selectIntentType,
  selectRedirectStatus,
} from './checkout-selectors';
import {
  FetchIntentResponse,
  IntentType,
  ProcessingStep,
} from './checkout-types';

export const INTENT_FETCH_DEBOUNCE = 300;

export function retrySteps() {
  // we will retry at 1, 2, and 3 seconds and consider that "normal"
  // we will then retry at 5, 10, 15, 30, 45, and consider that "degraded" because it is taking longer than expected
  // we will then retry once more at 60 seconds and consider that "failed" because it should never take this long under normal circumstances
  return eventChannel((emitter) => {
    const retries: [number, ProcessingStep][] = [
      [1000, 'normal'],
      [2000, 'normal'],
      [3000, 'normal'],
      [5000, 'degraded'],
      [10000, 'degraded'],
      [15000, 'degraded'],
      [30000, 'degraded'],
      [45000, 'degraded'],
      [60000, 'failed'],
    ];
    retries.forEach(([delay, kind]) => {
      setTimeout(() => {
        emitter(kind);
      }, delay);
    });
    return () => {};
  });
}

export function* updateProcessingMessage(kind: ProcessingStep) {
  yield put(checkoutActions.billingDetails.next.processingOverlay.open(kind));
}

export function* handleIntentFetchInitiated(action: { payload: string }) {
  try {
    const payload: FetchIntentResponse = yield call(
      fetchIntent,
      action.payload
    );

    yield put(
      checkoutActions.billingDetails.next.intentFetch.fulfilled(payload)
    );
  } catch (error) {
    const message: string = yield call(getErrorMessage, error);
    yield put(logMesssage(message));
    yield call(toast.error, message);
  }
}

export function* handleSuccessfulPayment() {
  const channel: EventChannel<ProcessingStep> = yield call(retrySteps);

  try {
    while (true) {
      const kind: ProcessingStep = yield take(channel);
      yield call(updateProcessingMessage, kind);

      try {
        const subscriptionInfo: SubscriptionInfoResponse = yield call(
          fetchSubscriptionInfoRequest
        );

        if (subscriptionInfo.currentPlan) {
          yield put(subscriptionActions.userSubscriptionToAPlan.fulfilled());

          // `return` is not super important here because `userSubscriptionToAPlan.fulfilled`
          // will cause a redirect to the dashboard and the saga will be cancelled
          // but it is good to be explicit inside a `while (true)` loop
          return;
        }
      } catch (error) {
        const message: string = yield call(getErrorMessage, error);
        yield put(logMesssage(message));
      }
    }
  } finally {
    channel.close();
  }
}

export function* handleProcessingPayment() {
  const channel: EventChannel<ProcessingStep> = yield call(retrySteps);
  try {
    while (true) {
      const kind: ProcessingStep = yield take(channel);
      yield call(updateProcessingMessage, kind);

      const intent: SetupIntent | PaymentIntent | null =
        yield call(retrieveIntent);

      if (!intent || intent.status === 'requires_payment_method') {
        yield call(handleFailedPayment, {});
        return;
      }

      if (intent.status === 'succeeded') {
        yield call(handleSuccessfulPayment);
        return;
      }
    }
  } finally {
    channel.close();
  }
}

export function* handleFailedPayment(action: { payload?: string }) {
  yield put(checkoutActions.billingDetails.next.processingOverlay.close());

  const message =
    action.payload || 'Payment failed. Please try a different payment method.';
  yield call(toast.error, message);

  const intentType: IntentType = yield select(selectIntentType);
  const clientSecret: string = yield select(selectClientSecret);
  yield put(
    logMesssage(
      `PAYMENT_FAILED: ${message} for ${intentType}_intent. See ${clientSecret}`
    )
  );
}

export function* retrieveIntent() {
  const clientSecret: string = yield select(selectClientSecret);
  if (!clientSecret) {
    return null;
  }

  const intentType: IntentType = yield select(selectIntentType);
  if (!intentType || (intentType !== 'payment' && intentType !== 'setup')) {
    return null;
  }

  const stripe: Stripe = yield call(
    loadStripe,
    process.env.REACT_APP_STRIPE_KEY!
  );

  if (intentType === 'payment') {
    const result: PaymentIntentResult = yield call(
      stripe.retrievePaymentIntent,
      clientSecret
    );
    if (result.error) {
      return null;
    }
    return result.paymentIntent;
  }

  if (intentType === 'setup') {
    const result: SetupIntentResult = yield call(
      stripe.retrieveSetupIntent,
      clientSecret
    );
    if (result.error) {
      return null;
    }
    return result.setupIntent;
  }

  return null;
}

export function* handleStripeRedirect() {
  const redirectStatus: string | undefined = yield select(selectRedirectStatus);
  if (!redirectStatus) {
    return;
  }

  yield put(
    checkoutActions.billingDetails.next.processingOverlay.open('normal')
  );

  const intent: SetupIntent | PaymentIntent | null = yield call(retrieveIntent);
  if (!intent || intent.status === 'requires_payment_method') {
    yield call(handleFailedPayment, {});
    return;
  }

  if (intent.status === 'succeeded') {
    yield call(handleSuccessfulPayment);
    return;
  }

  if (intent.status === 'processing') {
    yield call(handleProcessingPayment);
    return;
  }
}

export function* checkoutSaga() {
  yield all([
    debounce(
      INTENT_FETCH_DEBOUNCE,
      checkoutActions.billingDetails.next.intentFetch.initiated,
      handleIntentFetchInitiated
    ),
    takeEvery(
      checkoutActions.billingDetails.next.paymentStatus.failed,
      handleFailedPayment
    ),
    takeEvery(
      checkoutActions.billingDetails.initFromQuery,
      handleStripeRedirect
    ),
  ]);
}
