import * as Sentry from '@sentry/browser';
import fetch from 'isomorphic-fetch';
import { selectCurrentPlan } from 'src/app/subscription/subscription-selectors';
import type { SubscriptionStatus } from 'src/app/subscription/subscription-types';
import { ONE_GB_IN_BYTES } from 'src/common/constants';
import { meActions } from 'src/common/state/me/me-actions';
import { selectAllMeState } from 'src/common/state/me/me-selectors';
import { MEDIA_STATUS } from 'src/common/types/media';
import {
  selectActiveLabel,
  selectActiveLabelId,
} from 'src/dashboard/state/labels/labels-selectors';
import { isStaticLabel } from 'src/dashboard/state/labels/labels-utils';
import { dashboardUpsellActions } from 'src/dashboard/state/upsell/upsell-actions';
import { videosActions } from 'src/dashboard/state/videos/videos-actions';
import type { LabelWithCount } from 'src/dashboard/types';
import { env } from 'src/env';
import { selectStorageUpsellTargetPlanId } from 'src/pricing/state/pricing-selectors';
import type { RootState, AppDispatch } from 'src/redux';
import { generateUniqueId } from 'src/utils/generate-unique-id';

import { upload } from '../api/video';
import { selectIsMobile } from '../misc/selectors';
import {
  type EditVideoActionPayload,
  editVideoAction,
  shortcodeReceivedAction,
  uploadAction,
  uploadProgressAction,
} from './actions';
import { log } from './log';
import { errorVideo } from './thunks';
import { trackUploadCompleted, trackUploadProgress } from './uploads';

const DEFAULT_FREE_PLAN_MAX_SIZE = 0.25;
const CREATE_VIDEO_RESTRICTED_SUBSCRIPTION_STATUSES: SubscriptionStatus[] = [
  'UNPAID',
];

const initVideoInternal = (
  shortcode: string,
  { original_size, original_name, upload_source, title }: EditVideoActionPayload
) =>
  fetch(`${env.API_HOST}/videos/${shortcode}/initialize`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
    },
    body: JSON.stringify({
      original_size,
      original_name,
      upload_source,
      title,
    }),
  })
    .then(async (response) => {
      if (!response.ok) {
        const error = await response.json();
        throw Error(error.message);
      }
    })
    .catch((error) => {
      Sentry.captureException(error);
    });

const initVideo =
  (shortcode: string, payload: EditVideoActionPayload) =>
  (dispatch: AppDispatch) => {
    dispatch(editVideoAction({ shortcode, ...payload }));
    return initVideoInternal(shortcode, payload);
  };

type CreateVideoOptions = {
  title?: string;
  upload_source?: string;
};
export const createVideoFromFile =
  (file: File, options: CreateVideoOptions = {}) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const store = getState();
    const me = selectAllMeState(store);
    const currentPlan = selectCurrentPlan(store);
    const activeLabelId = selectActiveLabelId(store);
    const isActiveLabelStatic = isStaticLabel(activeLabelId);

    let fileName: string = 'unnamed';
    if (!selectIsMobile(store)) {
      const n = file.name.lastIndexOf('.');
      fileName = n > -1 ? file.name.substr(0, n) : file.name;
    }

    // Show the storage upsell modal
    const upsellPlanId = selectStorageUpsellTargetPlanId(store);
    if (upsellPlanId) {
      dispatch(
        dashboardUpsellActions.storageUpsellModal.triggered(upsellPlanId)
      );
    }

    if (
      !currentPlan ||
      (me.subscription_status &&
        CREATE_VIDEO_RESTRICTED_SUBSCRIPTION_STATUSES.includes(
          me.subscription_status
        ))
    ) {
      const maxSize = me.plan_max_size || DEFAULT_FREE_PLAN_MAX_SIZE;
      if (file.size > maxSize * ONE_GB_IN_BYTES) {
        let maxSizeStr = `${maxSize.toString()}GB`;
        if (maxSize < 1) {
          maxSizeStr = `${(maxSize * 1000).toString()}MB`;
        }
        const message = `This video exceeds the max file size for your plan (${maxSizeStr})`;
        dispatch(meActions.editUser({ videoExceedsLimits: 'video-toolarge' }));

        log(
          'File upload too large: ' +
            fileName +
            ' (' +
            file.type +
            ', ' +
            file.size +
            ' bytes)'
        );

        throw new Error(message);
      }
    }

    const local_id = generateUniqueId('alpha', 8);

    // Watch out, these might be overriden later by the editVideo
    const videoPayload: {
      local_id: string;
      title: string;
      labels?: Array<LabelWithCount>;
    } = {
      local_id,
      title: options.title || fileName,
    };

    if (activeLabelId && !isActiveLabelStatic) {
      const activeLabel = selectActiveLabel(store)!;
      videoPayload.labels = [activeLabel];
    }

    dispatch(videosActions.upload.createVideo(videoPayload));
    if (!file.size) {
      const error = 'Invalid file size (Try Safari if on iOS)';
      dispatch(
        editVideoAction({
          shortcode: local_id,
          status: MEDIA_STATUS.ERROR,
          error,
        })
      );
      throw new Error('Invalid file size (Try Safari if on iOS)');
    }

    const query = new URLSearchParams({
      size: file.size.toString(),
      version: env.VERSION,
    });
    if (activeLabelId && !isActiveLabelStatic) {
      query.append('label_id', activeLabelId.toString());
    }
    const url = `${env.API_HOST}/uploads/shortcode?${query}`;
    return fetch(url, {
      credentials: 'include',
      headers: {
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          const error = await response.json();
          throw Error(error.message);
        }
        return response.json();
      })
      .then((metadata) => {
        if (!metadata.shortcode) {
          throw Error('No shortcode present in metadata');
        }
        const shortcode = metadata.shortcode;
        const upload_size = file.size;
        metadata.local_id = local_id;

        dispatch(shortcodeReceivedAction(metadata));
        dispatch(
          initVideo(shortcode, {
            original_size: upload_size,
            original_name: file.name,
            upload_source: options.upload_source,
            title: options.title || fileName,
          })
        );

        let recentSpeeds: Array<number> = [];
        const startTime = Date.now();
        const task = upload(
          file,
          { ...metadata.options, ...options },
          metadata,
          (err, data) => {
            if (err) {
              log(
                `[FE Error] upload task from createVideoFromFile failed for ${shortcode} with error: ${err.message}`
              );

              dispatch(errorVideo(shortcode, err.message));
              return;
            }

            const upload_time = Date.now() - startTime;
            dispatch(
              uploadAction({
                shortcode,
                upload_metadata: metadata,
                transcode_options: options,
                transcode_data: data,
                upload_time,
                upload_percent: 100,
                percent: 0,
                waitingToTranscode: true,
              })
            );
            trackUploadCompleted(shortcode);
          },
          (data) => {
            if (data.speed != null) {
              recentSpeeds.unshift(data.speed > 0 ? data.speed : 0);
            }
            recentSpeeds = recentSpeeds.slice(0, 100);
            const sum = recentSpeeds.reduce((a, b) => a + b, 0);
            const avg = sum / recentSpeeds.length;
            dispatch(
              uploadProgressAction({
                shortcode,
                upload_percent: data.percent,
                upload_speed: avg,
                retries: data.retries,
              })
            );
            trackUploadProgress(shortcode, Math.round(data.percent));
          },
          () => {
            dispatch(
              editVideoAction({
                shortcode,
                waitingToUpload: false,
              })
            );
          },
          (message: string) => {
            if (!message.includes('0 retries] uploadPart(')) {
              log(`[FE Error] error ${message}`);
            }
          }
        );

        dispatch(
          editVideoAction({
            shortcode,
            waitingToUpload: task.queued,
          })
        );

        return metadata;
      })
      .catch((err) => {
        Sentry.captureException(err);
        dispatch(
          editVideoAction({
            shortcode: local_id,
            status: MEDIA_STATUS.ERROR,
            error: err.message,
          })
        );
      });
  };

export const createVideoFromFiles =
  (files: File[], options: CreateVideoOptions) => (dispatch: AppDispatch) => {
    const promises = files.map((file) =>
      dispatch(createVideoFromFile(file, options))
    );
    return Promise.all(promises);
  };

export const createVideo =
  (data: {}) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const store = getState();
    const activeLabelId = selectActiveLabelId(store);
    const isActiveLabelStatic = isStaticLabel(activeLabelId);
    try {
      const response = await fetch(`${env.API_HOST}/uploads/videos`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Pragma: 'no-cache',
          'Cache-Control': 'no-cache',
        },
        body: JSON.stringify({
          ...data,
          ...(activeLabelId && !isActiveLabelStatic
            ? { label_id: activeLabelId }
            : undefined),
        }),
        credentials: 'include',
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message);
      }

      const video = await response.json();
      dispatch(videosActions.upload.createVideo(video));
      return video;
    } catch (error) {
      throw error;
    }
  };
