import {
  createAction,
  type PayloadAction,
  type ActionCreator,
  type UnknownAction,
} from '@reduxjs/toolkit';
import fetch from 'isomorphic-fetch';
import { log } from 'src/actions/log';
import { env } from 'src/env';
import type { RootState, AppDispatch } from 'src/redux';

import type { LabelWithCount } from '../../types';
import { ActiveLabelId } from './labels-reducer';
import {
  selectCreateLabelName,
  selectDeleteLabelId,
  selectEditLabelId,
  selectEditNewLabelName,
} from './labels-selectors';

export const dashboardSidebarActions = {
  component: {
    labelsInitialised: createAction<LabelWithCount[]>(
      '[Dashboard Labels Sidebar component] sidebar for labels initialised'
    ),
    labelsFulfilled: createAction<LabelWithCount[]>(
      '[Dashboard Labels Sidebar component] labels fulfilled'
    ),
    labelsRejected: createAction(
      '[Dashboard Labels Sidebar component] labels rejected'
    ),
  },
  tryAgain: {
    buttonClicked: createAction(
      '[Dashboard Labels Sidebar Try Again] try again button clicked'
    ),
    labelsFulfilled: createAction<LabelWithCount[]>(
      '[Dashboard Labels Sidebar Try Again] labels fulfilled'
    ),
    labelsRejected: createAction(
      '[Dashboard Labels Sidebar Try Again] labels rejected'
    ),
  },
  labelButton: {
    clicked: createAction<ActiveLabelId>(
      '[Dashboard Labels Sidebar Label Button] label button clicked'
    ),
  },
  createLabelButton: {
    clicked: createAction(
      '[Dashboard Labels Sidebar Create Label Button] clicked'
    ),
  },
  createLabelModal: {
    cancelled: createAction('[Create Label Modal] cancelled'),
    inputChanged: createAction<string>('[Create Label Modal] input changed'),
    saveButtonClicked: createAction('[Create Label Modal] save button clicked'),
    saveLabelFulfilled: createAction<LabelWithCount>(
      '[Create Label Modal] save label fulfilled'
    ),
    saveLabelRejected: createAction<string | undefined>(
      '[Create Label Modal] save label rejected'
    ),
  },
  contextMenu: {
    deleteLabelButton: {
      clicked: createAction<number>(
        '[Dashboard Labels Sidebar ContextMenu Delete Label Button] clicked'
      ),
    },
    editLabelButton: {
      clicked: createAction<number>(
        '[Dashboard Labels Sidebar ContextMenu Edit Label Button] clicked'
      ),
    },
  },
  title: {
    contextMenu: {
      editLabelButton: {
        clicked: createAction<number>(
          '[Dashboard Labels Title ContextMenu Edit Label Button] clicked'
        ),
      },
      deleteLabelButton: {
        clicked: createAction<number>(
          '[Dashboard Labels Title ContextMenu Delete Label Button] clicked'
        ),
      },
    },
  },
  editLabelModal: {
    cancelled: createAction('[Edit Label Modal] cancelled'),
    inputChanged: createAction<string>('[Edit Label Modal] input changed'),
    saveButtonClicked: createAction('[Edit Label Modal] save button clicked'),
    saveLabelFulfilled: createAction<LabelWithCount>(
      '[Edit Label Modal] save label fulfilled'
    ),
    saveLabelRejected: createAction<string | undefined>(
      '[Edit Label Modal] save label rejected'
    ),
  },
  deleteLabelModal: {
    cancelled: createAction('[Delete Label Modal] cancelled'),
    saveButtonClicked: createAction('[Delete Label Modal] save button clicked'),
    saveLabelFulfilled: createAction<number | null>(
      '[Delete Label Modal] save label fulfilled'
    ),
    saveLabelRejected: createAction<string | undefined>(
      '[Delete Label Modal] save label rejected'
    ),
  },
  sidebar: {
    desktop: {
      closed: createAction('[Dashboard Labels Sidebar Desktop] closed'),
      toggled: createAction(
        '[Dashboard Labels Sidebar Desktop] visibility toggled'
      ),
    },
    mobile: {
      closed: createAction('[Dashboard Labels Sidebar Mobile] closed'),
      toggled: createAction(
        '[Dashboard Labels Sidebar Mobile] visibility toggled'
      ),
    },
    emptyState: {
      dismissed: createAction(
        '[Dashboard Labels Sidebar Empty State] dismissed'
      ),
    },
  },
};

type FetchActions<EntityType> = {
  fetchedStarted: ActionCreator<any>;
  fulfilled: (entity: EntityType) => PayloadAction<EntityType>;
  rejected: ActionCreator<UnknownAction>;
};

const fetchLabels =
  (actions: FetchActions<LabelWithCount[]>) =>
  async (dispatch: AppDispatch) => {
    dispatch(actions.fetchedStarted());

    try {
      // TODO: Refactor to use apiRequest
      const response = await fetch(`${env.API_HOST}/labels`, {
        credentials: 'include',
      });
      if (!response.ok) {
        throw Error(await response.text());
      }
      const { userLabels } = (await response.json()) as {
        userLabels: LabelWithCount[];
      };
      dispatch(actions.fulfilled(userLabels));
    } catch (e) {
      dispatch(actions.rejected());
      log(e);
    }
  };

export const fetchLabelsOnInitialisation = () => {
  const {
    component: {
      labelsInitialised: fetchedStarted,
      labelsFulfilled: fulfilled,
      labelsRejected: rejected,
    },
  } = dashboardSidebarActions;

  return fetchLabels({
    fetchedStarted,
    fulfilled,
    rejected,
  });
};

export const fetchLabelsOnTryAgain = () => {
  const {
    tryAgain: {
      buttonClicked: fetchedStarted,
      labelsFulfilled: fulfilled,
      labelsRejected: rejected,
    },
  } = dashboardSidebarActions;

  return fetchLabels({
    fetchedStarted,
    fulfilled,
    rejected,
  });
};

export const saveLabel =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(dashboardSidebarActions.createLabelModal.saveButtonClicked());
    const newLabelName = selectCreateLabelName(getState());

    try {
      const response = await fetch(
        `${process.env.REACT_APP_NEW_API_HOST}/labels`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Pragma: 'no-cache',
            'Cache-Control': 'no-cache',
          },
          body: JSON.stringify({
            name: newLabelName,
          }),
          credentials: 'include',
        }
      );

      if (!response.ok) {
        if (response.status >= 400 && response.status < 499) {
          const error = (await response.json()) as { message: string };
          dispatch(
            dashboardSidebarActions.createLabelModal.saveLabelRejected(
              error.message
            )
          );
          return;
        } else {
          dispatch(
            dashboardSidebarActions.createLabelModal.saveLabelRejected(
              'Something went wrong, try again in a few minutes.'
            )
          );
          return;
        }
      }

      const newLabel: LabelWithCount = {
        ...(await response.json()),
        count: 0,
      };
      dispatch(
        dashboardSidebarActions.createLabelModal.saveLabelFulfilled(newLabel)
      );
    } catch (e) {
      dispatch(dashboardSidebarActions.createLabelModal.saveLabelRejected());
      log(e);
    }
  };

export const updateLabel =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(dashboardSidebarActions.editLabelModal.saveButtonClicked());

    const labelId = selectEditLabelId(getState());
    const newLabelName = selectEditNewLabelName(getState());

    try {
      const response = await fetch(
        `${process.env.REACT_APP_NEW_API_HOST}/labels/${labelId}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Pragma: 'no-cache',
            'Cache-Control': 'no-cache',
          },
          body: JSON.stringify({
            name: newLabelName,
          }),
          credentials: 'include',
        }
      );

      if (!response.ok) {
        if (response.status >= 400 && response.status < 499) {
          const error = (await response.json()) as { message: string };
          dispatch(
            dashboardSidebarActions.editLabelModal.saveLabelRejected(
              error.message
            )
          );
          return;
        } else {
          dispatch(
            dashboardSidebarActions.editLabelModal.saveLabelRejected(
              'Something went wrong, try again in a few minutes.'
            )
          );
          return;
        }
      }

      const updatedLabel: LabelWithCount = await response.json();

      dispatch(
        dashboardSidebarActions.editLabelModal.saveLabelFulfilled(updatedLabel)
      );
    } catch (e) {
      dispatch(
        dashboardSidebarActions.editLabelModal.saveLabelRejected(
          'Something went wrong, try again in a few minutes.'
        )
      );
      log(e);
    }
  };

export const deleteLabel =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(dashboardSidebarActions.deleteLabelModal.saveButtonClicked());

    const labelId = selectDeleteLabelId(getState());

    try {
      const response = await fetch(
        `${process.env.REACT_APP_NEW_API_HOST}/labels/${labelId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Pragma: 'no-cache',
            'Cache-Control': 'no-cache',
          },
          credentials: 'include',
        }
      );

      if (!response.ok) {
        if (response.status >= 400 && response.status < 499) {
          const error = (await response.json()) as { message: string };
          dispatch(
            dashboardSidebarActions.deleteLabelModal.saveLabelRejected(
              error.message
            )
          );
          return;
        } else {
          dispatch(
            dashboardSidebarActions.deleteLabelModal.saveLabelRejected(
              'Something went wrong, try again in a few minutes.'
            )
          );
          return;
        }
      }

      dispatch(
        dashboardSidebarActions.deleteLabelModal.saveLabelFulfilled(labelId)
      );
    } catch (e) {
      dispatch(
        dashboardSidebarActions.deleteLabelModal.saveLabelRejected(
          'Something went wrong, try again in a few minutes.'
        )
      );
      log(e);
    }
  };
