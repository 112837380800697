import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'src/redux';

const selectAllVideoCaptionsState = (state: RootState) => state.videoCaptions;

const selectVideoCaptionsModalState = createSelector(
  selectAllVideoCaptionsState,
  (state) => state.videoCaptionsModal
);

export const selectVideoCaptionsModalIsShowing = createSelector(
  selectVideoCaptionsModalState,
  (modalState) => modalState.isShowing
);

export const selectVideoCaptionsShortcode = createSelector(
  selectVideoCaptionsModalState,
  (modalState) => modalState.shortcode
);

export const selectVideoCaptions = createSelector(
  selectVideoCaptionsModalState,
  (state) => state.captions
);

export const selectVideoCaptionsLoading = createSelector(
  selectVideoCaptionsModalState,
  (state) => state.isLoading
);

export const selectVideoCaptionsError = createSelector(
  selectVideoCaptionsModalState,
  (state) => state.errorMessage
);

const selectVideoCaptionsDeleteModalState = createSelector(
  selectAllVideoCaptionsState,
  (state) => state.videoCaptionsDeleteModal
);

export const selectVideoCaptionsDeleteModalIsShowing = createSelector(
  selectVideoCaptionsDeleteModalState,
  (state) => state.isShowing
);

export const selectVideoCaptionsDeleteId = createSelector(
  selectVideoCaptionsDeleteModalState,
  (state) => state.id
);

export const selectVideoCaptionsDeleteShortcode = createSelector(
  selectVideoCaptionsDeleteModalState,
  (state) => state.shortcode
);

export const selectVideoCaptionsDeleting = createSelector(
  selectVideoCaptionsDeleteModalState,
  (state) => state.isLoading
);

export const selectVideoCaptionsDeleteError = createSelector(
  selectVideoCaptionsDeleteModalState,
  (state) => state.errorMessage
);
