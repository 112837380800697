import React from 'react';
import { Link, browserHistory } from 'react-router';

import { Text } from 'src/common/components/text/text';
import { AvailablePlan } from 'src/pricing/types';

import { SuggestionCardContainer } from '../suggestion-card/suggestion-card-container';

type UpsellCardComponentOwnProps = {
  isLoggedIn: boolean;
  numberOfStaleVideos: number;
};

type UpsellCardComponentStateProps = {
  noTrial: boolean;
  plan: AvailablePlan | null;
  trialPeriod: number;
};

type UpsellCardComponentProps = UpsellCardComponentOwnProps &
  UpsellCardComponentStateProps;

export const UpsellCardComponent = ({
  isLoggedIn,
  noTrial,
  numberOfStaleVideos,
  plan,
  trialPeriod,
}: UpsellCardComponentProps) => {
  const handleClick = (to: string) => {
    browserHistory.push(to);
  };

  if (isLoggedIn) {
    if (!plan || !numberOfStaleVideos) {
      return null;
    }

    const to = '/upgrade?src_internal=free-upsell-keep-video';

    return (
      <SuggestionCardContainer
        callToActionLabel={noTrial ? 'Upgrade now' : 'Start free trial'}
        onClick={() => handleClick(to)}
        storeKey="upsell-for-free-tier"
        title="Need to save videos for longer?"
        plan={plan}
      >
        {noTrial ? (
          <Text>
            You have {numberOfStaleVideos} video
            {numberOfStaleVideos > 1 ? 's' : ''} that will be deleted soon.
            Upgrade to a <Link to={to}>paid plan</Link> to keep these videos and
            access premium features.
          </Text>
        ) : (
          <Text>
            You have {numberOfStaleVideos} video
            {numberOfStaleVideos > 1 ? 's' : ''} that will be deleted soon. Try{' '}
            {plan.name} for {trialPeriod} days or upgrade to a{' '}
            <Link to={to}>paid plan</Link> to keep these videos and access
            premium features.
          </Text>
        )}
      </SuggestionCardContainer>
    );
  }

  const to = '/signup?src_internal=card-sign-up';

  return (
    <SuggestionCardContainer
      callToActionLabel="Sign up for free"
      onClick={() => handleClick(to)}
      storeKey="upsell-for-anonymous"
      title="Need to save videos for longer?"
      className="suggestion-card--free"
    >
      <Text>
        Try Streamable for free to save your videos for 90 days, or upgrade to a{' '}
        <Link to={to}>paid plan</Link> to make sure you can access your videos
        whenever you want.
      </Text>
    </SuggestionCardContainer>
  );
};
