import type { ModalDropzoneError } from 'src/common/components/dropzone/dropzone';
import { ONE_GB_IN_BYTES } from 'src/common/constants';
import { getLengthOfVideoFile } from 'src/misc/utilities';

export type ReplaceVideoModalDropzoneError = ModalDropzoneError<
  'file-type' | 'file-count' | 'file-size' | 'file-length'
>;

export const getErrorMessage = (error?: ReplaceVideoModalDropzoneError) => {
  switch (error) {
    case 'file-count':
      return 'Can’t upload. Please select only one video';
    case 'file-type':
      return 'Can’t upload. Use a video in one of these formats: .mp4, .h264, .mov or .avi';
    case 'file-size':
      return 'Can’t upload. Your video is too large';
    case 'file-length':
      return 'Can’t upload. Your video is too long';
    case 'unexpected-error':
      return 'Can’t upload. File validation failed';
    case 'processing-error':
      return 'There was an error while processing your video file';
    default:
      return;
  }
};

export const checkError = async (
  files: File[],
  maxSize: number | null,
  maxLength: number | null
): Promise<ReplaceVideoModalDropzoneError> => {
  const video = files[0];

  if (files.length > 1) {
    return 'file-count';
  }

  if (!video.type.includes('video/')) {
    return 'file-type';
  }

  if (maxSize && video.size > maxSize * ONE_GB_IN_BYTES) {
    return 'file-size';
  }

  if (maxLength) {
    const duration: number | undefined = await new Promise((resolve) =>
      getLengthOfVideoFile(video, resolve)
    );

    if (duration && duration > maxLength) {
      return 'file-length';
    }
  }
};

export type UpsellReason = 'video-toolarge' | 'video-toolong';

export const errorToUpsellReason = (
  error: ReplaceVideoModalDropzoneError
): UpsellReason | null => {
  switch (error) {
    case 'file-size':
      return 'video-toolarge';
    case 'file-length':
      return 'video-toolong';
    default:
      return null;
  }
};
