import React from 'react';
import { Link } from 'react-router';

import classNames from 'classnames';
import { ActivatedSubscriptionInfo } from 'src/app/subscription/subscription-types';
import { Alert } from 'src/common/components/alert/alert';
import { Flex } from 'src/common/components/flex/flex';
import { Text } from 'src/common/components/text/text';
import { Toggle } from 'src/common/components/toggle/toggle';
import { DomainPrivacy, PrivacyVisibility } from 'src/common/types/privacy';
import { AvailablePlan } from 'src/pricing/types';

import { ContentSection } from '../content-section/content-section';
import { PrivacyAndSecurityRestrictions } from '../privacy-and-security-restrictions/privacy-and-security-restrictions';
import { PrivacyAndSecurityVisibility } from '../privacy-and-security-visibility/privacy-and-security-visibility';
import { ResetModal } from '../reset-modal/reset-modal';
import { SettingOption } from '../setting-option/setting-option';
import { AdvancedPrivacySettings } from './advanced-privacy-settings-component';
import './privacy-and-security-component.css';

export type PrivacyAndSecurityLayout = 'generic' | 'video';

type PrivacyAndSecurityComponentStateProps = {
  allowDownload: boolean;
  allowSharing: boolean;
  domainRestrictions: DomainPrivacy;
  allowedDomain: string | null;
  configurableViewCountEnabled: boolean;
  currentPlan: ActivatedSubscriptionInfo | null;
  proPlan?: AvailablePlan;
  hasAllowedDomainSaved: boolean;
  isAllowedDomainSaving: boolean;
  isLoggedIn: boolean;
  isPasswordPresent?: boolean;
  isResetModalShowing: boolean;
  isResetModalResetting: boolean;
  layout?: PrivacyAndSecurityLayout;
  hideViewCount: boolean;
  visibility: PrivacyVisibility;
  hasCustomPrivacySettings?: boolean;
  handleAllowDownloadToggle: () => void;
  handleAllowedDomainChange: (value: string) => void;
  handleAllowSharingToggle: () => void;
  handlePasswordSubmit?: (password: string) => void;
  handleTurnOffPasswordProtection?: () => void;
  handleResetModalCancelClick: () => void;
  handleResetModalConfirmClick: () => void;
  handleResetSettingsClick: () => void;
  handleHideViewCountToggle: () => void;
  handleVisibilityChange: (value: PrivacyVisibility) => void;
  handleDomainRestrictionsToggle: () => void;
  handleUpsellClick: (source: string) => void;
  openChangeCurrentPlanModal: (planId: string) => void;
};

type PrivacyAndSecurityComponentProps = PrivacyAndSecurityComponentStateProps;
export const PrivacyAndSecurityComponent = ({
  allowDownload,
  allowSharing,
  domainRestrictions,
  allowedDomain,
  configurableViewCountEnabled,
  currentPlan,
  proPlan,
  hasAllowedDomainSaved,
  isAllowedDomainSaving,
  isLoggedIn,
  isPasswordPresent = false,
  isResetModalShowing,
  isResetModalResetting,
  layout = 'generic',
  hasCustomPrivacySettings = false,
  hideViewCount,
  visibility,
  handleAllowDownloadToggle,
  handleAllowedDomainChange,
  handleAllowSharingToggle,
  handlePasswordSubmit,
  handleTurnOffPasswordProtection,
  handleResetModalCancelClick,
  handleResetModalConfirmClick,
  handleResetSettingsClick,
  handleHideViewCountToggle,
  handleVisibilityChange,
  handleDomainRestrictionsToggle,
  handleUpsellClick,
  openChangeCurrentPlanModal,
}: PrivacyAndSecurityComponentProps) => {
  const isPrivate = visibility === 'private';
  const isVideoPrivacy = layout === 'video';

  return (
    <>
      <Flex
        className={classNames('privacy-and-settings', {
          'privacy-and-settings--slim': isVideoPrivacy,
        })}
      >
        {!isVideoPrivacy && (
          <div className="privacy-and-settings__intro">
            <h1>Privacy & Security</h1>
            <h3>
              Change security and access preferences for your videos that don’t
              have individual privacy settings.
            </h3>
          </div>
        )}

        {isVideoPrivacy && (
          <Alert
            level="warning"
            className="alert"
            action={
              hasCustomPrivacySettings
                ? {
                    label: 'Reset defaults',
                    props: {
                      variant: 'link',
                      color: 'danger',
                      onClick: handleResetSettingsClick,
                    },
                  }
                : undefined
            }
          >
            {hasCustomPrivacySettings ? (
              <>This video has custom privacy settings</>
            ) : (
              <p>
                This video uses{' '}
                <Link className="alert-link" to="/settings">
                  default privacy settings
                </Link>
              </p>
            )}
          </Alert>
        )}

        <PrivacyAndSecurityVisibility
          currentPlan={currentPlan}
          visibility={visibility}
          handleVisibilityChange={handleVisibilityChange}
          handleUpsellClick={handleUpsellClick}
        />

        <PrivacyAndSecurityRestrictions
          domainRestrictions={domainRestrictions}
          handleDomainRestrictionsToggle={handleDomainRestrictionsToggle}
          allowedDomain={allowedDomain}
          currentPlan={currentPlan}
          proPlan={proPlan}
          hasAllowedDomainSaved={hasAllowedDomainSaved}
          isAllowedDomainSaving={isAllowedDomainSaving}
          isPasswordPresent={isPasswordPresent}
          layout={layout}
          visibility={visibility}
          handleAllowedDomainChange={handleAllowedDomainChange}
          handleUpsellClick={handleUpsellClick}
          handleTurnOffPasswordProtection={handleTurnOffPasswordProtection}
          openChangeCurrentPlanModal={openChangeCurrentPlanModal}
          handlePasswordSubmit={handlePasswordSubmit}
        />
        <ContentSection title="Player preferences">
          <SettingOption
            label="Allow downloading"
            secondaryLabel={
              <Text variant="secondary">
                Add download options to your video page and player.
              </Text>
            }
            withSeparator
            onClick={isPrivate ? undefined : handleAllowDownloadToggle}
            disabled={isPrivate}
          >
            <Toggle
              name="allow_downloading"
              checked={allowDownload}
              size="large"
              disabled={isPrivate}
            />
          </SettingOption>
          <SettingOption
            label="Allow sharing"
            secondaryLabel={
              <Text variant="secondary">
                Add share options to your video page and player.
              </Text>
            }
            withSeparator={configurableViewCountEnabled ? true : isLoggedIn}
            onClick={isPrivate ? undefined : handleAllowSharingToggle}
            disabled={isPrivate}
          >
            <Toggle
              name="allow_sharing"
              checked={allowSharing}
              size="large"
              disabled={isPrivate}
            />
          </SettingOption>
          {configurableViewCountEnabled && (
            <SettingOption
              label="View count"
              secondaryLabel={
                <Text variant="secondary">
                  Add the view count to your video page and player.
                </Text>
              }
              withSeparator={isLoggedIn}
              onClick={isPrivate ? undefined : handleHideViewCountToggle}
              disabled={isPrivate}
            >
              <Toggle
                name="show_view_count"
                checked={!hideViewCount}
                size="large"
                disabled={isPrivate}
              />
            </SettingOption>
          )}
        </ContentSection>
        {!isVideoPrivacy && isLoggedIn && (
          <AdvancedPrivacySettings
            layout={layout}
            handleResetSettingsClick={handleResetSettingsClick}
          />
        )}
      </Flex>

      <ResetModal
        isShowing={isResetModalShowing}
        layout={layout}
        disableConfirm={isResetModalResetting}
        onClose={handleResetModalCancelClick}
        onConfirm={handleResetModalConfirmClick}
      />
    </>
  );
};
