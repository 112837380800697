import { ActivatedSubscriptionInfo } from 'src/app/subscription/subscription-types';

import { ANNUAL_CADENCE, MONTHLY_CADENCE } from '../constants';
import { AvailablePlan } from '../types';

export const isUpgrade = (
  currentPlan: ActivatedSubscriptionInfo,
  targetPlan: AvailablePlan
): boolean => {
  const cadence =
    currentPlan.cadence === ANNUAL_CADENCE ? ANNUAL_CADENCE : MONTHLY_CADENCE;

  return targetPlan[cadence].price > currentPlan.price;
};
