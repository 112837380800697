import { AvailablePlan } from 'src/pricing/types';

export const calculateAnnualDiscount = (plan?: AvailablePlan) => {
  if (!plan) {
    return 0;
  }

  const annualPrice = Number(plan.annual.price);
  const monthlyPrice = Number(plan.monthly.price);

  const discount = Math.round(100 * (1 - annualPrice / 12 / monthlyPrice));

  return discount > 35 && discount < 40 ? 40 : discount;
};
