import React from 'react';
import { browserHistory } from 'react-router';

import moment from 'moment';
import {
  ActivatedSubscriptionInfo,
  SubscriptionInfo,
} from 'src/app/subscription/subscription-types';
import { ANNUAL_CADENCE } from 'src/pricing/constants';
import { AvailablePlan, PlanCadence } from 'src/pricing/types';
import { getPlanQueryParamFromPlanId } from 'src/pricing/utils/get-plan-query-param-from-plan-id';

import { Plan } from '../plan';
import { isDowngrade } from '../utils';

const FreeUserUpgradeButton = ({
  offerTrial,
  plan,
  cadence,
}: {
  offerTrial: boolean;
  plan: AvailablePlan;
  cadence: PlanCadence;
}) => {
  const redirectToCheckout = () => {
    const query = new URLSearchParams(window.location.search);
    const srcInternal = query.get('src_internal') || 'pricing';
    const planQueryParam = getPlanQueryParamFromPlanId(plan[cadence].id);
    const from = query.get('from');

    try {
      browserHistory.push(
        `/upgrade?plan=${planQueryParam}&cadence=${cadence}&src_internal=${srcInternal}${from ? `&from=${from}` : ''}`
      );
    } catch {
      browserHistory.push(`/upgrade?plan=${planQueryParam}&cadence=${cadence}`);
    }
  };

  return (
    <Plan.CallToAction onClick={redirectToCheckout}>
      {offerTrial ? 'Start free trial' : 'Upgrade Now'}
    </Plan.CallToAction>
  );
};

export type SwitchCadenceButtonProps = {
  currentPlan: ActivatedSubscriptionInfo | null;
  plan: AvailablePlan;
  subscriptionOnRenew: SubscriptionInfo | null;
  targetCadence: PlanCadence;
  willRenewOnDifferentPlan: boolean;
  enterChangeFlow: (planId: string) => void;
  hasPaidPlan: boolean;
  offerTrial: boolean;
  isPayPalUser: boolean;
};

export const SelfServePlanButtonComponent = ({
  currentPlan,
  plan,
  subscriptionOnRenew,
  targetCadence,
  willRenewOnDifferentPlan,
  enterChangeFlow,
  offerTrial,
  hasPaidPlan,
  isPayPalUser,
}: SwitchCadenceButtonProps) => {
  if (!hasPaidPlan) {
    return (
      <FreeUserUpgradeButton
        cadence={targetCadence}
        plan={plan}
        offerTrial={offerTrial}
      />
    );
  }

  const isCurrentPlan =
    currentPlan?.cadence === targetCadence &&
    plan[targetCadence].id === currentPlan.id;

  if (isCurrentPlan) {
    return (
      <Plan.CallToAction disabled onClick={undefined}>
        Current plan
      </Plan.CallToAction>
    );
  }

  if (isPayPalUser) {
    return (
      <Plan.CallToAction
        onClick={() => enterChangeFlow(plan[targetCadence].id)}
      >
        Upgrade
      </Plan.CallToAction>
    );
  }

  const isScheduledToChangeToThisMonthlyPlan =
    willRenewOnDifferentPlan &&
    subscriptionOnRenew?.cadence === plan[targetCadence].cadence &&
    subscriptionOnRenew?.id === plan[targetCadence].id;

  if (isScheduledToChangeToThisMonthlyPlan) {
    const effectiveDate = moment(subscriptionOnRenew.startBillingDate).format(
      'MMM Do, YYYY'
    );

    return (
      <Plan.CallToAction onClick={() => browserHistory.push('/billing')}>
        {`Effective ${effectiveDate}`}
      </Plan.CallToAction>
    );
  }

  const isUserSwitchingCadence =
    plan.annual.id === currentPlan?.id || plan.monthly.id === currentPlan?.id;

  if (isUserSwitchingCadence) {
    return (
      <Plan.CallToAction
        onClick={() => enterChangeFlow(plan[targetCadence].id)}
      >
        Switch to {targetCadence === ANNUAL_CADENCE ? 'annual' : 'monthly'}
      </Plan.CallToAction>
    );
  }

  return (
    <Plan.CallToAction onClick={() => enterChangeFlow(plan[targetCadence].id)}>
      {isDowngrade(currentPlan!, plan[targetCadence]) ? 'Downgrade' : 'Upgrade'}
    </Plan.CallToAction>
  );
};
