import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import classNames from 'classnames';
import 'material-icons/iconfont/filled.css';
import 'material-icons/iconfont/outlined.css';
import 'material-icons/iconfont/round.css';
import { selectIsLoggedIn } from 'src/common/state/me/me-selectors';
import { AssignLabelsModal } from 'src/dashboard/components/assign-labels-modal/assign-labels-modal';
import { CreateLabelModal } from 'src/dashboard/components/create-label-modal/create-label-modal';
import { DeleteLabelModal } from 'src/dashboard/components/delete-label-modal/delete-label-modal';
import { EditLabelModal } from 'src/dashboard/components/edit-label-modal/edit-label-modal';
import { EmbedPreviewModal } from 'src/dashboard/components/embed-preview-modal';
import { LabelsSidebar } from 'src/dashboard/components/labels-sidebar/labels-sidebar';
import { ReplaceVideoModal } from 'src/dashboard/components/replace-video-modal/replace-video-modal';
import { UpsellModal } from 'src/dashboard/components/upsell-modal/upsell-modal';
import { Upsell } from 'src/dashboard/components/upsell/upsell';
import { VideoCaptionsDeleteModal } from 'src/dashboard/components/video-captions-delete-modal/video-captions-delete-modal';
import { VideoCaptionsModal } from 'src/dashboard/components/video-captions-modal/video-captions-modal';
import { VideoPrivacyModal } from 'src/dashboard/components/video-privacy-modal/video-privacy-modal';
import { fetchLabelsOnInitialisation } from 'src/dashboard/state/labels/labels-actions';
import { getVideosForActiveLabel } from 'src/dashboard/state/videos/videos-selectors';
import type { RootState } from 'src/redux';
import { shouldRenderSidebar } from 'src/utils/selectors/should-render-sidebar';

import VideosList from './VideosList';

export type DashboardComponentProps = {
  isLoggedIn: boolean;
  query?: string;
  fetchLabelsOnInitialisation: () => void;
  videoCount: number;
  withSidebar: boolean;
};

const DashboardComponent = ({
  query,
  fetchLabelsOnInitialisation,
  videoCount,
  withSidebar,
}: DashboardComponentProps) => {
  useEffect(() => {
    if (withSidebar) {
      fetchLabelsOnInitialisation();
    }
  }, [fetchLabelsOnInitialisation, withSidebar]);

  return (
    <div
      className={classNames('sb-dashboard-container', {
        'sb-dashboard-container--empty': videoCount === 0,
      })}
    >
      {withSidebar && <LabelsSidebar asSlideOver withOverlay />}
      <VideosList query={query} />
      <CreateLabelModal />
      <EditLabelModal />
      <DeleteLabelModal />
      <AssignLabelsModal />
      <VideoPrivacyModal />
      <ReplaceVideoModal />
      <VideoCaptionsModal />
      <VideoCaptionsDeleteModal />
      <EmbedPreviewModal />
      <UpsellModal />
      <Upsell />
    </div>
  );
};

const mapDispatchToProps = {
  fetchLabelsOnInitialisation,
};

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: selectIsLoggedIn(state),
  videoCount: getVideosForActiveLabel(state).length,
  withSidebar: shouldRenderSidebar(state),
});

export const Dashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardComponent);
