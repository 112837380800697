import type { PayloadAction } from '@reduxjs/toolkit';

import { dashboardUpsellActions } from './upsell-actions';

export type UpsellState = {
  storageUpsellModal: {
    isOpen: boolean;
  };
};

const initialState: UpsellState = {
  storageUpsellModal: {
    isOpen: false,
  },
};

export const upsellReducer = (
  state = initialState,
  action: PayloadAction<any>
): UpsellState => {
  const { type } = action;
  switch (type) {
    case dashboardUpsellActions.storageUpsellModal.triggered.type:
      return {
        ...state,
        storageUpsellModal: {
          ...state.storageUpsellModal,
          isOpen: true,
        },
      };

    case dashboardUpsellActions.storageUpsellModal.closed.type:
      return {
        ...state,
        storageUpsellModal: {
          ...state.storageUpsellModal,
          isOpen: false,
        },
      };

    default:
      return state;
  }
};
