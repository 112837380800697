import { createSelector } from '@reduxjs/toolkit';
import { selectAllMeState } from 'src/common/state/me/me-selectors';

export const selectFlags = createSelector(selectAllMeState, (me) => me.flags);

type BooleanFlag =
  | 'expose-collections-menu-link'
  | 'expose-new-analytics-summary-page'
  | 'expose-video-manage-captions';
type StringFlag =
  | 'payment-collection-type'
  | 'restrict-free-videos-playback-by-owner-plan';
type Flag = BooleanFlag | StringFlag;

export const getSelectFlag = (flag: Flag) =>
  createSelector(selectFlags, (flags) => flags[flag]);

export const getSelectBooleanFlag = (flag: BooleanFlag) =>
  createSelector(getSelectFlag(flag), (flag) => !!flag);

export type PaymentCollectionType = 'card-element' | 'payment-element';
export const selectPaymentCollectionType = createSelector(
  selectFlags,
  (flags): PaymentCollectionType =>
    (flags['payment-collection-type'] as PaymentCollectionType) ||
    'card-element'
);
