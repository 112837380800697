import { createAction } from '@reduxjs/toolkit';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { subscriptionActions } from '../subscription/subscription-actions';
import { handleFetchSubscriptionInfo } from '../subscription/subscription-saga';
import { justSubscribed } from '../subscription/subscription-utils';
import { finishedAppLoading, appLoadingSlice } from './app-loading-reducer';

export const loadApp = createAction(`[${appLoadingSlice}] app started loading`);

export function* handleLoadApp() {
  yield all([call(handleFetchSubscriptionInfo)]);

  const userJustSubscribed: boolean = yield call(justSubscribed);
  if (userJustSubscribed) {
    yield put(subscriptionActions.tracking.subscribed());
  }

  yield put(finishedAppLoading());
}

export function* watchLoadApp() {
  yield takeEvery(loadApp, handleLoadApp);
}
