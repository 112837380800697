import React, { useState } from 'react';

import classNames from 'classnames';
import { CaretDown } from 'phosphor-react';

import { Button } from '../button/button';
import './accordion-item.css';

type AccordionItemProps = {
  className?: string;
  title: string;
};

export const AccordionItem: React.FunctionComponent<AccordionItemProps> = ({
  className,
  title,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li
      className={classNames('accordion-item', className, {
        'accordion-item--open': isOpen,
      })}
    >
      <Button
        className="accordion-item__button"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        <CaretDown
          className="accordion-item__button__icon"
          size={24}
          weight="bold"
        />
      </Button>

      <div className={classNames('accordion-item__content')}>{children}</div>
    </li>
  );
};
