import { createAction } from '@reduxjs/toolkit';
import fetch from 'isomorphic-fetch';
import { all, difference, intersection, prop } from 'ramda';
import { deselectAllAction, log } from 'src/actions';
import { selectVideosLabelIdsByShortcode } from 'src/dashboard/state/videos/videos-selectors';
import type {
  BulkLabelSelectionState,
  Label,
  LabelWithCount,
  VideoRepresentation,
} from 'src/dashboard/types';
import { getSelectedVideos, getVideosFromShortcodes } from 'src/misc/selectors';
import type { RootState, AppDispatch } from 'src/redux';

import { selectLabelsByIds } from '../labels/labels-selectors';
import { selectShortcodesToAssignLabelsTo } from './assign-labels-selectors';

export const dashboardAssignLabelsActions = {
  actionsBar: {
    editLabelsButton: {
      clicked: createAction<{ shortcodes: string[] }>(
        '[Dashboard Actions Bar Edit Labels Button] clicked'
      ),
    },
  },
  assignLabelsModal: {
    cancelled: createAction('[Assign Labels Modal] cancelled'),
    close: createAction('[Assign Labels Modal] close'),
    saved: createAction('[Assign Labels Modal] saved'),
    saveAssignmentsFulfilled: createAction<{
      shortcode: string;
      labelIdsToAdd: number[];
      labelIdsToRemove: number[];
      partiallyFulfilled?: boolean;
    }>('[Assign Labels Modal] assignments fulfilled'),
    saveAssignmentsRejected: createAction<string | undefined>(
      '[Assign Labels Modal] assignments rejected'
    ),
    labelCheckbox: {
      changed: createAction('[Assign Labels Modal Label Checkbox] changed'),
    },
    createLabelButtonClicked: createAction(
      '[Assign Labels Modal] create labels button clicked'
    ),
    createLabelFulfilled: createAction<LabelWithCount>(
      '[Assign Labels Modal] create labels fulfilled'
    ),
    createLabelRejected: createAction<string | undefined>(
      '[Assign Labels Modal] create labels rejected'
    ),
  },
  dragAndDropAssign: {
    dismissErrorAlert: createAction(
      '[Drag and Drop Assign] dismiss error alert'
    ),
    startDragging: createAction('[Drag and Drop Assign] start dragging'),
    stopDragging: createAction('[Drag and Drop Assign] stop dragging'),
    saveFulfilled: createAction<{
      shortcodes: string[];
      labelId: number;
      delta: number;
    }>('[Drag and Drop Assign] save fulfilled'),
    saveRejected: createAction<string>('[Drag and Drop Assign] save rejected'),
  },
  videoCard: {
    moreMenu: {
      editLabelsButton: {
        clicked: createAction<string>(
          '[Dashboard Video Card More Edit Labels Button] clicked'
        ),
      },
    },
    videoLabelsButton: {
      clicked: createAction<string>(
        '[Dashboard Video Card Video Labels Button] clicked'
      ),
    },
  },
};

export const dragAndDropCompleted =
  (labelId: Label['id'], draggedShortcode: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(dashboardAssignLabelsActions.dragAndDropAssign.stopDragging());

    const selected: VideoRepresentation[] = getSelectedVideos(getState());
    const shortcodes =
      selected.length > 0
        ? selected.map(({ shortcode }) => shortcode!)
        : [draggedShortcode];

    const videos: VideoRepresentation[] = getVideosFromShortcodes(
      shortcodes,
      getState()
    );
    const alreadyWithLabel = videos.filter((video) =>
      video.labels?.some((label) => label.id === labelId)
    );
    const delta = videos.length - alreadyWithLabel.length;

    if (delta === 0) {
      return;
    }

    return fetch(
      `${process.env.REACT_APP_NEW_API_HOST}/labels/${labelId}/assign`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Pragma: 'no-cache',
          'Cache-Control': 'no-cache',
        },
        body: JSON.stringify({
          // sorting is not really needed but it facilitates testing
          shortcodes: shortcodes.sort(),
        }),
        credentials: 'include',
      }
    )
      .then(async (response) => {
        if (response.ok) {
          dispatch(
            dashboardAssignLabelsActions.dragAndDropAssign.saveFulfilled({
              shortcodes,
              labelId,
              delta,
            })
          );
          dispatch(deselectAllAction());
        } else {
          const content = await response.json();
          throw new Error(content.message);
        }
      })
      .catch((error) => {
        const [label] = selectLabelsByIds(getState(), [labelId]);
        dispatch(
          dashboardAssignLabelsActions.dragAndDropAssign.saveRejected(
            `${shortcodes.length} item(s) could not be moved to "${label.name}". Please try again.`
          )
        );
        log(error);
      });
  };

export const saveAssignments =
  (labelsSelection: BulkLabelSelectionState) =>
  async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    dispatch(dashboardAssignLabelsActions.assignLabelsModal.saved());

    const shortcodes = selectShortcodesToAssignLabelsTo(getState());
    const promises = shortcodes.map(async (shortcode) => {
      const existingLabelIds = selectVideosLabelIdsByShortcode(
        getState(),
        shortcode
      );

      // `indeterminate` means it was not changed
      const unchangedLabelIds = labelsSelection
        .filter((label) => label.indeterminate)
        .map(prop('id'));

      // for the label ids that did change, out of all the ones that were marked as checked
      // the ones that were not already assigned to the video need to be added
      const checkedLabelIds = labelsSelection
        .filter((entry) => !entry.indeterminate && entry.checked)
        .map(prop('id'));
      const labelIdsToAdd = difference(checkedLabelIds, existingLabelIds);

      // any label that is unchecked will _not_ be present in the final state of the video
      const uncheckedLabelIds = labelsSelection
        .filter((entry) => !entry.indeterminate && !entry.checked)
        .map(prop('id'));
      // now, out of all those unchecked labels,
      // we can only remove labels that were already existing,
      // hence the use of `intersection`
      const labelIdsToRemove = intersection(
        uncheckedLabelIds,
        existingLabelIds
      );

      // everything that is checked is part of the final list of labels,
      // as well as any existing label that was not changed
      const labelIds = [
        ...checkedLabelIds,
        ...intersection(existingLabelIds, unchangedLabelIds),
      ];

      return fetch(
        `${process.env.REACT_APP_NEW_API_HOST}/videos/${shortcode}/labels`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Pragma: 'no-cache',
            'Cache-Control': 'no-cache',
          },
          body: JSON.stringify({
            // sorting is not really needed but it facilitates testing
            labels: labelIds.sort(),
          }),
          credentials: 'include',
        }
      )
        .then(async (response) => {
          if (response.ok) {
            dispatch(
              dashboardAssignLabelsActions.assignLabelsModal.saveAssignmentsFulfilled(
                {
                  shortcode,
                  labelIdsToAdd,
                  labelIdsToRemove,
                  partiallyFulfilled: shortcodes.length > 1,
                }
              )
            );
          }

          return { success: response.ok, shortcode };
        })
        .catch(() => ({ success: false, shortcode }));
    });

    return Promise.all(promises)
      .then((responses) => {
        if (!all(prop('success'), responses)) {
          const failedShortcodes = responses
            .filter((response) => response.success === false)
            .map((response) => response.shortcode);

          dispatch(
            dashboardAssignLabelsActions.assignLabelsModal.saveAssignmentsRejected(
              `The videos ${failedShortcodes.join(
                ', '
              )} failed to assign the labels, please try again in a few minutes.`
            )
          );
        } else {
          // if all requests succeeded we can close the modal and deselect all videos
          dispatch(deselectAllAction());
          dispatch(dashboardAssignLabelsActions.assignLabelsModal.close());
        }
      })
      .catch((e) => {
        dispatch(
          dashboardAssignLabelsActions.assignLabelsModal.saveAssignmentsRejected(
            'Something went wrong, try again in a few minutes.'
          )
        );
        log(e);
      });
  };

export const createLabelButtonClicked =
  (newLabelName: string) => async (dispatch: AppDispatch) => {
    dispatch(
      dashboardAssignLabelsActions.assignLabelsModal.createLabelButtonClicked()
    );

    try {
      const response = await fetch(
        `${process.env.REACT_APP_NEW_API_HOST}/labels`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Pragma: 'no-cache',
            'Cache-Control': 'no-cache',
          },
          body: JSON.stringify({ name: newLabelName }),
          credentials: 'include',
        }
      );

      if (!response.ok) {
        if (response.status >= 400 && response.status < 499) {
          dispatch(
            dashboardAssignLabelsActions.assignLabelsModal.createLabelRejected(
              ((await response.json()) as { message: string }).message
            )
          );
          return;
        } else {
          dispatch(
            dashboardAssignLabelsActions.assignLabelsModal.createLabelRejected(
              'Something went wrong, try again in a few minutes.'
            )
          );
          return;
        }
      }

      const newLabel: LabelWithCount = {
        ...(await response.json()),
        count: 0,
      };
      dispatch(
        dashboardAssignLabelsActions.assignLabelsModal.createLabelFulfilled(
          newLabel
        )
      );
    } catch (e) {
      dispatch(
        dashboardAssignLabelsActions.assignLabelsModal.createLabelRejected(
          (e as any)?.message ||
            'Something went wrong, try again in a few minutes.'
        )
      );
      log(e);
    }
  };
