import { connect } from 'react-redux';

import type { RootState } from 'src/redux';

import { Landing as LandingComponent } from './landing';
import { landingActions } from './state/landing-actions';
import {
  selectLandingPageData,
  selectLandingPageIsDataLoading,
} from './state/landing-selectors';

const mapStateToProps = (state: RootState) => ({
  page: selectLandingPageData(state),
  isPageDataLoading: selectLandingPageIsDataLoading(state),
});

const mapDispatchToProps = {
  fetchPageData: landingActions.fetchPage.initiated,
};

export const Landing = connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingComponent);
