export const ONE_GB_IN_BYTES = 1073741824;
export const DEFAULT_TRIAL_PERIOD = 7;

export const ELEMENTS_PLAN_ID = 'streamable-elements-4_99-usd-monthly';
export const ELEMENTS_PLAN_STORAGE_LIMIT = 50; // 50GB
export const ELEMENTS_PLAN_FEATURES = [
  'No size or length limits',
  'Stream in 4K UltraHD',
  '50GB cloud storage',
  '200GB bandwidth for your audience',
  'No 90 day video retention limit',
  'Streamable branding and Ads',
  'Video privacy settings',
];

export const ELEMENTS_PLAN = {
  name: 'Elements',
  description: 'Built for professional creators with advanced video hosting.',
  annual: null,
  monthly: {
    id: ELEMENTS_PLAN_ID,
    cadence: 'monthly',
    name: 'Elements',
    price: 4.99,
  },
  features: ELEMENTS_PLAN_FEATURES.map((feature) => ({
    description: feature,
  })),
};
