import { connect } from 'react-redux';

import { editUserAction } from 'src/actions';
import {
  selectMaxVideoLengthForPlan,
  selectMaxVideoSizeForPlan,
} from 'src/common/state/me/me-selectors';
import { replaceVideoActions } from 'src/dashboard/state/replace-video/replace-video-actions';
import type { RootState, AppDispatch } from 'src/redux';

import { type UpsellReason } from '../replace-video-modal-utils';
import { ReplaceVideoModalDropzoneComponent } from './replace-video-modal-dropzone-component';

type ReplaceVideoModalDropzoneComponentOwnProps = {};

const mapStateToProps = (state: RootState) => ({
  maxVideoSizeForPlan: selectMaxVideoSizeForPlan(state),
  maxVideoLengthForPlan: selectMaxVideoLengthForPlan(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  showUpsell: (reason: UpsellReason) =>
    dispatch(editUserAction({ videoExceedsLimits: reason })),
  uploadReplaceVideo: (videoFile: File) =>
    dispatch(replaceVideoActions.replaceVideo.initiated(videoFile)),
});

export const ReplaceVideoModalDropzone = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReplaceVideoModalDropzoneComponent) as unknown as (
  p: ReplaceVideoModalDropzoneComponentOwnProps
) => JSX.Element;
