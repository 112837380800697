import type { Reducer } from '@reduxjs/toolkit';

import { subscriptionActions, invoicesActions } from './subscription-actions';
import type {
  InvoicesResponse,
  SubscriptionInfoResponse,
  SubscriptionState,
} from './subscription-types';

const initialState: SubscriptionState = {
  subscriptionIsLoading: true,
  currentPlan: null,
  nextPlan: null,
  availablePlans: [],
  cancelScheduledPlanModal: {
    isShowing: false,
    isLoading: false,
    errorMessage: null,
  },
  subscribeToElementsPlanModal: {
    isLoading: false,
    errorMessage: null,
  },
  invoices: {
    failed: false,
    loading: false,
    items: null,
  },
  card: undefined,
  isCancelPlanModalShowing: false,
  isContinuePlanModalShowing: false,
  isPlanCancelling: false,
  isPlanReactivating: false,
};

export const subscriptionReducer: Reducer<SubscriptionState> = (
  state = initialState,
  action
): SubscriptionState => {
  switch (action.type) {
    case subscriptionActions.closeCancelPlanModal.type: {
      return { ...state, isCancelPlanModalShowing: false };
    }
    case subscriptionActions.closeContinuePlanModal.type: {
      return { ...state, isContinuePlanModalShowing: false };
    }
    case subscriptionActions.openCancelPlanModal.type: {
      return { ...state, isCancelPlanModalShowing: true };
    }
    case subscriptionActions.openContinuePlanModal.type: {
      return { ...state, isContinuePlanModalShowing: true };
    }
    case subscriptionActions.subscriptionInfoFetched.type: {
      const data = action.payload as SubscriptionInfoResponse;
      return {
        ...state,
        card: data.card || undefined,
        currentPlan: data.currentPlan,
        nextPlan: data.nextPlan,
        availablePlans: data.availablePlans,
        subscriptionIsLoading: false,
      };
    }
    case subscriptionActions.cancelScheduledPlanModalOpened.type: {
      return {
        ...state,
        cancelScheduledPlanModal: {
          ...state.cancelScheduledPlanModal,
          isShowing: true,
        },
      };
    }
    case subscriptionActions.cancelScheduledPlanModalClosed.type: {
      return {
        ...state,
        cancelScheduledPlanModal: {
          isShowing: false,
          isLoading: false,
          errorMessage: null,
        },
      };
    }
    case subscriptionActions.cancelScheduledPlanModalBusy.type: {
      return {
        ...state,
        cancelScheduledPlanModal: {
          ...state.cancelScheduledPlanModal,
          isLoading: true,
          errorMessage: null,
        },
      };
    }
    case subscriptionActions.cancelSchedulePlanFulfilled.type: {
      return {
        ...state,
        cancelScheduledPlanModal: {
          isShowing: false,
          isLoading: false,
          errorMessage: null,
        },
      };
    }
    case subscriptionActions.cancelSchedulePlanFailed.type: {
      const errorMessage = action.payload as string;
      return {
        ...state,
        cancelScheduledPlanModal: {
          ...state.cancelScheduledPlanModal,
          isLoading: false,
          errorMessage,
        },
      };
    }
    case subscriptionActions.subscribeToElementsPlan.initiated.type: {
      return {
        ...state,
        subscribeToElementsPlanModal: {
          ...state.subscribeToElementsPlanModal,
          isLoading: true,
          errorMessage: null,
        },
      };
    }
    case subscriptionActions.subscribeToElementsPlan.failed.type: {
      const errorMessage = (action.payload as { message: string }).message;
      return {
        ...state,
        subscribeToElementsPlanModal: {
          ...state.subscribeToElementsPlanModal,
          isLoading: false,
          errorMessage,
        },
      };
    }
    case subscriptionActions.subscribeToElementsPlan.fulfilled.type: {
      return {
        ...state,
        subscribeToElementsPlanModal: {
          ...state.subscribeToElementsPlanModal,
          isLoading: false,
          errorMessage: null,
        },
      };
    }
    case invoicesActions.invoices.fetchStarted.type: {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          loading: true,
          failed: false,
        },
      };
    }
    case invoicesActions.invoices.fetchFailed.type: {
      return {
        ...state,
        invoices: {
          ...state.invoices,
          loading: false,
          failed: true,
        },
      };
    }
    case invoicesActions.invoices.fetchFulfilled.type: {
      const data = action.payload as InvoicesResponse;
      return {
        ...state,
        invoices: {
          items: data.invoices,
          loading: false,
          failed: false,
        },
      };
    }
    case subscriptionActions.subscriptionCancelled.type: {
      const updatedState = {
        ...state,
        isCancelPlanModalShowing: false,
        isPlanCancelling: false,
        nextPlan: null,
      };

      if (state.currentPlan) {
        if (state.currentPlan.status === 'TRIAL') {
          return { ...updatedState, currentPlan: null };
        }

        return {
          ...updatedState,
          currentPlan: {
            ...state.currentPlan,
            status: 'CANCELLED',
          },
        };
      }

      return updatedState;
    }
    case subscriptionActions.subscriptionReactivated.type: {
      const updatedState = {
        ...state,
        isContinuePlanModalShowing: false,
        isPlanReactivating: false,
      };

      if (state.currentPlan) {
        const { status, ...nextPlan } = state.currentPlan;
        return {
          ...updatedState,
          currentPlan: {
            ...state.currentPlan,
            status: 'PAID',
          },
          nextPlan: nextPlan,
        };
      }

      return updatedState;
    }
    case subscriptionActions.cancelSubscription.type: {
      return { ...state, isPlanCancelling: true };
    }
    case subscriptionActions.reactivateSubscription.type: {
      return { ...state, isPlanReactivating: true };
    }
    default:
      return state;
  }
};
