import React, { useEffect, useRef } from 'react';

import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { Flex } from '../flex/flex';
import { Input, InputProps } from '../input/input';
import './checkbox.css';

type CheckboxProps = Omit<InputProps, 'type'> & { indeterminate?: boolean };

export const Checkbox = ({
  checked,
  className,
  indeterminate,
  ...rest
}: CheckboxProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && typeof indeterminate === 'boolean') {
      inputRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      className={classnames('sb-checkbox__container', className)}
    >
      <Input
        type="checkbox"
        className="sb-checkbox__input"
        checked={checked}
        ref={inputRef}
        {...rest}
      />
      <Flex
        alignItems="center"
        justifyContent="center"
        className={classnames('sb-checkbox__custom-checkbox', {
          'sb-checkbox__custom-checkbox--checked': checked,
          'sb-checkbox__custom-checkbox--indeterminate': indeterminate,
        })}
        aria-hidden="true"
      >
        {checked && !indeterminate && <FontAwesomeIcon icon={faCheck} />}
        {indeterminate && <FontAwesomeIcon icon={faMinus} />}
      </Flex>
    </Flex>
  );
};
