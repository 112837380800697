import type { PropsWithChildren } from 'react';
import ReactMarkdown from 'react-markdown';

import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

type MarkdownProps = PropsWithChildren<{
  withRemark?: boolean;
  withRehype?: boolean;
  className?: string;
}>;

export const Markdown = ({
  children,
  className,
  withRemark = false,
  withRehype = true,
}: MarkdownProps) => (
  // @ts-ignore
  <ReactMarkdown
    className={className}
    {...(withRehype ? { rehypePlugins: [rehypeRaw] } : {})}
    {...(withRemark ? { remarkPlugins: [remarkGfm] } : {})}
  >
    {children}
  </ReactMarkdown>
);
