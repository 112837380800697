import { connect } from 'react-redux';

import { dashboardSuggestionsActions } from 'src/dashboard/state/suggestions/suggestions-actions';
import { selectIsDismissed } from 'src/dashboard/state/suggestions/suggestions-selectors';
import type { RootState, AppDispatch } from 'src/redux';

import {
  SuggestionCardComponent,
  type SuggestionCardComponentOwnProps,
} from './suggestion-card-component';

const mapStateToProps = (
  state: RootState,
  ownProps: SuggestionCardComponentOwnProps
) => ({
  isDismissed: selectIsDismissed(ownProps.storeKey)(state),
});

const mapDispatchToProps = (
  dispatch: AppDispatch,
  ownProps: SuggestionCardComponentOwnProps
) => ({
  dismiss: () => {
    dispatch(
      dashboardSuggestionsActions.suggestionCard.dismissButton.clicked(
        ownProps.storeKey
      )
    );
  },
});

export const SuggestionCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SuggestionCardComponent);
