import { useEffect, useRef } from 'react';

import { BACKGROUND_VIDEOS } from '../constants';

export const useAnimateSlider = ({
  sliderRef,
  areVideosLoaded,
  speed,
  isPlaying,
}) => {
  const leftRef = useRef(0);

  useEffect(() => {
    let request;

    if (sliderRef.current && areVideosLoaded && isPlaying) {
      const helper = sliderRef.current.children[BACKGROUND_VIDEOS.length];

      const moveLeft = () => {
        sliderRef.current.style.transform = `translateX(${leftRef.current}px)`;
        const { left } = helper.getBoundingClientRect();
        if (left > 0) {
          leftRef.current -= speed;
        } else {
          leftRef.current = 0;
        }
        request = window.requestAnimationFrame(moveLeft);
      };

      moveLeft();
    }

    return () => {
      window.cancelAnimationFrame(request);
    };
  }, [sliderRef, areVideosLoaded, isPlaying, speed]);
};
