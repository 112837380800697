import React from 'react';

import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { Flex } from '../flex/flex';
import { Text } from '../text/text';
import './error-message.css';

type ErrorMessageProps = {
  className?: string;
  hideIcon?: boolean;
  message?: string;
};

export const ErrorMessage = ({
  className,
  hideIcon = false,
  message,
}: ErrorMessageProps) =>
  message ? (
    <Flex
      direction="row"
      alignItems="center"
      className={classnames('sb-error-container', className)}
      role="alert"
    >
      {!hideIcon && (
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          width={16}
          title="Warning Icon"
        />
      )}
      <Text>{message}</Text>
    </Flex>
  ) : null;
