import type {
  FacebookAdditionalEventPayload,
  FacebookEventPayload,
  FacebookEventTypes,
} from 'src/common/custom-typings';

function triggerFacebookEvent<
  T extends FacebookEventPayload,
  U extends FacebookAdditionalEventPayload,
>(event: FacebookEventTypes, payload: T, additionalPayload: U) {
  if (window.fbq) {
    try {
      window.fbq('track', event, payload, additionalPayload);
    } catch (e) {}
  }
}

export const trackFacebookStartTrialEvent = (
  payload: FacebookEventPayload,
  additionalPayload: FacebookAdditionalEventPayload
) => {
  triggerFacebookEvent<FacebookEventPayload, FacebookAdditionalEventPayload>(
    'StartTrial',
    payload,
    additionalPayload
  );
};
