import React from 'react';

import classNames from 'classnames';

import { AccordionItem } from './accordion-item';
import './accordion.css';

type AccordionProps = {
  children: React.ReactNode;
  className?: string;
};

const Accordion = ({ className, children }: AccordionProps) => (
  <ul className={classNames('accordion', className)}>{children}</ul>
);

Accordion.Item = AccordionItem;

export { Accordion };
