import { List, Map } from 'immutable';
import { MEDIA_STATUS } from 'src/common/types/media';
import type { VideoRepresentation } from 'src/dashboard/types';

import type { VideoMap, VideoCache } from './videos-types';

export type VideoKey = keyof VideoRepresentation;
type VideoThumbnailUrl = VideoRepresentation['thumbnail_url'];
type Videos = Array<VideoMap>;

export function setVideo(
  videoCache: VideoCache,
  videoMapOrRepresentation: VideoMap | VideoRepresentation,
  newKey: VideoKey = 'shortcode',
  oldKey: VideoKey = 'shortcode'
) {
  let video = getVideoMap(videoMapOrRepresentation);

  const existingVideo = videoCache.get(video.get(oldKey));
  if (existingVideo) {
    // don't update thumbnail
    const currentThumbnail: VideoThumbnailUrl =
      existingVideo.get('thumbnail_url');
    const newThumbnail: VideoThumbnailUrl = video.get('thumbnail_url');

    if (
      currentThumbnail &&
      newThumbnail &&
      currentThumbnail.split('?')[0] === newThumbnail.split('?')[0]
    ) {
      video = video.delete('thumbnail_url');
    }

    return videoCache
      .delete(video.get(oldKey))
      .set(video.get(newKey), existingVideo.merge(video));
  }
  return replaceVideo(videoCache, video, newKey);
}

export function setVideos(
  videoCache: VideoCache,
  videos: Videos | Array<VideoRepresentation>,
  replace = false
) {
  let output = videoCache;
  for (const video of videos) {
    if (replace) {
      output = replaceVideo(output, video);
    } else {
      output = setVideo(output, video);
    }
  }
  return output;
}

const isVideoRepresentation = (
  video: VideoRepresentation | VideoMap
): video is VideoRepresentation => !(video instanceof Map);

const getVideoMap = (video: VideoRepresentation | VideoMap) => {
  if (isVideoRepresentation(video)) {
    return Map(video) as VideoMap;
  }
  return video;
};

const getVideoProp = (video: VideoRepresentation | VideoMap, prop: VideoKey) =>
  isVideoRepresentation(video) ? video[prop] : video.get(prop);

export function syncVideos(
  videoCache: VideoCache,
  videos: Videos | Array<VideoRepresentation>
) {
  const fetchedStatuses = new Set([
    MEDIA_STATUS.READY,
    MEDIA_STATUS.PROCESSING,
    ...videos.map((video) => getVideoProp(video, 'status')),
  ]);

  let cache = setVideos(Map(), videos);

  const shortcodes: Array<string> = videos
    .map((video) => getVideoProp(video, 'shortcode'))
    .filter(Boolean);

  videoCache.forEach((video, key) => {
    if (!fetchedStatuses.has(video.get('status'))) {
      cache = cache.set(key, Map(video.toObject() as VideoMap));
      const shortcode = video.get('shortcode');
      if (shortcode) {
        shortcodes.push(shortcode);
      }
    }
  });

  return { cache, shortcodes: List(shortcodes) };
}

export function replaceVideo(
  videoCache: VideoCache,
  videoMapOrRepresentation: VideoMap | VideoRepresentation,
  key: VideoKey = 'shortcode'
) {
  const video = getVideoMap(videoMapOrRepresentation);
  return videoCache.set(video.get(key), video);
}
