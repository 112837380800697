import React from 'react';

import { Modal } from 'src/common/components/modal/modal';
import { Text } from 'src/common/components/text/text';

import type { PrivacyAndSecurityLayout } from '../privacy-and-security/privacy-and-security-component';

type ResetModalProps = {
  isShowing: boolean;
  onClose: () => void;
  onConfirm: () => void;
  disableConfirm: boolean;
  layout: PrivacyAndSecurityLayout;
};

export const ResetModal = ({ layout, ...props }: ResetModalProps) => {
  return (
    <Modal
      {...props}
      id="reset-all-videos-privacy-modal"
      title="Confirmation required"
      confirmLabel="Reset"
      variant="danger"
      zIndexOffset={1}
    >
      <Text>
        {layout === 'video' ? (
          <>You are about to apply default privacy settings to this video.</>
        ) : (
          <>
            You are about to apply default privacy settings to{' '}
            <b>all videos.</b>
          </>
        )}
        <br />
        This action cannot be undone.
      </Text>
    </Modal>
  );
};
