import { connect } from 'react-redux';

import { dashboardUpgradeActions } from 'src/dashboard/state/upgrade/upgrade-actions';
import { selectUpgradeModalIsOpen } from 'src/dashboard/state/upgrade/upgrade-selectors';
import { selectAvailablePlans } from 'src/pricing/state/pricing-selectors';
import type { RootState } from 'src/redux';

import { UpgradeModalComponent } from './upgrade-modal-component';

const mapStateToProps = (state: RootState) => ({
  isShowing: selectUpgradeModalIsOpen(state),
  availablePlans: selectAvailablePlans(state),
});

const mapDispatchToProps = {
  onClose: dashboardUpgradeActions.upgradeModal.closed,
};

export const UpgradeModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpgradeModalComponent);
