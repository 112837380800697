import type { PayloadAction } from '@reduxjs/toolkit';

import { fetchStatsAction, fetchCurrentUserAction } from '../../actions';

export type VideoArticle = {
  url: string;
  favicon?: string;
  domain: string;
  date_added: number;
};

export type StatsPlays = {
  grouped: 'day';
  items: Array<{
    day: number;
    hour: number;
    label: string;
    month: number;
    plays: number;
    timestamp: number;
    year: number;
  }>;
};

export type StatsState = {
  domain?: string;
  plays?: StatsPlays;
  live?: {
    count: number;
  };
  articles?: Array<VideoArticle>;
};

const initialState: StatsState = {};

export const statsReducer = (
  state = initialState,
  action: PayloadAction<any>
): StatsState => {
  switch (action.type) {
    case fetchCurrentUserAction.type:
      if (action.payload.isFetching) {
        return state;
      }
      return {
        ...state,
        domain: action.payload.domains?.[0] || null,
      };
    case fetchStatsAction.type:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export const selectStatsState = (state: any) => state.stats as StatsState;
