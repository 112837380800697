import { createAction } from '@reduxjs/toolkit';
import fetch from 'isomorphic-fetch';
import { log } from 'src/actions/log';
import type { AppDispatch } from 'src/redux';

import type { BlogPost } from '../types';

export const blogPageActions = {
  blogPostsFetched: createAction('[Blog Page] blog posts fetched'),
  blogPostsFulfilled: createAction<{ hasMore: boolean; posts: BlogPost[] }>(
    '[Blog Page] blog posts fulfilled'
  ),
  blogPostsRejected: createAction('[Blog Page] blog posts rejected'),
};

export const fetchBlogPosts =
  (page: number) => async (dispatch: AppDispatch) => {
    dispatch(blogPageActions.blogPostsFetched());

    try {
      const response = await fetch(
        `${process.env.REACT_APP_NEW_API_HOST}/blog/posts?page=${page}`,
        {
          credentials: 'include',
        }
      );
      if (!response.ok) {
        throw Error(await response.json()).message;
      }
      const { hasMore, posts } = await response.json();
      dispatch(blogPageActions.blogPostsFulfilled({ hasMore, posts }));
    } catch (e) {
      dispatch(blogPageActions.blogPostsRejected());
      log(e);
    }
  };
