import React from 'react';

import { useDroppable } from '@dnd-kit/core';
import classNames from 'classnames';
import { MaterialIcon } from 'material-icons';
import { Button } from 'src/common/components/button/button';
import { Flex } from 'src/common/components/flex/flex';
import { Text } from 'src/common/components/text/text';

import {
  ContextMenu,
  ContextMenuOptionProps,
} from '../labels-context-menu/labels-context-menu';
import './label-row-button.css';

type AvailableIconRowButtonIcons = Extract<
  MaterialIcon,
  'label' | 'refresh' | 'add' | 'grid_view' | 'label_off'
>;

type LabelRowButtonProps = {
  dropTargetId?: string;
  icon: AvailableIconRowButtonIcons;
  iconClassName?:
    | 'material-icons'
    | 'material-icons-round'
    | 'material-icons-outlined';
  id: string;
  isActive?: boolean;
  isDropTarget?: boolean;
  onClick: () => void;
  label: string;
  contextMenuOptions?: ContextMenuOptionProps[];
};

export const LabelRowButton = ({
  icon,
  iconClassName = 'material-icons',
  id,
  isActive = false,
  isDropTarget = false,
  onClick,
  label,
  contextMenuOptions,
}: LabelRowButtonProps) => {
  const { setNodeRef, isOver } = useDroppable({
    id,
    disabled: !isDropTarget,
  });

  return (
    <Flex
      direction="row"
      className={classNames('label-row', {
        'label-row--active': isActive,
        'label-row--targeted': isOver && isDropTarget,
      })}
      id={id}
      ref={isDropTarget ? setNodeRef : undefined}
    >
      <Button onClick={onClick} className="label-row__button" variant="link">
        <Flex
          direction="row"
          justifyContent="center"
          className="label-row__button__icon-container"
        >
          <span className={iconClassName}>{icon}</span>
        </Flex>
        <Text as="span" className="label-row__button__text">
          {label}
        </Text>
      </Button>
      {contextMenuOptions && (
        <Flex
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="label-row__context-menu-container"
        >
          <ContextMenu options={contextMenuOptions} />
        </Flex>
      )}
    </Flex>
  );
};
