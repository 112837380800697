import { createAction } from '@reduxjs/toolkit';
import type { UserOutput } from 'src/common/types/user';

import type { MeState } from './me-types';

export const meActions = {
  nav: {
    userMenu: {
      darkModeChanged: createAction<{ dark_mode: boolean }>(
        '[Nav UserMenu] Darkmode changed'
      ),
    },
  },
  profile: {
    darkModeChanged: createAction<{ dark_mode: boolean }>(
      '[Profile] Darkmode changed'
    ),
  },
  playerAppearance: {
    colorChanged: createAction<{ color: string }>(
      '[Player Appearance] Color changed'
    ),
    clearWatermark: createAction('[Player Appearance] Clear watermark'),
    watermarkLinkChanged: createAction<{ watermark_link: string }>(
      '[Player Appearance] Watermark link changed'
    ),
    removeBrandingChanged: createAction<{ remove_branding: boolean }>(
      '[Player Appearance] Remove branding changed'
    ),
  },
  editUser: createAction<Partial<UserOutput> | Partial<MeState>>('EDIT_USER'),
  acceptTermsModal: {
    accepted: createAction('[Accept Terms Modal] accepted'),
  },
};
