import React from 'react';

import classNames from 'classnames';

import './customer-logos-line-expression.css';

export const CustomerLogosLineExpression = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="537"
    height="446"
    className={classNames('customer-logos-line-expression', className)}
  >
    <path
      d="M 19.5 442 C 54 401.5 32.493 327 5.293 303 C -28.707 273 209.793 458.944 297.293 385 C 368.293 325 228.626 153 154.293 78 C 249.959 123 459.893 170.8 534.293 2"
      fill="transparent"
      strokeWidth="4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);
