import React, { Component } from 'react';

import { getEmbedCode } from '../utilities';
import './embedMaker.css';

export default class EmbedMaker extends Component {
  constructor() {
    super();
    this.state = {};
    this.setState = this.setState.bind(this);
    this.select = this.select.bind(this);
  }

  getCode(options) {
    if (!options) {
      options = this.props.options;
    }
    return getEmbedCode(this.props.video, options);
  }

  update(opts) {
    this.setState(opts);
    const newOptions = { ...this.props.options, ...opts };
    if (this.props.onChange) {
      this.props.onChange(newOptions);
    }
  }

  select() {
    this.refs.embedCode.select();
  }

  render() {
    let height, width;
    if (this.props.video) {
      height = this.props.video.height;
      width = this.props.video.width;
    }

    return (
      <div className="embed-maker">
        <div className="embed-section-flex">
          <div className="embed-section embed-size">
            <div className="embed-section-header">Size</div>
            {this.props.video ? (
              <div>
                <div className="embed-inline-control">
                  <label>
                    <input
                      id="responsive"
                      type="radio"
                      name="responsive"
                      value="yes"
                      checked={
                        this.props.options.responsive === false ? '' : 'checked'
                      }
                      onChange={(e) => {
                        this.update({ responsive: e.target.checked });
                      }}
                    />
                    <span>Responsive</span>
                  </label>
                </div>
                <div className="embed-inline-control">
                  <label>
                    <input
                      type="radio"
                      name="responsive"
                      value="no"
                      checked={
                        this.props.options.responsive === false ? 'checked' : ''
                      }
                      onChange={(e) => {
                        if (e.target.checked && !this.props.options.width) {
                          this.update({
                            responsive: !e.target.checked,
                            width: 560,
                            height: Math.round(
                              560 *
                                (this.props.video.height /
                                  this.props.video.width)
                            ),
                          });
                        } else {
                          this.update({ responsive: !e.target.checked });
                        }
                      }}
                    />
                    <span>Fixed Size</span>
                  </label>
                </div>
              </div>
            ) : (
              ''
            )}

            {this.props.options.responsive === false ? (
              <div>
                <div
                  className="embed-size-control"
                  style={{ marginRight: '10px' }}
                >
                  <input
                    id="embed-width-control"
                    value={this.props.options.width || ''}
                    className="form-control"
                    type="text"
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (isNaN(value)) {
                        this.update({
                          width: null,
                          height: null,
                        });
                      } else {
                        this.update({
                          width: e.target.value,
                          height: height
                            ? Math.round((value * height) / width)
                            : this.state.height,
                        });
                      }
                    }}
                    placeholder="width"
                  />
                  <span className="embed-size-control-label">px</span>
                </div>
                <div className="embed-size-control">
                  <input
                    id="embed-height-control"
                    value={this.props.options.height || ''}
                    className="form-control"
                    type="text"
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (isNaN(value)) {
                        this.update({
                          height: null,
                          width: null,
                        });
                      } else {
                        this.update({
                          height: e.target.value,
                          width: width
                            ? Math.round((value * width) / height)
                            : this.state.width,
                        });
                      }
                    }}
                    placeholder="height"
                  />
                  <span className="embed-size-control-label">px</span>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="embed-section">
            <div className="embed-section-header">Customize</div>
            <div className="embed-inline-control">
              <label>
                <input
                  id="loop"
                  type="checkbox"
                  name="loop"
                  checked={this.props.options.loop ? 'checked' : ''}
                  onChange={(e) => {
                    this.update({ loop: e.target.checked });
                  }}
                />
                <span>Loop</span>
              </label>
            </div>
            <div>
              <div className="embed-inline-control">
                <label>
                  <input
                    id="autoplay"
                    type="checkbox"
                    name="autoplay"
                    checked={this.props.options.autoplay ? 'checked' : ''}
                    onChange={(e) => {
                      this.update({ autoplay: e.target.checked });
                    }}
                  />
                  <span>Autoplay</span>
                </label>
              </div>
            </div>
            <div>
              <div className="embed-inline-control">
                <label>
                  <input
                    id="nocontrols"
                    type="checkbox"
                    name="nocontrols"
                    checked={this.props.options.noControls ? 'checked' : ''}
                    onChange={(e) => {
                      this.update({ noControls: e.target.checked });
                    }}
                  />
                  <span>Hide Controls</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <textarea
          id="embed-code"
          className="form-control"
          rows="2"
          ref="embedCode"
          onFocus={this.select}
          value={this.getCode()}
          readOnly
        />
      </div>
    );
  }
}
