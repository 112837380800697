import { useEffect } from 'react';
import { browserHistory } from 'react-router';

/**
 * The logic here is that initially the user does not have a paid plan
 * because the user is not fetched. We have to wait for the user to be loaded
 * then set the hasPaidPlan based on the user's plan.
 * If after the user is fetched and has a paid plan, we redirect to the dashboard.
 */
export const useRedirectIfUserInitiallyHasPaidPlan = ({
  hasPaidPlan,
  isMeFetching,
}: {
  hasPaidPlan: boolean;
  isMeFetching: boolean;
}) => {
  useEffect(() => {
    // We have to wait for the user to be loaded before we can check if they have a paid plan
    if (!isMeFetching) {
      // setInnerHasPaidPlan(hasPaidPlan);

      if (hasPaidPlan) {
        browserHistory.push('/');
      }
    }
  }, [isMeFetching]); // leaving hasPaidPlan out from the dependencies array because we don't want to update after it was initally set
};
