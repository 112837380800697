import { createReducer } from '@reduxjs/toolkit';
import type { Caption } from 'src/dashboard/types';

import { videoCaptionsActions } from './video-captions-actions';

export type VideoCaptionsState = {
  videoCaptionsModal: {
    shortcode?: string;
    isShowing: boolean;
    isLoading: boolean;
    captions: Caption[];
    errorMessage?: string;
  };
  videoCaptionsDeleteModal: {
    shortcode?: string;
    id?: number;
    isShowing: boolean;
    isLoading: boolean;
    errorMessage?: string;
  };
};

const initialState: VideoCaptionsState = {
  videoCaptionsModal: {
    isShowing: false,
    isLoading: false,
    captions: [],
  },
  videoCaptionsDeleteModal: {
    isShowing: false,
    isLoading: false,
  },
};

export const videoCaptionsReducer = createReducer(initialState, (builder) => {
  builder.addCase(
    videoCaptionsActions.captionsModal.opened,
    (state, { payload: shortcode }) => {
      state.videoCaptionsModal.shortcode = shortcode;
      state.videoCaptionsModal.isShowing = true;
    }
  );
  builder.addCase(videoCaptionsActions.captionsModal.closed, (state) => {
    state.videoCaptionsModal.isShowing = false;
  });
  builder.addCase(videoCaptionsActions.fetch.initiated, (state) => {
    state.videoCaptionsModal.isLoading = true;
    state.videoCaptionsModal.errorMessage = undefined;
  });
  builder.addCase(
    videoCaptionsActions.fetch.fulfilled,
    (state, { payload: captions }) => {
      state.videoCaptionsModal.captions = captions;
      state.videoCaptionsModal.isLoading = false;
    }
  );
  builder.addCase(
    videoCaptionsActions.fetch.failed,
    (state, { payload: error }) => {
      state.videoCaptionsModal.errorMessage = error;
      state.videoCaptionsModal.isLoading = false;
    }
  );
  builder.addCase(
    videoCaptionsActions.captionsDeleteModal.opened,
    (state, { payload }) => {
      state.videoCaptionsDeleteModal.shortcode = payload.shortcode;
      state.videoCaptionsDeleteModal.id = payload.id;
      state.videoCaptionsDeleteModal.isShowing = true;
    }
  );
  builder.addCase(videoCaptionsActions.captionsDeleteModal.closed, (state) => {
    // Not clearing shortcode because it is needed in the saga
    // state.videoCaptionsDeleteModal.shortcode = undefined;
    state.videoCaptionsDeleteModal.id = undefined;
    state.videoCaptionsDeleteModal.isShowing = false;
    state.videoCaptionsDeleteModal.errorMessage = undefined;
  });
  builder.addCase(videoCaptionsActions.delete.initiated, (state) => {
    state.videoCaptionsDeleteModal.errorMessage = undefined;
    state.videoCaptionsDeleteModal.isLoading = true;
  });
  builder.addCase(videoCaptionsActions.delete.fulfilled, (state) => {
    state.videoCaptionsDeleteModal.isLoading = false;
    state.videoCaptionsDeleteModal.isShowing = false;
  });
  builder.addCase(
    videoCaptionsActions.delete.failed,
    (state, { payload: error }) => {
      state.videoCaptionsDeleteModal.isLoading = false;
      state.videoCaptionsDeleteModal.errorMessage = error;
    }
  );
});
