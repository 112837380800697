// @ts-ignore
import FaCloudUpload from 'react-icons/lib/fa/cloud-upload';

type AllVideosEmptyStateProps = {
  isMobile: boolean;
};

export const AllVideosEmptyState = ({ isMobile }: AllVideosEmptyStateProps) => (
  <>
    <div className="placeholder__upload-icon">
      <FaCloudUpload />
    </div>
    <p className="placeholder__header">Select videos to upload</p>
    {!isMobile && (
      <p className="placeholder__subtitle">Or drag and drop video files</p>
    )}
  </>
);
