import { createReducer, type PayloadAction } from '@reduxjs/toolkit';
import type { PlanCadence } from 'src/pricing/types';

import { checkoutActions } from './checkout-actions';
import type {
  CheckoutQueryParams,
  FetchIntentResponse,
  IntentType,
  ProcessingStep,
  RedirectStatus,
} from './checkout-types';

export type CheckoutState = {
  query: {
    cadence?: PlanCadence;
    plan?: string;
    intentId?: string;
    redirectStatus?: RedirectStatus;
  };
  billingDetails: {
    next: {
      clientSecret: string | null;
      intentType: IntentType | null;
      processingOverlay: {
        isOpen: boolean;
        step: ProcessingStep | null;
      };
    };
  };
};

const initialState: CheckoutState = {
  query: {},
  billingDetails: {
    next: {
      clientSecret: null,
      intentType: null,
      processingOverlay: {
        isOpen: false,
        step: null,
      },
    },
  },
};

export const checkoutReducer = createReducer(initialState, (builder) => {
  builder.addCase(
    checkoutActions.billingDetails.cadenceSwitch.clicked,
    (state) => {
      state.billingDetails.next.clientSecret = null;
    }
  );

  builder.addCase<string, PayloadAction<CheckoutQueryParams>>(
    checkoutActions.billingDetails.initFromQuery.type,
    (state, { payload }) => {
      state.query.cadence = payload.cadence;
      state.query.plan = payload.plan;
      state.query.intentId = payload.setup_intent || payload.payment_intent;
      state.query.redirectStatus = payload.redirect_status;

      if (payload.setup_intent_client_secret) {
        state.billingDetails.next.clientSecret =
          payload.setup_intent_client_secret;
        state.billingDetails.next.intentType = 'setup';
      } else if (payload.payment_intent_client_secret) {
        state.billingDetails.next.clientSecret =
          payload.payment_intent_client_secret;
        state.billingDetails.next.intentType = 'payment';
      }
    }
  );

  builder.addCase<string, PayloadAction<FetchIntentResponse>>(
    checkoutActions.billingDetails.next.intentFetch.fulfilled.type,
    (state, { payload }) => {
      state.billingDetails.next.clientSecret = payload.client_secret;
      state.billingDetails.next.intentType = payload.type;
    }
  );

  builder.addCase<string, PayloadAction<ProcessingStep>>(
    checkoutActions.billingDetails.next.processingOverlay.open.type,
    (state, { payload }) => {
      state.billingDetails.next.processingOverlay.isOpen = true;
      state.billingDetails.next.processingOverlay.step = payload;
    }
  );

  builder.addCase(
    checkoutActions.billingDetails.next.processingOverlay.close,
    (state) => {
      state.billingDetails.next.processingOverlay.isOpen = false;
      state.billingDetails.next.processingOverlay.step = null;
    }
  );
});
