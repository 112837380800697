import { createAction } from '@reduxjs/toolkit';

import type { ShowUpsellCardParams } from './suggestions-types';

export const dashboardSuggestionsActions = {
  suggestionCard: {
    shown: createAction<ShowUpsellCardParams>(
      '[Dashboard Suggestion Card] shown'
    ),
    closed: createAction('[Dashboard Suggestion Card] closed'),
    accepted: createAction('[Dashboard Suggestion Card] accepted'),
    dismissButton: {
      clicked: createAction<string>(
        '[Dashboard Suggestion Card] dismiss button clicked'
      ),
    },
  },
};
