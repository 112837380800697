import { createAction } from '@reduxjs/toolkit';
import { meActions } from 'src/common/state/me/me-actions';

import type {
  CancelSubscriptionResponse,
  InvoicesResponse,
  ReactivateSubscriptionResponse,
  SubscriptionInfoResponse,
} from './subscription-types';

export const subscriptionActions = {
  // TODO: Reuse action from user-actions.ts as saga will react regardless
  editUser: createAction<
    { loading_plan: boolean } & Partial<CancelSubscriptionResponse>
  >(meActions.editUser.type),

  fetchSubscriptionInfo: createAction('[subscription] fetch subscription info'),
  subscriptionInfoFetched: createAction<SubscriptionInfoResponse>(
    '[subscription] subscription info fetched'
  ),
  confirmCancelScheduledPlan: createAction(
    '[subscription] confirm cancel scheduled plan'
  ),
  cancelScheduledPlanModalOpened: createAction(
    '[subscription] cancel scheduled plan modal opened'
  ),
  cancelScheduledPlanModalClosed: createAction(
    '[subscription] cancel scheduled plan modal closed'
  ),
  cancelScheduledPlanModalBusy: createAction(
    '[subscription] cancel scheduled plan modal busy'
  ),
  cancelSchedulePlanFulfilled: createAction(
    '[subscription] cancel scheduled plan fulfilled'
  ),
  cancelSchedulePlanFailed: createAction<string>(
    '[subscription] cancel scheduled plan failed'
  ),
  openCancelPlanModal: createAction('[subscription] open cancel plan modal'),
  closeCancelPlanModal: createAction('[subscription] close cancel plan modal'),
  openContinuePlanModal: createAction(
    '[subscription] open continue plan modal'
  ),
  closeContinuePlanModal: createAction(
    '[subscription] close continue plan modal'
  ),
  cancelSubscription: createAction<{ reason: string; reasonCategory?: string }>(
    '[subscription] cancel subscription'
  ),
  subscriptionCancelled: createAction<CancelSubscriptionResponse>(
    '[subscription] subscription cancelled'
  ),
  reactivateSubscription: createAction(
    '[subscription] reactivate subscription'
  ),
  subscriptionReactivated: createAction<ReactivateSubscriptionResponse>(
    '[subscription] subscription reactivated'
  ),
  subscribeToElementsPlan: {
    initiated: createAction<{ plan: string }>(
      '[subscription] subscribe to elements plan initiated'
    ),
    fulfilled: createAction(
      '[subscription] subscribe to elements plan fulfilled'
    ),
    failed: createAction<{ message: string }>(
      '[subscription] subscribe to elements plan failed'
    ),
  },
  tracking: {
    subscribed: createAction('[subscription tracking] subscribed'),
  },
  userSubscriptionToAPlan: {
    fulfilled: createAction(
      '[subscription] user subscription to a plan fulfilled'
    ),
  },
  sendCancelFeedback: {
    initiated: createAction<{ feedback: string }>(
      '[subscription] send cancel feedback initiated'
    ),
    fulfilled: createAction('[subscription] send cancel feedback fulfilled'),
  },
};

export const invoicesActions = {
  invoices: {
    fetchStarted: createAction<void>(`[subscription Invoices] start fetching`),
    fetchFailed: createAction<void>(
      `[subscription Invoices] invoices fetch failed`
    ),
    fetchFulfilled: createAction<InvoicesResponse>(
      `[subscription Invoices] fetch fulfilled`
    ),
  },
};
