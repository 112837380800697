import React from 'react';

import moment from 'moment';
import { Text } from 'src/common/components/text/text';

import './small-print-details.css';

type SmallPrintDetailsComponentProps = {
  noTrial: boolean;
  trialPeriod: number;
};

export const SmallPrintDetailsComponent = ({
  noTrial,
  trialPeriod,
}: SmallPrintDetailsComponentProps) => {
  const trialEndDate = moment().add(trialPeriod, 'days').format('MMM Do');

  return (
    <ul className="small-print-details">
      <li>
        <Text>
          <a
            target="_blank"
            href="https://terms.streamable.com/"
            rel="noreferrer"
          >
            Terms of Service
          </a>{' '}
          apply and you acknowledge the{' '}
          <a
            target="_blank"
            href="https://privacy.streamable.com/"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </Text>
      </li>
      {!noTrial && (
        <li>
          <Text>
            You'll only be charged on {trialEndDate}, after your {trialPeriod}
            -day free trial ends.
          </Text>
        </li>
      )}
    </ul>
  );
};
