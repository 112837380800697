import React, { useMemo } from 'react';
// @ts-ignore
import FaExclamationTriangle from 'react-icons/lib/fa/exclamation-triangle';

import classnames from 'classnames';
import DraggableVideoCard from 'src/app/dnd/video-card';
import type { VideoRepresentation } from 'src/dashboard/types';
import type { AppDispatch } from 'src/redux';

import { cancel, deleteVideo } from '../../../actions';
import '../../../misc/rc-checkbox.css';
import './videoItem.css';

type GridItemProps = {
  video: VideoRepresentation;
  userTier: string;
  selected: boolean;
  dispatch: AppDispatch;
  toggleDeleteModal: () => void;
  toggleSelect: () => void;
  toggleEmbedModal: () => void;
  togglePrivacyModal: () => void;
  toggleFileListModal: () => void;
  handleEditLabelsClick: () => void;
  children: React.ReactNode;
};

const GridItem = ({
  video,
  userTier,
  selected,
  dispatch,
  toggleDeleteModal,
  toggleSelect,
  toggleEmbedModal,
  togglePrivacyModal,
  toggleFileListModal,
  handleEditLabelsClick,
  children,
}: GridItemProps) => {
  const ErrorContent = useMemo(() => {
    if (!video.flagged && video.status !== 3) {
      return null;
    }

    const CommunityGuidelines = video.flagged ? (
      <a
        href="https://streamable.com/community-guidelines"
        target="_blank"
        referrerPolicy="no-referrer"
        rel="noreferrer"
      >
        Community Guidelines
      </a>
    ) : null;

    const DismissButton = video.flagged ? (
      <button onClick={() => dispatch(deleteVideo(video))}>Dismiss</button>
    ) : null;

    if (video.flagged === 1) {
      return (
        <>
          <p>
            This video has been removed for violating our {CommunityGuidelines}.
          </p>
          {DismissButton}
        </>
      );
    }

    if (video.flagged === 2) {
      return (
        <>
          <p>
            This video has been disabled due to a claim of copyright
            infringement. You can dismiss this notice or review options set out
            in our {CommunityGuidelines}.
          </p>
          <div>
            {DismissButton}
            &nbsp;
            <button
              onClick={() =>
                window.open(
                  'https://streamable.com/community-guidelines',
                  '_blank'
                )
              }
            >
              Review Options
            </button>
          </div>
        </>
      );
    }

    if (video.status === 3) {
      return (
        <>
          {video.error && (
            <p dangerouslySetInnerHTML={{ __html: video.error }}></p>
          )}
          <button onClick={() => dispatch(cancel(video, 'griditem'))}>
            OK
          </button>
        </>
      );
    }

    return null;
  }, [dispatch, video]);

  return (
    <div
      className={classnames(
        'col-md-6',
        'col-sm-12',
        'col-xl-4',
        'col-12',
        'grid',
        'video-item'
      )}
    >
      {ErrorContent ? (
        <div className="error-alert">
          <FaExclamationTriangle />
          {ErrorContent}
        </div>
      ) : (
        <div>
          {children}
          <div className="card-container">
            <DraggableVideoCard
              video={video}
              selected={selected}
              userTier={userTier}
              toggleDeleteModal={toggleDeleteModal}
              toggleEmbedModal={toggleEmbedModal}
              toggleFileListModal={toggleFileListModal}
              togglePrivacyModal={togglePrivacyModal}
              toggleSelect={toggleSelect}
              handleEditLabelsClick={handleEditLabelsClick}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default GridItem;
