import React from 'react';
import { connect } from 'react-redux';

import { useDraggable } from '@dnd-kit/core';
import classNames from 'classnames';
import { selectIsDragging } from 'src/dashboard/state/assign-labels/assign-labels-selectors';
import type { VideoRepresentation } from 'src/dashboard/types';
import { getSelectedVideos } from 'src/misc/selectors';
import type { RootState, AppDispatch } from 'src/redux';

import {
  VideoCard,
  type VideoCardProps,
} from '../videos/videoItem/subComponents/VideoCard';

type DraggableVideoCardProps = VideoCardProps & {
  dispatch: AppDispatch;
  isDragging: boolean;
  selectedVideos: VideoRepresentation[];
};

const DraggableVideoCard = ({
  dispatch,
  isDragging,
  selectedVideos,
  video,
  ...videoCardProps
}: DraggableVideoCardProps) => {
  const isSelected = selectedVideos.some(
    ({ shortcode }) => shortcode === video.shortcode
  );

  const {
    setNodeRef,
    listeners,
    isDragging: isThisCardDragging,
  } = useDraggable({
    id: video.shortcode!,
    disabled: selectedVideos.length > 1 && !isSelected,
  });

  // we support two drag-and-drop cases:
  // 1. doing it for a single video card
  // 2. selecting multiple videos and then dragging all of them
  const dragged =
    selectedVideos.length === 0 ? isThisCardDragging : isDragging && isSelected;

  return (
    <VideoCard
      ref={setNodeRef}
      video={video}
      {...videoCardProps}
      {...listeners}
      className={classNames('card', { 'card--dragged': dragged })}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedVideos: getSelectedVideos(state),
  isDragging: selectIsDragging(state),
});

export default connect(mapStateToProps)(DraggableVideoCard);
