import { PrivacySettings } from 'src/common/types/privacy';
import { apiRequest } from 'src/utils/api-request';

export const updateVideoSettings = (
  shortcode: string,
  settings: Partial<PrivacySettings & { password: string | null }>
) =>
  apiRequest<null>({
    path: `/videos/${shortcode}/settings`,
    method: 'PATCH',
    body: { ...settings },
  });

export const resetVideoPrivacySettings = (shortcode: string) =>
  apiRequest<null>({
    path: `/videos/${shortcode}/settings`,
    method: 'DELETE',
  });
