import React from 'react';

import { Modal } from 'src/common/components/modal/modal';
import { Text } from 'src/common/components/text/text';

import { ReplaceVideoModalDropzone } from './replace-video-modal-dropzone/replace-video-modal-dropzone';
import './replace-video-modal.css';

type ReplaceVideoModalComponentStateProps = {
  isShowing: boolean;
};

type ReplaceVideoModalComponentDispatchProps = {
  onClose: () => void;
};

type ReplaceVideoModalComponentProps = ReplaceVideoModalComponentStateProps &
  ReplaceVideoModalComponentDispatchProps;

export const ReplaceVideoModalComponent = ({
  isShowing,
  onClose,
}: ReplaceVideoModalComponentProps) => {
  return (
    <Modal
      id="replace-video-modal"
      className="replace-video-modal"
      isShowing={isShowing}
      onClose={onClose}
      title="Replace video"
      hideConfirm={true}
      hideCancel={true}
    >
      <Text className="replace-video-modal__instruction">
        Upload a new file to replace the original video forever but keep the
        customisations, URL, and views associated with its video page and
        anywhere it’s embedded.
      </Text>
      <ReplaceVideoModalDropzone />
    </Modal>
  );
};
