import React, { useState } from 'react';

import { faSearch, faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropDown from 'src/misc/dropdown/DropDown';

import './videos-search-and-sort.css';

type SortField = 'date_added' | 'plays' | 'title';

type SortOrder = 'ASC' | 'DESC';

type VideosSearchAndSortProps = {
  onSearchChanged: (search: string) => void;
  onSortChanged: (sortField: SortField, sortOrder: SortOrder) => void;
  search?: string;
  sortedBy: SortField;
};

export const VideosSearchAndSort = ({
  onSearchChanged,
  onSortChanged,
  search: searchProp,
  sortedBy,
}: VideosSearchAndSortProps) => {
  const [search, setSearch] = useState<string | null>(searchProp || null);
  const [searchSelected, setSearchSelected] = useState(false);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearch(searchTerm);
    onSearchChanged(searchTerm);
  };

  return (
    <div className="videos-search-and-sort">
      <button className="search-button" onClick={() => setSearchSelected(true)}>
        <FontAwesomeIcon icon={faSearch} />
        Search
      </button>
      {searchSelected || (search && search.length) ? (
        <input
          type="text"
          value={search || ''}
          className="filter-input"
          placeholder="Search by title..."
          autoFocus={true}
          onChange={onSearch}
          onBlur={() => setSearchSelected(false)}
        />
      ) : null}

      <DropDown
        className="sort-button"
        items={[
          {
            onClick: () => {
              onSortChanged('date_added', 'DESC');
            },
            title: 'Newest',
            selected: sortedBy === 'date_added',
          },
          {
            onClick: () => {
              onSortChanged('plays', 'DESC');
            },
            title: 'Popular',
            selected: sortedBy === 'plays',
          },
          {
            onClick: () => {
              onSortChanged('title', 'ASC');
            },
            title: 'A to Z',
            selected: sortedBy === 'title',
          },
        ]}
      >
        <FontAwesomeIcon icon={faSort} /> Sort
      </DropDown>
    </div>
  );
};
