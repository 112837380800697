import { createAction } from '@reduxjs/toolkit';

type PlayerPageMessageTypes = 'dashboard_back' | 'trigger_upgrade_modal';

export type PlayerPageMessage = {
  type: PlayerPageMessageTypes;
};

export const playerPagesActions = {
  receiveMessage: createAction<PlayerPageMessage>(
    '[player pages] receive message'
  ),
};
