import { compose } from 'ramda';

import { ProviderOptions } from './types';
import { withDesign } from './with-design';
import { withIsDarkModeSupported } from './with-is-dark-mode-supported';

export const withProviders = ({
  design,
  isDarkModeSupported,
}: ProviderOptions = {}) =>
  compose(withDesign(design), withIsDarkModeSupported(isDarkModeSupported));
