import { connect } from 'react-redux';

import {
  selectIsProcessingOverlayOpen,
  selectProcessingStep,
} from 'src/checkout/state/checkout-selectors';
import type { RootState } from 'src/redux';

import { ProcessingPaymentOverlayComponent } from './processing-payment-overlay-component';

const mapStateToProps = (state: RootState) => ({
  isShowing: selectIsProcessingOverlayOpen(state),
  step: selectProcessingStep(state),
});

export default connect(mapStateToProps)(ProcessingPaymentOverlayComponent);
