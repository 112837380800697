import React from 'react';

import { FAQItem } from 'src/common/components/faq/types';
import { EMAIL_ADDRESSES } from 'src/config';

import { PlanCadence } from './types';

export const PLANS = {
  free: {
    title: 'Free',
    description: 'Free, ad-supported video hosting for everyone.',
    cost: '$0',
    items: [
      {
        name: '250 MB / 10 min limit',
      },
      {
        name: '90 day video retention',
      },
      {
        name: 'Stream your videos in HD',
      },
      {
        name: 'Ad-supported playback',
      },
    ],
  },
};

export const ANNUAL_CADENCE: PlanCadence = 'annual';
export const MONTHLY_CADENCE: PlanCadence = 'monthly';
export const DEFAULT_CADENCE = ANNUAL_CADENCE;

export const FREQUENTLY_ASKED_QUESTIONS: FAQItem[] = [
  {
    title: 'Can I cancel my free trial?',
    content: (
      <p>
        All trials are zero-commitment and can be cancelled at any time in your
        account settings. If you decide to cancel during the trial period, you
        won't pay a dime.
      </p>
    ),
    isNoTrial: true,
  },
  {
    title: 'Will viewers see ads on my videos?',
    content: (
      <p>
        Viewers will never see ads on your videos if you're on the Pro or
        Business plan. On the free plan, ads will appear next to your videos
        when they are shared.
      </p>
    ),
  },
  {
    title: 'How long are videos stored?',
    content: (
      <p>
        On the Pro or Business plan, videos are only deleted upon your request.
        On the free plan, videos are deleted after 90 days.
      </p>
    ),
  },
  {
    title: 'How much bandwidth do I get?',
    content: (
      <p>
        The Pro plan comes with 1 terabyte of bandwidth (1,000GB) included per
        month. If you need more bandwidth, please{' '}
        <a
          href={`mailto:${EMAIL_ADDRESSES.sales}?Subject=Custom%20bandwidth`}
          rel="noreferrer"
          target="_blank"
        >
          contact us
        </a>
        .
      </p>
    ),
  },
  {
    title: 'What if I need a different plan?',
    content: (
      <p>
        Please email{' '}
        <a
          href={`mailto:${EMAIL_ADDRESSES.sales}`}
          target="_blank"
          rel="noreferrer"
        >
          {EMAIL_ADDRESSES.sales}
        </a>{' '}
        to set up a custom plan.
      </p>
    ),
  },
];
