import { Flex } from 'src/common/components/flex/flex';
import { Modal } from 'src/common/components/modal/modal';
import { Text } from 'src/common/components/text/text';

import './accept-terms-modal-component.scss';

type AcceptTermsModalStateProps = {
  isShowing: boolean;
};

type AcceptTermsModalDispatchProps = {
  onConfirm: () => void;
};

type AcceptTermsModalProps = AcceptTermsModalStateProps &
  AcceptTermsModalDispatchProps;

export const AcceptTermsModalComponent = ({
  isShowing,
  onConfirm,
}: AcceptTermsModalProps) => {
  return (
    <Modal
      id="accept-terms-modal"
      isShowing={isShowing}
      onConfirm={onConfirm}
      onClose={() => null}
      hideCancel
      disableClose
      confirmLabel="Agree"
      title="Terms of Service and Privacy Policy update"
    >
      <Flex direction="column" className="accept-terms__message">
        <Text>
          We are updating our{' '}
          <a
            href="https://terms.streamable.com/"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>{' '}
          and our{' '}
          <a
            href="https://privacy.streamable.com/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          . By tapping Agree below, you agree to the updated Terms of Service
          and you acknowledge receipt of our Privacy Policy.
        </Text>
        <Text>
          If you don't agree to the new Terms, you'll need to stop using
          Streamable and delete your account.
        </Text>
        <Text>
          We also encourage you to read our updated Privacy Policy to learn more
          about how we process your personal data. You can contact us at{' '}
          <a href="mailto:privacy@hopin.to">privacy@hopin.to</a> for any doubt
          and to exercise your rights.
        </Text>
      </Flex>
    </Modal>
  );
};
