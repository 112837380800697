import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { differenceWith, eqBy, prop } from 'ramda';
import { Modal } from 'src/common/components/modal/modal';
import {
  createLabelButtonClicked,
  dashboardAssignLabelsActions,
  saveAssignments,
} from 'src/dashboard/state/assign-labels/assign-labels-actions';
import {
  selectIsAssignLabelsModalShowing,
  selectSaveLabelAssignmentErrorMessage,
  selectIsSavingLabelAssignmentLoading,
  selectShortcodesToAssignLabelsTo,
  buildLabelSelectionState,
  selectIsCreatingLabel,
} from 'src/dashboard/state/assign-labels/assign-labels-selectors';
import { selectLabelsAsArray } from 'src/dashboard/state/labels/labels-selectors';
import type { BulkLabelSelectionState } from 'src/dashboard/types';
import type { RootState } from 'src/redux';

import { AssignLabelsModalComponent } from './assign-labels-modal-component';

type AssignLabelsModalContainerProps = {
  initialLabelsState: BulkLabelSelectionState;
  errorMessage?: string;
  isLoading: boolean;
  isShowing: boolean;
  shortcodes: string[];
  handleCheckboxChange: () => void;
  handleClose: () => void;
  handleConfirm: (labelsSelection: BulkLabelSelectionState) => void;
  handleCreateLabelClick: (newLabel: string) => void;
  isCreatingLabel: boolean;
};

export const toggleSelectedLabel =
  (labelId: number) => (labelsState: BulkLabelSelectionState) => {
    return labelsState.map((labelState) => {
      if (labelId === labelState.id) {
        return {
          ...labelState,
          // if it was indeterminate and it changed, it no longer is indeterminate
          indeterminate: false,

          // from indeterminate it should go to `checked`
          // otherwise, it toggles the `checked` status
          checked: labelState.indeterminate ? true : !labelState.checked,
        };
      }
      return labelState;
    });
  };

const AssignLabelsModalContainerBase = ({
  errorMessage,
  isLoading,
  isShowing,
  initialLabelsState,
  shortcodes,
  handleCheckboxChange,
  handleClose: handleCloseProp,
  handleConfirm,
  handleCreateLabelClick,
  isCreatingLabel,
}: AssignLabelsModalContainerProps) => {
  const [selectedLabelsState, setSelectedLabelsState] =
    useState(initialLabelsState);

  // the initial selection of labels is the existing
  // list of labels for the given shortcode
  // so we only perform the update when the shortcode
  // changes
  useEffect(() => {
    if (shortcodes.length > 0) {
      setSelectedLabelsState(initialLabelsState);
    }
  }, [shortcodes, setSelectedLabelsState]);

  const handleClose = useCallback(() => {
    setSelectedLabelsState([]);
    handleCloseProp();
  }, [handleCloseProp, setSelectedLabelsState]);

  const handleChange = useCallback(
    (labelId: number) => {
      setSelectedLabelsState(toggleSelectedLabel(labelId));
      handleCheckboxChange();
    },
    [handleCheckboxChange, setSelectedLabelsState]
  );

  // After we created a label, the length of the initial labels state array
  // changes because we added a new label, so we merge the new label into the
  // existing state.
  useEffect(() => {
    if (initialLabelsState.length > selectedLabelsState.length) {
      const newLabels = differenceWith(
        eqBy(prop('id')),
        initialLabelsState,
        selectedLabelsState
      );

      const updatedSelectedLabelsState = [
        ...selectedLabelsState,
        ...newLabels.map((label) => ({
          ...label,
          checked: true,
          indeterminate: false,
        })),
      ];

      setSelectedLabelsState(updatedSelectedLabelsState);
    }
  }, [initialLabelsState, selectedLabelsState, setSelectedLabelsState]);

  return (
    <Modal
      id="assgin-labels-modal"
      isShowing={isShowing}
      onClose={handleClose}
      onConfirm={() => handleConfirm(selectedLabelsState)}
      title="Labels"
      disableConfirm={isLoading || selectedLabelsState.length === 0}
      confirmLabel={isLoading ? 'Saving' : 'Save'}
    >
      <AssignLabelsModalComponent
        isDisabled={isLoading}
        errorMessage={errorMessage}
        selectedLabelsState={selectedLabelsState}
        handleChange={handleChange}
        onCreateLabelClick={handleCreateLabelClick}
        isCreatingLabel={isCreatingLabel}
      />
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => {
  const shortcodes = selectShortcodesToAssignLabelsTo(state);

  return {
    errorMessage: selectSaveLabelAssignmentErrorMessage(state),
    isLoading: selectIsSavingLabelAssignmentLoading(state),
    isShowing: selectIsAssignLabelsModalShowing(state),
    labels: selectLabelsAsArray(state),
    shortcodes,
    initialLabelsState: buildLabelSelectionState(state),
    isCreatingLabel: selectIsCreatingLabel(state),
  };
};

const mapDispatchToProps = {
  handleCheckboxChange:
    dashboardAssignLabelsActions.assignLabelsModal.labelCheckbox.changed,
  handleClose: dashboardAssignLabelsActions.assignLabelsModal.cancelled,
  handleConfirm: saveAssignments,
  handleCreateLabelClick: createLabelButtonClicked,
};

export const AssignLabelsModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignLabelsModalContainerBase);
