import moment from 'moment';
import type { BlogPost } from 'src/blog/types';

export const mapPostForView = (post: BlogPost) => {
  const publishedAt = moment(post.publishedAt);

  return {
    ...post,
    formattedPublishedAt: publishedAt.format('MMMM DD, YYYY'),
  };
};
