import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { subscriptionActions } from 'src/app/subscription/subscription-actions';
import { selectIsStripeUser } from 'src/app/subscription/subscription-selectors';
import { logMesssage } from 'src/common/logging/logging-actions';
import { dashboardUpsellActions } from 'src/dashboard/state/upsell/upsell-actions';
import { videoPrivacyActions } from 'src/dashboard/state/video-privacy/video-privacy-actions';
import { Unwrap } from 'src/utils/types';

import { pricingActions } from './pricing-actions';
import {
  confirmPlanChange,
  fetchChangePlanPreviewInvoice,
} from './pricing-api';
import { selectTargetPlanId } from './pricing-selectors';

export function* handleFetchChangePlanPreviewInvoice(action: {
  payload: string;
}) {
  const isStripeUser: boolean = yield select(selectIsStripeUser);

  if (isStripeUser) {
    yield put(pricingActions.changePlanModal.busy());
    try {
      const invoice: Unwrap<typeof fetchChangePlanPreviewInvoice> = yield call(
        fetchChangePlanPreviewInvoice,
        action.payload
      );

      yield put(
        pricingActions.changePlanModal.previewInvoiceFetched({ invoice })
      );
    } catch (error) {
      const { message } = error as Error;
      yield put(
        pricingActions.changePlanModal.previewInvoiceFetchFailed(message)
      );
      yield put(logMesssage(message));
    }
  }
}

export function* handleConfirmPlanChange() {
  yield put(pricingActions.changePlanModal.busy());
  const targetPlanId: string = yield select(selectTargetPlanId);

  try {
    const { plan }: Unwrap<typeof confirmPlanChange> = yield call(
      confirmPlanChange,
      targetPlanId
    );

    yield put(pricingActions.changePlanModal.changeFulfilled(plan));
    yield put(subscriptionActions.fetchSubscriptionInfo());
  } catch (error) {
    const { message } = error as Error;
    yield put(pricingActions.changePlanModal.changeFailed(message));
    yield put(logMesssage(message));
  }
}

export function* pricingSaga() {
  yield all([
    takeEvery(
      [
        pricingActions.planColumn.changePlanButton.clicked,
        videoPrivacyActions.videoPrivacyModal.restrictionSection
          .domainRestrictions.upsellClicked,
        dashboardUpsellActions.storageUpsellModal.upgradeButton.clicked,
      ],
      handleFetchChangePlanPreviewInvoice
    ),
    takeEvery(
      pricingActions.changePlanModal.confirmButton.clicked,
      handleConfirmPlanChange
    ),
  ]);
}
