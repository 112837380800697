import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { Button } from 'src/common/components/button/button';
import {
  type WrappedComponentDropzoneProps,
  Dropzone,
} from 'src/common/components/dropzone/dropzone';
import { setCookie } from 'src/misc/cookies';
import { processUploadedFiles } from 'src/misc/utilities';
import type { RootState, AppDispatch } from 'src/redux';

import './hero-dropzone.css';

type HeroDropzoneContentProps = WrappedComponentDropzoneProps;

const HeroDropzoneContent = ({
  isDragActive,
  triggerFileSelect,
}: HeroDropzoneContentProps) => (
  <div className="hero-dropzone" onClick={triggerFileSelect}>
    {isDragActive && (
      <div className="hero-dropzone__overlay">
        <p className="hero-dropzone__overlay__text">Drop here.</p>
      </div>
    )}

    <div className="hero-dropzone__info">
      <h3 className="hero-dropzone__info__title">Select videos to upload</h3>

      <p className="hero-dropzone__info__text">Or drag and drop video files</p>
    </div>

    <Button color="primary" className="hero-dropzone__cta">
      Upload video for free
    </Button>

    <p className="hero-dropzone__no-credit-card">
      *No credit card or account required
    </p>
    <p className="ticker-with-dropzone-hero__terms">
      By continuing, you accept our{' '}
      <a
        href="https://terms.streamable.com/"
        rel="noreferrer"
        target="_blank"
        onClick={(e) => e.stopPropagation()}
      >
        Terms of Service
      </a>{' '}
      and acknowledge receipt of our{' '}
      <a
        href="https://privacy.streamable.com/"
        rel="noreferrer"
        target="_blank"
        onClick={(e) => e.stopPropagation()}
      >
        Privacy Policy
      </a>
    </p>
  </div>
);

type HeroDropzoneComponentProps = {
  me: RootState['me'];
  dispatch: AppDispatch;
};

export const HeroDropzoneComponent = ({
  me,
  dispatch,
}: HeroDropzoneComponentProps) => (
  <Dropzone
    noClick={true}
    callback={() => {
      setCookie('dashboard', 'true', 90000);
      browserHistory.push('/');
    }}
    processFiles={(files) =>
      processUploadedFiles(
        dispatch,
        files,
        null,
        me.plan_max_length,
        me.plan_max_size
      )
    }
  >
    {(dropzoneProps) => <HeroDropzoneContent {...dropzoneProps} />}
  </Dropzone>
);

const mapStateToProps = (state: RootState) => ({ me: state.me });
const mapDispatchToProps = (dispatch: AppDispatch) => ({ dispatch });

export const HeroDropzone = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeroDropzoneComponent);
