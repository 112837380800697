import * as Sentry from '@sentry/browser';
import throttle from 'lodash/throttle';
import { apiRequest } from 'src/utils/api-request';

type TrackPayload = {
  uploadPercent?: number;
  event: 'progress' | 'complete';
};

async function trackUploadEvent(shortcode: string, payload: TrackPayload) {
  try {
    await apiRequest({
      path: `/uploads/${shortcode}/track`,
      method: 'POST',
      body: payload,
    });
  } catch (error) {
    Sentry.captureException(error);
  }
}

const trackUploadProgressInternal = async (
  shortcode: string,
  uploadPercent: TrackPayload['uploadPercent']
) => trackUploadEvent(shortcode, { uploadPercent, event: 'progress' });

export const trackUploadProgress = throttle(
  trackUploadProgressInternal,
  10000,
  {
    leading: false,
  }
);

export const trackUploadCompleted = (shortcode: string) =>
  trackUploadEvent(shortcode, { event: 'complete' });
