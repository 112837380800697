import React, { type ChangeEventHandler } from 'react';

import classNames from 'classnames';
import { Flex } from 'src/common/components/flex/flex';
import { Text } from 'src/common/components/text/text';

import './selectable-plan.css';

export type SelectablePlanProps = {
  handleSelect: ChangeEventHandler<HTMLInputElement>;
  isSelected: boolean;
  name: string;
  value: string;
  title: string;
  displayPrice: string;
  description: string;
  label?: string;
};

export const SelectablePlan = ({
  handleSelect,
  isSelected,
  name,
  value,
  title,
  displayPrice,
  description,
  label,
}: SelectablePlanProps) => (
  <label
    className={classNames('selectable-plan', {
      'selectable-plan--selected': isSelected,
    })}
  >
    <input
      type="radio"
      name={name}
      value={value}
      className="sr-only"
      checked={isSelected}
      onChange={handleSelect}
      data-testid={`${name}-${value}-radio`}
    />

    <Flex flex={1} className="selectable-plan__label-container">
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="selectable-plan__title-row"
      >
        <Text className="selectable-plan__heading">{title}</Text>
        {label && <Text className="selectable-plan__label">{label}</Text>}
      </Flex>
      <Text className="selectable-plan__display-price">{displayPrice}</Text>
      <Text className="selectable-plan__description">{description}</Text>
    </Flex>
  </label>
);
