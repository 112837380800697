import type { PayloadAction } from '@reduxjs/toolkit';

import { videoPrivacyActions } from './video-privacy-actions';

export type VideoPrivacyState = {
  videoPrivacyModal: {
    isShowing: boolean;
    shortcode?: string;
    allowedDomain: {
      isLoading: boolean;
      isDone: boolean;
    };
  };
  resetModal: {
    isShowing: boolean;
    isResetting: boolean;
  };
};

const initialState: VideoPrivacyState = {
  videoPrivacyModal: {
    isShowing: false,
    allowedDomain: {
      isLoading: false,
      isDone: false,
    },
  },
  resetModal: {
    isShowing: false,
    isResetting: false,
  },
};

export const videoPrivacyReducer = (
  state = initialState,
  action: PayloadAction<any>
): VideoPrivacyState => {
  const { type, payload } = action;
  switch (type) {
    case videoPrivacyActions.videoFooter.privacyItem.clicked.type:
    case videoPrivacyActions.videoPrivacyModal.triggeredBySearchParams.type:
      return {
        ...state,
        videoPrivacyModal: {
          isShowing: true,
          shortcode: payload,
          allowedDomain: {
            isLoading: false,
            isDone: false,
          },
        },
      };

    case videoPrivacyActions.videoPrivacyModal.closed.type:
      return {
        ...state,
        videoPrivacyModal: {
          isShowing: false,
          shortcode: undefined,
          allowedDomain: {
            isLoading: false,
            isDone: false,
          },
        },
      };

    case videoPrivacyActions.videoPrivacyModal.restrictionSection
      .allowedDomainInput.changed.type:
      return {
        ...state,
        videoPrivacyModal: {
          ...state.videoPrivacyModal,
          allowedDomain: {
            isLoading: true,
            isDone: false,
          },
        },
      };

    case videoPrivacyActions.videoPrivacyModal.restrictionSection
      .allowedDomainInput.saved.type:
      return {
        ...state,
        videoPrivacyModal: {
          ...state.videoPrivacyModal,
          allowedDomain: {
            isLoading: false,
            isDone: true,
          },
        },
      };

    case videoPrivacyActions.videoPrivacyModal.advanced.resetSettingsClicked
      .type:
      return {
        ...state,
        resetModal: {
          ...state.resetModal,
          isShowing: true,
        },
      };

    case videoPrivacyActions.videoPrivacyModal.resetModal.cancelClicked.type:
      return {
        ...state,
        resetModal: {
          ...state.resetModal,
          isShowing: false,
        },
      };

    case videoPrivacyActions.videoPrivacyModal.resetModal.resetting.type:
      return {
        ...state,
        resetModal: {
          ...state.resetModal,
          isResetting: true,
        },
      };
    case videoPrivacyActions.videoPrivacyModal.resetModal.resetSuccess.type:
      return {
        ...state,
        resetModal: {
          isResetting: false,
          isShowing: false,
        },
      };
    case videoPrivacyActions.videoPrivacyModal.resetModal.resetFail.type:
      return {
        ...state,
        resetModal: {
          ...state.resetModal,
          isResetting: false,
        },
      };

    default:
      return state;
  }
};
