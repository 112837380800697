import React, { Component } from 'react';

import ChangePayment from './settings/ChangePayment';
import UsageInfo from './settings/UsageInfo';
import { Dashboard } from './videos/dashboard';
import Welcome from './welcome/Welcome';

export default class ContentPane extends Component {
  constructor() {
    super();

    this.state = {
      ViewClass: null,
    };
  }

  componentWillMount() {
    this.updateView(this.props.viewParams.view);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.updateView(newProps.viewParams.view);
  }

  updateView(view) {
    if (this.currentView !== view) {
      this.currentView = view;
      switch (view || null) {
        case 'settings':
        case 'billing':
        case 'profile':
        case 'appearance':
          import('../settings/settings-container').then((c) =>
            this.setState({ ViewClass: c.SettingsContainer })
          );
          break;
        case 'password':
          import('./settings/ChangePassword').then((c) =>
            this.setState({ ViewClass: c.default })
          );
          break;
        case 'payments':
          import('./settings/payments-page').then((c) =>
            this.setState({ ViewClass: c.default })
          );
          break;
        case 'change_payment':
          this.setState({ ViewClass: ChangePayment });
          break;
        // TODO: Remove as it redirects to marketing page
        case 'upload-video':
          this.setState({ ViewClass: Welcome });
          break;
        case 'your_usage':
          this.setState({ ViewClass: UsageInfo });
          break;
        case 'stats':
          import('./stats/Stats').then((c) =>
            this.setState({ ViewClass: c.default })
          );
          break;
        case 'merge':
          import('./joiner/Joiner').then((c) =>
            this.setState({ ViewClass: c.default })
          );
          break;
        // TODO: Fastly not set up for this path
        case 'buildembed':
          import('./embed/Embed').then((c) =>
            this.setState({ ViewClass: c.default })
          );
          break;
        // TODO: Fastly not set up for this path
        case 'infringement':
          import('./report/DMCA').then((c) =>
            this.setState({ ViewClass: c.default })
          );
          break;
        case 'videos':
        case null:
        default:
          this.setState({ ViewClass: Dashboard });
          break;
      }
    }
  }

  render() {
    if (!this.state.ViewClass) {
      return <div />;
    }

    return (
      <this.state.ViewClass
        query={this.props.viewParams.query}
        shortcode={this.props.viewParams.shortcode}
        version={this.props.viewParams.version}
        key={this.props.viewParams.view}
        view={this.props.viewParams.view}
      />
    );
  }
}
