import React from 'react';

import classnames from 'classnames';
import { Text } from 'src/common/components/text/text';

import './content-section.css';

type ContentSectionProps = {
  className?: string;
  title: string;
};

export const ContentSection: React.FunctionComponent<ContentSectionProps> = ({
  children,
  className,
  title,
}) => {
  return (
    <div className={classnames('content-section', className)}>
      <Text as="h2" className="content-section__title">
        {title}
      </Text>
      {children}
    </div>
  );
};
