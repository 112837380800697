import {
  always,
  complement,
  ifElse,
  isNil,
  not,
  pipe,
  prop,
  propOr,
} from 'ramda';
import { ELEMENTS_PLAN_ID } from 'src/common/constants';
import { selectFeaturesFromPlanByName } from 'src/pricing/state/pricing-selectors';
import type { PlanCadence } from 'src/pricing/types';
import type { RootState } from 'src/redux';

import type {
  ActivatedSubscriptionInfo,
  SubscriptionStatus,
} from './subscription-types';

const selectSubscriptionSlice = (state: RootState) => state.subscription;

export const selectSubscriptionInvoices = pipe(
  selectSubscriptionSlice,
  prop('invoices')
);

export const selectSubscriptionInvoicesLoading = pipe(
  selectSubscriptionInvoices,
  prop('loading')
);

export const selectSubscriptionInvoicesFailed = pipe(
  selectSubscriptionInvoices,
  prop('failed')
);

export const selectSubscriptionInvoicesItems = pipe(
  selectSubscriptionInvoices,
  prop('items')
);

export const selectSubscriptionIsLoading = pipe(
  selectSubscriptionSlice,
  prop('subscriptionIsLoading')
);

export const selectSubscriptionFinishedLoading = pipe(
  selectSubscriptionIsLoading,
  not
);

export const selectCancelScheduledPlanModal = pipe(
  selectSubscriptionSlice,
  prop('cancelScheduledPlanModal')
);

export const selectCurrentPlan = pipe(
  selectSubscriptionSlice,
  prop('currentPlan')
);

export const selectIsCurrentPlanStillAvailable = pipe(
  selectSubscriptionSlice,
  ({ currentPlan, availablePlans }) => {
    if (!currentPlan) {
      return true; // Free
    }

    if (currentPlan.id === ELEMENTS_PLAN_ID) {
      return true;
    }

    return availablePlans.some(
      (plan) =>
        plan.monthly.id === currentPlan.id || plan.annual.id === currentPlan.id
    );
  }
);

export const selectCurrentPlanId = pipe(
  selectCurrentPlan,
  propOr('', 'id')
) as (state: RootState) => string;
export const selectNextPlan = pipe(selectSubscriptionSlice, prop('nextPlan'));
export const selectAvailablePlans = pipe(
  selectSubscriptionSlice,
  prop('availablePlans')
);
export const selectNextAvailablePlan = pipe(
  selectSubscriptionSlice,
  ({ currentPlan, availablePlans }) => {
    if (!currentPlan) {
      // No upsell for anonymous users
      return;
    }

    const currentPlanIndex = availablePlans.findIndex(
      (plan) => plan.name === currentPlan?.name
    );
    const nextPlan = availablePlans[currentPlanIndex + 1];
    return nextPlan;
  }
);
export const selectProPlan = pipe(selectAvailablePlans, (plans) =>
  plans.find((plan) => plan.name === 'Pro')
);

export const selectWillRenewOnDifferentPlan = pipe(
  selectSubscriptionSlice,
  ({ currentPlan, nextPlan }) => {
    if (!currentPlan || !nextPlan) {
      return false;
    }
    return currentPlan.id !== nextPlan.id;
  }
);

// @ts-expect-error TS has problems with the type interference of `propOr`.
export const selectCurrentPlansEndDate: (state: RootState) => string | null =
  pipe(selectCurrentPlan, propOr(null, 'nextBillingDate'));

// @ts-expect-error TS has problems with the type interference of `propOr`.
export const selectCurrentPlansStatus: (
  state: RootState
) => SubscriptionStatus | null = pipe(
  selectCurrentPlan,
  propOr(null, 'status')
);

// @ts-expect-error TS has problems with the type interference of `propOr`.
export const selectCurrentPlansStripeSubscriptionId: (
  state: RootState
) => string | undefined = pipe(
  selectCurrentPlan,
  propOr(null, 'stripeSubscriptionId')
);

// @ts-expect-error TS has problems with the type interference of `propOr`.
export const selectCurrentPlansName: (state: RootState) => string = pipe(
  selectCurrentPlan,
  propOr('', 'name')
);

// @ts-expect-error TS has problems with the type interference of `propOr`.
export const selectCurrentPlansCadence: (state: RootState) => PlanCadence =
  pipe(selectCurrentPlan, propOr('annual', 'cadence'));

export const selectCurrentPlanIsAnnual = pipe(
  selectCurrentPlansCadence,
  (cadence) => cadence === 'annual'
);

// @ts-expect-error TS has problems with the type interference of `propOr`.
export const selectCurrentPlansLimits: (
  state: RootState
) => ActivatedSubscriptionInfo['limits'] = pipe(
  selectCurrentPlan,
  propOr({}, 'limits')
);

// @ts-expect-error TS has problems with the type interference of `propOr`.
export const selectCurrentPlansPrice: (state: RootState) => number = pipe(
  selectCurrentPlan,
  propOr(0, 'price')
);

export const selectHasCurrentPlan = pipe(selectCurrentPlan, complement(isNil));

export const selectCurrentPlanIsCancelled = pipe(
  selectCurrentPlansStatus,
  (status) => status === 'CANCELLED'
);

export const selectCanContinueSubscription = ifElse(
  selectHasCurrentPlan,
  selectCurrentPlanIsCancelled,
  always(false)
);

export const selectCanCancelSubscription = ifElse(
  selectHasCurrentPlan,
  pipe(selectCurrentPlanIsCancelled, not),
  always(false)
);

export const selectShowBilling = (state: RootState, ownProps: any) => {
  const currentPlanEndDate = selectCurrentPlansEndDate(state);
  const now = new Date();
  const isBeforeEndDate =
    !!currentPlanEndDate && now < new Date(currentPlanEndDate);

  return isBeforeEndDate || Boolean(ownProps.billing_info);
};

export const selectUsersCardInfo = pipe(selectSubscriptionSlice, prop('card'));

export const selectIsStripeUser = pipe(
  selectCurrentPlan,
  (plan) => plan?.processor === 'stripe'
);

export const selectIsPayPalUser = pipe(
  selectCurrentPlan,
  (plan) => plan?.processor === 'paypal'
);

export const selectIsContinuePlanModalShowing = pipe(
  selectSubscriptionSlice,
  prop('isContinuePlanModalShowing')
);

export const selectIsCancelPlanModalShowing = pipe(
  selectSubscriptionSlice,
  prop('isCancelPlanModalShowing')
);

export const selectIsPlanReactivating = pipe(
  selectSubscriptionSlice,
  prop('isPlanReactivating')
);

export const selectIsPlanCancelling = pipe(
  selectSubscriptionSlice,
  prop('isPlanCancelling')
);

export const selectCurrentPlansFeatures = (state: RootState) => {
  const currentPlanName = selectCurrentPlansName(state);
  return selectFeaturesFromPlanByName(currentPlanName)(state);
};

export const selectUserHasCustomPlan = pipe(
  selectCurrentPlan,
  (plan) => plan?.id === 'custom'
);

export const selectIsSwitchingToElementsPlan = pipe(
  selectSubscriptionSlice,
  prop('subscribeToElementsPlanModal'),
  prop('isLoading')
);
