import { toast } from 'react-hot-toast';

import type { PayloadAction } from '@reduxjs/toolkit';
import { pipe } from 'ramda';
import {
  all,
  call,
  debounce,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { logMesssage } from 'src/common/logging/logging-actions';
import type {
  DomainPrivacy,
  PrivacyVisibility,
} from 'src/common/types/privacy';
import { fetchVideos } from 'src/dashboard/state/videos/videos-saga';
import { settingsActions } from 'src/settings/state/settings-actions';
import { calculateNewPrivacySettings } from 'src/settings/utils/calculate-new-privacy-settings';

import { resetAllVideoPrivacySettings, updateSettings } from './settings-api';
import {
  selectPrivacyAllowDownload,
  selectPrivacyAllowSharing,
  selectPrivacyAllowedDomainValue,
  selectPrivacyDomainRestrictions,
  selectPrivacyHideViewCount,
  selectPrivacyVisibility,
} from './settings-selectors';

export function* handleVisibilityChange(
  action: PayloadAction<PrivacyVisibility>
) {
  const allowDownload: boolean = yield select(selectPrivacyAllowDownload);
  const allowSharing: boolean = yield select(selectPrivacyAllowSharing);
  const allowedDomain: string = yield select(selectPrivacyAllowedDomainValue);
  const visibility: PrivacyVisibility = yield select(selectPrivacyVisibility);

  yield put(settingsActions.privacy.visibility.initiated(action.payload));

  try {
    yield call(
      updateSettings,
      pipe(
        calculateNewPrivacySettings,
        ({ allowed_domain, ...newSettings }) => ({
          ...newSettings,
          ...(allowed_domain ? { allowed_domain: allowed_domain.value } : {}),
        })
      )({ visibility: action.payload })
    );
    yield call(fetchVideos);
  } catch (error) {
    const { message } = error as Error;

    yield call(toast.error, message);

    // Could not update on the BE, switch back the setting
    yield put(
      settingsActions.privacy.visibility.errored({
        allow_download: allowDownload,
        allow_sharing: allowSharing,
        allowed_domain: allowedDomain,
        visibility,
      })
    );
    yield put(logMesssage(message));
  }
}

export function* handleAllowDownloadToggle() {
  const allowDownload: boolean = yield select(selectPrivacyAllowDownload);

  try {
    yield call(updateSettings, { allow_download: allowDownload });
    yield call(fetchVideos);
  } catch (error) {
    const { message } = error as Error;

    yield call(toast.error, message);

    // Could not update on the BE, switch back the setting
    yield put(
      settingsActions.privacy.preferences.allowDownload.errored(!allowDownload)
    );
    yield put(logMesssage(message));
  }
}

export function* handleAllowSharingToggle() {
  const allowSharing: boolean = yield select(selectPrivacyAllowSharing);

  try {
    yield call(updateSettings, { allow_sharing: allowSharing });
    yield call(fetchVideos);
  } catch (error) {
    const { message } = error as Error;

    yield call(toast.error, message);

    // Could not update on the BE, switch back the setting
    yield put(
      settingsActions.privacy.preferences.allowSharing.errored(!allowSharing)
    );
    yield put(logMesssage(message));
  }
}

export function* handleHideViewCountToggle() {
  const hideViewCount: boolean = yield select(selectPrivacyHideViewCount);

  try {
    yield call(updateSettings, { hide_view_count: hideViewCount });
    yield call(fetchVideos);
  } catch (error) {
    const { message } = error as Error;

    yield call(toast.error, message);

    // Could not update on the BE, switch back the setting
    yield put(
      settingsActions.privacy.preferences.hideViewCount.errored(!hideViewCount)
    );
    yield put(logMesssage(message));
  }
}

export function* handleDomainRestrictionsToggle() {
  const domainRestrictions: DomainPrivacy = yield select(
    selectPrivacyDomainRestrictions
  );

  try {
    yield call(updateSettings, {
      domain_restrictions: domainRestrictions,
    });
    yield call(fetchVideos);
  } catch (error) {
    const { message } = error as Error;

    yield call(toast.error, message);

    // Could not update on the BE, switch back the setting
    yield put(settingsActions.privacy.restrictions.domainRestriction.errored());
    yield put(logMesssage(message));
  }
}

export function* handleAllowedDomainChange(action: PayloadAction<string>) {
  const allowedDomain: string = yield select(selectPrivacyAllowedDomainValue);

  yield put(
    settingsActions.privacy.restrictions.allowedDomains.initiated(
      action.payload
    )
  );

  try {
    yield call(updateSettings, { allowed_domain: action.payload });
    yield call(fetchVideos);
    yield put(settingsActions.privacy.restrictions.allowedDomains.saved());
  } catch (error) {
    const { message } = error as Error;

    yield call(toast.error, message);

    // Could not update on the BE, switch back the setting
    yield put(
      settingsActions.privacy.restrictions.allowedDomains.errored(allowedDomain)
    );
    yield put(logMesssage(message));
  }
}

export function* handleResetModalConfirmClick() {
  try {
    yield put(settingsActions.resetModal.resetting());
    yield call(resetAllVideoPrivacySettings);
    yield call(fetchVideos);
    yield put(settingsActions.resetModal.resetSuccess());
  } catch (error) {
    const { message } = error as Error;

    yield call(toast.error, message);

    yield put(settingsActions.resetModal.resetFail());
    yield put(logMesssage(message));
  }
}

export function* watchSettingsSaga() {
  yield all([
    takeEvery(
      settingsActions.privacy.visibility.changed,
      handleVisibilityChange
    ),
    takeEvery(
      settingsActions.privacy.preferences.allowDownload.toggled,
      handleAllowDownloadToggle
    ),
    takeEvery(
      settingsActions.privacy.preferences.allowSharing.toggled,
      handleAllowSharingToggle
    ),
    takeEvery(
      settingsActions.privacy.preferences.hideViewCount.toggled,
      handleHideViewCountToggle
    ),
    takeEvery(
      settingsActions.privacy.restrictions.domainRestriction.toggled,
      handleDomainRestrictionsToggle
    ),
    debounce(
      1000,
      settingsActions.privacy.restrictions.allowedDomains.input.changed,
      handleAllowedDomainChange
    ),
    takeEvery(
      settingsActions.resetModal.confirmClicked,
      handleResetModalConfirmClick
    ),
  ]);
}
