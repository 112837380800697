import { apiRequest } from 'src/utils/api-request';

import { FetchIntentResponse } from './checkout-types';

export const fetchIntent = async (plan: string) =>
  apiRequest<FetchIntentResponse>({
    path: '/payments/intents',
    method: 'POST',
    body: { plan },
  });
