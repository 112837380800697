import React from 'react';

import { FAQ as Faq } from 'src/common/components/faq/faq';

import type { PageFaq } from '../../state/landing-types';
import './faq.css';

type FAQProps = {
  faq: PageFaq[];
};

export const FAQ = ({ faq }: FAQProps) =>
  faq.length ? (
    <div className="faq">
      <Faq
        questions={faq.map((item) => ({
          title: item.question,
          content: item.answer,
        }))}
      />
    </div>
  ) : null;
