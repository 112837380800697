import React, { forwardRef } from 'react';

import classnames from 'classnames';

import { ErrorMessage } from '../error-message/error-message';
import { Flex } from '../flex/flex';
import { Input, InputProps } from '../input/input';
import { Text } from '../text/text';
import './text-field.css';

type TextFieldProps = Omit<InputProps, 'hasError'> & {
  hint?: string;
  label?: string;
  errorMessage?: string;
};

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ hint, label, className, errorMessage, ...inputProps }, inputRef) => (
    <Flex className={classnames('sb-text-field', className)}>
      <Flex as="label" className="sb-text-field__label">
        {label}
        <Input hasError={!!errorMessage} {...inputProps} ref={inputRef} />
      </Flex>

      {hint && (
        <Text variant="secondary" className="sb-text-field__hint">
          {hint}
        </Text>
      )}

      <ErrorMessage
        className="sb-text-field__error-message"
        message={errorMessage}
      />
    </Flex>
  )
) as (props: TextFieldProps) => JSX.Element;
