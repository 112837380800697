import { toast } from 'react-hot-toast';

import { List } from 'immutable';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { logMesssage } from 'src/common/logging/logging-actions';
import { selectSelectedVideos } from 'src/common/state/me/me-selectors';
import { Collection, VideoRepresentation } from 'src/dashboard/types';
import { getShortcodesFromVideosList } from 'src/dashboard/utils/get-shortcodes-from-videos-list';
import { openLinkInNewTab } from 'src/dashboard/utils/open-link-in-new-tab';
import { getErrorMessage } from 'src/utils/get-error-message';

import { dashboardCollectionActions } from './collection-actions';
import { createCollection } from './collection-api';

export function* handleCreateCollection() {
  try {
    const selectedVideos: List<VideoRepresentation> =
      yield select(selectSelectedVideos);
    const newCollection: Collection = yield call(createCollection, {
      shortcodes: getShortcodesFromVideosList(selectedVideos),
    });

    yield put(dashboardCollectionActions.createCollection.fulfilled());
    yield call(
      openLinkInNewTab,
      `${process.env.REACT_APP_NEXTJS_HOST}/c/${newCollection.shortcode}`
    );
  } catch (error) {
    const message: string = yield call(getErrorMessage, error);
    yield put(
      logMesssage(
        `Attempt to create a collection failed with the message: ${message}`
      )
    );
    yield call(toast.error, message);
    yield put(dashboardCollectionActions.createCollection.failed());
  }
}

export function* watchCollectionSaga() {
  yield all([
    takeEvery(
      dashboardCollectionActions.videoListHeader.shareButton.clicked,
      handleCreateCollection
    ),
  ]);
}
