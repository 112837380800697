import { cancel } from 'src/actions';
import { replaceVideoActions } from 'src/dashboard/state/replace-video/replace-video-actions';
import { videoCaptionsActions } from 'src/dashboard/state/video-captions/video-captions-actions';
import { videoPrivacyActions } from 'src/dashboard/state/video-privacy/video-privacy-actions';
import { useAppDispatch, useAppSelector } from 'src/redux';
import { selectShouldExposeNewAnalyticsSummaryPage } from 'src/utils/selectors/should-expose-new-analytics-summary-page';
import { selectShouldExposeVideoManageCaptions } from 'src/utils/selectors/should-expose-video-manage-captions';

import {
  VideoFooterComponent,
  type VideoFooterComponentOwnProps,
} from './video-footer-component';

export const VideoFooterContainer = (props: VideoFooterComponentOwnProps) => {
  const dispatch = useAppDispatch();

  const shouldExposeNewAnalyticsSummaryPage = useAppSelector(
    selectShouldExposeNewAnalyticsSummaryPage
  );
  const shouldExposeVideoManageCaptions = useAppSelector(
    selectShouldExposeVideoManageCaptions
  );

  const cancelVideo = () => dispatch(cancel(props.video, 'footer'));
  const handlePrivacyClick = () => {
    dispatch(
      videoPrivacyActions.videoFooter.privacyItem.clicked(
        props.video.shortcode!
      )
    );
  };

  const handleReplaceClick = (shortcode: string) =>
    dispatch(replaceVideoActions.videoFooter.replaceItem.clicked(shortcode));

  const openCaptionsModal = () =>
    dispatch(videoCaptionsActions.captionsModal.opened(props.video.shortcode!));

  return (
    <VideoFooterComponent
      {...props}
      shouldExposeNewAnalyticsSummaryPage={shouldExposeNewAnalyticsSummaryPage}
      shouldExposeVideoManageCaptions={shouldExposeVideoManageCaptions}
      openCaptionsModal={openCaptionsModal}
      handleReplaceClick={handleReplaceClick}
      cancelVideo={cancelVideo}
      handlePrivacyClick={handlePrivacyClick}
    />
  );
};
