import React from 'react';

import Tooltip from 'rc-tooltip';
import { Chip } from 'src/common/components/chip/chip';
import { Text } from 'src/common/components/text/text';
import { NUMBER_OF_LABELS_TO_SHOW_ON_VIDEO_CARD } from 'src/dashboard/constants';
import { LabelWithCount } from 'src/dashboard/types';

import './video-labels.css';

type VideoLabelsProps = {
  labels: LabelWithCount[];
  handleLabelsClick: () => void;
};

export const VideoLabels = ({
  labels,
  handleLabelsClick,
}: VideoLabelsProps) => {
  const labelsToDisplay = labels.slice(
    0,
    NUMBER_OF_LABELS_TO_SHOW_ON_VIDEO_CARD
  );
  const labelsNotToDisplay = labels.slice(
    NUMBER_OF_LABELS_TO_SHOW_ON_VIDEO_CARD
  );

  return (
    <button className="video-labels" onClick={handleLabelsClick}>
      <span className="material-icons video-labels__icon">label</span>

      {labelsToDisplay.length ? (
        <div className="video-labels__list">
          {labelsToDisplay.map((label) => (
            <Chip
              key={`video-label-${label.id}`}
              className="video-labels__list__item"
            >
              <Text title={label.name}>{label.name}</Text>
            </Chip>
          ))}

          {labelsNotToDisplay.length > 0 && (
            <Tooltip
              placement="top"
              overlay={labelsNotToDisplay.map((label) => label.name).join(', ')}
            >
              <Text>+{labelsNotToDisplay.length}</Text>
            </Tooltip>
          )}
        </div>
      ) : (
        <Text className="video-labels__none">None</Text>
      )}
    </button>
  );
};
