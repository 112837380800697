type RedditSignupEventPayload = {
  products: [
    {
      id: string;
      name: string;
      category: string;
    },
  ];
};

function triggerRedditEvent<P>(event: string, payload: P) {
  try {
    window.rdt('track', event, payload);
  } catch (e) {
    // Couldn't log event, don't interrupt execution
  }
}

export const triggerRedditSignupEvent = (
  id: string,
  name: string,
  category: string
) => {
  triggerRedditEvent<RedditSignupEventPayload>('SignUp', {
    products: [{ id, name, category }],
  });
};
