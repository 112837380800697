import { toast } from 'react-hot-toast';

import * as Sentry from '@sentry/browser';
import fetch from 'isomorphic-fetch';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { subscriptionActions } from 'src/app/subscription/subscription-actions';
import { getUser } from 'src/common/state/me/me-api';
import type { MeState } from 'src/common/state/me/me-types';
import { MEDIA_STATUS } from 'src/common/types/media';
import type { UserOutput } from 'src/common/types/user';
import { videosActions } from 'src/dashboard/state/videos/videos-actions';
import type { VideoRepresentation } from 'src/dashboard/types';
import { transformVideoAfterFetch } from 'src/dashboard/utils/transform-video-after-fetch';
import { env } from 'src/env';
import { getCookie } from 'src/misc/cookies';
import type { RootState, AppDispatch } from 'src/redux';
import { disableDarkMode } from 'src/utils/disable-dark-mode';
import { enableDarkMode } from 'src/utils/enable-dark-mode';
import { generateUniqueId } from 'src/utils/generate-unique-id';
import { isDarkModeEnabled } from 'src/utils/is-dark-mode-enabled';
import { setDarkModeCookie } from 'src/utils/set-dark-mode-cookie';

import {
  transcode,
  type UploadTask,
  type UploadTaskOptions,
} from '../api/video';
import { createFetchVideoRequest, type FetchVideoOptions } from '../api/videos';
import {
  getUploads,
  getVideo,
  getSelectedVideos,
  getAllVideos,
} from '../misc/selectors';
import { getBestSourceUrl } from '../misc/utilities';
import {
  type UploadActionPayload,
  type FetchStatsPayload,
  clearVideoAction,
  clearVideosAction,
  deselectAllAction,
  editUserAction,
  editVideoAction,
  extractUrlAction,
  fetchCurrentUserAction,
  fetchStatsAction,
  fetchVideoAction,
  loginAction,
  messageAction,
  pollingUpdateReceivedAction,
  retranscodeAction,
  shortcodeReceivedAction,
  subscribeAction,
  updateCardAction,
  uploadAction,
} from './actions';
import { createVideo } from './create-video';
import { log } from './log';

export * from './create-video';
export * from './log';

type BeaconIdentificationData = Partial<
  Pick<UserOutput, 'payment_processor' | 'subscription_status'> & {
    version: string;
    name: string;
    email: string;
    plan_name: string | null;
    user_since: string;
  }
>;

const onUserIsFetched = (
  user: UserOutput,
  options = { activateDarkMode: false }
) => {
  setDarkModeCookie({ dark_mode: user.dark_mode || undefined });

  if (options.activateDarkMode) {
    isDarkModeEnabled() ? enableDarkMode() : disableDarkMode();
  }

  setTimeout(() => {
    const data: BeaconIdentificationData = {};

    if (user.user_name) {
      data.name = user.user_name + ' [' + user.plan_name + ']';
      if (user.user_name.includes('@')) {
        data.email = user.user_name;
      }
      data.payment_processor = user.payment_processor;
      data.plan_name = user.plan;
      data.subscription_status = user.subscription_status;
      data.user_since = moment.unix(user.date_added).fromNow();
    }

    data.version = env.VERSION;

    window.Beacon?.('identify', data);
  }, 0);
};

type NewApiActionType = 'billing_info' | 'flags' | 'usage';

const NEW_API_ACTIONS: Array<NewApiActionType> = [
  'billing_info',
  'flags',
  'usage',
];

const getExtraUserInfoUrlByType = (type: NewApiActionType) => {
  const newAPI = NEW_API_ACTIONS.includes(type as NewApiActionType);

  if (newAPI) {
    if (type === 'billing_info') {
      return `${env.API_HOST}/payments/billing_info`;
    } else {
      return `${env.API_HOST}/me/${type}`;
    }
  }

  return `${env.OLD_API_HOST}/me/${type}`;
};

export const fetchExtraUserInfo =
  (type: NewApiActionType) =>
  async (dispatch: AppDispatch): Promise<MeState | undefined> => {
    try {
      const url = getExtraUserInfoUrlByType(type);
      const response = await fetch(url, {
        credentials: 'include',
      });

      if (!response.ok) {
        throw Error(await response.text());
      }
      const json = await response.json();
      dispatch(fetchCurrentUserAction(json));
      return json;
    } catch (e) {
      //
    }
  };

export const fetchCurrentUser =
  () =>
  async (dispatch: AppDispatch): Promise<UserOutput | undefined> => {
    dispatch(fetchCurrentUserAction({ isFetching: true }));

    try {
      const user = await getUser();

      dispatch(fetchCurrentUserAction({ isFetching: false, ...user }));
      if (user.user_name) {
        onUserIsFetched(user);
      }

      dispatch(fetchExtraUserInfo('flags'));

      // TODO: What is this?
      if (WebSocket && user.socket) {
        console.log('Connecting to socket', user.socket);
        const ws = new WebSocket(user.socket);
        ws.onmessage = (e) => {
          const parsedMessage = JSON.parse(e.data);
          if (parsedMessage.type === 'ping') {
            ws.send(JSON.stringify({ type: 'pong' }));
            return;
          }
          dispatch(messageAction(parsedMessage));
        };
      }

      return user;
    } catch (error) {
      // Fetch flags if there was an error fetching the user
      dispatch(fetchExtraUserInfo('flags'));

      Sentry.captureException(error);
      dispatch(fetchCurrentUserAction(error as Error));
    }
  };

export const userSubscribePlan =
  ({
    paymentMethodId,
    plan,
    subscriptionId,
    token,
    noTrial,
    defaultPlan,
  }: {
    paymentMethodId: string;
    plan?: string | null;
    subscriptionId?: string | null;
    token: string;
    noTrial: boolean;
    defaultPlan: string;
  }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    let url = `${env.API_HOST}/me/subscribe/${plan}`;
    dispatch(subscribeAction({ purchasing: true }));

    const searchParams = new URLSearchParams(window.location.search);
    const srcInternal = searchParams.get('src_internal');

    const body: {
      token: string;
      src_internal: string | null;
      notrial?: boolean;
      payment_method?: string;
      subscription_id?: string;
    } = {
      token,
      src_internal: srcInternal,
    };
    if (noTrial) {
      body.notrial = true;
    }
    if (paymentMethodId) {
      body.payment_method = paymentMethodId;
    } else if (subscriptionId) {
      body.subscription_id = subscriptionId;
    } else {
      throw new Error('No payment method specified.');
    }

    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(body),
      credentials: 'include',
    })
      .then(async (response) => {
        if (!response.ok) {
          if (response.status === 429) {
            throw new Error('Too many attempts. Please try again later.');
          } else {
            const errorResponse = await response.json();
            throw new Error(errorResponse.message);
          }
        }

        log(
          `CHECKOUT EXPERIMENT(subscription): ${JSON.stringify({
            trial: !body.notrial,
            defaultPlan,
            src_internal: srcInternal || null,
            selectedPlan: plan,
            userName: getState().me.user_name,
            subscribedAt: new Date(),
          })}`
        );

        return response.json();
      })
      .then(async (user) => {
        await dispatch(fetchCurrentUserAction(user));
        dispatch(subscribeAction({ purchasing: false }));
        dispatch(subscriptionActions.userSubscriptionToAPlan.fulfilled());
        return user;
      })
      .catch((error) => {
        log(`[FE Error] CHECKOUT STRIPE SUBSCRIPTION FAILED - ${error}`);
        Sentry.captureException(error);
        dispatch(subscribeAction(error as Error));
      });
  };

export const userChangePayment =
  (paymentMethodId: string, token: string) => (dispatch: AppDispatch) => {
    dispatch(updateCardAction({ purchasing: true }));
    const url = `${env.API_HOST}/me/card`;
    return fetch(url, {
      credentials: 'include',
      method: 'PUT',
      headers: {
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        payment_method: paymentMethodId,
        token,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          if (response.status === 429) {
            throw Error('Too many attempts. Please try again later.');
          } else {
            throw Error((await response.json()).message);
          }
        }
        return response.json();
      })
      .then(async (json) => {
        await dispatch(fetchCurrentUserAction(json));
        dispatch(updateCardAction({ purchasing: false }));
        return json;
      })
      .catch((error) => {
        Sentry.captureException(error);
        dispatch(updateCardAction(error as Error));
      });
  };

export const fetchVideo =
  (shortcode: string, version: number) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const store = getState();
    if (store?.video?.shortcode !== shortcode) {
      dispatch(clearVideoAction());
    }
    dispatch(fetchVideoAction({ isFetching: true, shortcode }));
    let url = `${env.API_HOST}/videos/${shortcode}`;
    if (version) {
      url += `?version=${version}`;
    }

    return fetch(url, {
      credentials: 'include',
      headers: {
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
      },
    })
      .then(async (response) => {
        if (!response.ok) {
          throw Error(await response.text());
        }
        return response.json();
      })
      .then((json) => {
        dispatch(fetchVideoAction({ isFetching: false, ...json }));
        return json;
      })
      .catch((error) => {
        Sentry.captureException(error);
        dispatch(fetchVideoAction(error as Error));
      });
  };

type PrivacySettingsThunkParam = {
  privacy: number;
  password: string;
};

const updatePrivacySettingsInternal = (
  shortcode: string,
  privacySettings: PrivacySettingsThunkParam
) =>
  fetch(`${env.API_HOST}/videos/${shortcode}/privacy`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
    },
    body: JSON.stringify(privacySettings),
  })
    .then(async (response) => {
      if (!response.ok) {
        const error = await response.json();
        throw Error(error.message);
      }
    })
    .catch((error) => {
      Sentry.captureException(error);
    });

export const errorVideo =
  (shortcode: string, error: string = 'Unknown Video Error') =>
  (dispatch: AppDispatch) => {
    dispatch(editVideoAction({ shortcode, status: MEDIA_STATUS.ERROR, error }));

    return fetch(`${env.API_HOST}/videos/${shortcode}/error`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify({ error }),
    })
      .then(async (response) => {
        if (!response.ok) {
          const data = await response.json();
          throw Error(data.message);
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

const renameVideoInternal = (shortcode?: string, title?: string) => {
  if (typeof shortcode === 'undefined') {
    return;
  }

  return fetch(`${env.API_HOST}/videos/${shortcode}/rename`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
    },
    body: JSON.stringify({ title }),
  })
    .then(async (response) => {
      if (!response.ok) {
        const error = await response.json();
        throw Error(error.message);
      }
    })
    .catch((error) => {
      Sentry.captureException(error);
    });
};

const renameVideoDebounced = debounce(renameVideoInternal, 1000);

export const renameVideo =
  (shortcode: string, title: string, debounced: boolean) =>
  (dispatch: AppDispatch) => {
    dispatch(editVideoAction({ isSaving: true, shortcode, title }));

    if (debounced) {
      renameVideoDebounced(shortcode, title);
    } else {
      return renameVideoInternal(shortcode, title);
    }
  };

export const updatePrivacySettings =
  (shortcode: string, privacySettings: PrivacySettingsThunkParam) =>
  (dispatch: AppDispatch) => {
    dispatch(editVideoAction({ shortcode, ...privacySettings }));

    return updatePrivacySettingsInternal(shortcode, privacySettings);
  };

export const cancelVideo = (shortcode: string) =>
  fetch(`${env.API_HOST}/videos/${shortcode}/cancel`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
    },
    credentials: 'include',
  })
    .then(async (response) => {
      if (!response.ok) {
        const error = await response.json();
        throw Error(error.message);
      }
    })
    .catch((error) => {
      Sentry.captureException(error);
    });

export const cancelVideoEdits =
  ({ shortcode, version }: VideoRepresentation) =>
  (dispatch: AppDispatch) =>
    fetch(`${env.API_HOST}/videos/${shortcode}/cancelEdits`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
      },
      credentials: 'include',
      body: JSON.stringify({ version }),
    })
      .then((response) => response.json())
      .then((video) => {
        dispatch(editVideoAction(video));
        return video;
      });

export const revertVideo = (shortcode: string) => (dispatch: AppDispatch) =>
  fetch(`${env.API_HOST}/videos/${shortcode}/revert`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
    },
    body: JSON.stringify({ targetVersion: 0 }),
  })
    .then(async (response) => {
      if (!response.ok) {
        const error = await response.json();
        throw Error(error.message);
      }
      return response.json();
    })
    .then((response) => {
      dispatch(editVideoAction({ shortcode, ...response }));
    })
    .catch((error) => {
      Sentry.captureException(error);
    });

export const createVideoWithOptions =
  (options: UploadTaskOptions, data?: UploadActionPayload) =>
  async (dispatch: AppDispatch) => {
    try {
      const video = await dispatch(
        createVideo({
          ...data,
          upload_source: options.upload_source,
          title: options.title,
          status: 1,
        })
      );
      const { shortcode, token } = video;
      options.shortcode = shortcode;
      options.token = token;
      await new Promise<void>((resolve, reject) => {
        transcode(options, (error) => {
          if (error) {
            return reject(error);
          }
          return resolve();
        });
      });
      if (data) {
        dispatch(uploadAction(data));
      }
      return video;
    } catch (error) {
      // Unique 8 character shortcode to avoid duplicates or conflicts with existing shortcodes.
      const uniqueShortcode = generateUniqueId('alpha', 8);
      Sentry.captureException(error);
      dispatch(
        shortcodeReceivedAction({
          shortcode: options.shortcode || uniqueShortcode,
        })
      );

      let message = 'Unknown Error';
      if (error instanceof Error) {
        message = error.message;
      }
      dispatch(
        editVideoAction({
          shortcode: options.shortcode || uniqueShortcode,
          status: MEDIA_STATUS.ERROR,
          error: message,
        })
      );

      log(
        `[FE Error] createVideoWithOptions failed for ${
          options.shortcode || uniqueShortcode
        } with error: ${message}`
      );
    }
  };

export const createVideoFromUrl =
  (url: string, options: UploadTaskOptions, data: UploadActionPayload) =>
  (dispatch: AppDispatch) =>
    dispatch(createVideoWithOptions({ ...options, url }, data));

export const createVideoFromUrls =
  (urls: string[], options: UploadTaskOptions, data?: UploadActionPayload) =>
  (dispatch: AppDispatch) =>
    dispatch(createVideoWithOptions({ ...options, urls }, data));

export const pollVideo =
  (shortcode: string, targetVersion: number) =>
  (dispatch: AppDispatch, getState: () => RootState) =>
    new Promise<void>((resolve, reject) => {
      const store = getState();
      const video = getVideo(store.videos.cache, shortcode);
      if (targetVersion && video?.version === targetVersion) {
        resolve();
      } else {
        dispatch(fetchVideo(shortcode, targetVersion))
          .then(() => {
            setTimeout(() => {
              const shortcode = getState().video.shortcode;
              if (!shortcode) {
                reject();
                return;
              }
              dispatch(pollVideo(shortcode, targetVersion))
                .then(resolve)
                .catch(reject);
            }, 500);
          })
          .catch(reject);
      }
    });

export const screenshot =
  (shortcode: string, options = {}) =>
  (dispatch: AppDispatch) =>
    fetch(`${env.API_HOST}/screenshots/${shortcode}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(options),
      credentials: 'include',
    }).then(async (response) => {
      const result = await response.json();
      if (!response.ok) {
        log(
          `[FE Error] Screenshot API call failed for shortcode ${shortcode} with error: ${result.message}`
        );
        throw Error(result.message);
      }
      dispatch(editVideoAction({ ...result, shortcode }));
      return result;
    });

export const uploadScreenshot =
  (shortcode: string, screenshot: string) => (dispatch: AppDispatch) => {
    const formData = new FormData();
    formData.append('screenshot', screenshot);
    return fetch(`${env.API_HOST}/screenshots/${shortcode}/upload`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    }).then(async (response) => {
      const result = await response.json();
      if (!response.ok) {
        log(
          `[FE Error] uploadScreenshot API call failed for shortcode ${shortcode} with error: ${result.message}`
        );
        throw Error(result.message);
      }
      dispatch(editVideoAction({ ...result, shortcode }));
      return result;
    });
  };

export const retranscodeVideo =
  (video: VideoRepresentation, options: UploadTaskOptions = {}) =>
  (dispatch: AppDispatch) => {
    options.version = (video.max_version || video.version || 0) + 1;
    options.url = `https:${getBestSourceUrl(video)}`;
    options.shortcode = video.shortcode;
    const output = {
      ...video,
      status: 1,
      percent: 0,
      max_version: options.version,
    };
    dispatch(retranscodeAction(output));
    return new Promise((resolve, reject) => {
      transcode(options, (error, data) => {
        if (error) {
          dispatch(retranscodeAction(error));
          reject(error);
          return;
        }
        resolve(output);
      });
    });
  };

export const fetchRealtimeStats =
  (options: { domain?: string; shortcode?: string; interval?: number } = {}) =>
  (dispatch: AppDispatch) => {
    const { domain, shortcode } = options;
    if (shortcode) {
      fetch(`${env.OLD_API_HOST}/${shortcode}/stats/live`, {
        credentials: 'include',
      })
        .then((response) => response.json())
        .then((json) => {
          dispatch(fetchStatsAction({ live: json }));
        })
        .catch((error) => {
          dispatch(fetchStatsAction(error as Error));
        });
    } else if (domain) {
      fetch(`${env.OLD_API_HOST}/domain/${domain}/stats/hot`, {
        credentials: 'include',
      })
        .then((response) => response.json())
        .then((json) => dispatch(fetchStatsAction({ hot: json })))
        .catch(() => dispatch(fetchStatsAction({ hot: null })));
      fetch(`${env.OLD_API_HOST}/domain/${domain}/stats/live`, {
        credentials: 'include',
      })
        .then((response) => response.json())
        .then((json) => dispatch(fetchStatsAction({ live: json })))
        .catch(() => dispatch(fetchStatsAction({ live: null })));
    } else {
      fetch(`${env.OLD_API_HOST}/stats/hot`, {
        credentials: 'include',
      })
        .then((response) => response.json())
        .then((json) => dispatch(fetchStatsAction({ hot: json })))
        .catch(() => dispatch(fetchStatsAction({ hot: null })));
      fetch(`${env.OLD_API_HOST}/stats/live`, {
        credentials: 'include',
      })
        .then((response) => response.json())
        .then((json) => dispatch(fetchStatsAction({ live: json })))
        .catch(() => dispatch(fetchStatsAction({ live: null })));
    }
  };

export const fetchStats =
  ({
    interval,
    domain,
    shortcode,
    trending,
  }: Pick<
    FetchStatsPayload,
    'interval' | 'domain' | 'shortcode' | 'trending'
  > = {}) =>
  (dispatch: AppDispatch) => {
    const options: FetchStatsPayload = {
      interval,
      domain,
      shortcode,
      trending,
    };
    dispatch(fetchStatsAction(options));

    if (shortcode) {
      fetch(`${env.OLD_API_HOST}/${shortcode}/plays`, {
        credentials: 'include',
      })
        .then((response) => response.json())
        .then((json) => dispatch(fetchStatsAction({ plays: json })))
        .catch((error) => dispatch(fetchStatsAction(error as Error)));

      fetch(`${env.OLD_API_HOST}/${shortcode}/articles`, {
        credentials: 'include',
      })
        .then((response) => response.json())
        .then((json) => dispatch(fetchStatsAction({ articles: json })))
        .catch((error) => dispatch(fetchStatsAction(error as Error)));
    } else if (domain) {
      fetch(`${env.OLD_API_HOST}/domain/${domain}/stats?interval=${interval}`, {
        credentials: 'include',
      })
        .then((response) => response.json())
        .then((json) => dispatch(fetchStatsAction(json)))
        .catch((error) => dispatch(fetchStatsAction(error as Error)));
    } else if (trending) {
      fetch(`${env.OLD_API_HOST}/trending/${trending}/articles`, {
        credentials: 'include',
      })
        .then((response) => response.json())
        .then((json) => dispatch(fetchStatsAction({ articles: json })))
        .catch((error) => dispatch(fetchStatsAction(error as Error)));
    } else {
      const urlRoot = `${env.OLD_API_HOST}/stats/`;
      fetch(`${urlRoot}plays`, { credentials: 'include' })
        .then((response) => response.json())
        .then((json) => dispatch(fetchStatsAction({ plays: json })))
        .catch(() => dispatch(fetchStatsAction({ plays: null })));
    }

    dispatch(fetchRealtimeStats({ interval, domain, shortcode }));
  };

export const fetchVideos =
  (options: FetchVideoOptions, force: boolean) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const { videos } = getState();

    // shallow compare options and video state to see if we're getting new
    let shouldClear = !!force;
    for (const k of Object.keys(options)) {
      const key = k as keyof FetchVideoOptions;
      // @ts-ignore
      if (videos[key] !== options[key]) {
        shouldClear = true;
      }
    }

    if (shouldClear) {
      dispatch(clearVideosAction());
    }

    if (shouldClear || !videos.loaded) {
      dispatch(videosActions.dashboard.fetchVideos.initiated(options));

      return createFetchVideoRequest(options)
        .then(({ total, videos }) => {
          dispatch(
            videosActions.dashboard.fetchVideos.fulfilled({
              total,
              videos: videos.map(transformVideoAfterFetch),
            })
          );
        })
        .catch((error) => {
          Sentry.captureException(error);
          dispatch(videosActions.dashboard.fetchVideos.failed(error as Error));
        });
    }
  };

export const login =
  (email: string, password: string) => (dispatch: AppDispatch) => {
    return fetch(`${env.OLD_API_HOST}/check`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify({
        username: email,
        password,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(await response.text());
        }
        const json = await response.json();
        if (json.error) {
          throw new Error(json.message);
        }
        dispatch(subscriptionActions.fetchSubscriptionInfo());
        await dispatch(fetchCurrentUserAction(json));
        await dispatch(fetchExtraUserInfo('flags'));
        await dispatch(
          loginAction({
            error: false,
            message: null,
            ...json,
          })
        );
        onUserIsFetched(json, { activateDarkMode: true });

        return json;
      })
      .catch((error) => {
        Sentry.captureException(error);
        dispatch(loginAction(error as Error));
      });
  };

export const externalConnect =
  (authToken: string, email: string, provider: string) =>
  (dispatch: AppDispatch): Promise<UserOutput> => {
    return fetch(`${env.OLD_API_HOST}/extconnect`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify({
        authToken,
        email,
        provider,
      }),
    })
      .then((response) => response.json())
      .then(async (json) => {
        if (json.error) {
          throw new Error(json.message);
        }
        await dispatch(loginAction(json));
        dispatch(subscriptionActions.fetchSubscriptionInfo());
        await dispatch(fetchExtraUserInfo('flags'));
        onUserIsFetched(json, { activateDarkMode: true });

        return json;
      })
      .catch((error) => {
        Sentry.captureException(error);
        dispatch(loginAction(error as Error));
      });
  };

export const signup =
  (
    username: string,
    password: string,
    email: string,
    verificationRedirect: string
  ) =>
  (dispatch: AppDispatch) => {
    return fetch(`${env.OLD_API_HOST}/users`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify({
        username,
        password,
        email,
        verification_redirect: verificationRedirect,
      }),
    })
      .then(async (response) => {
        if (!response.ok) {
          throw new Error(await response.text());
        }
        const user = await response.json();
        await dispatch(loginAction(user));
        dispatch(subscriptionActions.fetchSubscriptionInfo());
        await dispatch(fetchExtraUserInfo('flags'));
        onUserIsFetched(user);

        return user;
      })
      .catch((error) => {
        Sentry.captureException(error);
        dispatch(loginAction(error as Error));
      });
  };

export const checkTranscodes =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const store = getState();
    const uploads = getUploads(store).filter((v) => v.status === 1);
    const waiting = getAllVideos(store).filter((v) => v.waitingToTranscode);

    waiting.forEach((v) => {
      const { shortcode, transcode_options, upload_metadata } = v;

      if (uploads.length > 2) {
        dispatch(editVideoAction({ queued: 1, shortcode }));
      } else {
        dispatch(editVideoAction({ waitingToTranscode: false, shortcode }));

        const transcoderOptions: UploadTaskOptions = {
          ...transcode_options,
          ...(upload_metadata?.transcoder_options || upload_metadata?.options),
        };
        transcode(transcoderOptions, (error, data) => {
          if (error) {
            log(
              `[FE Error] transcode call from checkTranscodes failed for shortcode ${shortcode} with error: ${error.message}`
            );
            if (shortcode) {
              dispatch(errorVideo(shortcode, error.message));
            }
            return;
          }
          dispatch(editVideoAction({ status: 1, shortcode }));
        });
      }
    });
  };

export const pollUploads =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const store = getState();
    const uploads = getAllVideos(store).filter((v) => v.status === 1);
    if (uploads.length) {
      const timeout = setTimeout(() => {
        dispatch(editUserAction({ networkerror: true }));
      }, 5000);

      uploads.forEach(({ shortcode, max_version: maxVersion }) => {
        fetch(
          `${env.API_HOST}/videos/${shortcode}?version=${maxVersion || 0}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Pragma: 'no-cache',
              'Cache-Control': 'no-cache',
            },
            credentials: 'include',
          }
        )
          .then(async (response) => {
            if (!response.ok) {
              throw Error(await response.text());
            }
            return response.json();
          })
          .then((video) => {
            dispatch(pollingUpdateReceivedAction([video]));
            dispatch(editUserAction({ networkerror: false }));
            clearTimeout(timeout);
          })
          .catch((error) => {
            log(
              `[poll2] - Failed to fetch progress of ${shortcode} version ${maxVersion}: ${error}`
            );
            dispatch(editUserAction({ networkerror: true }));
            clearTimeout(timeout);
          });
      });
    }
  };

export const editUser = (data: {}) => (dispatch: AppDispatch) => {
  dispatch(editUserAction(data));

  setDarkModeCookie(data);
  if (isDarkModeEnabled()) {
    enableDarkMode();
  } else {
    disableDarkMode();
  }

  return fetch(`${env.OLD_API_HOST}/me`, {
    credentials: 'include',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
    },
    body: JSON.stringify({
      session: getCookie('session'),
      ...data,
    }),
  })
    .then(async (response) => {
      if (!response.ok) {
        throw Error(await response.text());
      }
      return response.json();
    })
    .then((json) => {
      dispatch(editUserAction(json));
    })
    .catch((error) => {
      Sentry.captureException(error);
      alert(error.message);
    });
};

export const uploadPic = (photoFile: File) => (dispatch: AppDispatch) => {
  const formData = new FormData();
  formData.append('watermark', photoFile);

  return fetch(`${env.OLD_API_HOST}/me/pic`, {
    credentials: 'include',
    method: 'POST',
    body: formData,
  })
    .then((response) => response.json())
    .then((json) => dispatch(editUserAction(json)));
};

export const deleteVideo =
  (video: VideoRepresentation) => async (dispatch: AppDispatch) => {
    if (video.shortcode) {
      try {
        dispatch(videosActions.deleteVideo({ isDeleting: true, ...video }));
        const response = await fetch(
          `${env.API_HOST}/videos/${video.shortcode}`,
          {
            credentials: 'include',
            method: 'DELETE',
          }
        );
        const isDeletedString = await response.text();
        if (isDeletedString !== 'true') {
          throw new Error('Failed to delete video');
        }
        dispatch(videosActions.deleteVideo({ ...video, isDeleting: false }));
      } catch (error) {
        toast.error('Error deleting video');
        dispatch(videosActions.deleteVideo(error as Error));
        log(`[FE deleteVideo] failed for ${video.shortcode}: ${error}`);
      }
    } else {
      log(
        `[FE deleteVideo] called for a video without shortcode: ${JSON.stringify(
          video
        )}`
      );
    }
  };

export const deleteSelectedVideos =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const store = getState();
    const selectedVideos = getSelectedVideos(store);
    const deleteAllActions = selectedVideos.map((video) => deleteVideo(video));

    await Promise.all(
      deleteAllActions.map((deleteAction) => dispatch(deleteAction))
    );

    dispatch(deselectAllAction());
  };

export const extractFromUrl =
  (sourceUrl: string) => (dispatch: AppDispatch) => {
    dispatch(extractUrlAction({ isFetching: true }));

    const searchParams = new URLSearchParams();
    searchParams.append('url', sourceUrl);

    return fetch(`${env.API_HOST}/extract?${searchParams}`, {
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((json) => {
        dispatch(extractUrlAction({ isFetching: false, retries: 0, ...json }));
      })
      .catch((error) => {
        Sentry.captureException(error);
        dispatch(extractUrlAction(error as Error));
      });
  };

export const loadFile = (file: File) => (dispatch: AppDispatch) => {
  dispatch(
    extractUrlAction({
      playback_url: window.URL.createObjectURL(file),
      mime: file.type,
      file,
    })
  );
};

export const cancel =
  (
    video: VideoRepresentation & { local_id?: string; task?: UploadTask },
    source: string
  ) =>
  (dispatch: AppDispatch) => {
    if (!video.max_version) {
      if (video.shortcode && video.shortcode !== video.local_id) {
        cancelVideo(video.shortcode);
      } else {
        if (video.shortcode && video.shortcode.length > 6) {
          log(
            `[cancelVideo] request from ${source} has long shortcode ${video.shortcode}: source=${video.upload_source}, status=${video.status}, error=${video.error}`
          );
        }
      }
      dispatch(videosActions.deleteVideo(video));
    } else {
      dispatch(cancelVideoEdits(video));
    }

    if (video.task) {
      video.task.cancel();
    }
  };

export const clearStats = () => (dispatch: AppDispatch) =>
  dispatch(
    fetchStatsAction({
      plays: null,
      hot: null,
      pages: null,
      videos: null,
      live: null,
      vtrs: null,
    })
  );
