import { Button } from 'src/common/components/button/button';
import { Flex } from 'src/common/components/flex/flex';

import './labels-empty-state.scss';

type LabelsEmptyStateProps = {
  handleCreateLabelClick: () => void;
  handleDismissEmptyState: () => void;
};

export const LabelsEmptyState = ({
  handleCreateLabelClick,
  handleDismissEmptyState,
}: LabelsEmptyStateProps) => {
  return (
    <Flex direction="column" alignItems="center" className="labels-empty-state">
      <Button
        variant="icon"
        onClick={handleDismissEmptyState}
        className="labels-empty-state__dismiss-btn"
      >
        <span className="material-icons labels-empty-state__dismiss-btn__icon">
          close
        </span>
      </Button>
      <span className="material-icons-outlined labels-empty-state__icon">
        new_label
      </span>
      <span>Use labels to organize and filter your video library.</span>
      <Button
        onClick={handleCreateLabelClick}
        color="primary"
        className="labels-empty-state__create-btn"
      >
        Create new label
      </Button>
    </Flex>
  );
};
