import React from 'react';

import { Button, ButtonProps } from 'src/common/components/button/button';

import './plan-call-to-action.css';

type PlanCallToActionProps = Pick<
  ButtonProps,
  'children' | 'disabled' | 'onClick'
>;

export const PlanCallToAction = ({
  children,
  disabled = false,
  onClick,
}: PlanCallToActionProps) => (
  <Button
    onClick={onClick}
    className="plan-call-to-action"
    disabled={disabled}
    color="primary"
  >
    {children}
  </Button>
);
