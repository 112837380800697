import React, { useState } from 'react';

import { Button } from 'src/common/components/button/button';
import { Flex } from 'src/common/components/flex/flex';
import { Text } from 'src/common/components/text/text';

import { resendVerificationEmail } from './email-verification-api';
import './email-verification-required.css';

export const EmailVerificationRequired = () => {
  const [verificationSent, setVerificationSent] = useState(false);

  const handleResendVerificationEmail = async (e: React.MouseEvent) => {
    e.preventDefault();
    await resendVerificationEmail();
    setVerificationSent(true);
  };

  return (
    <Flex className="email-verification-required">
      <Text as="h3" className="email-verification-required__title">
        Email Verification Required
      </Text>

      {verificationSent ? (
        <Text className="email-verification-required__copy">
          Email verification sent. Please check your inbox for further
          instructions.
        </Text>
      ) : (
        <>
          <Text className="email-verification-required__copy">
            Please check your inbox for further instructions. Didn't get it?
          </Text>
          <Button
            onClick={handleResendVerificationEmail}
            color="primary"
            className="email-verification-required__cta"
          >
            Resend Email
          </Button>
        </>
      )}
    </Flex>
  );
};
