import React from 'react';

import classnames from 'classnames';

import './text-with-splash-expression.css';

type TextWithSplashExpressionProps = {
  className?: string;
  showSplashExpression?: boolean;
  children: React.ReactNode;
};

export const TextWithSplashExpression = ({
  className,
  children,
  showSplashExpression = true,
}: TextWithSplashExpressionProps) => (
  <span className={classnames('text-with-splash-expression', className)}>
    {children}
    {showSplashExpression && (
      <svg
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="text-with-splash-expression__expression"
      >
        <path
          d="M4.73461 12.0222L13.8933 2.86667M1 9L2.5 1M8.11354 17L16 14.5"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    )}
  </span>
);
