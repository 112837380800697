import React, { useEffect } from 'react';

import { disableDesign } from 'src/utils/disable-design';
import { enableDesign } from 'src/utils/enable-design';

import { Design } from './types';

export const withDesign =
  (design: Design | undefined = undefined) =>
  (WrappedComponent: React.FunctionComponent<any>) => {
    return function DesignProvider(props: any) {
      useEffect(() => {
        if (design === 'v1') {
          disableDesign('v2');
        } else {
          enableDesign('v2');
        }
      }, [design]);

      return <WrappedComponent {...props} />;
    };
  };
