import { MouseEvent } from 'react';

import { MouseSensor, MouseSensorOptions } from '@dnd-kit/core';

const FORBIDDEN_DOM_CLASSES = [
  HTMLButtonElement,
  HTMLTextAreaElement,
  HTMLInputElement,
  HTMLAnchorElement,
];

export class CustomMouseSensor extends MouseSensor {
  static activators: {
    eventName: 'onMouseDown';
    handler: (
      { nativeEvent }: MouseEvent<Element>,
      { onActivation }: MouseSensorOptions
    ) => boolean;
  }[] = [
    {
      eventName: 'onMouseDown',
      handler: ({ nativeEvent: event }, { onActivation }) => {
        if (!(event.target instanceof HTMLElement)) {
          return false;
        }

        for (const domClass of FORBIDDEN_DOM_CLASSES) {
          if (event.target instanceof domClass) {
            return false;
          }
        }

        onActivation?.({ event });

        return true;
      },
    },
  ];
}
