import React, { ReactElement } from 'react';

import classNames from 'classnames';

import { Flex } from '../flex/flex';
import './switch.css';

export type SwitchOption = {
  label: string | ReactElement;
  value: string;
};

type SwitchProps = {
  className?: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  options: SwitchOption[];
  value?: string;
};

type SwitchRadioProps = {
  checked: boolean;
  label: string | ReactElement;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
};

const SwitchRadio = ({
  checked,
  label,
  name,
  onChange,
  value,
}: SwitchRadioProps) => (
  <label
    className={classNames('sb-switch__label', {
      'sb-switch__label--checked': checked,
    })}
  >
    <input
      type="radio"
      name={name}
      value={value}
      onChange={onChange}
      checked={checked}
      className="sb-switch__input"
    />
    {label}
  </label>
);

export const Switch = ({
  className,
  name,
  onChange,
  options,
  value,
}: SwitchProps) => (
  <Flex
    // TODO: Check - these are wrong props, not applied?
    // @ts-expect-error
    align="center"
    justify="center"
    direction="row"
    className={classNames('sb-switch', className)}
    inline={true}
  >
    {options.map((option, i) => (
      <SwitchRadio
        key={`switch-option-${i}`}
        name={name}
        value={option.value}
        onChange={onChange}
        label={option.label}
        checked={option.value === value}
      />
    ))}
  </Flex>
);
