import { apiRequest } from 'src/utils/api-request';

import type { PageConfig } from './landing-types';

export const getMarketingPageBySlug = (slug: string) =>
  apiRequest<{ page: PageConfig }>({
    path: `/marketing/page/${slug}`,
    method: 'GET',
  });

export const trackMarketingPageVisit = (slug: string) =>
  apiRequest({
    path: `/marketing/page/${slug}/visit`,
    method: 'POST',
  });
