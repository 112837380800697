import React from 'react';

import { FeatureItem as FeatureItemType } from 'src/landing/type';

import './feature-item.css';

type FeatureItemProps = FeatureItemType & {
  mediaTitle?: string;
};

export const FeatureItem = ({
  content,
  mediaLayout = 'right',
  mediaSrc,
  mediaTitle,
  title,
}: FeatureItemProps) => (
  <article
    className={`feature-item${
      mediaLayout ? ` feature-item--${mediaLayout}` : ''
    }`}
  >
    <div className="feature-item__details">
      <h2 className="feature-item__details__title">{title}</h2>
      <p className="feature-item__details__text">{content}</p>
    </div>

    <div className="feature-item__media-container">
      {mediaSrc && (
        <img
          src={mediaSrc}
          alt={mediaTitle || title}
          className="feature-item__media"
        />
      )}
    </div>
  </article>
);
