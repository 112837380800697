export const calculateAllowedDomainHintMessage = ({
  hasSaved,
  isSaving,
  isValid,
}: {
  hasSaved: boolean;
  isSaving: boolean;
  isValid: boolean;
}) => {
  if (!isValid) {
    return undefined;
  }

  if (isSaving) {
    return 'Saving...';
  }

  if (hasSaved) {
    return 'Saved. Changes will take effect within the next 30 minutes.';
  }

  return 'Add one or multiple domains with commas (e.g."site1.com, site2.com").';
};
