import React from 'react';

import { Flex } from 'src/common/components/flex/flex';
import { FeatureItem } from 'src/landing/components/feature-item/feature-item';

import type { PageFeature } from '../../state/landing-types';
import './features.css';

type FeaturesProps = {
  features: PageFeature[];
};

export const Features = ({ features }: FeaturesProps) => (
  <div className="features-v3">
    <Flex className="features-v3__container">
      {features.map((feature, i) => (
        <FeatureItem
          key={`marketing-page-feature-${i}`}
          title={feature.title}
          content={feature.description}
          mediaSrc={feature.image?.url}
          mediaTitle={feature.image?.title}
          mediaLayout={i % 2 === 0 ? 'right' : 'left'}
        />
      ))}
    </Flex>
  </div>
);
