import * as Sentry from '@sentry/browser';
import { queue } from 'async';
import S3 from 'aws-sdk/clients/s3';
import fetch from 'isomorphic-fetch';
import throttle from 'lodash/throttle';
import { env } from 'src/env';

export type UploadTaskOptions = {
  preset?: string;
  screenshot?: boolean;
  shortcode?: string;
  upload_source?: 'web' | 'concat';
  url?: string;
  urls?: string[];
  title?: string;
  token?: string;
  height?: number;
  width?: number;
  mute?: boolean;
  version?: number;
};

export type UploadMetaData = {
  key: string;
  transcoder: boolean;
  shortcode: string;
  url: string;
  fields: Record<string, never>;
  time: number;
  credentials: never;
  accelerated: boolean;
  bucket: string;
  version: number;
  options: UploadTaskOptions;
  transcoder_options?: UploadTaskOptions;
};

export type UploadProgressData = {
  retries?: number;
  percent: number;
  speed?: number;
};
export type UploadProgressCallback = (progress: UploadProgressData) => void;

type StartCallback = () => void;

type Logger = (message: string) => void;

export type UploadTask = {
  file: File;
  options: UploadTaskOptions;
  uploadMetaData: UploadMetaData;
  finished?: boolean;
  queued: boolean;
  startCb: StartCallback;
  progressCb: UploadProgressCallback;
  logger: Logger;
  cancel: () => void;
};

type UploadFinishedError = null | Error | { name?: string; message?: string };
export type UploadFinishedData = UploadTask | S3.ManagedUpload.SendData;
export type UploadFinishedCallback = (
  error: UploadFinishedError,
  data?: UploadFinishedData
) => void;

type TranscoderResponse = {};

type TranscodeCallback = (
  error: Error | null,
  data?: TranscoderResponse
) => void;

const q = queue((task: UploadTask, onTaskFinished: UploadFinishedCallback) => {
  task.startCb();
  const s3Task = s3Upload(
    task.file,
    task.options,
    task.uploadMetaData,
    (err, data) => {
      task.finished = true;
      onTaskFinished(err, data);
    },
    task.progressCb,
    task.logger
  );
  task.cancel = () => {
    if (task.finished) {
      return;
    }

    task.finished = true;
    s3Task.cancel();
    onTaskFinished({ name: 'cancelled' });
  };
}, 3);

const TRANSCODE_BASE_API_ENDPOINT = process.env.REACT_APP_NEW_API_HOST;

export const transcode = (
  options: UploadTaskOptions,
  callback: TranscodeCallback
) =>
  fetch(`${TRANSCODE_BASE_API_ENDPOINT}/transcode/${options.shortcode}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(options),
    credentials: 'include',
  })
    .then(async (response) => {
      if (response.status === 429) {
        throw Error(
          'You’re uploading too much… please wait a little bit before trying again.'
        );
      }
      if (!response.ok) {
        if (TRANSCODE_BASE_API_ENDPOINT === env.OLD_API_HOST) {
          throw Error(await response.text());
        } else {
          const result = await response.json();
          throw Error(result && result.message);
        }
      }
      return response.json();
    })
    .then((json) => {
      if (json) {
        callback(null, json);
      }
    })
    .catch((error) => {
      Sentry.captureException(error);
      callback(error);
    });

function triggerXhr(
  file: File,
  options: UploadTaskOptions,
  uploadMetaData: UploadMetaData,
  callback: UploadFinishedCallback,
  logger: (message: string) => void
) {
  let cancelled = false;
  const formData = new FormData();
  // transcoding starts immediately!
  if (uploadMetaData.transcoder) {
    // add options to POST
    for (const k of Object.keys(options)) {
      const key = k as keyof UploadTaskOptions;
      formData.append(key, (options[key] || '').toString());
    }
  }

  for (const fieldName of Object.keys(uploadMetaData.fields)) {
    formData.append(fieldName, uploadMetaData.fields[fieldName]);
  }
  formData.append('file', file);

  const xhr = new window.XMLHttpRequest();
  xhr.open('post', uploadMetaData.url, true);

  xhr.onreadystatechange = () => {
    if (cancelled) {
      return;
    }
    if (xhr.readyState === 4) {
      xhr.onerror = null;
      if (xhr.status >= 200 && xhr.status < 400) {
        callback(null, xhr.responseText ? JSON.parse(xhr.responseText) : '');
      } else {
        logger(
          `[triggerXhr] upload failed shortcode=${
            uploadMetaData.shortcode
          }, response=${xhr.responseText}, now=${Date.now()}, url=${
            uploadMetaData.url
          }`
        );
        callback(
          new Error(
            'Cannot connect to server. (' + (xhr.status || '').toString() + ')'
          )
        );
      }
    }
  };
  xhr.onerror = (err) => {
    xhr.onreadystatechange = null;
    if (cancelled) {
      return;
    }
    // @ts-ignore
    let errorThrown = err.message;
    if (!errorThrown || errorThrown.length === 0) {
      errorThrown = 'Network error. Please try again.';
    }
    if (errorThrown === 'abort') {
      return;
    }

    callback(new Error(errorThrown));
  };
  xhr.send(formData);

  return {
    cancel: () => {
      cancelled = true;
      xhr.abort();
    },
  };
}

export function upload(
  file: File,
  options: UploadTaskOptions,
  uploadMetaData: UploadMetaData,
  callback: UploadFinishedCallback,
  progressCb: UploadProgressCallback,
  startCb: StartCallback,
  logger: Logger
) {
  const task: UploadTask = {
    file,
    options,
    uploadMetaData,
    progressCb,
    queued: false,
    startCb,
    logger,
    cancel: () => {
      // This must be overwritten by the queue
      throw new Error('Not implemented');
    },
  };
  const running = q.running();
  if (running >= q.concurrency) {
    task.queued = true;
  }
  q.push<UploadTask>(task, (err, task) => {
    if (err && err.name === 'cancelled') {
      // ignore if it's cancelled
      return;
    }
    callback(err || null, task);
  });
  return task;
}

export function s3Upload(
  file: File,
  options: UploadTaskOptions,
  uploadMetaData: UploadMetaData,
  callback: UploadFinishedCallback,
  progressCb: UploadProgressCallback,
  logger: Logger
) {
  let aborted = false;
  let xhrTask: null | { cancel: () => void } = null;
  const clockSkew = uploadMetaData.time
    ? uploadMetaData.time * 1000 - new Date().getTime()
    : 0;

  var bucket = new S3({
    apiVersion: '2006-03-01',
    region: 'us-east-1',
    credentials: uploadMetaData['credentials'],
    useAccelerateEndpoint: uploadMetaData.accelerated,
    logger: {
      log: (message) => {
        if (logger) {
          logger(`[s3] shortcode=${uploadMetaData.shortcode}:${message}`);
        }
      },
    },
    maxRetries: 15,
    systemClockOffset: clockSkew,
    retryDelayOptions: {
      customBackoff: (retries) => retries * 1000,
    },
  });

  const progressCbThrottled = throttle(progressCb, 1000);
  const upload = bucket.upload(
    {
      Key: uploadMetaData['key'],
      Body: file,
      Bucket: uploadMetaData['bucket'],
      ACL: 'public-read',
    },
    {
      queueSize: 3,
    },
    (err, data) => {
      if (err) {
        if (err.name === 'ETagMissing' || err.name === 'NetworkingError') {
          logger(
            `[s3Upload] Failed: shortcode=${uploadMetaData.shortcode}, error=${err.message}, accelerated=${uploadMetaData.accelerated}, referrer=${document.referrer} user_agent=${navigator.userAgent}`
          );
        }
        if (!aborted) {
          logger(
            `S3 Multipart Upload Failed: shortcode=${uploadMetaData.shortcode}, ${err.name}`
          );
          progressCb({
            retries: 1,
            percent: 0,
          });
          xhrTask = triggerXhr(
            file,
            options,
            uploadMetaData,
            (err, data) => {
              if (err) {
                logger(
                  `S3 XHR Upload Failed: shortcode=${uploadMetaData.shortcode}, ${err.name}`
                );
                callback(err);
              } else {
                callback(null, data);
              }
            },
            logger
          );
        }
      } else {
        callback(null, data);
      }
    }
  );

  let lastTime = Date.now();
  let lastLoaded = 0;
  let lastPercent = 0;
  let called = 0;
  upload.on('httpUploadProgress', (event) => {
    let speed = 0;
    if (event.loaded) {
      const timeDelta = Date.now() - lastTime;
      const loadedDelta = event.loaded - lastLoaded;

      speed = timeDelta ? (loadedDelta / timeDelta) * 1000 : 0;

      lastLoaded = event.loaded;
      lastTime = Date.now();
    }
    let percent = parseInt(String((event.loaded / event.total) * 100), 10);
    if (percent < lastPercent) {
      percent = lastPercent;
    }
    lastPercent = percent;
    if (called < 1000) {
      progressCb({
        percent,
        speed,
      });
    } else {
      progressCbThrottled({
        percent,
        speed,
      });
    }
    called += 1;
  });

  return {
    cancel: () => {
      aborted = true;
      upload.abort();
      if (xhrTask) {
        xhrTask.cancel();
      }
    },
  };
}
