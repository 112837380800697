import React from 'react';

import { Flex } from 'src/common/components/flex/flex';
import { Skeleton } from 'src/common/components/skeleton/skeleton';

import './widget-skeleton.css';

export const WidgetSkeleton = () => (
  <Flex className="widget-skeleton" alignItems="center" justifyContent="center">
    <Skeleton height={64} animation="wave" />
    <Skeleton height={32} animation="wave" />
    <Skeleton height={54} animation="wave" />
  </Flex>
);
