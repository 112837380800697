import { defaultTo, pipe, prop } from 'ramda';
import type { VideoRepresentation } from 'src/dashboard/types';
import { Video } from 'src/misc/video-progress/video-progress-utils';
import type { RootState } from 'src/redux';

import { selectVideoFromCacheByShortcode } from '../videos/videos-selectors';

const selectAllReplaceVideoState = (state: RootState) => state.replaceVideo;

const selectReplaceVideoModal = pipe(
  selectAllReplaceVideoState,
  prop('replaceVideoModal')
);

export const selectReplaceVideoModalIsShowing = pipe(
  selectReplaceVideoModal,
  prop('isShowing')
);

export const selectReplaceVideoModalShortcode = pipe(
  selectReplaceVideoModal,
  prop('shortcode')
);

export type ReplaceVideo = (VideoRepresentation & Video) | null;

export const selectReplaceVideoModalVideo = (
  state: RootState
): ReplaceVideo => {
  const shortcode = selectReplaceVideoModalShortcode(state);
  if (!shortcode) {
    return null;
  }

  const video = pipe(selectVideoFromCacheByShortcode, defaultTo(null))(
    state,
    shortcode
  );

  return video as ReplaceVideo;
};
