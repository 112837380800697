import React from 'react';
import { Helmet } from 'react-helmet';

import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { prop } from 'ramda';
import { Flex } from 'src/common/components/flex/flex';
import { AvailablePlan, PlanCadence } from 'src/pricing/types';
import { PaymentCollectionType } from 'src/utils/selectors/select-flags';

import './checkout-component.css';
import BillingDetailsLegacy from './components/billing-details-legacy';
import BillingDetailsPaymentElement from './components/billing-details-next';
import EmailVerificationRequired from './components/email-verification-required';
import { LeftPanel } from './components/left-panel/left-panel';
import UserAuthentication from './components/user-authentication';

export type CheckoutComponentProps = {
  chosenPlan: AvailablePlan | null;
  handleSelectCadence: (plan: PlanCadence) => void;
  isLoggedIn: boolean;
  isVerificationRequired: boolean;
  noTrial: boolean;
  paymentCollectionType: PaymentCollectionType;
  selectedCadence: PlanCadence | null;
  srcInternal?: string;
  trialPeriod: number;
};

const BillingDetailsKinds = {
  'card-element': BillingDetailsLegacy,
  'payment-element': BillingDetailsPaymentElement,
};

export const CheckoutComponent = ({
  chosenPlan,
  handleSelectCadence,
  isLoggedIn,
  isVerificationRequired,
  noTrial,
  paymentCollectionType,
  selectedCadence,
  srcInternal,
  trialPeriod,
}: CheckoutComponentProps) => {
  const BillingDetails = BillingDetailsKinds[paymentCollectionType];
  const shouldRenderBillingDetails = isLoggedIn && !isVerificationRequired;

  return (
    <div className="checkout">
      <Helmet>
        <title>Checkout - Streamable</title>
        <meta name="description" content="Checkout on Streamable"></meta>
        <link rel="canonical" href={`https://streamable.com/checkout`} />
      </Helmet>

      <div
        className={classnames(
          'checkout-container',
          isLoggedIn ? 'payment' : 'signin'
        )}
      >
        <LeftPanel
          title={
            chosenPlan && selectedCadence
              ? chosenPlan[selectedCadence].name
              : ''
          }
          features={chosenPlan?.features.map(prop('description')) || []}
          noTrial={noTrial}
          trialPeriod={trialPeriod}
          comparePlansLink={`/pricing?src_internal=${srcInternal}&cadence=${selectedCadence}`}
          isLoading={!chosenPlan}
        />

        <div
          className={classnames(
            'checkout-box',
            isLoggedIn ? 'payment' : 'signin',
            {
              notrial: noTrial,
            }
          )}
        >
          <div
            className={classnames('card', {
              'card--billing-details': shouldRenderBillingDetails,
            })}
          >
            {isLoggedIn ? (
              isVerificationRequired ? (
                <EmailVerificationRequired />
              ) : (
                <BillingDetails
                  chosenPlan={chosenPlan}
                  handleSelectCadence={handleSelectCadence}
                  selectedCadence={selectedCadence}
                  noTrial={noTrial}
                />
              )
            ) : (
              <UserAuthentication />
            )}
          </div>

          {isLoggedIn && !isVerificationRequired && (
            <Flex
              direction="row"
              alignItems="center"
              justifyContent="center"
              className="footer"
            >
              <FontAwesomeIcon icon={faLock} /> Secured by SSL encryption
            </Flex>
          )}
        </div>
      </div>
    </div>
  );
};
