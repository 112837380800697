import { pipe, prop } from 'ramda';
import type { RootState } from 'src/redux';

const selectAllVideoPrivacyState = (state: RootState) => state.videoPrivacy;

export const selectVideoPrivacyModalIsShowing = pipe(
  selectAllVideoPrivacyState,
  prop('videoPrivacyModal'),
  prop('isShowing')
);

export const selectShortcodeForVideoPrivacyModal = pipe(
  selectAllVideoPrivacyState,
  prop('videoPrivacyModal'),
  prop('shortcode')
);

export const selectVideoPrivacyResetModalIsShowing = pipe(
  selectAllVideoPrivacyState,
  prop('resetModal'),
  prop('isShowing')
);

export const selectVideoPrivacyResetModalIsResetting = pipe(
  selectAllVideoPrivacyState,
  prop('resetModal'),
  prop('isResetting')
);

export const selectVideoPrivacyAllowedDomainIsSaving = pipe(
  selectAllVideoPrivacyState,
  prop('videoPrivacyModal'),
  prop('allowedDomain'),
  prop('isLoading')
);

export const selectVideoPrivacyAllowedDomainHasSaved = pipe(
  selectAllVideoPrivacyState,
  prop('videoPrivacyModal'),
  prop('allowedDomain'),
  prop('isDone')
);
