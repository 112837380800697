import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { Button } from 'src/common/components/button/button';
import { selectFreeTrialDuration } from 'src/common/state/me/me-selectors';
import type { MeState } from 'src/common/state/me/me-types';
import { dashboardUpgradeActions } from 'src/dashboard/state/upgrade/upgrade-actions';
import type { RootState, AppDispatch } from 'src/redux';

import { editUserAction } from '../../../actions';
import { getExperiment } from '../../../utils/get-experiment';
import { Modal, ModalBody } from '../modals';
import '../modals.css';
import './exceeds-limits-modal.css';

type ExceedsLimitsModalProps = {
  me: MeState;
  trialPeriod?: number;
  handleUpsellClick: () => void;
  closeVideoExceedsModal: () => void;
};

const ExceedsLimitsModal = ({
  me,
  trialPeriod,
  handleUpsellClick,
  closeVideoExceedsModal,
}: ExceedsLimitsModalProps) => {
  const noTrial = getExperiment('noFreeTrials') ? true : me.no_trial;

  let maxSizeStr = '';
  if (me.plan_max_size) {
    maxSizeStr = `${me.plan_max_size.toString()}GB`;
    if (me.plan_max_size < 1) {
      maxSizeStr = `${(me.plan_max_size * 1000).toString()}MB`;
    }
  }
  let maxLengthStr = '';
  if (me.plan_max_length) {
    maxLengthStr = `${(me.plan_max_length / 60).toString()} minute`;
  }

  const sizeError =
    me.videoExceedsLimits && me.videoExceedsLimits.includes('toolarge');

  const forYouText = me.user_name ? 'your plan' : 'guest accounts';

  const showPaymentFailedMessaging =
    me.plan && me.subscription_status === 'UNPAID';

  const modalSubtitle = showPaymentFailedMessaging ? (
    <p>
      In order to continue uploading video larger than 250MB or 10 minutes,
      please update your payment method.
    </p>
  ) : (
    <p className="upgrade-modal--subtitle-reason">
      <span className="upgrade-modal--subtitle-textline">
        {sizeError
          ? `Your video exceeds the ${maxSizeStr} max size limit for ${forYouText}.`
          : `Your video exceeds the ${maxLengthStr} duration limit for ${forYouText}.`}
      </span>{' '}
      {!noTrial && (
        <span className="upgrade-modal--subtitle-textline">
          Try Streamable Basic for {trialPeriod} days to continue uploading this
          video.
        </span>
      )}
    </p>
  );

  useEffect(() => {
    if (me.videoExceedsLimits) {
      const sizeError = me.videoExceedsLimits.includes('toolarge');

      window.dataLayer = window.dataLayer || [];
      if (sizeError) {
        window.dataLayer.push({
          event: 'upgrade-modal-open-too-large',
        });
      } else {
        window.dataLayer.push({
          event: 'upgrade-modal-open-too-long',
        });
      }
    }
  }, [me.videoExceedsLimits]);

  return (
    <Modal
      isOpen={!!me.videoExceedsLimits}
      toggle={closeVideoExceedsModal}
      className="full-modal"
      title=""
      darkMode={me.dark_mode}
      hideOverlayScroll={true}
    >
      <ModalBody>
        <div className="container">
          <h2 className="upgrade-modal--title">
            {showPaymentFailedMessaging
              ? 'Your payment method was declined'
              : 'Unlock Streamable Basic'}
          </h2>

          <div className="upgrade-modal--subtitle">{modalSubtitle}</div>

          <div className="upgrade-moda--cta">
            <Button
              onClick={() => {
                if (showPaymentFailedMessaging) {
                  browserHistory.push('/billing');
                } else {
                  handleUpsellClick();
                }
                closeVideoExceedsModal();
              }}
            >
              {showPaymentFailedMessaging
                ? 'Update payment method'
                : noTrial
                  ? 'Upgrade to Basic'
                  : 'Start free trial'}
            </Button>

            {!showPaymentFailedMessaging && (
              <p className="upgrade-modal-cta-trial">
                {noTrial
                  ? 'Cancel anytime.'
                  : `${trialPeriod} days free trial. Cancel anytime.`}
              </p>
            )}
          </div>

          <p
            className="upgrade-modal-cta-cancel"
            onClick={closeVideoExceedsModal}
          >
            No Thanks
          </p>
        </div>
      </ModalBody>
    </Modal>
  );
};
const mapStateToProps = (state: RootState) => ({
  me: state.me,
  trialPeriod: selectFreeTrialDuration(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  closeVideoExceedsModal: () =>
    dispatch(editUserAction({ videoExceedsLimits: undefined })),
  handleUpsellClick: () =>
    dispatch(
      dashboardUpgradeActions.upgradeModal.triggered({
        queryParams: {
          from: 'dashboard',
          src_internal: 'exceeds-limits-modal',
        },
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExceedsLimitsModal);
