import React from 'react';

import { Switch } from '@headlessui/react';
import classnames from 'classnames';

import { Flex } from '../flex/flex';
import './toggle.css';

export type ToggleProps = {
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?: (newValue: boolean) => void;
  name?: string;
  size?: 'small' | 'large';
  value?: string;
};

export const Toggle = ({
  checked = false,
  defaultChecked = false,
  disabled = false,
  onChange = () => {},
  name,
  size = 'small',
  value,
}: ToggleProps) => (
  <Switch
    className={classnames('sb-toggle', `sb-toggle--${size}`)}
    checked={checked}
    defaultChecked={defaultChecked}
    onChange={onChange}
    name={name}
    value={value}
    disabled={disabled}
  >
    <Flex
      direction="row"
      alignItems="center"
      className="sb-toggle__visible-toggle"
    />
  </Switch>
);
