import React from 'react';

export const XLogo = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1294_1475)">
      <path
        d="M11.9839 9.77491L17.8122 3H16.4311L11.3704 8.88256L7.32843 3H2.6665L8.77875 11.8955L2.6665 19H4.0477L9.39193 12.7878L13.6605 19H18.3225L11.9836 9.77491H11.9839ZM10.0922 11.9738L9.47289 11.0881L4.54536 4.03974H6.6668L10.6434 9.72795L11.2627 10.6137L16.4317 18.0075H14.3103L10.0922 11.9742V11.9738Z"
        fill="currentColor"
      />
      <title>X Logo</title>
    </g>
    <defs>
      <clipPath id="clip0_1294_1475">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.666504)"
        />
      </clipPath>
    </defs>
  </svg>
);
