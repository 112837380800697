import { pipe, prop } from 'ramda';
import type { RootState } from 'src/redux';

const selectAllLandingState = (state: RootState) => state.landing;

export const selectLandingSlug = pipe(selectAllLandingState, prop('slug'));

export const selectLandingPage = pipe(selectAllLandingState, prop('page'));

export const selectLandingPageData = pipe(selectLandingPage, prop('data'));

export const selectLandingPageIsDataLoading = pipe(
  selectLandingPage,
  prop('isLoading')
);
