import React from 'react';

import classNames from 'classnames';

import { type Video, extractVideoStats } from './video-progress-utils';
import './video-progress.css';

type VideoProgressProps = {
  className?: string;
  onCancel?: () => void;
  video: Video;
};

export const VideoProgress = ({
  video,
  className,
  onCancel,
}: VideoProgressProps) => {
  const { key, status, percent } = extractVideoStats(video);

  return (
    <div className={classNames('progress-overlay', className)}>
      <div className="progress-status">{status}</div>
      <div className="progress-bar-track">
        <div
          className="progress-bar-value"
          key={key}
          style={{ width: `${percent.toString()}%` }}
        ></div>
      </div>
      {onCancel && video.shortcode ? (
        <div className="progress-cancel">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onCancel();
            }}
          >
            Cancel
          </a>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
