import React from 'react';
import { browserHistory } from 'react-router';

import { Flex } from 'src/common/components/flex/flex';
import { Text } from 'src/common/components/text/text';
import type { PageConfig } from 'src/landing-new/state/landing-types';
import { Markdown } from 'src/misc/markdown';

import { ReactComponent as LinkIcon } from './assets/link.svg';
import './clipper.css';

type ClipperProps = {
  page: PageConfig;
};

export const Clipper = ({ page }: ClipperProps) => {
  const handleClipUrl = (url: string) => {
    browserHistory.push(`/clipper?url=${url}`);
  };
  return (
    <Flex className="clipper" alignItems="center" justifyContent="center">
      <Text as="h1" className="clipper__title">
        {page.title || 'Clip from URL'}
      </Text>
      <Text as="p" className="clipper__copy">
        <Markdown>
          {page.description ||
            `Clip video from YouTube, Twitter, and hundreds of other sites. It's free
        to use for videos up to 10 minutes long — no gimmicks and no watermarks.
        A free trial is available for larger files.`}
        </Markdown>
      </Text>
      <Flex className="input-container" alignItems="center" direction="row">
        <LinkIcon />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const url = (
              (e.target as HTMLFormElement).elements[0] as HTMLInputElement
            ).value;
            handleClipUrl(url);
          }}
        >
          <input
            placeholder="Paste a video link"
            autoComplete="off"
            onPaste={(e) => {
              const url = e.clipboardData.getData('text');
              handleClipUrl(url);
            }}
          ></input>
        </form>
      </Flex>
    </Flex>
  );
};
