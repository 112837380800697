import { connect } from 'react-redux';

import {
  selectFreeTrialDuration,
  selectNoTrial,
} from 'src/common/state/me/me-selectors';
import { selectPlanForUpsell } from 'src/pricing/state/pricing-selectors';
import type { RootState } from 'src/redux';

import { UpsellCardComponent } from './upsell-card-component';

const mapStateToProps = (state: RootState) => ({
  noTrial: selectNoTrial(state),
  plan: selectPlanForUpsell(state),
  trialPeriod: selectFreeTrialDuration(state),
});

export const UpsellCardContainer =
  connect(mapStateToProps)(UpsellCardComponent);
