import { createSelector } from '@reduxjs/toolkit';
import { pipe, prop } from 'ramda';
import type { RootState } from 'src/redux';

const selectAllSettingsState = (state: RootState) => state.settings;

export const selectResetModalIsShowing = pipe(
  selectAllSettingsState,
  prop('resetModal'),
  prop('isShowing')
);

export const selectResetModalIsResetting = pipe(
  selectAllSettingsState,
  prop('resetModal'),
  prop('isResetting')
);

export const selectPlayerWatermarkLinkIsSaving = pipe(
  selectAllSettingsState,
  prop('player'),
  prop('watermarkLink'),
  prop('isLoading')
);

export const selectPlayerWatermarkLinkHasSaved = pipe(
  selectAllSettingsState,
  prop('player'),
  prop('watermarkLink'),
  prop('isDone')
);

export const selectPrivacySettings = createSelector(
  selectAllSettingsState,
  (settings) => settings.privacy
);

export const selectPrivacyAllowDownload = createSelector(
  selectPrivacySettings,
  (privacy) => privacy.allow_download || false
);

export const selectPrivacyAllowSharing = createSelector(
  selectPrivacySettings,
  (privacy) => privacy.allow_sharing || false
);

export const selectPrivacyHideViewCount = createSelector(
  selectPrivacySettings,
  (privacy) => privacy.hide_view_count || false
);

export const selectPrivacyDomainRestrictions = pipe(
  selectAllSettingsState,
  prop('privacy'),
  prop('domain_restrictions')
);

export const selectPrivacyAllowedDomainValue = pipe(
  selectAllSettingsState,
  prop('privacy'),
  prop('allowed_domain'),
  prop('value')
);

export const selectPrivacyAllowedDomainIsSaving = pipe(
  selectAllSettingsState,
  prop('privacy'),
  prop('allowed_domain'),
  prop('isLoading')
);

export const selectPrivacyAllowedDomainHasSaved = pipe(
  selectAllSettingsState,
  prop('privacy'),
  prop('allowed_domain'),
  prop('isDone')
);

export const selectPrivacyVisibility = pipe(
  selectAllSettingsState,
  prop('privacy'),
  prop('visibility')
);
