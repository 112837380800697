import React from 'react';

import { useIsThinnerThanBreakpoint } from '../hooks/use-is-thinner-than-breakpoint';

const SLIDEOVER_BREAKPOINT = 992;

// Define a HOC that takes a component and returns a new component with the isMobile prop
export function withIsSlideOver(WrappedComponent) {
  // Create a new component that renders the WrappedComponent with the isMobile prop
  const WithIsSlideOver = (props) => {
    const isSlideOver = useIsThinnerThanBreakpoint({
      breakpoint: SLIDEOVER_BREAKPOINT,
    });

    // Render the WrappedComponent with the isMobile prop and the other props
    return <WrappedComponent {...props} isSlideOver={isSlideOver} />;
  };

  // Set the display name for easier debugging
  WithIsSlideOver.displayName = `withIsSlideOver(${getDisplayName(
    WrappedComponent
  )})`;

  return WithIsSlideOver;
}

// Helper function to get the display name of a component
function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
