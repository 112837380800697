import React from 'react';

import Logo from 'src/assets/newblacklogo.png';

import type { CommonLogoProps } from './types';

export const BlackLogo = ({ style }: CommonLogoProps) => (
  <img
    className="logo"
    src={Logo}
    role="presentation"
    alt="Black Logo"
    style={style}
  />
);
