import React, { useEffect, useRef, useCallback } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';
import FaLock from 'react-icons/lib/fa/lock';
import FaArrowLeft from 'react-icons/lib/ti/arrow-left';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import {
  Elements,
  CardElement,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { selectAllMeState } from 'src/common/state/me/me-selectors';
import { selectCheckoutState } from 'src/reducers/checkout';

import {
  updateCardAction,
  userChangePayment,
  fetchExtraUserInfo,
  log,
} from '../../actions';
import { subscriptionActions } from '../subscription/subscription-actions';
import './settings.css';

const mapStateToProps = (state, ownProps) => ({
  checkout: selectCheckoutState(state),
  me: selectAllMeState(state),
});

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const ChangePayment = ({ dispatch, checkout, me }) => {
  const recaptchaRef = useRef();

  useEffect(() => {
    if (!me.user_name) {
      browserHistory.push('/login?redirect=%2Fchange_payment');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // reset it on error so the user can try again
  if (checkout.error && window.grecaptcha) {
    window.grecaptcha.reset();
  }

  const onSubmit = useCallback(
    (elements, stripe) => async (e) => {
      e.preventDefault();

      log('CHANGE PAYMENT RECAPTCHA SHOWN');

      const start = Date.now();
      const token = await recaptchaRef.current.executeAsync();
      const delta = Date.now() - start;

      log(
        'CHANGE PAYMENT RECAPTCHA TOKEN RETURNED IN ' + delta.toString() + 'ms'
      );

      // this is necessary so it can be run again on error!
      recaptchaRef.current.reset();
      if (!stripe || !elements || !token) {
        return;
      }
      dispatch(updateCardAction({ purchasing: true }));

      const cardElement = elements.getElement(CardElement);

      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (result.error) {
        dispatch(updateCardAction(new Error(result.error.message)));
      } else {
        const user = await dispatch(
          userChangePayment(result.paymentMethod.id, token)
        );
        if (user && !user.error) {
          await dispatch(fetchExtraUserInfo('billing_info'));
          await dispatch(subscriptionActions.fetchSubscriptionInfo());
          browserHistory.push(`/billing?alert=card_changed`);
        }
      }
    },
    [dispatch, recaptchaRef]
  );

  return (
    <>
      <div className="update-card--back">
        <a
          className="back"
          href="/"
          onClick={(e) => {
            e.preventDefault();
            browserHistory.goBack();
          }}
        >
          <FaArrowLeft />
        </a>
      </div>
      <Elements stripe={stripePromise}>
        <ElementsConsumer>
          {({ elements, stripe }) => (
            <div className="settings">
              <Helmet>
                <title>Change Payment - Streamable</title>
                <meta name="description" content="Update your card."></meta>
                <link
                  rel="canonical"
                  href={`https://streamable.com/change_payment`}
                />
              </Helmet>
              <div className="card change-payment">
                <div className="card-header">Update Card</div>
                <div className="card-block">
                  <form
                    style={{ marginBottom: '5px' }}
                    onSubmit={onSubmit(elements, stripe)}
                  >
                    <CardElement
                      onReady={() => {
                        dispatch(
                          updateCardAction({
                            purchasing: false,
                            error: false,
                            message: null,
                          })
                        );
                      }}
                      options={{
                        hidePostalCode: true,
                        style: {
                          base: {
                            color: 'rgb(116, 132, 144)',
                            fontFamily: 'Open Sans, sans-serif',
                            fontSize: '1rem',
                            border: '1px solid #ced4da',
                            '::placeholder': {
                              color: '#aaa',
                            },
                          },
                          invalid: {
                            color: '#9e2146',
                          },
                        },
                      }}
                    />
                    {checkout.error && (
                      <div className="alert alert-warning">
                        {checkout.message}
                      </div>
                    )}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ marginTop: '1px' }}>
                        <FaLock />
                      </span>
                      <span style={{ flex: 1, marginLeft: '5px' }}>
                        Secured with SSL encryption
                      </span>
                      <button
                        id="submit"
                        type="submit"
                        className={`blue-button ${
                          checkout.purchasing || !stripe ? 'disabled' : ''
                        }`}
                        disabled={checkout.purchasing || !stripe}
                      >
                        Update Card
                      </button>
                    </div>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey="6LefNMAZAAAAANHI6PRR6Vh9yMBsCmipPTw8jPOP"
                    />
                  </form>
                </div>
              </div>
            </div>
          )}
        </ElementsConsumer>
      </Elements>
    </>
  );
};

export default connect(mapStateToProps)(ChangePayment);
