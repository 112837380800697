import { eventChannel } from 'redux-saga';
import { call, all, takeEvery, select, put } from 'redux-saga/effects';
import { loadApp } from 'src/app/app-loading/app-loading-saga';
import { replaceSearchParamsInUrl } from 'src/dashboard/utils/replace-search-params-in-url';

import {
  type UpgradeQueryParams,
  dashboardUpgradeActions,
} from './upgrade-actions';
import { selectUpgradeModalIsOpen } from './upgrade-selectors';

const SEARCH_PARAMS = {
  CHECKOUT: 'checkout',
  FROM: 'from',
  SRC_INTERNAL: 'src_internal',
};
const SEARCH_PARAMS_VALUES = {
  PICK_A_PLAN: 'pick-a-plan',
};

export function* handleOpenUpgradeModal({
  payload,
}: {
  payload?: { queryParams?: UpgradeQueryParams };
} = {}) {
  const newParams = new URLSearchParams(window.location.search);
  newParams.set(SEARCH_PARAMS.CHECKOUT, SEARCH_PARAMS_VALUES.PICK_A_PLAN);

  if (payload?.queryParams?.from) {
    newParams.set('from', payload.queryParams.from);
  }

  if (payload?.queryParams?.src_internal) {
    newParams.set('src_internal', payload.queryParams.src_internal);
  }

  yield call(
    [window.history, 'pushState'],
    null,
    '',
    replaceSearchParamsInUrl(newParams)
  );
}

export function* handleCloseUpgradeModal() {
  const newParams = new URLSearchParams(window.location.search);
  newParams.delete(SEARCH_PARAMS.CHECKOUT);
  newParams.delete(SEARCH_PARAMS.FROM);
  newParams.delete(SEARCH_PARAMS.SRC_INTERNAL);

  yield call(
    [window.history, 'replaceState'],
    null,
    '',
    replaceSearchParamsInUrl(newParams)
  );
}

export function* handleBrowserNavigation() {
  const searchParams = new URLSearchParams(window.location.search);
  const checkout = searchParams.get(SEARCH_PARAMS.CHECKOUT);
  const shouldBeOpen = checkout === SEARCH_PARAMS_VALUES.PICK_A_PLAN;
  const isOpen: boolean = yield select(selectUpgradeModalIsOpen);

  if (shouldBeOpen !== isOpen) {
    if (shouldBeOpen) {
      yield put(dashboardUpgradeActions.upgradeModal.triggered());
    } else {
      yield put(dashboardUpgradeActions.upgradeModal.closed());
    }
  }
}

export function* watchUpgradeModalSaga() {
  const popStateChannel = eventChannel((emitter) => {
    window.addEventListener('popstate', emitter);
    return () => window.removeEventListener('popstate', emitter);
  });
  yield all([
    takeEvery(loadApp, handleCloseUpgradeModal),
    takeEvery(popStateChannel, handleBrowserNavigation),
    takeEvery(
      dashboardUpgradeActions.upgradeModal.triggered,
      handleOpenUpgradeModal
    ),
    takeEvery(
      dashboardUpgradeActions.upgradeModal.closed,
      handleCloseUpgradeModal
    ),
  ]);
}
