import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Checkout from 'src/checkout';
import { CheckoutNavBar } from 'src/checkout/components/navbar';
import ProcessingPaymentOverlay from 'src/checkout/components/processing-payment-overlay';
import { checkoutActions } from 'src/checkout/state/checkout-actions';
import { ToastManager } from 'src/common/components/toast/toast-manager';

const CheckoutPage = ({ params, location, initFromQuery }) => {
  useEffect(() => {
    initFromQuery(location.query);
  }, [initFromQuery, location.query]);

  return (
    <div className="s_p_a">
      <CheckoutNavBar />
      <ToastManager />
      <ProcessingPaymentOverlay />
      <Checkout
        action="login"
        key="checkout"
        viewParams={{ ...params, query: location.query }}
      />
    </div>
  );
};

const mapDispatchToProps = {
  initFromQuery: checkoutActions.billingDetails.initFromQuery,
};

export default connect(null, mapDispatchToProps)(CheckoutPage);
