import { ActivatedSubscriptionInfo } from 'src/app/subscription/subscription-types';
import { ANNUAL_CADENCE } from 'src/pricing/constants';
import { AvailablePlanPrice } from 'src/pricing/types';

export const isDowngrade = (
  currentPlan: ActivatedSubscriptionInfo,
  targetPlan: AvailablePlanPrice
) => {
  if (!currentPlan.published) {
    return false;
  }

  const currentPlanPrice =
    currentPlan.cadence === ANNUAL_CADENCE
      ? currentPlan.price / 12.0
      : currentPlan.price;

  const targetPlanPrice =
    targetPlan.cadence === ANNUAL_CADENCE
      ? targetPlan.price / 12.0
      : targetPlan.price;

  return currentPlanPrice > targetPlanPrice;
};
