export const triggerGoogleAnalyticsEvent = (
  event: string,
  eventName = 'event'
) => {
  // Make sure to define gtag function in /public/index.html
  // Tag manager installation instruction - https://developers.google.com/tag-platform/gtagjs/install
  // @ts-expect-error
  const gtag = window.gtag;
  if (gtag) {
    gtag(eventName, event);
  } else {
    console.error(
      'Could not trigger a GTM event, check if gtag is defined and configured'
    );
  }
};
