import { createAction } from '@reduxjs/toolkit';

export const dashboardUpsellActions = {
  storageUpsellModal: {
    triggered: createAction<string>(
      '[Dashboard Storage Upsell Modal] triggered'
    ),
    closed: createAction('[Dashboard Storage Upsell Modal] closed'),
    upgradeButton: {
      clicked: createAction<string>(
        '[Dashboard Storage Upsell Modal] upgrade button clicked'
      ),
    },
  },
};
