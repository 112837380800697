import React from 'react';
import { Link } from 'react-router';

import { Flex } from 'src/common/components/flex/flex';
import { Text } from 'src/common/components/text/text';

import './navigation-bar-sub-menu-section.css';

type NavigationBarSubMenuSectionProps = {
  title: string;
  links: { label: string; slug: string }[];
  onClick?: () => void;
};

export const NavigationBarSubMenuSection = ({
  title,
  links,
  onClick: handleClick,
}: NavigationBarSubMenuSectionProps) => (
  <Flex as="section" className="navigation-bar-sub-menu-section">
    <Text as="p" className="navigation-bar-sub-menu-section__title">
      {title}
    </Text>

    <Flex as="ul" className="navigation-bar-sub-menu-section__list">
      {links.map(({ label, slug }, i) => (
        <li key={`navigation-bar-sub-menu-section-link-${slug}`}>
          <Link
            to={slug}
            className="navigation-bar-sub-menu-section__link"
            onClick={handleClick}
          >
            {label}
          </Link>
        </li>
      ))}
    </Flex>
  </Flex>
);
