import { connect } from 'react-redux';

import { useEffectOnce } from 'src/common/hooks/use-effect-once';

import { loadApp } from './app-loading-saga';

type AppLoadingPropsFromRedux = {
  loadApp: typeof loadApp;
};

function AppLoadingComponent({ loadApp }: AppLoadingPropsFromRedux) {
  useEffectOnce(() => {
    loadApp();
  });

  return null;
}

const mapDispatchToProps = { loadApp };

export default connect(undefined, mapDispatchToProps)(AppLoadingComponent);
