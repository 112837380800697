import React, {
  ElementType,
  ComponentPropsWithoutRef,
  forwardRef,
  Ref,
  PropsWithChildren,
} from 'react';

import classNames from 'classnames';

import './flex.css';

type FlexProps<T extends ElementType> = {
  className?: string;
  alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'normal';
  direction?: 'column' | 'row';
  as?: T;
  flex?: number;
  justifyContent?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-around'
    | 'space-between'
    | 'space-evenly';
  inline?: boolean;
};

type FlexPropsGeneric<T extends ElementType> = FlexProps<T> &
  Omit<ComponentPropsWithoutRef<T>, keyof FlexProps<T>>;

export const Flex = forwardRef(
  <T extends ElementType = 'div'>(
    {
      as,
      children,
      className,
      alignItems = 'normal',
      direction = 'column',
      justifyContent = 'flex-start',
      inline = false,
      ...restOfProps
    }: FlexPropsGeneric<T>,
    ref: Ref<HTMLElement>
  ) => {
    const componentType = as || 'div';

    return React.createElement(
      componentType,
      {
        className: classNames(
          'sb-flex',
          `sb-flex--${direction}`,
          `sb-flex--align-items-${alignItems}`,
          `sb-flex--justify-content-${justifyContent}`,
          {
            'sb-flex--inline': inline,
          },
          className
        ),
        ...restOfProps,
        ref,
      },
      children
    );
  }
) as <T extends ElementType = 'div'>(
  props: PropsWithChildren<FlexPropsGeneric<T>> & {
    id?: string;
    onClick?: () => void;
    ref?: Ref<HTMLElement>;
  }
) => JSX.Element;
