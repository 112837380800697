import { all } from 'redux-saga/effects';
import { watchLoadApp } from 'src/app/app-loading/app-loading-saga';
import {
  watchSubscriptionSaga,
  watchInvoicesSaga,
} from 'src/app/subscription/subscription-saga';
import { checkoutSaga } from 'src/checkout/state/checkout-saga';
import { loggingSaga } from 'src/common/logging/logging-saga';
import { watchMeSaga } from 'src/common/state/me/me-saga';
import { watchCollectionSaga } from 'src/dashboard/state/collection/collection-sagas';
import { watchReplaceVideoSaga } from 'src/dashboard/state/replace-video/replace-video-saga';
import { watchSuggestionsSaga } from 'src/dashboard/state/suggestions/suggestions-saga';
import { watchVideoPrivacySaga } from 'src/dashboard/state/video-privacy/video-privacy-saga';
import { watchLandingSaga } from 'src/landing-new/state/landing-saga';
import { pricingSaga } from 'src/pricing/state/pricing-saga';
import { watchSettingsSaga } from 'src/settings/state/settings-saga';

import { embedPreviewSaga } from './dashboard/state/embed-preview/embed-preview-saga';
import { watchPlayerPagesSaga } from './dashboard/state/player-pages/player-pages-sagas';
import { watchUpgradeModalSaga } from './dashboard/state/upgrade/upgrade-modal-saga';
import { watchVideoCaptionsSaga } from './dashboard/state/video-captions/video-captions-saga';
import type { AppDispatch } from './redux/store';

function* rootSaga(dispatch: AppDispatch) {
  yield all([
    watchLoadApp(),
    checkoutSaga(),
    embedPreviewSaga(),
    pricingSaga(),
    loggingSaga(),
    watchInvoicesSaga(),
    watchMeSaga(),
    watchSettingsSaga(),
    watchSubscriptionSaga(),
    watchVideoPrivacySaga(),
    watchLandingSaga(),
    watchReplaceVideoSaga(dispatch),
    watchSuggestionsSaga(),
    watchCollectionSaga(),
    watchUpgradeModalSaga(),
    watchPlayerPagesSaga(),
    watchVideoCaptionsSaga(),
  ]);
}

export default rootSaga;
