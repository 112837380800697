import type { EffectCallback } from 'react';
import { useEffect } from 'react';

import { useFirstMountState } from './use-first-mount-state';

/**
 * Accepts a function that contains imperative, possibly effectful code.
 * It executes that function exactly once.
 *
 * @param effect Imperative function that can return a cleanup function
 */
export function useEffectOnce(effect: EffectCallback) {
  const isFirstMount = useFirstMountState();

  useEffect(() => {
    if (isFirstMount) {
      return effect();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
