import { connect } from 'react-redux';

import { dashboardSuggestionsActions } from 'src/dashboard/state/suggestions/suggestions-actions';
import {
  selectUpsellPlanWithEnterprise,
  selectUpsellCardParams,
} from 'src/dashboard/state/suggestions/suggestions-selectors';
import type { AvailablePlan } from 'src/pricing/types';
import type { RootState, AppDispatch } from 'src/redux';

import { UpsellComponent } from './upsell-component';

const mapStateToProps = (state: RootState) => ({
  ...selectUpsellCardParams(state),
  plan: selectUpsellPlanWithEnterprise(state) as AvailablePlan,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  dispatch,
  dismiss: () => dispatch(dashboardSuggestionsActions.suggestionCard.closed()),
});

export const Upsell = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpsellComponent);
