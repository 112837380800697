import { pipe, prop } from 'ramda';
import type { RootState } from 'src/redux';

export const selectAllCheckoutState = (state: RootState) =>
  state.checkoutReducer;

const selectBillingDetailsNext = pipe(
  selectAllCheckoutState,
  prop('billingDetails'),
  prop('next')
);

export const selectClientSecret = pipe(
  selectBillingDetailsNext,
  prop('clientSecret')
);

export const selectIntentType = pipe(
  selectBillingDetailsNext,
  prop('intentType')
);

export const selectRedirectStatus = pipe(
  selectAllCheckoutState,
  prop('query'),
  prop('redirectStatus')
);

export const selectIsProcessingOverlayOpen = pipe(
  selectBillingDetailsNext,
  prop('processingOverlay'),
  prop('isOpen')
);

export const selectProcessingStep = pipe(
  selectBillingDetailsNext,
  prop('processingOverlay'),
  prop('step')
);
