import React from 'react';

import { Logo } from 'src/common/components/logo';

import ReportView from '../app/report/Report';

const Report = ({ params, location }) => (
  <div style={{ minHeight: '100vh' }}>
    <div className="app-nav">
      <nav className="navbar navbar-full">
        <a href="/" key="logo">
          <Logo type="black" />
        </a>
      </nav>
    </div>
    <ReportView
      viewParams={params}
      shortcode={params.shortcode}
      query={location.query}
    />
  </div>
);

export default Report;
