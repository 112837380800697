import { prop, pipe } from 'ramda';
import type { RootState } from 'src/redux';

const selectAllCollectionsState = (state: RootState) => state.collections;

export const selectCreateCollectionIsLoading = pipe(
  selectAllCollectionsState,
  prop('createCollection'),
  prop('isLoading')
);
