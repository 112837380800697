import React from 'react';

import classnames from 'classnames';

import './text-with-highlight-stroke.css';

type TextWithHighlightStrokeProps = {
  className?: string;
  children: React.ReactNode;
};

export const TextWithHighlightStroke = ({
  className,
  children,
}: TextWithHighlightStrokeProps) => (
  <span className={classnames('text-with-highlight-stroke', className)}>
    {children}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="94"
      height="9"
      className="text-with-highlight-stroke__underline"
    >
      <path
        d="M 2.609 5.143 C 8.81 5.143 51.243 1.607 91.389 3.857"
        fill="transparent"
        strokeWidth="3.92"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  </span>
);
