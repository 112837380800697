import React from 'react';

import { calculateAnnualPrice } from 'src/checkout/utils/calculate-annual-price';
import { calculateMonthlyPrice } from 'src/checkout/utils/calculate-monthly-price';
import { formatPrice } from 'src/checkout/utils/format-price';
import { Flex } from 'src/common/components/flex/flex';
import { Text } from 'src/common/components/text/text';
import { MONTHLY_CADENCE } from 'src/pricing/constants';
import { AvailablePlan, PlanCadence } from 'src/pricing/types';

import './due-today.css';

export type DueTodayComponentProps = {
  noTrial: boolean;
  trialPeriod: number;
  chosenPlan: AvailablePlan | null;
  selectedCadence: PlanCadence | null;
};

export const DueTodayComponent = ({
  chosenPlan,
  noTrial,
  selectedCadence,
  trialPeriod,
}: DueTodayComponentProps) => {
  if (!chosenPlan) {
    return null;
  }

  const calculateDisplayPrice =
    selectedCadence === MONTHLY_CADENCE
      ? calculateMonthlyPrice
      : calculateAnnualPrice;

  return (
    <Flex className="due-today">
      <Flex
        direction="row"
        justifyContent="space-between"
        className="due-today__totals"
      >
        <Text>
          ${calculateDisplayPrice(chosenPlan)}
          {selectedCadence === MONTHLY_CADENCE ? ' x 1 month' : ' x 12 months'}
        </Text>
        <Text>
          $
          {formatPrice(
            selectedCadence === MONTHLY_CADENCE
              ? chosenPlan.monthly.price
              : chosenPlan.annual.price
          )}
        </Text>
      </Flex>
      <Flex direction="row" justifyContent="space-between">
        <Flex direction="row" className="due-today__label">
          <Text>Due today</Text>
          {!noTrial && (
            <Text className="due-today__free-trial">
              ({trialPeriod} days free)
            </Text>
          )}
        </Flex>
        {noTrial ? (
          <Text data-testid="due-today-amount">
            $
            {formatPrice(
              selectedCadence === MONTHLY_CADENCE
                ? chosenPlan.monthly.price
                : chosenPlan.annual.price
            )}
          </Text>
        ) : (
          <Text>$0.00</Text>
        )}
      </Flex>
    </Flex>
  );
};
