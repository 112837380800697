import CBS from './cbs.png';
import MLB from './mlb.png';
import PCGamer from './pc-gamer.png';
import TheGuardian from './the-guardian.png';
import USAToday from './usa-today.png';

export const LOGOS = [
  { src: CBS, label: 'CBS' },
  { src: MLB, label: 'MLB' },
  { src: PCGamer, label: 'PC Gamer' },
  { src: TheGuardian, label: 'The Guardian' },
  { src: USAToday, label: 'USA Today' },
];
