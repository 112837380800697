import { createSelector } from '@reduxjs/toolkit';
import { List } from 'immutable';
import { converge, defaultTo, filter, pipe, prop, reject } from 'ramda';
import { selectSelectedVideos } from 'src/common/state/me/me-selectors';
import { selectActiveLabelId } from 'src/dashboard/state/labels/labels-selectors';
import { VideoRepresentation } from 'src/dashboard/types';
import type { RootState } from 'src/redux';

import { ActiveLabelId } from '../labels/labels-reducer';
import { selectShortcodeForVideoPrivacyModal } from '../video-privacy/video-privacy-selectors';
import { VideoCache } from './videos-types';

export const selectAllVideosState = (state: RootState) => state.videos;

const isNewUpload = (video: VideoRepresentation) => !!video.new_upload;

const mapShortcodesToVideos = (cache: VideoCache, shortcodes: List<string>) => {
  const videos = shortcodes.reduce((result, shortcode) => {
    const video = cache.get(shortcode);
    if (video) {
      const newVideo = video.toObject() as VideoRepresentation;
      return [...result, newVideo];
    }
    return result;
  }, [] as VideoRepresentation[]);

  // TODO: So it's a sort?
  return [...filter(isNewUpload, videos), ...reject(isNewUpload, videos)];
};

export const selectVideosIsLoading = pipe(
  selectAllVideosState,
  prop('isLoading')
);

export const getCache = pipe(selectAllVideosState, prop('cache'));

export const getVideoShortcodes = pipe(
  selectAllVideosState,
  prop('shortcodes')
);

export const getUploadShortcodes = pipe(selectAllVideosState, prop('uploads'));

export const getPendingShortcodes = pipe(selectAllVideosState, prop('pending'));

export const getVideos = createSelector(
  [getCache, getVideoShortcodes],
  mapShortcodesToVideos
);

export const getVideosForActiveLabel: (
  state: RootState
) => VideoRepresentation[] = converge<
  VideoRepresentation[],
  [
    (state: RootState) => VideoCache,
    (state: RootState) => ActiveLabelId,
    (state: RootState) => List<string>,
    (state: RootState) => List<string>,
  ]
>(
  (cache, activeLabelId, videoShortcodes, pendingShortcodes) => {
    const videos = mapShortcodesToVideos(
      cache,
      videoShortcodes.concat(pendingShortcodes)
    );

    if (activeLabelId === 'all-videos') {
      return videos;
    }

    if (activeLabelId === 'unlabelled-videos') {
      return videos.filter((video) => !video.labels?.length);
    }

    const withActiveLabelOnly = (video: VideoRepresentation) =>
      video.labels?.some((label) => label.id === activeLabelId);

    return videos.filter(withActiveLabelOnly);
  },
  [getCache, selectActiveLabelId, getVideoShortcodes, getPendingShortcodes]
);

export const selectVideosLabelIdsByShortcode = (
  state: RootState,
  shortcode: string
): number[] => {
  const allVideos = selectAllVideosState(state);
  const video = allVideos.cache.get(shortcode);

  if (video) {
    return video.toObject().labels.map(prop('id'));
  }

  const selectedVideos = selectSelectedVideos(state);
  const checkedVideo = selectedVideos.find(
    (video) => video.shortcode === shortcode
  );

  if (checkedVideo && checkedVideo.labels) {
    return checkedVideo.labels.map(prop('id'));
  }

  return [];
};

export const selectVideoFromCacheByShortcode = (
  state: RootState,
  shortcode: string
) => {
  const cache = getCache(state);
  return cache && (cache.get(shortcode)?.toObject() as VideoRepresentation);
};

export const selectVideoForPrivacyModal = (
  state: RootState
): VideoRepresentation | null => {
  const shortcode = selectShortcodeForVideoPrivacyModal(state);
  if (!shortcode) {
    return null;
  }

  return pipe(selectVideoFromCacheByShortcode, defaultTo(null))(
    state,
    shortcode
  );
};
