import React from 'react';
import { Link } from 'react-router';

import { Text } from 'src/common/components/text/text';

import './get-started-for-free-banner.css';

export const GetStartedForFreeBanner = () => (
  <section className="get-started-for-free-banner-v2">
    <div className="get-started-for-free-banner-v2__content">
      <Text as="h2" className="get-started-for-free-banner-v2__content__title">
        Start uploading for free, and keep uploading for free
      </Text>
      <Link
        to="/signup"
        className="get-started-for-free-banner-v2__content__cta"
      >
        Sign up for free
      </Link>
    </div>
  </section>
);
