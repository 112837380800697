import React, { Component } from 'react';
import ClipboardButton from 'react-clipboard.js';
import { connect } from 'react-redux';

import { deleteVideo, editUserAction } from 'src/actions';
import { Text } from 'src/common/components/text/text';
import { embedPreviewActions } from 'src/dashboard/state/embed-preview/embed-preview-actions';
import { dashboardUpgradeActions } from 'src/dashboard/state/upgrade/upgrade-actions';
import { getCookie } from 'src/misc/cookies';
import EmbedMaker from 'src/misc/modals/EmbedMaker';
import { FileList } from 'src/misc/modals/FileList/FileList';
import { Modal, ModalFooter, ModalBody } from 'src/misc/modals/modals';
import { getEmbedCode } from 'src/misc/utilities';
import { shouldExposeNewEmbedModal } from 'src/utils/selectors/should-expose-new-embed-modal';

import GridItem from './GridItem';
import './videoItem.css';

class VideoItem extends Component {
  constructor() {
    super();
    this.state = {
      embedModalOpen: false,
      deleteModalOpen: false,
      privacyModalOpen: false,
      privacySettingsChanged: false,
      fileListOpen: false,
    };
    this.toggleEmbedModal = this.toggleEmbedModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.togglePrivacyModal = this.togglePrivacyModal.bind(this);
    this.toggleFileListModal = this.toggleFileListModal.bind(this);
    this.toggleSelect = this.toggleSelect.bind(this);
    this.setState = this.setState.bind(this);
    this.resetEmbed = this.resetEmbed.bind(this);
  }

  toggleEmbedModal() {
    this.setState({
      clipped: false,
      defaultsSaved: false,
      embedModalOpen: !this.state.embedModalOpen,
    });
    this.resetEmbed();
  }

  resetEmbed() {
    const embedOptions = {
      autoplay: false,
      loop: true,
      ...JSON.parse(getCookie('embed_options') || '{}'),
      ...this.props.me.embed_options,
    };
    this.setState({
      embedOptions,
    });
  }

  toggleDeleteModal() {
    this.setState({
      deleteModalOpen: !this.state.deleteModalOpen,
    });
  }

  togglePrivacyModal() {
    if (this.privacyModal) {
      this.privacyModal.reset();
    }
    this.setState({
      privacyModalOpen: !this.state.privacyModalOpen,
      privacySettingsChanged: false,
    });
  }

  toggleFileListModal() {
    this.setState({
      fileListOpen: !this.state.fileListOpen,
    });
  }

  toggleSelect() {
    if (this.props.selected) {
      this.props.onDeselect();
    } else {
      this.props.onSelect();
    }
  }

  render() {
    const self = this;

    return (
      <GridItem
        dispatch={this.props.dispatch}
        video={this.props.video}
        userTier={this.props.userTier}
        selected={this.props.selected}
        toggleDeleteModal={this.toggleDeleteModal}
        toggleSelect={this.toggleSelect}
        toggleEmbedModal={
          this.props.showNewEmbedModal
            ? this.props.openNewEmbedModal
            : this.toggleEmbedModal
        }
        togglePrivacyModal={this.togglePrivacyModal}
        toggleFileListModal={this.toggleFileListModal}
        handleEditLabelsClick={this.props.handleEditLabelsClick}
      >
        <Modal
          isOpen={this.state.embedModalOpen}
          toggle={this.toggleEmbedModal}
          className="app-modal"
          title="Build Embed Code"
          darkMode={this.props.me.dark_mode}
        >
          <ModalBody>
            <EmbedMaker
              ref={(r) => {
                self.embedMaker = r;
              }}
              options={self.state.embedOptions}
              onChange={(opts) => {
                self.setState({ embedOptions: opts, defaultsSaved: false });
              }}
              video={this.props.video}
            />
          </ModalBody>
          <ModalFooter>
            <ClipboardButton
              component="button"
              className={`blue-button ${self.state.clipped ? 'clipped' : ''}`}
              option-text={() =>
                getEmbedCode(self.props.video, self.state.embedOptions)
              }
              onSuccess={() => {
                self.setState({ clipped: true });
                self.embedMaker.select();
                window.ga(
                  'send',
                  'event',
                  'Embeds',
                  'copied',
                  'dashboard_advanced'
                );
              }}
              onError={() => self.embedMaker.select()}
            >
              {self.state.clipped ? 'Copied!' : 'Copy Embed Code'}
            </ClipboardButton>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.deleteModalOpen}
          toggle={this.toggleDeleteModal}
          className="app-modal"
          title="Delete Video"
          darkMode={this.props.me.dark_mode}
        >
          <ModalBody>
            <Text className="delete-video-modal__content">
              Are you sure you want to delete{' '}
              {this.props.video?.title ? (
                <Text as="strong">{this.props.video.title}</Text>
              ) : (
                'this video'
              )}
              ?
            </Text>
          </ModalBody>
          <ModalFooter>
            <button
              className="danger-button"
              onClick={() => {
                this.toggleDeleteModal();
                this.props.dispatch(deleteVideo(this.props.video));
              }}
            >
              Delete
            </button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.fileListOpen}
          toggle={this.toggleFileListModal}
          className="download-files-modal"
          title="Download"
          darkMode={this.props.me.dark_mode}
        >
          <ModalBody>
            <FileList
              ref={(r) => {
                self.fileListModal = r;
              }}
              video={this.props.video}
              me={this.props.me}
              handleUpsellClick={this.props.handleUpsellClick}
            />
          </ModalBody>
        </Modal>
        {!this.props.isMobile &&
        this.props.showWelcome &&
        this.props.video.status < 3 ? (
          <div className="callout">
            <div
              className="callout-close"
              onClick={(e) =>
                this.props.dispatch(editUserAction({ first_run: false }))
              }
            >
              ✕
            </div>
            {this.props.video.status === 2 ? (
              <div>
                <h1>Your video is ready!</h1>
                You can now share the link or{' '}
                {this.props.video.upload_source === 'compress'
                  ? 'download the compressed file'
                  : 'edit the video'}{' '}
                — and much more!
              </div>
            ) : (
              <div>
                <h1>Your video is in the works!</h1>
                {this.props.video.upload_source === 'compress'
                  ? 'Your download will be ready soon.'
                  : `Your video will be ready for viewing soon.`}{' '}
                In the meantime, you can copy the link or edit the video title.
              </div>
            )}
            <button
              className="callout-close-button"
              onClick={(e) =>
                this.props.dispatch(editUserAction({ first_run: false }))
              }
            >
              OK
            </button>
          </div>
        ) : (
          ''
        )}
      </GridItem>
    );
  }
}

const mapStateToProps = (state) => ({
  showNewEmbedModal: shouldExposeNewEmbedModal(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  openNewEmbedModal: (video) =>
    dispatch(embedPreviewActions.embedPreviewModal.opened(video)),
  handleUpsellClick: (source) =>
    dispatch(
      dashboardUpgradeActions.upgradeModal.triggered({
        queryParams: {
          from: 'video-item',
          src_internal: source,
        },
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoItem);
