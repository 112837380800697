import React from 'react';

import { Button } from 'src/common/components/button/button';
import { Text } from 'src/common/components/text/text';

import { ContentSection } from '../content-section/content-section';
import { SettingOption } from '../setting-option/setting-option';
import { PrivacyAndSecurityLayout } from './privacy-and-security-component';

type AdvancedPrivacySettingsProps = {
  layout?: PrivacyAndSecurityLayout;
  handleResetSettingsClick: () => void;
};

export const AdvancedPrivacySettings = ({
  layout,
  handleResetSettingsClick,
}: AdvancedPrivacySettingsProps) => {
  return (
    <ContentSection title="Advanced" className="privacy-and-settings__advanced">
      <SettingOption
        label="Reset individual video privacy settings"
        secondaryLabel={
          <Text variant="secondary">
            Apply default privacy settings to all videos.
          </Text>
        }
      >
        <Button
          className="privacy-and-settings__advanced__cta"
          variant="link"
          onClick={handleResetSettingsClick}
        >
          Reset settings
        </Button>
      </SettingOption>
    </ContentSection>
  );
};
