import React from 'react';

import { getExperiment } from 'src/utils/get-experiment';

import { StaleVideosAlert } from '../stale-videos-alert/stale-videos-alert';
import { UpsellCardContainer } from '../upsell-card/upsell-card-container';

type UpsellMessageProps = {
  isLoggedIn: boolean;
  numberOfStaleVideos: number;
  showPlanPicker: (source: string) => void;
};

export const UpsellMessageContainer = (props: UpsellMessageProps) => {
  if (
    !!getExperiment('upsellForAnonymCustomersInDismissibleCard') ||
    !!getExperiment('upsellForFreeTierCustomersInDismissibleCard')
  ) {
    return <UpsellCardContainer {...props} />;
  }

  if (props.numberOfStaleVideos) {
    return (
      <StaleVideosAlert
        numberOfStaleVideos={props.numberOfStaleVideos}
        showPlanPicker={() =>
          props.showPlanPicker(
            `stale-notification${props.isLoggedIn ? '' : '-anon'}`
          )
        }
      />
    );
  }

  return null;
};
