import React from 'react';

import { Dropzone } from 'src/common/components/dropzone/dropzone';

import {
  type ReplaceVideoModalDropzoneError,
  type UpsellReason,
  checkError,
} from '../replace-video-modal-utils';
import { ReplaceVideoModalDropzoneContent } from './replace-video-modal-dropzone-content';
import './replace-video-modal-dropzone.css';

type ReplaceVideoModalDropzoneComponentOwnProps = {};

type ReplaceVideoModalDropzoneComponentProps =
  ReplaceVideoModalDropzoneComponentOwnProps & {
    maxVideoSizeForPlan?: number | null;
    maxVideoLengthForPlan?: number | null;
    showUpsell: (reason: UpsellReason) => void;
    uploadReplaceVideo: (videoFile: File) => void;
  };

export const ReplaceVideoModalDropzoneComponent = ({
  maxVideoSizeForPlan = null,
  maxVideoLengthForPlan = null,
  showUpsell,
  uploadReplaceVideo,
}: ReplaceVideoModalDropzoneComponentProps) => (
  <Dropzone<ReplaceVideoModalDropzoneError>
    noClick
    inputProps={{
      multiple: false,
      accept: 'video/*',
    }}
    checkError={(files) =>
      checkError(files, maxVideoSizeForPlan, maxVideoLengthForPlan)
    }
    processFiles={async ([videoFile]) => {
      uploadReplaceVideo(videoFile);
    }}
  >
    {(dropzoneProps) => (
      <ReplaceVideoModalDropzoneContent
        {...dropzoneProps}
        showUpsell={showUpsell}
      />
    )}
  </Dropzone>
);
