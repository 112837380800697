import React from 'react';
import { connect } from 'react-redux';

import { ErrorMessage } from 'src/common/components/error-message/error-message';
import { Flex } from 'src/common/components/flex/flex';
import { Modal } from 'src/common/components/modal/modal';
import { Text } from 'src/common/components/text/text';
import {
  dashboardSidebarActions,
  deleteLabel,
} from 'src/dashboard/state/labels/labels-actions';
import {
  selectLabelToDelete,
  selectDeleteLabelErrorMessage,
  selectIsLabelDeletionLoading,
  selectIsDeleteLabelModalShowing,
} from 'src/dashboard/state/labels/labels-selectors';
import type { LabelWithCount } from 'src/dashboard/types';
import type { RootState, AppDispatch } from 'src/redux';

type DeleteLabelModalProps = {
  handleClose: () => void;
  handleSave: () => void;
  isLoading: boolean;
  isShowing: boolean;
  errorMessage?: string;
  label?: LabelWithCount;
};

const DeleteLabelModalComponent = ({
  handleClose,
  handleSave,
  isLoading,
  isShowing,
  errorMessage,
  label,
}: DeleteLabelModalProps) => (
  <Modal
    id="delete-label-modal"
    isShowing={isShowing}
    onClose={handleClose}
    onConfirm={handleSave}
    title="Delete label"
    disableConfirm={isLoading}
    confirmLabel={isLoading ? 'Deleting' : 'Delete'}
    confirmAutofocus
  >
    <Flex direction="column">
      {label && (
        <Text>
          {label.count
            ? `Remove the label "${label.name}" from ${label.count} item${
                label.count > 1 ? 's' : ''
              } and delete the
          label?`
            : `Remove the label "${label.name}"?`}
        </Text>
      )}
      <ErrorMessage message={errorMessage} />
    </Flex>
  </Modal>
);

const mapStateToProps = (state: RootState) => ({
  label: selectLabelToDelete(state),
  isLoading: selectIsLabelDeletionLoading(state),
  isShowing: selectIsDeleteLabelModalShowing(state),
  errorMessage: selectDeleteLabelErrorMessage(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  handleClose: () =>
    dispatch(dashboardSidebarActions.deleteLabelModal.cancelled()),
  handleSave: () => dispatch(deleteLabel()),
});

export const DeleteLabelModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteLabelModalComponent);
