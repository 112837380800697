import React from 'react';
import FaPlay from 'react-icons/lib/fa/play';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import Checkbox from 'rc-checkbox';
import { zoomAction } from 'src/actions';
import { MEDIA_STATUS } from 'src/common/types/media';
import { VideoProgress } from 'src/misc/video-progress/video-progress';
import { shouldExposeNewVideoDetailsPage } from 'src/utils/selectors/should-expose-new-video-details-page';

import '../../../../misc/rc-checkbox.css';
import '../videoItem.css';
import { VideoNotifications } from './VideoNotifications';

function nFormatter(num, digits) {
  var si = [
      { value: 1e18, symbol: 'E' },
      { value: 1e15, symbol: 'P' },
      { value: 1e12, symbol: 'T' },
      { value: 1e9, symbol: 'G' },
      { value: 1e6, symbol: 'M' },
      { value: 1e3, symbol: 'k' },
    ],
    rx = /\.0+$|(\.[0-9]*[1-9])0+$/,
    i;
  for (i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
      );
    }
  }
  return num.toFixed(digits).replace(rx, '$1');
}

const VideoThumbnail = ({
  video,
  selected,
  toggleSelect,
  userTier,
  expired,
  zoomVideo,
  goToNewVideoDetailsPage,
}) => {
  const thumbnailUrl = video.thumbnail_url
    ? video.thumbnail_url
    : video.poster_url;

  if (expired) {
    return (
      <div className="video-thumbnail-container">
        <div className="card-img-top text-card">
          <p className="text-card--header">Your video has expired</p>
          <p className="text-card--description">
            <Link
              className="text-card--link"
              to="/signup?signup_src=expired-video-dashboard-thumbnail"
            >
              Sign up
            </Link>{' '}
            <span>now to save videos for longer</span>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="video-thumbnail-container">
      {(() => {
        switch (video.status) {
          case undefined:
          case null:
          case MEDIA_STATUS.UPLOADING:
          case MEDIA_STATUS.PROCESSING:
            return <VideoProgress video={video} />;
          case MEDIA_STATUS.READY:
            return goToNewVideoDetailsPage ? (
              <Link
                className="video-details-button"
                to={`/videos/${video.shortcode}/details`}
                target="_self"
              />
            ) : (
              <div
                className="play-button"
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  if (e.ctrlKey || e.shiftKey) {
                    e.preventDefault();
                    return true;
                  }

                  if (video.status === MEDIA_STATUS.READY) {
                    zoomVideo();
                  }

                  e.stopPropagation();
                  return false;
                }}
              >
                <FaPlay />
              </div>
            );
          default:
            return <div />;
        }
      })()}
      {thumbnailUrl ? (
        <img
          className="card-img-top"
          role="presentation"
          alt="Thumbnail"
          src={thumbnailUrl}
        />
      ) : (
        <div className="card-img-top" />
      )}
      {video.status === MEDIA_STATUS.READY && (
        <div className="video-header">
          <Checkbox
            checked={selected}
            onChange={toggleSelect}
            className="video-item-checkbox"
          />
          <Link
            className="header-item float-right"
            to={`/videos/${video.shortcode}/analytics`}
            onClick={(e) => e.stopPropagation()}
          >
            {nFormatter(video.plays, 0)} view
            {video.plays === 1 ? '' : 's'}
          </Link>
        </div>
      )}
      <VideoNotifications video={video} userTier={userTier} />
    </div>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  zoomVideo: () => dispatch(zoomAction(ownProps.video)),
});

const mapStateToProps = (state) => ({
  goToNewVideoDetailsPage: shouldExposeNewVideoDetailsPage(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoThumbnail);
