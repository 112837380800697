import React from 'react';
import { Link } from 'react-router';

import { HeroDropzone } from '../hero-dropzone/hero-dropzone';
import { HeroLineExpression } from '../hero-line-expression/hero-line-expression';
import { HeroTextLineExpression } from '../hero-text-line-expression/hero-text-line-expression';
import { HeroVideoTicker } from '../hero-video-ticker/hero-video-ticker';
import './hero.scss';

export const Hero = () => (
  <header className="ticker-with-dropzone-hero">
    <div className="ticker-with-dropzone-hero__info">
      <h1 className="ticker-with-dropzone-hero__title">
        Video hosting made easy
      </h1>

      <HeroTextLineExpression className="ticker-with-dropzone-hero__text-line-expression" />

      <p className="ticker-with-dropzone-hero__text">
        Join thousands of businesses and creators who trust Streamable to upload
        and share their videos.
      </p>
    </div>

    <div className="ticker-with-dropzone-hero__ticker-terms-wrapper">
      <div className="ticker-with-dropzone-hero__ticker-container">
        <HeroDropzone />

        <HeroVideoTicker />
      </div>
    </div>

    <p className="ticker-with-dropzone-hero__text ticker-with-dropzone-hero__text--upload">
      Want to upload a video link? <Link to="/clipper">Clip from URL</Link>
    </p>

    <HeroLineExpression className="ticker-with-dropzone-hero__line-expression" />
  </header>
);
