import { pipe, prop } from 'ramda';
import type { RootState } from 'src/redux';

export const selectAllEmbedPreviewState = (state: RootState) =>
  state.embedPreview;

export const selectIsEmbedPreviewModalOpen = pipe(
  selectAllEmbedPreviewState,
  prop('embedPreviewModal'),
  prop('isOpen')
);

export const selectEmbedPlayerOptions = pipe(
  selectAllEmbedPreviewState,
  prop('embedPreviewModal'),
  prop('playerOptions')
);

export const selectEmbedContainerOptions = pipe(
  selectAllEmbedPreviewState,
  prop('embedPreviewModal'),
  prop('containerOptions')
);

export const selectEmbedVideo = pipe(
  selectAllEmbedPreviewState,
  prop('embedPreviewModal'),
  prop('video')
);

export const selectEmbedCodeCopied = pipe(
  selectAllEmbedPreviewState,
  prop('embedPreviewModal'),
  prop('codeCopied')
);
