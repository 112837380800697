export const replaceSearchParamsInUrl = (params: URLSearchParams) => {
  const isParamsEmpty = params.keys().next().done;

  const { origin, pathname, hash } = window.location;
  let newUrl = `${origin}${pathname}`;

  if (!isParamsEmpty) {
    newUrl += `?${params.toString()}`;
  }

  newUrl += hash;

  return newUrl;
};
