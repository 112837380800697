import React from 'react';

import classNames from 'classnames';
import { NavigationBar, Footer } from 'src/landing-new/components';
import type { NavigationBarVariant } from 'src/landing-new/components/navigation-bar/navigation-bar';

import { GetStartedForFreeBanner } from '../get-started-for-free-banner/get-started-for-free-banner';
import './public-page-layout.css';

type PublicPageLayoutProps = {
  className?: string;
  variant?: NavigationBarVariant;
};

export const PublicPageLayout: React.FunctionComponent<
  PublicPageLayoutProps
> = ({ children, className, variant }) => (
  <>
    <NavigationBar variant={variant} />

    <main className={classNames('main', className)}>
      {children}

      <GetStartedForFreeBanner />
    </main>

    <Footer />
  </>
);
