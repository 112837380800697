import type { Middleware, PayloadAction } from '@reduxjs/toolkit';

export const extendActionsErrorMiddleware: Middleware = () => (next) => (a) => {
  const action = a as PayloadAction<Error> & { error?: string };

  action.error =
    action.payload instanceof Error
      ? action.payload.message || 'Unknown error dispatched to redux'
      : undefined;

  return next(action);
};
