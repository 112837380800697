import { toast } from 'react-hot-toast';

import type { PayloadAction } from '@reduxjs/toolkit';
import { all, call, takeEvery, put, select } from 'redux-saga/effects';
import { editVideoAction } from 'src/actions';
import type { UploadTask } from 'src/api/video';
import { logMesssage } from 'src/common/logging/logging-actions';
import { MEDIA_STATUS } from 'src/common/types/media';
import { selectStorageUpsellTargetPlanId } from 'src/pricing/state/pricing-selectors';
import type { AppDispatch } from 'src/redux';
import { getErrorMessage } from 'src/utils/get-error-message';

import { dashboardUpsellActions } from '../upsell/upsell-actions';
import { replaceVideoActions } from './replace-video-actions';
import { replaceVideoApi } from './replace-video-api';
import type { ReplaceVideoPartialResponseMetadata } from './replace-video-api-types';
import { selectReplaceVideoModalShortcode } from './replace-video-selectors';
import { uploadFileToS3WithStore } from './replace-video-upload';
import {
  formatApiMetadataToActionParam,
  optimisticUploadStartedState,
} from './replace-video-utils';

export function* replaceVideo(
  action: PayloadAction<File>,
  dispatch: AppDispatch
) {
  const videoFile = action.payload;
  const shortcode: string = yield select(selectReplaceVideoModalShortcode);

  try {
    yield put(editVideoAction(optimisticUploadStartedState(shortcode)));
    yield put(replaceVideoActions.replaceVideoModal.closed());

    const replaceVideoMetadata: ReplaceVideoPartialResponseMetadata =
      yield call(replaceVideoApi, shortcode, videoFile);

    if (!replaceVideoMetadata.shortcode) {
      throw Error('No shortcode present in metadata');
    }

    // Reset progress (starting upload)
    const editVideoResetActionParams =
      formatApiMetadataToActionParam(replaceVideoMetadata);
    yield put(editVideoAction(editVideoResetActionParams));

    // Upload video
    const task: UploadTask = yield call(
      uploadFileToS3WithStore,
      videoFile,
      replaceVideoMetadata,
      dispatch
    );

    yield put(
      editVideoAction({
        shortcode,
        waitingToUpload: task.queued,
      })
    );
  } catch (error) {
    const message: string = yield call(getErrorMessage, error);
    yield call(toast.error, message);
    yield put(
      editVideoAction({
        shortcode,
        status: MEDIA_STATUS.ERROR,
        error: message,
      })
    );
    yield put(replaceVideoActions.replaceVideo.failed());
    yield put(logMesssage(message));

    const upsellPlanId: string | null = yield select(
      selectStorageUpsellTargetPlanId
    );
    if (upsellPlanId) {
      yield put(
        dashboardUpsellActions.storageUpsellModal.triggered(upsellPlanId)
      );
    }
  }
}

export function* watchReplaceVideoSaga(dispatch: AppDispatch) {
  yield all([
    takeEvery(replaceVideoActions.replaceVideo.initiated, (action) =>
      replaceVideo(action, dispatch)
    ),
  ]);
}
