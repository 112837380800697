import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import classNames from 'classnames';
import { compose } from 'redux';
import { withIsSlideOver } from 'src/common/hocs/with-is-slide-over';
import { withProviders } from 'src/common/hocs/with-providers';
import {
  selectAllMeState,
  selectIsLoggedIn,
} from 'src/common/state/me/me-selectors';
import { AcceptTermsModal } from 'src/dashboard/components/accept-terms-modal/accept-terms-modal';
import { TopBannerComponent } from 'src/dashboard/components/top-banner/top-banner-component';
import { UpgradeModal } from 'src/dashboard/components/upgrade-modal/upgrade-modal';
import { selectIsSidebarOpen } from 'src/dashboard/state/labels/labels-selectors';
import { selectReplaceVideoModalIsShowing } from 'src/dashboard/state/replace-video/replace-video-selectors';
import { selectStorageUpsellModalIsOpen } from 'src/dashboard/state/upsell/upsell-selectors';
import ExceedsLimitsModal from 'src/misc/modals/ExceedsLimitsModal';
import { getUploads, selectIsMobile } from 'src/misc/selectors';
import { processUploadedFiles } from 'src/misc/utilities';
import Navbar from 'src/nav/Navbar';
import ChangePlanModal from 'src/pricing/components/change-plan-modal';
import { selectFlags } from 'src/utils/selectors/select-flags';

import ContentPane from './ContentPane';
import './app.css';
import DragAndDropContext from './dnd/context';

const mapStateToProps = (state, ownProps) => ({
  viewParams: ownProps.viewParams,
  me: selectAllMeState(state),
  flags: selectFlags(state),
  uploads: getUploads(state),
  isMobile: selectIsMobile(state),
  isLoggedIn: selectIsLoggedIn(state),
  isSidebarOpen: selectIsSidebarOpen(ownProps.isSlideOver)(state),
  isReplaceVideoModalOpen: selectReplaceVideoModalIsShowing(state),
  isStorageUpsellModalOpen: selectStorageUpsellModalIsOpen(state),
});

class App extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (window.ga) {
      window.ga('set', 'metric1', this.props.me.embed_plays || 0);
      window.ga('set', 'metric2', this.props.me.total_plays || 0);
      window.ga('set', 'dimension2', this.props.me.user_name);
      window.ga('set', 'userId', this.props.me.user_name);
    }
  }

  render() {
    const { isLoggedIn, isSidebarOpen } = this.props;

    return (
      <DragAndDropContext>
        <Dropzone
          onDrop={(acceptedFiles, rejectedFiles) => {
            processUploadedFiles(
              this.props.dispatch,
              acceptedFiles,
              null,
              this.props.me.plan_max_length,
              this.props.me.plan_max_size
            );
          }}
          noClick
          disabled={
            this.props.viewParams.view === 'settings' ||
            this.props.isReplaceVideoModalOpen ||
            this.props.isStorageUpsellModalOpen
          }
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <>
              <div
                className={classNames('app-container', {
                  'app-container--sidebar-open': isLoggedIn && isSidebarOpen,
                })}
                {...getRootProps()}
              >
                {isDragActive ? (
                  <div className="drop-accepted">
                    <div className="drop-accepted-text">Drop anywhere.</div>
                  </div>
                ) : (
                  ''
                )}
                <input {...getInputProps()} />
                <ExceedsLimitsModal />
                <TopBannerComponent
                  banner={this.props.flags['global-dashboard-banner']}
                />
                {!isLoggedIn && (
                  <div className="anon-bar">
                    <span>
                      You are using Streamable as a guest. Videos expire after 2
                      days.{' '}
                      <Link to="/signup?signup_src=guest-banner">
                        Sign up for free
                      </Link>{' '}
                      to host your videos for longer.
                    </span>
                  </div>
                )}
                {this.props.me.plan &&
                this.props.me.subscription_status === 'UNPAID' ? (
                  <div className="delinquent-bar">
                    <span>
                      We had a problem billing your subscription. Please update
                      your billing information now.
                    </span>{' '}
                    <span className="mobile-only">
                      Your account is past due.
                    </span>{' '}
                    {this.props.me.payment_processor ? (
                      this.props.me.payment_processor === 'paypal' ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.paypal.com/myaccount/"
                        >
                          Go to PayPal
                        </a>
                      ) : (
                        <Link to="/change_payment">Update Card</Link>
                      )
                    ) : (
                      <Link to="/billing">Billing Info</Link>
                    )}
                  </div>
                ) : (
                  ''
                )}
                <Navbar
                  viewParams={this.props.viewParams}
                  rootUrl={
                    this.props.viewParams.query
                      ? this.props.viewParams.query.rootUrl
                      : null
                  }
                  key="navbar"
                />
                <div className="main-content">
                  <ContentPane viewParams={this.props.viewParams} />
                </div>
                <div className="main-footer" data-nosnippet>
                  <ul className="nav nav-pills nav-stacked nav-bottom">
                    <li className="terms-footer">
                      <a href="https://terms.streamable.com/">Terms</a>
                      <a href="https://streamable.com/community-guidelines">
                        Community Guidelines
                      </a>
                      <a href="https://privacy.streamable.com/">Privacy</a>
                      <a href="https://streamableapp.zendesk.com/hc/en-us">
                        Support
                      </a>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          if (window.zE) {
                            window.zE('webWidget', 'open');
                          } else if (window.Beacon) {
                            window.Beacon('open');
                          } else {
                            window.location = 'mailto:support@streamable.com';
                          }
                        }}
                      >
                        Contact Us
                      </a>
                      {/* This is here to prevent layout shift caused on initial load of matiarl icon font */}
                      <div
                        style={{
                          position: 'absolute',
                          visibility: 'hidden',
                          right: '100px',
                          bottom: 0,
                        }}
                      >
                        <span className="material-symbols-outlined icon">
                          download
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <UpgradeModal />
              <ChangePlanModal />
              <AcceptTermsModal />
            </>
          )}
        </Dropzone>
      </DragAndDropContext>
    );
  }
}

export default compose(
  withIsSlideOver,
  connect(mapStateToProps),
  withProviders({ design: 'v1' })
)(App);
