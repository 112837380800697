import type { AvailablePlan, PlanCadence } from 'src/pricing/types';

export const ENTERPRISE_PLAN_NAME = 'Enterprise';

export const ENTERPRISE_PLAN: Omit<AvailablePlan, PlanCadence> = {
  name: ENTERPRISE_PLAN_NAME,
  description: '',
  features: [
    'Everything in the Business Plan',
    'Custom bandwidth pricing',
    'High-volume storage',
    'Reporting an more...',
  ].map((description) => ({ description })),
};
