import React from 'react';

import classnames from 'classnames';
import { Button, ButtonProps } from 'src/common/components/button/button';

import './alert.css';

export type AlertLevel = 'success' | 'warning' | 'danger' | 'info';

type AlertActionProps = {
  label: React.ReactNode;
  props: Partial<ButtonProps>;
};

type AlertProps = {
  action: Partial<AlertActionProps>;
  className?: string;
  level: AlertLevel;
};

export const Alert: React.FunctionComponent<
  React.PropsWithChildren<Partial<AlertProps>>
> = ({ action, children, className, level = 'success' }) => (
  <div
    className={classnames('sb-alert', `sb-alert--${level}`, className)}
    role="alert"
  >
    <div>{children}</div>
    {action && (
      <Button className="sb-alert__action" color="primary" {...action.props}>
        {action.label || ''}
      </Button>
    )}
  </div>
);
