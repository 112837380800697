import { connect } from 'react-redux';

import { meActions } from 'src/common/state/me/me-actions';
import type { RootState } from 'src/redux';

import { AcceptTermsModalComponent } from './accept-terms-modal-component';

const mapStateToProps = (state: RootState) => ({
  isShowing:
    process.env.REACT_APP_MANDATORY_TERMS_ACCEPTANCE === 'true' &&
    state.me.terms_accepted === null,
});

const mapDispatchToProps = {
  onConfirm: meActions.acceptTermsModal.accepted,
};

export const AcceptTermsModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceptTermsModalComponent);
