import React, { Component } from 'react';
import CloseIcon from 'react-icons/lib/md/close';
import { connect } from 'react-redux';

import moment from 'moment';

import Player from '../../misc/player/Player';

class ZoomOverlay extends Component {
  constructor() {
    super();
    this.state = {};

    this.setState = this.setState.bind(this);
  }

  calcSize() {
    let padding = 215;
    if (this.props.video.height > this.props.video.width) {
      padding = 120;
    }

    let height;
    if (this.props.video.height > window.innerHeight - padding) {
      height = window.innerHeight - padding;
      const MIN_HEIGHT = 200;
      const minHeight =
        this.props.video.height < MIN_HEIGHT
          ? this.props.video.height
          : MIN_HEIGHT;
      if (height < minHeight) {
        height = minHeight;
      }
    } else {
      height = this.props.video.height;
    }
    let width = (this.props.video.width / this.props.video.height) * height;
    if (width > 800 && window.innerWidth < 1200) {
      width = 800;
    }

    return { width, height };
  }

  render() {
    const { width } = this.calcSize();
    return (
      <div className="zoom-backdrop" onClick={this.props.onClose}>
        <div className="zoom-header">
          <div key="close" className="close-icon" onClick={this.props.onClose}>
            <CloseIcon />
          </div>
        </div>
        <div className="zoom-content">
          <div style={{ maxWidth: width }} className="zoom-inner">
            <div className="zoom-title">{this.props.video.title}</div>
            <div className="zoom-footer">
              {moment.unix(this.props.video.date_added).calendar()} ·{' '}
              {this.props.video.plays.toLocaleString()} view
              {this.props.plays === 1 ? '' : 's'}
            </div>
            <div style={{ position: 'relative' }}>
              <div
                className="filler"
                style={{
                  maxWidth: width,
                  paddingBottom:
                    (
                      (this.props.video.height / this.props.video.width) *
                      100
                    ).toString() + '%',
                }}
              ></div>
              <img
                className="poster"
                alt="Poster"
                src={this.props.video.poster_url}
              />
              <Player
                onSizeChange={(w, h) => {
                  this.setState({ width: w });
                }}
                me={this.props.me}
                video={this.props.video}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                mobile={this.props.isMobile}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(ZoomOverlay);
