import { createAction } from '@reduxjs/toolkit';

export const replaceVideoActions = {
  videoFooter: {
    replaceItem: {
      clicked: createAction<string>('[Video Footer Replace Item] clicked'),
    },
  },
  replaceVideoModal: {
    closed: createAction('[Replace Video Modal] closed'),
  },
  replaceVideo: {
    initiated: createAction<File>(
      '[Replace Video Modal] replace video initiated'
    ),
    fulfilled: createAction('[Replace Video Modal] replace video fulfilled'),
    failed: createAction('[Replace Video Modal] replace video failed'),
  },
};
