import React from 'react';

import { DismissibleCard } from 'src/common/components/dismissible-card/dismissible-card';
import { AvailablePlan } from 'src/pricing/types';

import { SuggestedPlanFree } from '../suggested-plan-free/suggested-plan-free';
import { SuggestedPlanPaid } from '../suggested-plan-paid/suggested-plan-paid';
import './suggestion-card-component.css';

export type SuggestionCardComponentOwnProps = {
  callToActionLabel: string;
  children: React.ReactNode;
  className?: string;
  onClick: () => void;
  plan?: AvailablePlan;
  storeKey: string;
  title: string;
  withPricingLink?: boolean;
  triggerGtmEventOnMount?: boolean;
};

type SuggestionCardComponentStateProps = {
  isDismissed: boolean;
};

type SuggestionCardComponentDispatchProps = {
  dismiss: () => void;
};

type SuggestionCardComponentProps = SuggestionCardComponentOwnProps &
  SuggestionCardComponentStateProps &
  SuggestionCardComponentDispatchProps;

export const SuggestionCardComponent = ({
  callToActionLabel,
  children,
  className,
  dismiss: handleDissmiss,
  isDismissed,
  onClick,
  plan,
  title,
  withPricingLink = false,
  triggerGtmEventOnMount = true,
}: SuggestionCardComponentProps) => (
  <DismissibleCard
    isDismissed={isDismissed}
    handleDismiss={handleDissmiss}
    title={title}
    className={className}
  >
    {children}
    {plan ? (
      <SuggestedPlanPaid
        callToActionLabel={callToActionLabel}
        onClick={onClick}
        plan={plan}
        withPricingLink={withPricingLink}
        triggerGtmEventOnMount={triggerGtmEventOnMount}
      />
    ) : (
      <SuggestedPlanFree
        callToActionLabel={callToActionLabel}
        onClick={onClick}
        withPricingLink={withPricingLink}
        triggerGtmEventOnMount={triggerGtmEventOnMount}
      />
    )}
  </DismissibleCard>
);
