import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { GetStartedForFreeBanner } from 'src/common/components/get-started-for-free-banner-v2/get-started-for-free-banner';
import { withProviders } from 'src/common/hocs/with-providers';
import { CustomerLogos } from 'src/landing/components/customer-logos/customer-logos';
import ExceedsLimitsModal from 'src/misc/modals/ExceedsLimitsModal';

import {
  Hero,
  Features,
  Footer,
  NavigationBar,
  Links,
  FAQ,
  Testimonials,
  LoadingSkeleton,
} from './components';
import { LongTextSection } from './components/long-text-section';
import './landing.css';
import type { PageConfig } from './state/landing-types';
import { buildMarketingPageCanonicalHref } from './utils/build-marketing-page-canonical-href-';

type LandingComponentProps = {
  slug: string;
  page: PageConfig | null;
  isPageDataLoading: boolean;
  fetchPageData: (slug: string) => void;
};

const LandingComponent = ({
  slug,
  page,
  isPageDataLoading,
  fetchPageData,
}: LandingComponentProps) => {
  useEffect(() => {
    fetchPageData(slug);
  }, [fetchPageData, slug]);

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={buildMarketingPageCanonicalHref(slug, page?.category)}
        />
        {page?.title && <title>{page.title}</title>}
        {page?.description && (
          <meta name="description" content={page.description}></meta>
        )}
        {!page?.indexable && <meta name="robots" content="noindex" />}
      </Helmet>
      <NavigationBar />
      <main className="landing-top">
        {isPageDataLoading ? (
          <LoadingSkeleton />
        ) : (
          page && (
            <>
              <Hero page={page} />
              <CustomerLogos withLineGraphic={false} />
              <Features features={page.features} />
              <Testimonials testimonials={page.testimonials || []} />
              <FAQ faq={page.faq} />
              <LongTextSection longTextSection={page.longTextSection} />
              <Links links={page.links} />
            </>
          )
        )}
      </main>
      <GetStartedForFreeBanner />
      <Footer />

      <ExceedsLimitsModal />
    </>
  );
};

export const Landing = withProviders({
  isDarkModeSupported: false,
})(LandingComponent);
