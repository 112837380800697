import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { faBars, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compose } from 'redux';
import { Logo } from 'src/common/components/logo';
import { TextWithSplashExpression } from 'src/common/components/text-with-splash-expression/text-with-splash-expression';
import { withIsSlideOver } from 'src/common/hocs/with-is-slide-over';
import { meActions } from 'src/common/state/me/me-actions';
import { selectAllMeState } from 'src/common/state/me/me-selectors';
import { dashboardSidebarActions } from 'src/dashboard/state/labels/labels-actions';
import { dashboardUpgradeActions } from 'src/dashboard/state/upgrade/upgrade-actions';
import { removeCookie } from 'src/misc/cookies';
import { selectIsMobile } from 'src/misc/selectors';
import { apiRequest } from 'src/utils/api-request';
import { getSelectBooleanFlag } from 'src/utils/selectors/select-flags';
import { shouldRenderSidebar } from 'src/utils/selectors/should-render-sidebar';

import { Menu } from './menu';
import './navbar.css';

const mapStateToProps = (state, ownProps) => ({
  me: selectAllMeState(state),
  view: ownProps.viewParams.view || '',
  isMobile: selectIsMobile(state),
  withSidebar: shouldRenderSidebar(state),
  shouldExposeCollectionsMenuLink: getSelectBooleanFlag(
    'expose-collections-menu-link'
  )(state),
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  toggleMobileSidebar: () =>
    dispatch(dashboardSidebarActions.sidebar.mobile.toggled()),
  toggleDesktopSidebar: () =>
    dispatch(dashboardSidebarActions.sidebar.desktop.toggled()),
  onLogoClicked: () =>
    dispatch(dashboardSidebarActions.labelButton.clicked('all-videos')),
  onDarkModeChanged: (dark_mode) =>
    dispatch(meActions.nav.userMenu.darkModeChanged({ dark_mode })),
  showUpgradeModal: () =>
    dispatch(dashboardUpgradeActions.upgradeModal.triggered()),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  toggleSidebar: ownProps.isSlideOver
    ? dispatchProps.toggleMobileSidebar
    : dispatchProps.toggleDesktopSidebar,
});

const NavbarComp = ({
  me,
  view,
  toggleSidebar,
  onLogoClicked,
  onDarkModeChanged,
  withSidebar,
  shouldExposeCollectionsMenuLink,
  showUpgradeModal,
}) => {
  const [showUserMenu, setShowUserMenu] = useState(false);

  useEffect(() => {
    const onWindowClick = () => {
      setShowUserMenu(false);
    };

    window.addEventListener('click', onWindowClick);

    return () => {
      window.removeEventListener('click', onWindowClick);
    };
  }, []);

  const onUpgradeClick = useCallback(
    (e) => {
      e.preventDefault();
      showUpgradeModal();
    },
    [showUpgradeModal]
  );

  const onLogoutClick = useCallback(async () => {
    const isLoggedOutSuccessfully = await apiRequest({
      path: '/auth/logout',
    });

    if (isLoggedOutSuccessfully) {
      window.location = '/';
    } else {
      console.log('user_id not accessed by the server');
    }

    removeCookie('user_name');
    removeCookie('plan');
    removeCookie('dashboard');
    removeCookie('dark_mode');
    try {
      if (window.location.hostname.includes('localhost')) {
        removeCookie('user_code');
      }
    } catch {}
  }, []);

  const navElements = [];
  if (withSidebar && view === 'videos') {
    navElements.push(
      <Link
        to=""
        className="nav-item action-btn action-btn--icon-only action-btn--sidebar-toggle"
        key="toggleSidebar"
        onClick={toggleSidebar}
      >
        <FontAwesomeIcon icon={faBars} />
      </Link>
    );
  }
  navElements.push(
    <Link
      to="/"
      key="logo"
      onClick={() => {
        onLogoClicked();
        if (!me.user_name) {
          removeCookie('dashboard');
        }
      }}
    >
      <Logo type="black" />
    </Link>
  );

  if (view !== 'report') {
    navElements.push(<div className="spacer" key="spacer" />);

    if (me.user_name && !me.plan) {
      navElements.push(
        <TextWithSplashExpression key="pricingLink" className="upgrade">
          <Link
            className="upgrade__link"
            onClick={onUpgradeClick}
            to="/upgrade?src_internal=loggedin-dropdown-menu"
          >
            <FontAwesomeIcon icon={faStar} className="upgrade__link__icon" />
            <span>Upgrade</span>
          </Link>
        </TextWithSplashExpression>
      );
    }
    if (me.user_name) {
      navElements.push(
        <Link
          key="userLinkMyAccount"
          className="nav-item action-btn"
          to=""
          onClick={(e) => {
            setShowUserMenu(true);
            e.stopPropagation();
          }}
          style={{
            visibility: showUserMenu ? 'hidden' : 'visible',
          }}
        >
          My Account <span className="material-icons">arrow_drop_down</span>
        </Link>
      );
    } else {
      navElements.push(
        <Link
          key="userLinkLogin"
          className="nav-item login"
          to="/login?login_src=navbar"
        >
          Log In
        </Link>
      );
      navElements.push(
        <Link
          key="userLinkSignUp"
          className="nav-item signup"
          to="/signup?signup_src=navbar"
        >
          Sign Up
        </Link>
      );
    }
  }

  const isUserMenuVisible = showUserMenu && view !== 'report';

  return (
    <div className="app-nav">
      <nav className="navbar navbar-full">{navElements}</nav>
      {isUserMenuVisible && (
        <Menu
          onDarkModeChanged={onDarkModeChanged}
          onUpgradeClick={onUpgradeClick}
          onLogoutClick={onLogoutClick}
          freePlan={!me.plan}
          darkMode={me.dark_mode}
          withCollections={shouldExposeCollectionsMenuLink}
        />
      )}
    </div>
  );
};

export default compose(
  withIsSlideOver,
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(NavbarComp);
