// Reference document
// https://docs.google.com/spreadsheets/d/1Q1iKLp4FLgl-AT655Y9mbWprwg73Het7mWY3fimsKEc/edit#gid=911188465

export const TOP_LEVEL_SLUGS = [
  'import-extension', // Not yet created
  'video-manager',
  'backup-video',
  'merge-video',
  'trim-video',
  'share-video',
  'download-video',
  'repurpose-clips',
  'video-analytics',
  'video-file-transfer',
  'video-hosting',
];

const VIEW_SLUGS_BY_CATEGORY = {
  tools: [
    'clipper',
    'upload-video',
    'crop-video',
    'mute-video',
    'rotate-video',
    'embed-video',
    'trim-video',
    'compress-video',
    'send-multiple-videos',
  ],

  'share-video': [
    'gif-converter', // Not yet created
    'video-privacy',
    'password-protect',
    'ad-free-video-player',
    'reddit',
    'discord',
    'no-account-sharing',
  ],

  clipper: [
    'clip-from-youtube',
    'tiktok-video-downloader',
    'twitch-video-downloader',
    'vimeo-video-downloader',
    'twitter-video-downloader',
  ],

  creator: [
    'video-portfolio',
    'video-on-patreon',
    'client-video-sharing',
    'affordable-hosting',
    'short-films',
  ],

  marketing: ['social-media', 'video-testimonials', 'product-demos'],

  'video-hosting': [
    'gameplay-clips',
    'reaction-videos',
    'vlogs',
    'tutorial-videos',
    'unboxing-videos',
    'wedding-videos',
    'squarespace',
    'wix',
    'shopify',
    'wordpress',
  ],

  partners: ['streamyard'],

  'special-offers': [
    'creators',
    'extended-trial',
    'bf-elements-plan',
    'elements-plan',
  ],
};

export const CATEGORY_SLUGS = Object.keys(VIEW_SLUGS_BY_CATEGORY);

export const ALL_SLUGS = Object.entries(VIEW_SLUGS_BY_CATEGORY)
  .reduce((result, [category, slugs]) => {
    return result.concat(slugs.map((slug) => `/${category}/${slug}`));
  }, [] as string[])
  .concat(TOP_LEVEL_SLUGS.map((slug) => `/${slug}`));
