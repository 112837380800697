import React from 'react';

import {
  ActivatedSubscriptionInfo,
  SubscriptionInfo,
} from 'src/app/subscription/subscription-types';
import { useEffectOnce } from 'src/common/hooks/use-effect-once';
import { Plan } from 'src/pricing/components/plan';
import { ANNUAL_CADENCE } from 'src/pricing/constants';
import {
  AvailablePlan,
  AvailablePlanPrice,
  PlanCadence,
} from 'src/pricing/types';
import { formatUSD } from 'src/pricing/utils/format-currency';
import { triggerGoogleAnalyticsEvent } from 'src/utils/trigger-google-analytics-event';

import { SelfServePlanButtonComponent } from './self-serve-plan-button-component';

export type SelfServePlanStateProps = {
  currentPlan: ActivatedSubscriptionInfo | null;
  isGuest: boolean;
  hasPaidPlan: boolean;
  offerTrial: boolean;
  subscriptionOnRenew: SubscriptionInfo | null;
  targetCadence: PlanCadence;
  willRenewOnDifferentPlan: boolean;
  isPayPalUser: boolean;
};

export type SelfServePlanDispatchProps = {
  enterChangeFlow: (planId: string) => void;
};

export type SelfServePlanOwnProps = {
  plan: AvailablePlan;
};

export type SelfServePlanComponentProps = SelfServePlanStateProps &
  SelfServePlanDispatchProps &
  SelfServePlanOwnProps;

const calculatePrice = (plan: AvailablePlanPrice) => {
  if (plan.cadence === ANNUAL_CADENCE) {
    return plan.price / 12.0;
  }
  return plan.price;
};

export const SelfServePlanComponent = ({
  isPayPalUser,
  currentPlan,
  offerTrial,
  plan,
  hasPaidPlan,
  subscriptionOnRenew,
  targetCadence,
  willRenewOnDifferentPlan,
  enterChangeFlow,
}: SelfServePlanComponentProps) => {
  const price = calculatePrice(plan[targetCadence]);

  const originalValue =
    targetCadence === 'annual'
      ? formatUSD(calculatePrice(plan['monthly']))
      : null;

  useEffectOnce(() => {
    if (plan.annual.id === 'streamable-basic-119_88-usd-annually') {
      triggerGoogleAnalyticsEvent('displayed_basic_yearly_full_price_9_99');
    }
    if (plan.annual.id === 'streamable-basic-107_88-usd-annually') {
      triggerGoogleAnalyticsEvent('displayed_basic_yearly_reduced_price_8_99');
    }
  });

  return (
    <Plan key={plan.name}>
      <Plan.TitleContainer>
        <Plan.Title>{plan.name}</Plan.Title>
      </Plan.TitleContainer>
      <Plan.Price
        value={formatUSD(price)}
        originalValue={originalValue}
        duration={
          targetCadence === ANNUAL_CADENCE ? 'billed yearly' : 'billed monthly'
        }
      />

      <SelfServePlanButtonComponent
        currentPlan={currentPlan}
        plan={plan}
        hasPaidPlan={hasPaidPlan}
        targetCadence={targetCadence}
        enterChangeFlow={enterChangeFlow}
        subscriptionOnRenew={subscriptionOnRenew}
        willRenewOnDifferentPlan={willRenewOnDifferentPlan}
        offerTrial={offerTrial}
        isPayPalUser={isPayPalUser}
      />
      <Plan.FeaturesList>
        {plan.features.map((item) => (
          <Plan.FeatureItem key={`pro-plan-feature-item-${item.label}`}>
            {item.description}
          </Plan.FeatureItem>
        ))}
      </Plan.FeaturesList>
    </Plan>
  );
};
