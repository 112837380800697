import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { labelsReducer } from 'src/dashboard/state/labels/labels-reducer';
import { suggestionsReducer } from 'src/dashboard/state/suggestions/suggestions-reducer';

export const persistedLabelsReducer = persistReducer(
  {
    whitelist: [
      'isSidebarOpenDesktop',
      'isSidebarOpenMobile',
      'isEmptyStateDismissed',
    ],
    storage,
    key: 'labels',
  },
  labelsReducer
);
export type PersistedLabelsState = ReturnType<typeof persistedLabelsReducer>;

export const persistedSuggestionsReducer = persistReducer(
  {
    whitelist: ['suggestions'],
    storage,
    key: 'suggestions',
  },
  suggestionsReducer
);
export type PersistedSuggestionsState = ReturnType<
  typeof persistedSuggestionsReducer
>;
