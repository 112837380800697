import { createAction } from '@reduxjs/toolkit';

import type { PageConfig } from './landing-types';

export const landingActions = {
  fetchPage: {
    initiated: createAction<string>('[Landing] fetch page initiated'),
    fulfilled: createAction<PageConfig>('[Landing] fetch page fulfilled'),
    failed: createAction<string>('[Landing] fetch page failed'),
  },
};
