import { deselectAllAction, deleteSelectedVideos } from 'src/actions';
import {
  selectDarkMode,
  selectSelectedVideos,
} from 'src/common/state/me/me-selectors';
import { dashboardAssignLabelsActions } from 'src/dashboard/state/assign-labels/assign-labels-actions';
import { dashboardCollectionActions } from 'src/dashboard/state/collection/collection-actions';
import { selectCreateCollectionIsLoading } from 'src/dashboard/state/collection/collection-selectors';
import { useAppDispatch, useAppSelector } from 'src/redux';
import { shouldRenderSidebar } from 'src/utils/selectors/should-render-sidebar';

import { VideoListHeaderComponent } from './video-list-header-component';

export const VideoListHeader = () => {
  const createCollectionIsLoading = useAppSelector(
    selectCreateCollectionIsLoading
  );
  const isDarkModeEnabled = useAppSelector(selectDarkMode);
  const selectedVideos = useAppSelector(selectSelectedVideos);
  const withSidebar = useAppSelector(shouldRenderSidebar);

  const dispatch = useAppDispatch();

  const handleBulkEditLabels = (shortcodes: string[]) =>
    dispatch(
      dashboardAssignLabelsActions.actionsBar.editLabelsButton.clicked({
        shortcodes,
      })
    );
  const handleCreateCollection = (shortcodes: string[]) =>
    dispatch(
      dashboardCollectionActions.videoListHeader.shareButton.clicked(shortcodes)
    );
  const handleDeselectAll = () => dispatch(deselectAllAction());
  const handleDeleteSelectedVideos = () => dispatch(deleteSelectedVideos());

  return (
    <VideoListHeaderComponent
      createCollectionIsLoading={createCollectionIsLoading}
      isDarkModeEnabled={isDarkModeEnabled}
      selectedVideos={selectedVideos}
      withSidebar={withSidebar}
      handleBulkEditLabels={handleBulkEditLabels}
      handleCreateCollection={handleCreateCollection}
      handleDeselectAll={handleDeselectAll}
      handleDeleteSelectedVideos={handleDeleteSelectedVideos}
    />
  );
};
