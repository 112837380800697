import { MenuItem, DarkModeToggle } from '../menu-item';
import { MenuItemGroup } from '../menu-item-group';
import './menu-component.css';

type MenuProps = {
  onLogoutClick: () => void;
  onUpgradeClick: () => void;
  onDarkModeChanged: (darkMode: boolean) => void;
  darkMode: boolean;
  freePlan: boolean;
  withCollections: boolean;
};

export const Menu = ({
  onLogoutClick,
  onUpgradeClick,
  onDarkModeChanged,
  darkMode,
  freePlan,
  withCollections,
}: MenuProps) => (
  <div className="user-menu">
    <div className="user-menu-header">
      My Account <span className="material-icons">arrow_drop_up</span>
    </div>
    <MenuItemGroup label="MY SETTINGS">
      <MenuItem to="/profile" icon="account_circle" label="My Profile" />
      <MenuItem to="/appearance" icon="palette" label="Player Appearance" />
      <MenuItem to="/settings" icon="lock" label="Privacy & Security" />
      <MenuItem to="/billing" icon="paid" label="Plan & Billing" />
    </MenuItemGroup>
    <MenuItemGroup label="THEME">
      <DarkModeToggle
        onDarkModeChanged={onDarkModeChanged}
        darkMode={darkMode}
      />
    </MenuItemGroup>
    <MenuItemGroup label="MORE OPTIONS">
      {freePlan && (
        <MenuItem
          to="/upgrade?src_internal=loggedin-dropdown-menu"
          label="Upgrade"
          onClick={onUpgradeClick}
          icon="star"
        />
      )}
      {withCollections && (
        <MenuItem
          to={`${process.env.REACT_APP_NEXTJS_HOST}/collections`}
          label="Collections"
          icon="video_library"
        />
      )}
      <MenuItem
        to="https://streamableapp.zendesk.com/hc/en-us"
        label="Help Center"
        icon="help"
        target="_blank"
      />
      <MenuItem to="/blog" label="What's new" icon="campaign" />
    </MenuItemGroup>
    <div className="border-top" />
    <MenuItem label="Log out" icon="logout" onClick={onLogoutClick} />
  </div>
);
