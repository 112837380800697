import { combineReducers } from '@reduxjs/toolkit';
import {
  appLoadingSlice,
  reducer as appLoadingReducer,
} from 'src/app/app-loading/app-loading-reducer';
import { statsReducer } from 'src/app/stats/stats.reducer';
import { subscriptionReducer } from 'src/app/subscription/subscription-reducer';
import { blogItemReducer } from 'src/blog-item/state/blog-item-reducer';
import { blogReducer } from 'src/blog/state/blog-reducer';
import { checkoutReducer } from 'src/checkout/state/checkout-reducer';
import { meReducer } from 'src/common/state/me/me-reducer';
import { assignLabelsReducer } from 'src/dashboard/state/assign-labels/assign-labels-reducer';
import { collectionsReducer } from 'src/dashboard/state/collection/collection-reducer';
import { embedPreviewReducer } from 'src/dashboard/state/embed-preview/embed-preview-reducer';
import { replaceVideoReducer } from 'src/dashboard/state/replace-video/replace-video-reducer';
import { upgradeReducer } from 'src/dashboard/state/upgrade/upgrade-reducer';
import { upsellReducer } from 'src/dashboard/state/upsell/upsell-reducer';
import { videoCaptionsReducer } from 'src/dashboard/state/video-captions/video-captions-reducer';
import { videoPrivacyReducer } from 'src/dashboard/state/video-privacy/video-privacy-reducer';
import { videosReducer } from 'src/dashboard/state/videos/videos-reducer';
import { landingReducer } from 'src/landing-new/state/landing-reducer';
import { pricingReducer } from 'src/pricing/state/pricing-reducer';
import { settingsReducer } from 'src/settings/state/settings-reducer';

import checkout from './checkout';
import clipper from './clipper';
import {
  persistedLabelsReducer,
  persistedSuggestionsReducer,
} from './persisted';
import video from './video';

const rootReducer = combineReducers({
  [appLoadingSlice]: appLoadingReducer,
  assignLabels: assignLabelsReducer,
  blog: blogReducer,
  blogItem: blogItemReducer,
  // TODO: Move this reducer into checkoutReducer
  checkout,
  checkoutReducer,
  clipper,
  collections: collectionsReducer,
  embedPreview: embedPreviewReducer,
  labels: persistedLabelsReducer,
  landing: landingReducer,
  me: meReducer,
  pricing: pricingReducer,
  settings: settingsReducer,
  replaceVideo: replaceVideoReducer,
  stats: statsReducer,
  subscription: subscriptionReducer,
  suggestions: persistedSuggestionsReducer,
  upgrade: upgradeReducer,
  upsell: upsellReducer,
  video,
  videoPrivacy: videoPrivacyReducer,
  videos: videosReducer,
  videoCaptions: videoCaptionsReducer,
});

export default rootReducer;
