import type { Caption } from 'src/dashboard/types';
import { apiRequest } from 'src/utils/api-request';

export type VideoCaptionsListResponse = {
  captions: Caption[];
};

export const getVideoCaptionsApi = (shortcode: string) =>
  apiRequest<VideoCaptionsListResponse>({
    path: `/videos/${shortcode}/captions`,
    method: 'GET',
  });

export const deleteVideoCaptionsApi = (shortcode: string, captionId: number) =>
  apiRequest({
    path: `/videos/${shortcode}/captions/${captionId}`,
    method: 'DELETE',
    contentType: 'text',
  });
