import type { PayloadAction } from '@reduxjs/toolkit';
import type { FetchingState } from 'src/common/types/fetching-state';

import { dashboardCollectionActions } from './collection-actions';

export type CollectionsState = {
  createCollection: Pick<FetchingState, 'isLoading'>;
};

const initialState: CollectionsState = {
  createCollection: {
    isLoading: false,
  },
};

export const collectionsReducer = (
  state = initialState,
  action: PayloadAction<any>
) => {
  switch (action.type) {
    case dashboardCollectionActions.videoListHeader.shareButton.clicked.type:
      return {
        ...state,
        createCollection: {
          isLoading: true,
        },
      };

    case dashboardCollectionActions.createCollection.fulfilled.type:
    case dashboardCollectionActions.createCollection.failed.type:
      return {
        ...state,
        createCollection: {
          isLoading: false,
        },
      };

    default:
      return state;
  }
};
