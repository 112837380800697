import { connect } from 'react-redux';

import { log, subscribeAction, userSubscribePlan } from 'src/actions';
import {
  selectFreeTrialDuration,
  selectHasPaidPlan,
} from 'src/common/state/me/me-selectors';
import type { PlanCadence } from 'src/pricing/types';
import { selectCheckoutState } from 'src/reducers/checkout';
import type { RootState, AppDispatch } from 'src/redux';

import { BillingDetailsComponent } from './billing-details-component';
import { CARD_ERROR_SUPPORT_MESSAGE_UUID } from './constants';

const mapStateToProps = (state: RootState) => ({
  checkout: selectCheckoutState(state),
  hasPaidPlan: selectHasPaidPlan(state),
  trialPeriod: selectFreeTrialDuration(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  handleCardElementReady: () => {
    dispatch(
      subscribeAction({
        purchasing: false,
        error: false,
        message: null,
      })
    );
  },
  handleAlreadySubscribed: () => {
    dispatch(
      subscribeAction(
        new Error(
          'You are already subscribed to a plan. Please contact support@streamable.com for help.'
        )
      )
    );
  },
  handleSubscriptionError: (errorMessage: string) => {
    // If the message was displayed before, HelpScout (Beacon) won't show it
    // to the user on the next call. That's the reason why we don't need to
    // use state to avoid showing the message again.
    if (window.Beacon) {
      window.Beacon('show-message', CARD_ERROR_SUPPORT_MESSAGE_UUID, {
        delay: 100,
      });
    }
    dispatch(subscribeAction(new Error(errorMessage)));
  },
  handleSubscriptionInProgress: () => {
    dispatch(subscribeAction({ purchasing: true, error: false }));
  },
  handleSubscribeToPlan: ({
    paymentMethodId,
    plan,
    token,
    noTrial,
    cadence,
  }: {
    paymentMethodId: string;
    plan?: string | null;
    token: string;
    noTrial: boolean;
    cadence: PlanCadence;
  }) => {
    dispatch(
      userSubscribePlan({
        paymentMethodId,
        plan,
        subscriptionId: null,
        token,
        noTrial,
        defaultPlan: cadence,
      })
    );
  },
  log,
});

export const BillingDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingDetailsComponent);
