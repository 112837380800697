import React, { forwardRef, type InputHTMLAttributes } from 'react';

import classnames from 'classnames';

import './textarea.css';

export type InputProps = Omit<
  InputHTMLAttributes<HTMLTextAreaElement>,
  'onChange'
> & {
  onChange?: (newValue: string) => void;
};

export const Textarea = forwardRef<HTMLTextAreaElement, InputProps>(
  ({ className, onChange, ...rest }, ref) => {
    const handleChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(event.currentTarget.value);
      }
    };

    return (
      <textarea
        className={classnames('sb-textarea', className)}
        onChange={handleChange}
        ref={ref}
        {...rest}
      />
    );
  }
) as (
  props: InputProps & { ref?: React.ForwardedRef<HTMLTextAreaElement> }
) => JSX.Element;
