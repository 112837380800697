import React from 'react';

const Pagination = ({ page, pages, onChange }) => {
  let pageArray = [];
  var needSpacer = true;

  for (var i = 0; i < pages; i++) {
    if (Math.abs(page - i) > 2 && i < pages - 2 && i > 3) {
      if (needSpacer) {
        pageArray.push('...');
        needSpacer = false;
      }
    } else {
      needSpacer = true;
      pageArray.push(i + 1);
    }
  }

  let onClick = function (page) {
    return () => {
      onChange(page);
    };
  };
  return (
    <nav aria-label="Page navigation" className="text-center">
      <ul className="pagination pagination-sm justify-content-center">
        <li className="page-item">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className={`page-link ${page === 1 ? 'disabled' : ''}`}
            onClick={onClick(page - 1)}
            aria-label="Previous"
          >
            <span aria-hidden="true">Previous</span>
            <span className="sr-only">Previous</span>
          </a>
        </li>

        {pageArray.map((i, idx) => (
          <li className={`page-item ${page === i ? 'active' : ''}`} key={idx}>
            {i === '...' ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a className="page-link"> ... </a>
            ) : (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a className="page-link" onClick={onClick(i)}>
                {i}
              </a>
            )}
          </li>
        ))}
        <li className="page-item">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className={`page-link ${page >= pages ? 'disabled' : ''}`}
            aria-label="Next"
            onClick={onClick(page + 1)}
          >
            <span aria-hidden="true">Next</span>
            <span className="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
