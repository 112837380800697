// @ts-nocheck - TODO: Type '"plaintext-only"' is not assignable to type 'Booleanish | "inherit" | undefined'.
import React, { useState } from 'react';
import ReactDropzone, {
  DropzoneProps as ReactDropzoneProps,
} from 'react-dropzone';

export type ModalDropzoneError<T = undefined> =
  | undefined
  | T
  | 'processing-error'
  | 'unexpected-error';

export type WrappedComponentDropzoneProps<ValidationError = undefined> = {
  isDragActive: boolean;
  isProcessing: boolean;
  error?: ModalDropzoneError<ValidationError>;
  triggerFileSelect: () => void;
};

type WithDropzoneOptions<ValidationError = undefined> = Omit<
  ReactDropzoneProps,
  'children'
> & {
  className?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  processFiles: (files: File[]) => Promise<void>;
  callback?: () => void;
  checkError?: (
    acceptedFiles: File[]
  ) => Promise<ModalDropzoneError<ValidationError>>;
  children: (
    props: WrappedComponentDropzoneProps<ModalDropzoneError<ValidationError>>
  ) => React.ReactElement;
};

export const Dropzone = <ValidationError = undefined,>({
  className = '',
  inputProps = {},
  processFiles,
  callback,
  checkError,
  children,
  ...props
}: WithDropzoneOptions<ValidationError>) => {
  const [error, setError] = useState<ModalDropzoneError<ValidationError>>();
  const [isProcessing, setIsProcessing] = useState(false);

  const onDrop = async (acceptedFiles: File[]) => {
    try {
      const validationError: ModalDropzoneError<ValidationError> = checkError
        ? await checkError(acceptedFiles)
        : undefined;

      if (validationError) {
        throw new Error(validationError as string);
      }

      setError(undefined);
      setIsProcessing(true);
      await processFiles(acceptedFiles).catch(() => {
        throw new Error('processing-error');
      });
      callback?.();
    } catch (e: any) {
      setError(e?.message || 'unexpected-error');
      console.error('Error processing files', e);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <ReactDropzone onDrop={onDrop} {...props}>
      {({ getRootProps, getInputProps, isDragActive }) => {
        // @ts-expect-error
        const { ref, ...libInputProps } = getInputProps();

        const triggerFileSelect = () => {
          if (error) {
            setError(undefined);
          }
          return ref.current?.click();
        };

        return (
          <div className={className} {...getRootProps()}>
            <input ref={ref} {...libInputProps} {...inputProps} />

            {children({
              isDragActive,
              isProcessing,
              error,
              triggerFileSelect,
            })}
          </div>
        );
      }}
    </ReactDropzone>
  );
};
