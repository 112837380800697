import {
  converge,
  equals,
  keys,
  length,
  lte,
  not,
  pick,
  pipe,
  prop,
  sortBy,
  values,
} from 'ramda';
import type { RootState } from 'src/redux';

import { MAX_NUMBER_OF_LABELS } from '../../constants';
import type { Label, LabelWithCount } from '../../types';

const selectAllLabelsState = (state: RootState) => state.labels;

export const selectActiveLabelId = pipe(
  selectAllLabelsState,
  prop('activeLabelId')
);

export const selectIsInitialFetchDone = pipe(
  selectAllLabelsState,
  prop('isInitialFetchDone')
);

export const selectIsError = pipe(selectAllLabelsState, prop('isError'));

export const selectIsLoading = pipe(selectAllLabelsState, prop('isLoading'));

export const selectIsSidebarDesktopOpen = pipe(
  selectAllLabelsState,
  prop('isSidebarOpenDesktop')
);

export const selectIsSidebarMobileOpen = pipe(
  selectAllLabelsState,
  prop('isSidebarOpenMobile')
);

export const selectIsSidebarOpen = (isMobile: boolean) => (state: RootState) =>
  isMobile
    ? selectIsSidebarMobileOpen(state)
    : selectIsSidebarDesktopOpen(state);

const selectLabels = pipe(selectAllLabelsState, prop('labels'));

export const selectLabelsAsArray = pipe(
  selectLabels,
  values,
  sortBy<LabelWithCount>(prop('name'))
);

export const selectLabelsByIds = (state: RootState, idsToSelect: number[]) =>
  pipe(selectLabels, pick(idsToSelect), values)(state);

export const sortLabelsAlphabetically = (labels: Label[]) =>
  labels.sort((a, b) => a.name.localeCompare(b.name));

export const selectLabelsAlphabeticallyByIds = pipe(
  selectLabelsByIds,
  sortLabelsAlphabetically
);

export const selectActiveLabel = converge<
  LabelWithCount | undefined,
  [typeof selectActiveLabelId, typeof selectLabels]
>(prop, [selectActiveLabelId, selectLabels]);

const selectAllLabelCreateModal = pipe(
  selectAllLabelsState,
  prop('createModal')
);

export const selectIsCreateLabelModalShowing = pipe(
  selectAllLabelCreateModal,
  prop('isShowing')
);

export const selectCreateLabelErrorMessage = pipe(
  selectAllLabelCreateModal,
  prop('errorMessage')
);

export const selectIsLabelCreationLoading = pipe(
  selectAllLabelCreateModal,
  prop('isLoading')
);

export const selectCreateLabelName = pipe(
  selectAllLabelCreateModal,
  prop('labelName')
);

const selectAllLabelEditModal = pipe(selectAllLabelsState, prop('editModal'));

export const selectIsEditLabelModalShowing = pipe(
  selectAllLabelEditModal,
  prop('isShowing')
);

export const selectEditLabelErrorMessage = pipe(
  selectAllLabelEditModal,
  prop('errorMessage')
);

export const selectIsLabelEditionLoading = pipe(
  selectAllLabelEditModal,
  prop('isLoading')
);

export const selectEditLabelId = pipe(selectAllLabelEditModal, prop('labelId'));

export const selectEditNewLabelName = pipe(
  selectAllLabelEditModal,
  prop('newLabelName')
);

const selectAllLabelDeleteModal = pipe(
  selectAllLabelsState,
  prop('deleteModal')
);

export const selectIsDeleteLabelModalShowing = pipe(
  selectAllLabelDeleteModal,
  prop('isShowing')
);

export const selectDeleteLabelErrorMessage = pipe(
  selectAllLabelDeleteModal,
  prop('errorMessage')
);

export const selectIsLabelDeletionLoading = pipe(
  selectAllLabelDeleteModal,
  prop('isLoading')
);

export const selectDeleteLabelId = pipe(
  selectAllLabelDeleteModal,
  prop('labelId')
);

export const selectLabelToDelete = converge<
  LabelWithCount | undefined,
  [typeof selectDeleteLabelId, typeof selectLabels]
>(prop, [selectDeleteLabelId, selectLabels]);

const selectNumberOfLabels = pipe(selectLabels, keys, length);

export const selectIsNumberOfLabelsExceeded = pipe(
  selectNumberOfLabels,
  lte(MAX_NUMBER_OF_LABELS)
);

export const selectIsThereAnyLabel = pipe(selectNumberOfLabels, equals(0), not);

export const selectIsEmptyStateDismissed = pipe(
  selectAllLabelsState,
  prop('isEmptyStateDismissed')
);
