import React from 'react';

import { PageTestimonial } from 'src/landing-new/state/landing-types';

import { TestimonialItem } from '../testimonial-item/testimonial-item';
import './testimonials.css';

const HEADING =
  'Streamable is loved by over 50 million users and customers from 122 countries';

const TESTIMONIALS: Array<PageTestimonial> = [
  {
    testimonial:
      'I love it when a web tool provides a simple service seamlessly. @streamable is a super clean #video publishing tool. Host a vid with no surrounding noise but the logo. Embed tool as well. Great place to host and share a video.',
    name: 'Danny McLellan',
    title: 'VP Events at Twitter',
    image: {
      title: 'Picture of Danny McLellan from Twitter',
      url: 'https://statics.streamable.com/static/landing/danny.jpg',
    },
  },
  {
    testimonial:
      'Shoutout to @streamable for being so good at converting videos, else I would not have any awesome plays of the game to share with my server members!',
    name: 'Uponia Gaming',
    title: 'Twitch.tv',
    image: {
      title: 'Avatar of Uponia Gaming on Twitch.tv',
      url: 'https://statics.streamable.com/static/landing/uponia.png',
    },
  },
];

type TestimonialsProps = {
  heading?: string;
  testimonials?: Array<PageTestimonial>;
};

export const Testimonials = ({
  heading = HEADING,
  testimonials = TESTIMONIALS,
}: TestimonialsProps) => (
  <section className="testimonials">
    <div className="testimonials__content">
      <h2 className="testimonials__title">{heading}</h2>

      <div className="testimonials__container">
        {testimonials.map((testimonial, i) => (
          <TestimonialItem key={`testiominal-article-${i}`} {...testimonial} />
        ))}
      </div>
    </div>
  </section>
);
