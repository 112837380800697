import { useEffect } from 'react';

export type ModalId = `${string}-modal`;
export type CloseTrigger =
  | 'backdrop'
  | 'close-button'
  | 'footer-button'
  | 'escape';

let OPEN_MODALS_IDS: Array<ModalId> = [];

export const closeModalById = (id: ModalId) => {
  OPEN_MODALS_IDS = OPEN_MODALS_IDS.filter((modalId) => modalId !== id);
};

export const useModalEscapeHandler = (
  id: ModalId,
  isShowing: boolean,
  handleClose: (trigger: CloseTrigger) => void
) => {
  useEffect(() => {
    if (!isShowing) {
      return;
    }

    if (!OPEN_MODALS_IDS.includes(id)) {
      OPEN_MODALS_IDS.unshift(id);
    }

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && id === OPEN_MODALS_IDS[0]) {
        handleClose('escape');
      }
    };

    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [id, handleClose, isShowing]);
};
