import React from 'react';

import { Flex } from 'src/common/components/flex/flex';
import { Skeleton } from 'src/common/components/skeleton/skeleton';

import './features-skeleton.css';

const features = new Array(3).fill(0);

export const FeaturesSkeleton = () => (
  <Flex className="features-skeleton">
    {features.map(() => (
      <Flex className="feature-skeleton-item" direction="row">
        <Flex className="feature-skeleton-text" justifyContent="center">
          <Skeleton height={'6rem'} animation="wave" />
          <Skeleton height={'1rem'} animation="wave" />
          <Skeleton height={'1rem'} animation="wave" />
          <Skeleton height={'1rem'} animation="wave" />
        </Flex>
        <Skeleton
          className="feature-skeleton-image"
          height={''}
          variant="rectangular"
          animation="wave"
        />
      </Flex>
    ))}
  </Flex>
);
