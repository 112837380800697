import { connect } from 'react-redux';

import {
  selectCurrentPlan,
  selectProPlan,
} from 'src/app/subscription/subscription-selectors';
import { selectIsLoggedIn } from 'src/common/state/me/me-selectors';
import type { PrivacyVisibility } from 'src/common/types/privacy';
import { dashboardUpgradeActions } from 'src/dashboard/state/upgrade/upgrade-actions';
import { videoPrivacyActions } from 'src/dashboard/state/video-privacy/video-privacy-actions';
import {
  selectVideoPrivacyAllowedDomainHasSaved,
  selectVideoPrivacyAllowedDomainIsSaving,
  selectVideoPrivacyModalIsShowing,
  selectVideoPrivacyResetModalIsResetting,
  selectVideoPrivacyResetModalIsShowing,
} from 'src/dashboard/state/video-privacy/video-privacy-selectors';
import { selectVideoForPrivacyModal } from 'src/dashboard/state/videos/videos-selectors';
import type { RootState, AppDispatch } from 'src/redux';
import { selectShouldExposeConfigurableViewCount } from 'src/utils/selectors/should-expose-configurable-view-count';

import { VideoPrivacyModalComponent } from './video-privacy-modal-component';

const mapStateToProps = (state: RootState) => ({
  currentPlan: selectCurrentPlan(state),
  configurableViewCountEnabled: selectShouldExposeConfigurableViewCount(state),
  proPlan: selectProPlan(state),
  hasAllowedDomainSaved: selectVideoPrivacyAllowedDomainHasSaved(state),
  isAllowedDomainSaving: selectVideoPrivacyAllowedDomainIsSaving(state),
  isLoggedIn: selectIsLoggedIn(state),
  isResetModalShowing: selectVideoPrivacyResetModalIsShowing(state),
  isResetModalResetting: selectVideoPrivacyResetModalIsResetting(state),
  isShowing: selectVideoPrivacyModalIsShowing(state),
  video: selectVideoForPrivacyModal(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  handleAllowDownloadToggle: (shortcode: string) =>
    dispatch(
      videoPrivacyActions.videoPrivacyModal.playerPreferencesSection.allowDownload.toggled(
        shortcode
      )
    ),
  handleAllowedDomainChange: (payload: {
    shortcode: string;
    allowedDomain: string;
  }) =>
    dispatch(
      videoPrivacyActions.videoPrivacyModal.restrictionSection.allowedDomainInput.changed(
        payload
      )
    ),
  handleAllowSharingToggle: (shortcode: string) =>
    dispatch(
      videoPrivacyActions.videoPrivacyModal.playerPreferencesSection.allowSharing.toggled(
        shortcode
      )
    ),
  handleDomainRestrictionsToggle: (shortcode: string) =>
    dispatch(
      videoPrivacyActions.videoPrivacyModal.restrictionSection.domainRestrictions.toggled(
        shortcode
      )
    ),
  handleTurnOffPasswordProtection: (shortcode: string) =>
    dispatch(
      videoPrivacyActions.videoPrivacyModal.restrictionSection.passwordProtection.turnedOff(
        shortcode
      )
    ),
  handleResetModalCancelClick: () =>
    dispatch(videoPrivacyActions.videoPrivacyModal.resetModal.cancelClicked()),
  handleResetModalConfirmClick: (shortcode: string) =>
    dispatch(
      videoPrivacyActions.videoPrivacyModal.resetModal.confirmClicked(shortcode)
    ),
  handleResetSettingsClick: () =>
    dispatch(
      videoPrivacyActions.videoPrivacyModal.advanced.resetSettingsClicked()
    ),
  handleHideViewCountToggle: (shortcode: string) =>
    dispatch(
      videoPrivacyActions.videoPrivacyModal.playerPreferencesSection.hideViewCount.toggled(
        shortcode
      )
    ),
  handleVisibilityChange: (payload: {
    shortcode: string;
    visibility: PrivacyVisibility;
  }) =>
    dispatch(
      videoPrivacyActions.videoPrivacyModal.privacySection.visibility.changed(
        payload
      )
    ),
  handlePasswordSubmit: (payload: { shortcode: string; password: string }) =>
    dispatch(
      videoPrivacyActions.videoPrivacyModal.restrictionSection.setPasswordButton.clicked(
        payload
      )
    ),
  handleUpsellClick: (source: string) =>
    dispatch(
      dashboardUpgradeActions.upgradeModal.triggered({
        queryParams: {
          from: 'video-privacy',
          src_internal: source,
        },
      })
    ),
  onClose: () => dispatch(videoPrivacyActions.videoPrivacyModal.closed()),
  openChangeCurrentPlanModal: (planId: string) =>
    dispatch(
      videoPrivacyActions.videoPrivacyModal.restrictionSection.domainRestrictions.upsellClicked(
        planId
      )
    ),
  handleFirstRender: () =>
    dispatch(videoPrivacyActions.videoPrivacyModal.firstRendered()),
});

export const VideoPrivacyModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoPrivacyModalComponent);
