import { connect } from 'react-redux';

import { replaceVideoActions } from 'src/dashboard/state/replace-video/replace-video-actions';
import { selectReplaceVideoModalIsShowing } from 'src/dashboard/state/replace-video/replace-video-selectors';
import type { RootState } from 'src/redux';

import { ReplaceVideoModalComponent } from './replace-video-modal-component';

const mapStateToProps = (state: RootState) => ({
  isShowing: selectReplaceVideoModalIsShowing(state),
});

const mapDispatchToProps = {
  onClose: replaceVideoActions.replaceVideoModal.closed,
};

export const ReplaceVideoModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReplaceVideoModalComponent);
