import React from 'react';
import ReactModal from 'react-modal/dist/react-modal';

import './modals.css';

export const Modal = ({
  isOpen,
  toggle,
  className,
  title,
  children,
  darkMode,
  hideOverlayScroll = false,
}) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={toggle}
    className={`Modal__Bootstrap modal-dialog ${className} ${
      darkMode ? 'dark' : ''
    }`}
    style={{
      overlay: { overflowY: !hideOverlayScroll ? 'scroll' : '' },
    }}
    overlayClassName="modal-backdrop"
    ariaHideApp={false}
  >
    <div className="modal-content">
      <ModalHeader>
        <h4 className="modal-title">{title}</h4>
        <button type="button" className="close" onClick={toggle}>
          <span aria-hidden="true" className="modal-title--x">
            ✕
          </span>
          <span className="sr-only">Close</span>
        </button>
      </ModalHeader>
      {children}
    </div>
  </ReactModal>
);

export const ModalFooter = ({ children }) => (
  <div className="modal-footer">{children}</div>
);

export const ModalHeader = ({ children }) => (
  <div className="modal-header">{children}</div>
);

export const ModalBody = ({ children }) => (
  <div className="modal-body">{children}</div>
);
