import React from 'react';

import { ActivatedSubscriptionInfo } from 'src/app/subscription/subscription-types';
import { Modal } from 'src/common/components/modal/modal';
import { useEffectOnce } from 'src/common/hooks/use-effect-once';
import { PrivacyVisibility } from 'src/common/types/privacy';
import { VideoRepresentation } from 'src/dashboard/types';
import { getIsVideoPasswordPresent } from 'src/dashboard/utils/get-is-video-password-present';
import { getVideoAllowedDomainValue } from 'src/dashboard/utils/get-video-allowed-domain-value';
import { getVideoPrivacyDomainRestrictionsValue } from 'src/dashboard/utils/get-video-privacy-domain-restrictions-value';
import { getVideoPrivacyIsCustom } from 'src/dashboard/utils/get-video-privacy-is-custom';
import { getVideoPrivacyVisibilityValue } from 'src/dashboard/utils/get-video-privacy-visibility-value';
import { AvailablePlan } from 'src/pricing/types';
import { PrivacyAndSecurityComponent } from 'src/settings/components/privacy-and-security/privacy-and-security-component';

import './video-privacy-modal-component.css';

type VideoPrivacyModalComponentStateProps = {
  configurableViewCountEnabled: boolean;
  currentPlan: ActivatedSubscriptionInfo | null;
  proPlan?: AvailablePlan;
  hasAllowedDomainSaved: boolean;
  isAllowedDomainSaving: boolean;
  isLoggedIn: boolean;
  isShowing: boolean;
  isResetModalShowing: boolean;
  isResetModalResetting: boolean;
  video: VideoRepresentation | null;
};

type VideoPrivacyModalComponentDispatchProps = {
  handleAllowDownloadToggle: (shortcode: string) => void;
  handleAllowedDomainChange: (payload: {
    shortcode: string;
    allowedDomain: string;
  }) => void;
  handleAllowSharingToggle: (shortcode: string) => void;
  handleDomainRestrictionsToggle: (shortcode: string) => void;
  handlePasswordSubmit: (payload: {
    shortcode: string;
    password: string;
  }) => void;
  handleTurnOffPasswordProtection: (shortcode: string) => void;
  handleResetModalCancelClick: () => void;
  handleResetModalConfirmClick: (shortcode: string) => void;
  handleResetSettingsClick: () => void;
  handleHideViewCountToggle: (shortcode: string) => void;
  handleVisibilityChange: (payload: {
    shortcode: string;
    visibility: PrivacyVisibility;
  }) => void;
  handleUpsellClick: (source: string) => void;
  openChangeCurrentPlanModal: (planId: string) => void;
  onClose: () => void;
  handleFirstRender: () => void;
};

type VideoPrivacyModalComponentProps = VideoPrivacyModalComponentStateProps &
  VideoPrivacyModalComponentDispatchProps;

export const VideoPrivacyModalComponent = ({
  configurableViewCountEnabled,
  currentPlan,
  proPlan,
  hasAllowedDomainSaved,
  isAllowedDomainSaving,
  isShowing,
  isResetModalShowing,
  isResetModalResetting,
  video,
  onClose,
  handleAllowDownloadToggle,
  handleAllowedDomainChange,
  handleAllowSharingToggle,
  handleDomainRestrictionsToggle,
  handlePasswordSubmit,
  handleTurnOffPasswordProtection,
  handleUpsellClick,
  handleVisibilityChange,
  handleResetModalConfirmClick,
  handleHideViewCountToggle,
  handleFirstRender,
  ...privacyAndSecurityComponentProps
}: VideoPrivacyModalComponentProps) => {
  useEffectOnce(() => {
    handleFirstRender();
  });

  if (!video) {
    return null;
  }

  return (
    <Modal
      id="video-privacy-modal"
      isShowing={isShowing}
      onClose={onClose}
      title="Privacy Settings"
      className="video-privacy-modal"
      hideCancel={true}
      hideConfirm={true}
    >
      <PrivacyAndSecurityComponent
        allowDownload={!!video.privacy_settings?.allow_download}
        allowSharing={!!video.privacy_settings?.allow_sharing}
        allowedDomain={getVideoAllowedDomainValue(video)}
        configurableViewCountEnabled={configurableViewCountEnabled}
        currentPlan={currentPlan}
        proPlan={proPlan}
        domainRestrictions={getVideoPrivacyDomainRestrictionsValue(video)}
        hasAllowedDomainSaved={hasAllowedDomainSaved}
        isAllowedDomainSaving={isAllowedDomainSaving}
        isPasswordPresent={getIsVideoPasswordPresent(video)}
        isResetModalShowing={isResetModalShowing}
        isResetModalResetting={isResetModalResetting}
        layout="video"
        hasCustomPrivacySettings={getVideoPrivacyIsCustom(video)}
        hideViewCount={!!video.privacy_settings?.hide_view_count}
        visibility={getVideoPrivacyVisibilityValue(video)}
        handleAllowDownloadToggle={() =>
          handleAllowDownloadToggle(video.shortcode!)
        }
        handleAllowedDomainChange={(allowedDomain) =>
          handleAllowedDomainChange({
            shortcode: video.shortcode!,
            allowedDomain,
          })
        }
        handleAllowSharingToggle={() =>
          handleAllowSharingToggle(video.shortcode!)
        }
        handleDomainRestrictionsToggle={() =>
          handleDomainRestrictionsToggle(video.shortcode!)
        }
        handlePasswordSubmit={(password) =>
          handlePasswordSubmit({
            shortcode: video.shortcode!,
            password,
          })
        }
        handleTurnOffPasswordProtection={() =>
          handleTurnOffPasswordProtection(video.shortcode!)
        }
        handleVisibilityChange={(value) =>
          handleVisibilityChange({
            shortcode: video.shortcode!,
            visibility: value,
          })
        }
        handleResetModalConfirmClick={() =>
          handleResetModalConfirmClick(video.shortcode!)
        }
        handleHideViewCountToggle={() => {
          handleHideViewCountToggle(video.shortcode!);
        }}
        handleUpsellClick={handleUpsellClick}
        {...privacyAndSecurityComponentProps}
      />
    </Modal>
  );
};
