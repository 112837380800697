import { createAction } from '@reduxjs/toolkit';

import {
  EmbedContainerSizeType,
  EmbedPlayerOptionName,
} from './embed-preview-types';

export const EMBED_PREVIEW_ACTION_TYPES = {};

export const embedPreviewActions = {
  embedPreviewModal: {
    changeContainerHeight: createAction<number>(
      '[Embed Preview Modal] change container height'
    ),
    changeContainerWidth: createAction<number>(
      '[Embed Preview Modal] change container width'
    ),
    codeCopied: createAction('[Embed Preview Modal] code copied'),
    enableCodeCopy: createAction('[Embed Preview Modal] enable code copy'),
    opened: createAction<{ shortcode: string; width: number; height: number }>(
      '[Embed Preview Modal] opened'
    ),
    changeContainerSizeType: createAction<EmbedContainerSizeType>(
      '[Embed Preview Modal] change container size type'
    ),
    closed: createAction('[Embed Preview Modal] closed'),
    togglePlayerOption: createAction<EmbedPlayerOptionName>(
      '[Embed Preview Modal] toggle player option'
    ),
  },
};
