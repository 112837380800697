import { useCallback, useEffect, useState } from 'react';

export const useLoadVideos = (sliderRef, callback) => {
  const [areVideosLoaded, setAreVideosLoaded] = useState(false);

  const loadVideos = useCallback(() => {
    if (
      sliderRef.current &&
      sliderRef.current.offsetParent &&
      !areVideosLoaded
    ) {
      if ('IntersectionObserver' in window) {
        const lazyVideoObserver = new IntersectionObserver(function (entries) {
          entries.forEach(function (video) {
            if (video.isIntersecting) {
              video.target.src = video.target.dataset.src;
              video.target.load();
              lazyVideoObserver.unobserve(video.target);
            }
          });
        });

        for (const videoElement of sliderRef.current.children) {
          lazyVideoObserver.observe(videoElement);
        }
      } else {
        for (const videoElement of sliderRef.current.children) {
          videoElement.src = videoElement.dataset.src;
        }
      }

      setAreVideosLoaded(true);
      if (callback) {
        callback();
      }
    }
  }, [callback, sliderRef, areVideosLoaded, setAreVideosLoaded]);

  useEffect(() => {
    loadVideos();
  }, [loadVideos]);

  useEffect(() => {
    window.addEventListener('resize', loadVideos);

    return () => {
      window.removeEventListener('resize', loadVideos);
    };
  }, [loadVideos]);

  return { areVideosLoaded };
};
