import React from 'react';
import ReactDOM from 'react-dom';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Root from './Root';
import './scss/bootstrap.css';
import './scss/main.css';

ReactDOM.render(<Root />, document.getElementById('root'));

if (module.hot) {
  module.hot.accept('./Root', () => {
    ReactDOM.render(<Root />, document.getElementById('root'));
  });
}
