import { all, call, takeEvery, put, select } from 'redux-saga/effects';
import { getErrorMessage } from 'src/utils/get-error-message';

import { videoCaptionsActions } from './video-captions-actions';
import {
  type VideoCaptionsListResponse,
  getVideoCaptionsApi,
  deleteVideoCaptionsApi,
} from './video-captions-api';
import {
  selectVideoCaptionsDeleteShortcode,
  selectVideoCaptionsShortcode,
} from './video-captions-selectors';

export function* fetchVideoCaptions({
  payload: shortcode,
}: ReturnType<typeof videoCaptionsActions.fetch.initiated>) {
  try {
    const { captions }: VideoCaptionsListResponse = yield call(
      getVideoCaptionsApi,
      shortcode
    );
    yield put(videoCaptionsActions.fetch.fulfilled(captions));
  } catch (error) {
    const message: string = yield call(getErrorMessage, error);
    console.error(message);
    yield put(videoCaptionsActions.fetch.failed('Failed to fetch captions'));
  }
}

export function* deleteVideoCaptions({
  payload: captionId,
}: ReturnType<typeof videoCaptionsActions.delete.initiated>) {
  try {
    const shortcode: string = yield select(selectVideoCaptionsShortcode);
    yield call(deleteVideoCaptionsApi, shortcode, captionId);

    yield put(videoCaptionsActions.delete.fulfilled());
    yield put(videoCaptionsActions.captionsModal.opened(shortcode));
    yield put(videoCaptionsActions.fetch.initiated(shortcode));
  } catch (error) {
    const message: string = yield call(getErrorMessage, error);
    console.error(message);
    yield put(videoCaptionsActions.delete.failed('Failed to delete captions'));
  }
}

export function* handleDeleteModalOpened() {
  yield put(videoCaptionsActions.captionsModal.closed());
}
export function* handleDeleteModalClosed() {
  const shortcode: string = yield select(selectVideoCaptionsDeleteShortcode);
  yield put(videoCaptionsActions.captionsModal.opened(shortcode));
}

export function* watchVideoCaptionsSaga() {
  yield all([
    takeEvery(videoCaptionsActions.fetch.initiated, fetchVideoCaptions),
    takeEvery(videoCaptionsActions.delete.initiated, deleteVideoCaptions),
    takeEvery(
      videoCaptionsActions.captionsDeleteModal.opened,
      handleDeleteModalOpened
    ),
    takeEvery(
      videoCaptionsActions.captionsDeleteModal.closed,
      handleDeleteModalClosed
    ),
  ]);
}
