import { FeatureItem } from './type';

export const BACKGROUND_VIDEOS = [
  'https://streamable.com/l/diqi0e/mp4-mobile.mp4',
  'https://streamable.com/l/17z0j2/mp4-mobile.mp4',
  'https://streamable.com/l/dwina2/mp4-mobile.mp4',
  'https://streamable.com/l/kzhi0f/mp4-mobile.mp4',
  'https://streamable.com/l/lttg48/mp4-mobile.mp4',
  'https://streamable.com/l/e03hi7/mp4-mobile.mp4',
  'https://streamable.com/l/prgi89/mp4-mobile.mp4',
  'https://streamable.com/l/cnugix/mp4-mobile.mp4',
  'https://streamable.com/l/vytztg/mp4-mobile.mp4',
];

export const TICKER_CONFIG = {
  speed: {
    fast: 0.7,
    slow: 0.3,
  },
};

export const HOME_PAGE_FEATURES: FeatureItem[] = [
  {
    title: 'Upload and share your videos instantly',
    content:
      "The fastest video uploader on the web — don't take our word for it, try it! After your videos are uploaded, stream in 4K UHD (60fps). No home theater necessary.",
    mediaSrc:
      'https://statics.streamable.com/static/landing/animations/upload-animation-compressed.gif',
    mediaLayout: 'left',
  },
  {
    title: 'Video editing that gets out of your way',
    content:
      'With one-click resizing to landscape, square, or vertical formats, you can get back hours of time while creating videos for every platform and channel.',
    mediaSrc:
      'https://statics.streamable.com/static/landing/animations/edit-animation-compressed-v2.gif',
  },
];
