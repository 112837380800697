import { sendLogToServer } from 'src/common/logging/logging-api';
import { env } from 'src/env';

// NOTE: this is deprecated and should not be used anymore.
// Instead you should be using the `logMessage` action that is hooked up with
// the logging-saga.
export const log = (errorOrMessage: string | unknown) => {
  let errorMessage = 'Unknown Error';
  if (errorOrMessage instanceof Error) {
    errorMessage = errorOrMessage.message;
  }
  return sendLogToServer(errorMessage, env.VERSION);
};
