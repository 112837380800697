import { useEffect } from 'react';

export const useWaitWindowProperty = <T = any>(
  property: string,
  callback: (value: T) => void,
  interval: number = 100
) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      // @ts-expect-error
      const value = window[property];
      if (value) {
        clearInterval(intervalId);
        callback(value);
      } else {
        console.log(`window.${property} not loaded`);
      }
    }, interval);
  }, []);
};
