export const getPlanQueryParamFromPlanId = (planId: string) => {
  if (planId.includes('basic')) {
    return 'basic';
  }

  if (planId.includes('business')) {
    return 'business';
  }

  return 'pro';
};
