import React from 'react';
import { PropsWithChildren, useState } from 'react';
import { connect } from 'react-redux';

import {
  DndContext,
  DragOverlay,
  type DragEndEvent,
  type DragStartEvent,
  type UniqueIdentifier,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  dashboardAssignLabelsActions,
  dragAndDropCompleted,
} from 'src/dashboard/state/assign-labels/assign-labels-actions';
import type { Label, VideoRepresentation } from 'src/dashboard/types';
import { getAllVideos, getSelectedVideos } from 'src/misc/selectors';
import type { RootState, AppDispatch } from 'src/redux';
import { shouldRenderSidebar } from 'src/utils/selectors/should-render-sidebar';

import './draggable.css';
import ErrorAlertModal from './error-alert-modal';
import { snapTopLeftToCursor } from './modifiers/snap-top-left-to-cursor';
import { CustomMouseSensor } from './sensors';

const ACTIVATION_DISTANCE = 7;

type ContextProps = {
  selectedVideos: VideoRepresentation[];
  videos: VideoRepresentation[];
  withSidebar: boolean;

  assignLabel: (labelId: Label['id'], draggedShortcode: string) => void;
  dragStarted: () => void;
  dragEnded: () => void;
};

export const DragAndDropContextComponent = ({
  assignLabel,
  children,
  dragEnded,
  dragStarted,
  selectedVideos,
  videos,
  withSidebar,
}: PropsWithChildren<ContextProps>) => {
  const [draggedShortcode, setDraggedShortcode] = useState<UniqueIdentifier>();

  const handleDragStart = (event: DragStartEvent) => {
    setDraggedShortcode(event.active.id);
    dragStarted();
  };

  const handleDragEnd = (event: DragEndEvent) => {
    setDraggedShortcode('');

    // do nothing if the drag event ended outside of the drop areas
    // `event.over` here means "over a drop area"
    if (!event.over) {
      dragEnded();
      return;
    }

    assignLabel(
      Number.parseInt(event.over.id as string, 10),
      event.active.id as string
    );
  };

  const mouseSensor = useSensor(CustomMouseSensor, {
    activationConstraint: {
      distance: ACTIVATION_DISTANCE,
    },
  });

  const sensors = useSensors(mouseSensor);

  const video = videos.find(({ shortcode }) => shortcode === draggedShortcode);
  const thumbnailUrl = video?.thumbnail_url ?? video?.poster_url;

  if (!withSidebar) {
    return <>{children}</>;
  }

  const selectedCount = selectedVideos.length;

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={pointerWithin}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      {children}

      <DragOverlay dropAnimation={null} modifiers={[snapTopLeftToCursor]}>
        {draggedShortcode && (
          <div className="draggable-video-item-stack">
            <div className="draggable-video-item-stack__card">
              {selectedCount > 1 && (
                <span className="badge">{selectedCount} items</span>
              )}
              <div className="video-thumbnail-container">
                {thumbnailUrl ? (
                  <img
                    className="card-img-top"
                    alt="Thumbnail"
                    role="presentation"
                    src={thumbnailUrl}
                  />
                ) : (
                  <div className="card-img-top" />
                )}
              </div>
            </div>
            {selectedCount > 1 && (
              <div className="draggable-video-item-stack__card draggable-video-item-stack__card--stacked-0" />
            )}
            {selectedCount > 2 && (
              <div className="draggable-video-item-stack__card draggable-video-item-stack__card--stacked-1" />
            )}
          </div>
        )}
      </DragOverlay>

      <ErrorAlertModal />
    </DndContext>
  );
};

const mapStateToProps = (state: RootState) => ({
  selectedVideos: getSelectedVideos(state),
  videos: getAllVideos(state),
  withSidebar: shouldRenderSidebar(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  dragStarted: () =>
    dispatch(dashboardAssignLabelsActions.dragAndDropAssign.startDragging()),
  dragEnded: () =>
    dispatch(dashboardAssignLabelsActions.dragAndDropAssign.stopDragging()),
  assignLabel: (labelId: Label['id'], draggedShortcode: string) =>
    dispatch(dragAndDropCompleted(labelId, draggedShortcode)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DragAndDropContextComponent);
