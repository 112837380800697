import { call, put } from 'redux-saga/effects';
import { fetchVideoAction } from 'src/actions';
import { VideoRepresentation } from 'src/dashboard/types';
import { transformVideoAfterFetch } from 'src/dashboard/utils/transform-video-after-fetch';

import { fetchVideoApi, fetchVideosApi } from '../videos/videos-api';
import { videosActions } from './videos-actions';

export function* fetchVideo(shortcode: string) {
  try {
    yield put(fetchVideoAction({ isFetching: true, shortcode }));
    const videoData: VideoRepresentation = yield call(fetchVideoApi, shortcode);
    yield put(fetchVideoAction({ isFetching: false, ...videoData }));
  } catch (error) {
    yield put(fetchVideoAction(error as Error));
  }
}

export function* fetchVideos() {
  try {
    yield put(videosActions.dashboard.fetchVideos.initiated());

    const { total, videos }: { total: number; videos: VideoRepresentation[] } =
      yield call(fetchVideosApi);
    yield put(
      videosActions.dashboard.fetchVideos.fulfilled({
        total,
        videos: videos.map(transformVideoAfterFetch),
      })
    );
  } catch (error) {
    yield put(videosActions.dashboard.fetchVideos.failed(error as Error));
  }
}
