import React from 'react';
import { Link } from 'react-router';

import { Button } from 'src/common/components/button/button';

import './footer-link.css';

type FooterLinkProps = {
  href?: string;
  label: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const FooterLink = ({ href, label, onClick }: FooterLinkProps) => {
  if (onClick) {
    return (
      <Button onClick={onClick} variant="link" className="footer-v3-btn">
        {label}
      </Button>
    );
  }

  if (href?.startsWith('http')) {
    return (
      <a
        href={href}
        target="_blank"
        className="footer-v3-link"
        rel="noreferrer"
      >
        {label}
      </a>
    );
  }

  return (
    <Link to={href || ''} className="footer-v3-link">
      {label}
    </Link>
  );
};
