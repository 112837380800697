import React, { ReactChild, forwardRef } from 'react';

import { Text } from 'src/common/components/text/text';

import './upgrade-modal.css';

type UpgradeModalCardProps = {
  variant?: 'popular' | 'default';
  children?: ReactChild;
};

export const UpgradeModalCard = forwardRef<
  HTMLDivElement,
  UpgradeModalCardProps
>(({ variant, children }: UpgradeModalCardProps, ref) => {
  if (variant === 'popular') {
    return (
      <div className="upgrade-modal-card__popular-wrapper" ref={ref}>
        <Text className="upgrade-modal-card__popular-wrapper__text">
          Most popular
        </Text>
        <div className="upgrade-modal-card">{children}</div>
      </div>
    );
  }

  return (
    <div className="upgrade-modal-card__padding">
      <div className="upgrade-modal-card">{children}</div>
    </div>
  );
}) as (
  props: UpgradeModalCardProps & { ref?: React.ForwardedRef<HTMLDivElement> }
) => JSX.Element;
