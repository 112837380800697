import { ExtendedPrivacySetting } from '../types';

export const calculateNewPrivacySettings = (
  settings: Partial<ExtendedPrivacySetting>
): Partial<ExtendedPrivacySetting> => {
  if (settings.visibility === 'private') {
    return {
      ...settings,
      allow_download: false,
      allow_sharing: false,
      domain_restrictions: 'off',
      visibility: 'private',
    };
  }

  return settings;
};
