import { createAction } from '@reduxjs/toolkit';
import type { FetchVideoOptions } from 'src/api/videos';
import type { Label, VideoRepresentation } from 'src/dashboard/types';

export type CreateVideoPayload = Partial<VideoRepresentation> & {
  local_id?: string;
  title?: string;
  labels?: Label[];
};

export type DeleteVideoPayload = Partial<VideoRepresentation> & {
  isDeleting?: boolean;
};

export const videosActions = {
  dashboard: {
    fetchVideos: {
      failed: createAction<Error>('[Dashboard] fetching of videos failed'),
      fulfilled: createAction<{ total: number; videos: VideoRepresentation[] }>(
        '[Dashboard] fetching of videos fulfilled'
      ),
      initiated: createAction<undefined | FetchVideoOptions>(
        '[Dashboard] fetching of videos initiated'
      ),
    },
  },
  deleteVideo: createAction<Error | DeleteVideoPayload>('DELETE_VIDEO'),
  upload: {
    createVideo: createAction<CreateVideoPayload>('CREATE_VIDEO'),
  },
};
