import React from 'react';

import { Flex } from 'src/common/components/flex/flex';
import { WidgetSkeleton } from 'src/landing-new/components/loading-skeleton/widget-skeleton';
import type { PageConfig } from 'src/landing-new/state/landing-types';

import { Clipper } from './clipper/clipper';
import { SignUp } from './sign-up-cta/sign-up-cta';
import { Uploader } from './uploader/uploader';
import './widget.css';

type WidgetProps = {
  page: PageConfig | null;
  isLoading: boolean;
};

export const Widget = ({ page, isLoading }: WidgetProps) => (
  <Flex className="widget" alignItems="center">
    {isLoading ? (
      <WidgetSkeleton />
    ) : (
      <>
        {page && page.widget === 'upload' && <Uploader page={page} />}
        {page && page.widget === 'clipper' && <Clipper page={page} />}
        {page && page.widget === 'sign-up-cta' && <SignUp page={page} />}
      </>
    )}
  </Flex>
);
