import type { PayloadAction } from '@reduxjs/toolkit';
import { Map } from 'immutable';

import { extractUrlAction, clearClipperAction } from '../actions';

type ClipperPayload = {
  isFetching?: boolean;
  retries?: number;
  playback_url?: string;
  mime?: string;
  file?: File;
  error?: Error;
};

export type ClipperState = {
  extract?: null | (Map<unknown, unknown> & ClipperPayload);
};

const initialState: ClipperState = {};

export default function clipper(
  state = initialState,
  action: PayloadAction<ClipperPayload> & { error?: Error }
): ClipperState {
  switch (action.type) {
    case extractUrlAction.type:
      const extract = Map(action.payload);
      return {
        ...state,
        extract: action.error ? extract.set('error', action.error) : extract,
      };
    case clearClipperAction.type:
      return { ...state, extract: null };
    default:
      return state;
  }
}
