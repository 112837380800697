import {
  editVideoAction,
  errorVideo,
  log,
  uploadAction,
  uploadProgressAction,
} from 'src/actions';
import {
  upload,
  type UploadMetaData,
  type UploadFinishedCallback,
  type UploadProgressCallback,
} from 'src/api/video';
import type { AppDispatch } from 'src/redux';

import type { ReplaceVideoPartialResponseMetadata } from './replace-video-api-types';

const IGNORE_MESSAGE_SUBSTRING = '0 retries] uploadPart(';
const shouldIgnoreErrorMessage = (message: string) =>
  message.includes(IGNORE_MESSAGE_SUBSTRING);

export const uploadFileToS3WithStore = (
  file: File,
  metadata: ReplaceVideoPartialResponseMetadata,
  dispatch: AppDispatch
) => {
  const shortcode = metadata.shortcode;
  let recentSpeeds: Array<number> = [];
  const startTime = Date.now();

  const handleData: UploadFinishedCallback = (error, data) => {
    if (error) {
      dispatch(errorVideo(shortcode, error.message));
      return;
    }

    dispatch(
      uploadAction({
        shortcode,
        upload_metadata: metadata,
        transcode_options: metadata.options,
        transcode_data: data,
        upload_time: Date.now() - startTime,
        upload_percent: 100,
        percent: 0,
        waitingToTranscode: true,
      })
    );
  };

  const getUploadSpeed = (speed: number | null) => {
    if (speed != null) {
      recentSpeeds.unshift(speed > 0 ? speed : 0);
    }
    recentSpeeds = recentSpeeds.slice(0, 100);
    let sum = recentSpeeds.reduce((a, b) => a + b, 0);
    return sum / recentSpeeds.length;
  };

  const handleProgress: UploadProgressCallback = (progress) => {
    dispatch(
      uploadProgressAction({
        shortcode,
        upload_percent: progress.percent,
        upload_speed: getUploadSpeed(progress.speed || null),
        retries: progress.retries,
      })
    );
  };

  const startCallback = () => {
    dispatch(
      editVideoAction({
        shortcode,
        waitingToUpload: false,
      })
    );
  };

  const handleLogger = (message: string) => {
    if (!shouldIgnoreErrorMessage(message)) {
      log(message);
    }
  };

  const task = upload(
    file,
    { ...metadata.options, upload_source: 'web' },
    metadata as UploadMetaData,
    handleData,
    handleProgress,
    startCallback,
    handleLogger
  );

  return task;
};
