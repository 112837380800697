import { Collection } from 'src/dashboard/types';
import { apiRequest } from 'src/utils/api-request';

export const createCollection = (newCollection: {
  title?: string;
  shortcodes: string[];
}) => {
  return apiRequest<Collection>({
    path: '/collections',
    method: 'POST',
    body: newCollection,
  });
};
