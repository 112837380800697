import React from 'react';

import { Button } from 'src/common/components/button/button';
import { TextWithHighlightStroke } from 'src/common/components/text-with-highlight-stroke/text-with-highlight-stroke';
import { Text } from 'src/common/components/text/text';

import { onBusinessClick } from '../utils/onBusinessClick';
import './sales-led-plan-component.css';

export const SalesLedPlan = () => {
  return (
    <section className="sales-led-plan-section">
      <div className="sales-led-plan-section__content">
        <Text as="h2" className="sales-led-plan-section__content__title">
          Need a plan <TextWithHighlightStroke>without</TextWithHighlightStroke>{' '}
          limits?
        </Text>

        <Text className="sales-led-plan-section__content__text">
          Our Enterprise plan gives you custom bandwidth pricing, high-volume
          storage, reporting, and more.
        </Text>

        <Button
          color="primary"
          onClick={() => onBusinessClick()}
          className="sales-led-plan-section__content__cta"
        >
          Contact
        </Button>
      </div>
    </section>
  );
};
