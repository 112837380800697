import { call, select, takeEvery } from 'redux-saga/effects';

import { selectVersion } from '../state/me/me-selectors';
import { logMesssage } from './logging-actions';
import { sendLogToServer } from './logging-api';

export function* handleLogMessageRequest(action: { payload: string }) {
  const version: string = yield select(selectVersion);

  yield call(sendLogToServer, action.payload, version);
}

export function* loggingSaga() {
  yield takeEvery(logMesssage, handleLogMessageRequest);
}
