import { connect } from 'react-redux';

import {
  selectCurrentPlan,
  selectIsStripeUser,
} from 'src/app/subscription/subscription-selectors';
import { pricingActions } from 'src/pricing/state/pricing-actions';
import {
  selectAllChangePlanModalState,
  selectTargetCadence,
  selectTargetProduct,
  selectTargetProductPrice,
} from 'src/pricing/state/pricing-selectors';
import type { RootState } from 'src/redux';

import { ChangePlanModalComponent } from './change-plan-modal-component';

const mapDispatchToProps = {
  handleClose: pricingActions.changePlanModal.close,
  applyPlanChange: pricingActions.changePlanModal.confirmButton.clicked,
};

const mapStateToProps = (state: RootState) => ({
  ...selectAllChangePlanModalState(state),
  isPayPalUser: !selectIsStripeUser(state),
  targetCadence: selectTargetCadence(state),
  targetProductPrice: selectTargetProductPrice(state),
  targetProduct: selectTargetProduct(state),
  currentPlan: selectCurrentPlan(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePlanModalComponent);
