import type { PayloadAction } from '@reduxjs/toolkit';
import { List, Map } from 'immutable';
import { omit } from 'ramda';
import {
  loginAction,
  fetchCurrentUserAction,
  deselectAction,
  selectAction,
  deselectAllAction,
  editUserAction,
  zoomAction,
} from 'src/actions';
import { dashboardCollectionActions } from 'src/dashboard/state/collection/collection-actions';
import { videosActions } from 'src/dashboard/state/videos/videos-actions';
import { env } from 'src/env';
import { getCookie } from 'src/misc/cookies';
import { pricingActions } from 'src/pricing/state/pricing-actions';

import type { MeState } from './me-types';

const initialState: MeState = {
  loading_plan: false,
  selectedVideos: List(),
  uploads: 0,
  isFetching: true,
  embed_options: Map(),
  ua: typeof navigator !== 'undefined' ? navigator.userAgent : null,
  // NOTE: this initial state is important because it ensures that the initial render
  // of the app matches the render after fetching the user from the `/me` endpoint.
  // It is also important because it otherwise introduces a race condition that can
  // cause the Billing page to always redirect users to the login page.
  user_name: getCookie('user_name'),
  dark_mode: getCookie('dark_mode') === 'true',
  version: env.VERSION,
  plan: undefined,
  promos: [],
  first_run: !getCookie('dashboard'),
  flags: {},
  // Plan fields
  plan_name: undefined,
  plan_price: undefined,
  plan_annual: undefined,
  plan_plays: undefined,
  plan_requests: undefined,
  plan_max_length: undefined,
  plan_max_size: undefined,
  plan_hide_branding: undefined,
  terms_accepted: undefined,
};

export const meReducer = (
  state = initialState,
  action: PayloadAction<any> & { error?: string }
): MeState => {
  const { type, payload, error } = action;

  switch (type) {
    case loginAction.type:
      if (error) {
        return {
          ...state,
          error: true,
          message: payload.message,
        };
      }

      return {
        ...state,
        ...omit(['privacy_settings'], payload),
      };
    case fetchCurrentUserAction.type:
      if (error) {
        return {
          ...state,
          user_name: undefined,
          isFetching: false,
        };
      }

      return {
        ...state,
        ...omit(['privacy_settings'], payload),
        isFetching:
          payload.isFetching !== undefined ? payload.isFetching : false,
      };
    case pricingActions.changePlanModal.changeFulfilled.type: {
      return {
        ...state,
        ...payload, // this payload has the shape of PlanDetails
      };
    }
    case deselectAction.type: {
      const shortcodeList = state.selectedVideos.map(
        ({ shortcode }) => shortcode
      );
      const indexToDelete = shortcodeList.indexOf(payload.shortcode);

      return {
        ...state,
        selectedVideos: state.selectedVideos.delete(indexToDelete),
      };
    }
    case selectAction.type:
      return {
        ...state,
        selectedVideos: state.selectedVideos.push(payload),
      };
    case deselectAllAction.type:
    case dashboardCollectionActions.createCollection.fulfilled.type:
      return {
        ...state,
        selectedVideos: List(),
      };
    case editUserAction.type:
      if (error) {
        return {
          ...state,
          error: true,
          message: payload.message,
          isFetching: false,
        };
      }
      return {
        ...state,
        ...omit(['privacy_settings'], payload),
        isFetching:
          payload.isFetching !== undefined ? payload.isFetching : false,
      };
    case zoomAction.type:
      return {
        ...state,
        zoomed: payload,
      };
    case videosActions.upload.createVideo.type:
      return {
        ...state,
        uploads: state.uploads + 1,
      };
    case videosActions.deleteVideo.type: {
      // TODO: Payload can be error type

      const shortcodeList = state.selectedVideos.map(
        ({ shortcode }) => shortcode
      );
      const indexToDelete = shortcodeList.indexOf(payload.shortcode);

      return {
        ...state,
        selectedVideos: state.selectedVideos.delete(indexToDelete),
      };
    }

    default:
      return state;
  }
};
