import { connect } from 'react-redux';

import { checkoutActions } from 'src/checkout/state/checkout-actions';
import {
  selectClientSecret,
  selectRedirectStatus,
} from 'src/checkout/state/checkout-selectors';
import type { RootState } from 'src/redux';

import { BillingDetailsComponent } from './billing-details-component';

const mapStateToProps = (state: RootState) => ({
  clientSecret: selectClientSecret(state),
  redirectStatus: selectRedirectStatus(state),
  shouldRenderPlanCadencePicker: true,
});

const mapDispatchToProps = {
  fetchIntent: checkoutActions.billingDetails.next.intentFetch.initiated,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingDetailsComponent);
