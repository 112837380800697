import React from 'react';
import { browserHistory } from 'react-router';

import { Modal, ModalFooter, ModalBody } from './modals';
import './modals.css';

const UpgradeModal = ({
  isOpen,
  darkMode,
  noTrial,
  header,
  subheader,
  srcInternal,
  onClose,
}) => (
  <Modal
    isOpen={isOpen}
    toggle={onClose}
    className="app-modal toolarge-modal"
    title=""
    darkMode={darkMode}
  >
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a
      href="#"
      onClick={() =>
        browserHistory.push(`/upgrade?src_internal=${srcInternal}`)
      }
    >
      <div className="toolarge-modal-tab">
        {noTrial ? 'Upgrade to basic' : 'Try basic free'}
      </div>
    </a>
    <ModalBody>
      <div className="toolarge-modal-content">
        <div className="toolarge-modal-content-header">{header}</div>
        <div>{subheader}</div>
        <img
          alt="Rocket in abox"
          src={`//statics.streamable.com/static/rocket${
            darkMode ? '-dark' : ''
          }.png`}
        />
      </div>
    </ModalBody>
    <ModalFooter>
      <button className="close-button" onClick={onClose}>
        Maybe Later
      </button>
      <button
        className="blue-button"
        onClick={() => {
          onClose();
          browserHistory.push(`/upgrade?src_internal=${srcInternal}`);
        }}
      >
        {noTrial ? 'Upgrade Now' : 'Start Free Trial'}
      </button>
    </ModalFooter>
  </Modal>
);

export default UpgradeModal;
