import { PrivacySettings } from 'src/common/types/privacy';
import { UserOutput } from 'src/common/types/user';
import { apiRequest } from 'src/utils/api-request';

export const updateSettings = (settings: Partial<PrivacySettings>) =>
  apiRequest<{ user: UserOutput }>({
    path: `/me/settings`,
    method: 'PATCH',
    body: { ...settings },
  });

export const resetAllVideoPrivacySettings = () =>
  apiRequest<null>({
    path: `/me/settings/videos/reset-all`,
    method: 'DELETE',
  });
