import { connect } from 'react-redux';

import { selectNoTrial } from 'src/common/state/me/me-selectors';
import type { RootState } from 'src/redux';

import { PaymentCollectionSkeleton } from './payment-collection-skeleton-component';

const mapStateToProps = (state: RootState) => ({
  noTrial: selectNoTrial(state),
});

export default connect(mapStateToProps)(PaymentCollectionSkeleton);
