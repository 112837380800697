import { connect } from 'react-redux';

import {
  selectFreeTrialDuration,
  selectNoTrial,
} from 'src/common/state/me/me-selectors';
import type { RootState } from 'src/redux';

import { SmallPrintDetailsComponent } from './small-print-details-component';

const mapStateToProps = (state: RootState) => ({
  noTrial: selectNoTrial(state),
  trialPeriod: selectFreeTrialDuration(state),
});

export default connect(mapStateToProps)(SmallPrintDetailsComponent);
