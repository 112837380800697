import React from 'react';

import { ActivatedSubscriptionInfo } from 'src/app/subscription/subscription-types';
import { SelectableOption } from 'src/common/components/selectable-option/selectable-option';
import { Text } from 'src/common/components/text/text';
import { PrivacyVisibility } from 'src/common/types/privacy';

import { VISIBILITY_OPTIONS } from '../../constants';
import { ContentSection } from '../content-section/content-section';
import { UpsellLabel } from '../upsell-label/upsell-label';

type PrivacyAndSecurityVisibilityProps = {
  currentPlan: ActivatedSubscriptionInfo | null;
  visibility: PrivacyVisibility;
  handleVisibilityChange: (value: PrivacyVisibility) => void;
  handleUpsellClick: (source: string) => void;
};

export const PrivacyAndSecurityVisibility = ({
  currentPlan,
  visibility,
  handleVisibilityChange,
  handleUpsellClick,
}: PrivacyAndSecurityVisibilityProps) => (
  <ContentSection title="Visibility">
    {VISIBILITY_OPTIONS.map((option) => {
      const withUpsell =
        option.value === 'hidden_on_streamable' && !currentPlan;
      return (
        <SelectableOption
          key={option.value}
          label={
            <UpsellLabel
              enableUpsell={withUpsell}
              label={option.label}
              planName="Basic"
            />
          }
          secondaryLabel={<Text variant="secondary">{option.description}</Text>}
          handleSelect={(event) => {
            if (withUpsell) {
              handleUpsellClick('visibility');
            } else {
              handleVisibilityChange(
                event.currentTarget.value as PrivacyVisibility
              );
            }
          }}
          isSelected={option.value === visibility}
          name="visibility"
          value={option.value}
        />
      );
    })}
  </ContentSection>
);
