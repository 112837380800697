import React from 'react';

import { ActivatedSubscriptionInfo } from 'src/app/subscription/subscription-types';
import AnimatedLogo from 'src/assets/logo.gif';
import { ErrorMessage } from 'src/common/components/error-message/error-message';
import { Flex } from 'src/common/components/flex/flex';
import { Modal } from 'src/common/components/modal/modal';
import { ANNUAL_CADENCE } from 'src/pricing/constants';
import {
  AvailablePlan,
  AvailablePlanPrice,
  ChangePlanPreviewInvoice,
} from 'src/pricing/types';

import './change-plan-modal.css';
import {
  PaypalPreviewDescription,
  PreviewDescription,
} from './preview-description';

export type ChangePlanModalProps = {
  errorMessage?: string;
  isLoading: boolean;
  isShowing: boolean;
  isPayPalUser: boolean;
  previewInvoice: ChangePlanPreviewInvoice | null;
  currentPlan: ActivatedSubscriptionInfo | null;
  targetProduct?: AvailablePlan;
  targetProductPrice?: AvailablePlanPrice;
  handleClose: () => void;
  applyPlanChange: () => void;
};

export const generateModalTitle = (
  targetProduct: AvailablePlan,
  targetProductPrice: AvailablePlanPrice,
  isOnlySwitchingCadence: boolean
) => {
  if (isOnlySwitchingCadence) {
    const cadence =
      targetProductPrice.cadence === ANNUAL_CADENCE ? 'yearly' : 'monthly';
    return `You're switching to ${cadence} billing`;
  }

  return `You're switching to Streamable ${targetProduct.name}`;
};

export const ChangePlanModalComponent = ({
  currentPlan,
  errorMessage,
  isLoading,
  isShowing,
  isPayPalUser,
  previewInvoice,
  targetProduct,
  targetProductPrice,
  handleClose,
  applyPlanChange,
}: ChangePlanModalProps) => {
  if (!currentPlan || !targetProduct || !targetProductPrice) {
    return null;
  }
  const isOnlySwitchingCadence =
    currentPlan.id === targetProduct.monthly.id ||
    currentPlan.id === targetProduct.annual.id;

  const title = generateModalTitle(
    targetProduct,
    targetProductPrice,
    isOnlySwitchingCadence
  );

  return (
    <Modal
      id="change-plan-modal"
      isShowing={isShowing}
      onClose={handleClose}
      title={title}
      confirmLabel={isLoading && previewInvoice ? 'Confirming' : 'Confirm'}
      disableConfirm={!previewInvoice || isLoading}
      hideCancel={isPayPalUser}
      {...(isPayPalUser
        ? { hideConfirm: true }
        : { hideConfirm: false, onConfirm: applyPlanChange })}
    >
      <Flex direction="column">
        {isPayPalUser && <PaypalPreviewDescription />}
        {previewInvoice ? (
          <PreviewDescription
            currentPlan={currentPlan}
            targetProductPrice={targetProductPrice}
            invoice={previewInvoice!}
            isOnlySwitchingCadence={isOnlySwitchingCadence}
          />
        ) : (
          isLoading && (
            <Flex
              direction="row"
              alignItems="center"
              className="change-plan-modal__loading"
            >
              <img src={AnimatedLogo} alt="presentation" />
              <p className="change-plan-modal__loading__text">
                Loading your subscription details...
              </p>
            </Flex>
          )
        )}
        <ErrorMessage message={errorMessage} />
      </Flex>
    </Modal>
  );
};
