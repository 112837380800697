import React from 'react';

import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActivatedSubscriptionInfo,
  SubscriptionLimit,
} from 'src/app/subscription/subscription-types';
import { Flex } from 'src/common/components/flex/flex';
import { Modal, ModalProps } from 'src/common/components/modal/modal';
import { Text } from 'src/common/components/text/text';
import { EMAIL_ADDRESSES } from 'src/config';
import { AvailablePlan } from 'src/pricing/types';

import { Button } from '../../../common/components/button/button';
import { UpsellModalTitleUnderline } from './upsell-modal-title-underline';
import './upsell-modal.css';

export type UpsellModalComponentProps = Omit<ModalProps, 'id' | 'onConfirm'> & {
  currentPlan: ActivatedSubscriptionInfo | null;
  limits: { storage: SubscriptionLimit };
  targetPlanId: string | null;
  targetProduct?: AvailablePlan;
  onClickUpgrade: (productId: string) => void;
};

export const UpsellModalComponent = (props: UpsellModalComponentProps) => {
  const {
    onClose,
    limits: { storage },
    targetProduct,
    targetPlanId,
    onClickUpgrade,
  } = props;

  const storageFeature = targetProduct?.features.find((f) =>
    (f.label || f.description).endsWith('_storage')
  );

  const requiresCustomPlan = targetPlanId === 'custom';

  return (
    <Modal {...props} id="upsel-modal" hideCancel hideConfirm>
      <Flex
        className="upsell-modal"
        justifyContent="center"
        alignItems="center"
      >
        <FontAwesomeIcon
          role="button"
          className="upsell-modal__close"
          icon={faXmark}
          color="white"
          size="2x"
          onClick={() => onClose()}
        />
        <Flex className="upsell-modal__content">
          <div className="upsell-modal__content__heading">
            <Text as="h2">
              Unlock&nbsp;
              <span>
                Streamable {targetProduct?.name}
                <UpsellModalTitleUnderline />
              </span>
            </Text>
          </div>

          <p className="upsell-modal__content__prompt">
            You’ve hit the {storage && `${storage.limit}GB`} storage limit for
            your plan.&nbsp;
            {requiresCustomPlan
              ? 'To continue uploading videos talk to sales about our custom hosting plans.'
              : `Upgrade to Streamable ${targetProduct?.name} to get ${
                  storageFeature?.description || 'more cloud storage'
                } and continue
              uploading videos.`}
          </p>

          <div className="upsell-modal__content__error">
            <div>
              <FontAwesomeIcon icon={faTriangleExclamation} />
              <span>Upload failed</span>
            </div>
          </div>
          <div>
            {requiresCustomPlan ? (
              <>
                <a
                  className="upsell-modal__content__cta-link"
                  href={`mailto:${EMAIL_ADDRESSES.sales}?subject=Enterprise%20pricing`}
                >
                  Get in touch
                </a>
              </>
            ) : (
              <Button
                color="primary"
                variant="filled"
                className="upsell-modal__content__cta"
                onClick={() => {
                  if (targetPlanId) {
                    onClickUpgrade(targetPlanId);
                  } else {
                    console.error('Upsell Modal: No target plan ID provided');
                  }
                }}
              >
                Upgrade to {targetProduct?.name}
              </Button>
            )}
            <span className="upsell-modal__content__cancellation">
              Cancel anytime.
            </span>
          </div>

          <div>
            <Button
              className="upsell-modal__content__dismiss"
              variant="link"
              onClick={() => onClose()}
            >
              No thanks
            </Button>
          </div>
        </Flex>
      </Flex>
    </Modal>
  );
};
