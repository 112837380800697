import { UserOutput } from 'src/common/types/user';
import { apiRequest } from 'src/utils/api-request';

type UserSettingAttributes = {
  dark_mode: boolean;
  color: string;
  clear_watermark: boolean;
  watermark_link: string;
  remove_branding: boolean;
  terms_accepted: boolean;
};

export const getUser = () =>
  apiRequest<UserOutput>({
    path: `/me`,
    method: 'GET',
  });

export const updateUser = (attr: Partial<UserSettingAttributes>) =>
  apiRequest<UserOutput>({
    path: `/me`,
    method: 'PUT',
    body: attr,
  });
