import React, {
  ElementType,
  ComponentPropsWithRef,
  PropsWithChildren,
} from 'react';

import classNames from 'classnames';

import './text.css';

type TextProps<T extends ElementType> = {
  className?: string;
  as?: T;
  variant?: 'primary' | 'secondary';
};

type TextPropsGeneric<T extends ElementType> = TextProps<T> &
  Omit<ComponentPropsWithRef<T>, keyof TextProps<T>>;

export const Text = (<T extends ElementType = 'p'>({
  children,
  className,
  as,
  variant = 'primary',
  ...restOfProps
}: TextPropsGeneric<T>) => {
  const componentType = as || 'p';

  return React.createElement(
    componentType,
    {
      className: classNames('sb-text', `sb-text--${variant}`, className),
      ...restOfProps,
    },
    children
  );
}) as <T extends ElementType = 'p'>(
  props: PropsWithChildren<TextPropsGeneric<T>>
) => JSX.Element;
