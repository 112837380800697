import React from 'react';

import { calculateAnnualPrice } from 'src/checkout/utils/calculate-annual-price';
import { calculateMonthlyPrice } from 'src/checkout/utils/calculate-monthly-price';
import { Flex } from 'src/common/components/flex/flex';
import { LogoMark } from 'src/common/components/logo-mark/logo-mark';
import { useEffectOnce } from 'src/common/hooks/use-effect-once';
import { Plan } from 'src/pricing/components/plan';
import { AvailablePlan } from 'src/pricing/types';
import { triggerGoogleAnalyticsEvent } from 'src/utils/trigger-google-analytics-event';

import { SuggestedPlanFreeProps } from '../suggested-plan-free/suggested-plan-free';
import { PricingLink } from '../suggested-plan-free/suggested-plan-pricing-link';
import '../suggested-plan-free/suggested-plan.css';
import { ENTERPRISE_PLAN_NAME } from '../upsell/upsell-constants';

type SuggestedPlanPaidProps = SuggestedPlanFreeProps & {
  plan: AvailablePlan;
  withPricingLink?: boolean;
  triggerGtmEventOnMount?: boolean;
};

export const SuggestedPlanPaid = ({
  callToActionLabel,
  onClick,
  plan,
  withPricingLink = false,
  triggerGtmEventOnMount = true,
}: SuggestedPlanPaidProps) => {
  useEffectOnce(() => {
    if (triggerGtmEventOnMount) {
      triggerGoogleAnalyticsEvent('upsell_card_for_free_customers_rendered');
    }
  });

  return (
    <Flex as="article" className="suggested-plan">
      <Flex
        direction="row"
        alignItems="center"
        className="suggested-plan__title-container"
      >
        <LogoMark />
        <Plan.Title>{`Streamable ${plan.name}`}</Plan.Title>
      </Flex>
      {plan.name === ENTERPRISE_PLAN_NAME ? (
        <Plan.Price value="Custom" frequencyLabel="" />
      ) : (
        <Plan.Price
          value={`$${calculateAnnualPrice(plan)}`}
          duration="Billed yearly"
          secondaryLabel={`or $${calculateMonthlyPrice(plan)} billed monthly`}
        />
      )}
      <Plan.Description>{plan.description}</Plan.Description>
      <Plan.FeaturesList>
        {plan.features.map((item) => (
          <Plan.FeatureItem key={item.label}>
            {item.description}
          </Plan.FeatureItem>
        ))}
      </Plan.FeaturesList>
      <Plan.CallToAction onClick={onClick}>
        {callToActionLabel}
      </Plan.CallToAction>
      {withPricingLink && <PricingLink />}
    </Flex>
  );
};
