import React, { ChangeEventHandler, ReactNode } from 'react';

import classNames from 'classnames';
import { Flex } from 'src/common/components/flex/flex';
import { Text } from 'src/common/components/text/text';

import './selectable-option.css';

export type SelectableOptionProps = {
  disabled?: boolean;
  label: ReactNode;
  secondaryLabel?: ReactNode;
  children?: ReactNode;
  handleSelect: ChangeEventHandler<HTMLInputElement>;
  isSelected: boolean;
  name: string;
  value: string | number;
  size?: 'default' | 'slim';
};

export const SelectableOption = ({
  disabled = false,
  label,
  secondaryLabel,
  children,
  handleSelect,
  isSelected,
  name,
  size,
  value,
}: SelectableOptionProps) => (
  <label
    className={classNames('selectable-option', {
      'selectable-option--selected': isSelected,
      'selectable-option--disabled': disabled,
      'selectable-option--slim': size === 'slim',
    })}
  >
    <input
      type="radio"
      name={name}
      value={value}
      className="sr-only"
      checked={isSelected}
      onChange={handleSelect}
      data-testid={`${name}-${value}-radio`}
    />

    <div className="selectable-option__radio" />

    <Flex
      alignItems="center"
      direction="row"
      className="selectable-option__label-container"
    >
      <Text>{label}</Text>

      {secondaryLabel}
    </Flex>

    {children}
  </label>
);
