import './menu-item-group-component.css';

type MenuItemGroupProps = {
  label: string;
  children: React.ReactNode;
};

export const MenuItemGroup = ({ label, children }: MenuItemGroupProps) => (
  <div className="menu-item-group">
    <div className="menu-item-group__title">{label}</div>
    {children}
  </div>
);
