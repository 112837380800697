import React from 'react';
import { connect } from 'react-redux';

import { Flex } from 'src/common/components/flex/flex';
import { TextWithSplashExpression } from 'src/common/components/text-with-splash-expression/text-with-splash-expression';
import { Text } from 'src/common/components/text/text';
import { CadenceSwitch } from 'src/pricing/components/cadence-switch';
import { FreePlan } from 'src/pricing/components/plan/free-plan';
import { SelfServePlan } from 'src/pricing/components/plan/self-serve-plan';
import { selectAvailablePlans } from 'src/pricing/state/pricing-selectors';
import { AvailablePlan } from 'src/pricing/types';
import type { RootState } from 'src/redux';

import './plans.css';

type PlansComponentProps = {
  availablePlans: AvailablePlan[] | null;
};

const PlansComponent = ({ availablePlans }: PlansComponentProps) => (
  <section className="pricing-plans">
    <Flex
      alignItems="center"
      className="pricing-plans__cadence-switch-container"
    >
      <div className="pricing-plans__cadence-switch-container__copy-container">
        <Text className="pricing-plans__cadence-switch-container__copy-contaner__copy">
          <TextWithSplashExpression>
            Pay yearly and save on your monthly subscription costs.
          </TextWithSplashExpression>
        </Text>
      </div>
      <CadenceSwitch />
    </Flex>

    <div className="pricing-plans__content">
      <FreePlan />

      {availablePlans &&
        availablePlans.map((availablePlan, i) => (
          <SelfServePlan
            plan={availablePlan}
            key={`${availablePlan.name}-${i}`}
          />
        ))}
    </div>
  </section>
);

const mapStateToProps = (state: RootState) => ({
  availablePlans: selectAvailablePlans(state),
});

export const Plans = connect(mapStateToProps)(PlansComponent);
