import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';

import { extractFromUrl } from 'src/actions';
import { Logo } from 'src/common/components/logo';
import { selectAllMeState } from 'src/common/state/me/me-selectors';
import { selectIsIOs, selectIsMobile } from 'src/misc/selectors';
import { processUploadedFiles } from 'src/misc/utilities';

import './welcome.css';

const mapStateToProps = (state) => ({
  isIOs: selectIsIOs(state),
  me: selectAllMeState(state),
  isMobile: selectIsMobile(state),
});

class Welcome extends Component {
  fileInput = React.createRef();
  urlInput = React.createRef();
  componentDidMount() {
    document.onpaste = (e) => {
      if (e.target.id === 'primary-url-input') return;
      let paste = (e.clipboardData || window.clipboardData).getData('text');
      this.props.dispatch(extractFromUrl(paste));
      browserHistory.push(`/clipper?url=${paste}`);
    };
  }

  componentWillUnmount() {
    document.onpaste = null;
  }

  render() {
    return (
      <div className="welcome">
        <a className="home" href="https://streamable.com">
          <Logo type="black" />
        </a>
        <div className="uploader">
          <h1>Two ways to get started.</h1>
          <form style={{ display: 'none' }}>
            <input
              id="upload-input"
              multiple={!this.props.isIOs}
              type="file"
              ref={this.fileInput}
              accept={this.props.isMobile ? 'video/*' : null}
              onChange={() => {
                const files = this.fileInput.current.files;
                processUploadedFiles(
                  this.props.dispatch,
                  files,
                  null,
                  this.props.me.plan_max_length,
                  this.props.me.plan_max_size
                );
              }}
            />
          </form>
          <form
            className="form start-form"
            onSubmit={(e) => {
              e.preventDefault();
              this.props.dispatch(extractFromUrl(this.urlInput.current.value));
              browserHistory.push(
                `/clipper?url=${this.urlInput.current.value}`
              );
            }}
          >
            <div className="upload start-form-pane">
              <h2>Upload a video</h2>
              <p>
                Upload a video file (.mp4, .mov, .avi) from your computer or
                phone.
              </p>
              <input
                type="submit"
                style={{ visibility: 'hidden', position: 'absolute' }}
              />
              <button
                className="btn upload-button"
                onClick={(e) => {
                  e.preventDefault();
                  this.fileInput.current.click();
                }}
              >
                Select a video file
              </button>
            </div>
            <div className="clip start-form-pane">
              <h2>Clip from URL</h2>
              <p>Create a video clip from another video hosted on the web.</p>
              <div className="url-picker">
                <input
                  className="form-control"
                  id="primary-url-input"
                  type="text"
                  placeholder="Paste a video URL"
                  autoComplete="off"
                  ref={this.urlInput}
                  onPaste={(e) => {
                    let targetInput = e.target;
                    setTimeout(() => {
                      this.props.dispatch(extractFromUrl(targetInput.value));
                      browserHistory.push('/clipper?url=' + targetInput.value);
                    }, 0);
                  }}
                />
              </div>
            </div>
          </form>
          <div className="footer">
            <span>
              Don't have a video handy?{' '}
              <Link to="/clipper?url=https://streamable.com/z1plzy">
                Clip a sample video →
              </Link>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Welcome);
