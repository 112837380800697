import React from 'react';
import { Helmet } from 'react-helmet';

import { FAQ } from 'src/common/components/faq/faq';
import { PublicPageLayout } from 'src/common/components/public-page-layout/public-page-layout';
import { withProviders } from 'src/common/hocs/with-providers';

import ChangePlanModal from './components/change-plan-modal';
import { SalesLedPlan } from './components/plan/sales-led-plan';
import { Plans } from './components/plans/plans';
import { FREQUENTLY_ASKED_QUESTIONS } from './constants';
import './pricing.css';

const PricingComponent = () => (
  <PublicPageLayout className="pricing-page">
    <Helmet>
      <title>{window._STM_PREDATA.pricing.title}</title>
      <meta
        name="description"
        content={window._STM_PREDATA.pricing.description}
      ></meta>
      <link rel="canonical" href={`https://streamable.com/pricing`} />
    </Helmet>

    <header>
      <h1 className="pricing-page__title">Video hosting plans</h1>
      <p className="pricing-page__description">
        Start hosting videos for free. Upgrade to unlock more features.
      </p>
    </header>

    <Plans />

    <ChangePlanModal />

    <SalesLedPlan />

    <FAQ questions={FREQUENTLY_ASKED_QUESTIONS} />
  </PublicPageLayout>
);

export const Pricing = withProviders({
  isDarkModeSupported: false,
})(PricingComponent);
