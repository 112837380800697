import './menu-item-component';

type DarkModeToggleProps = {
  onDarkModeChanged: (darkMode: boolean) => void;
  darkMode: boolean;
};

export const DarkModeToggle = ({
  onDarkModeChanged,
  darkMode,
}: DarkModeToggleProps) => (
  <div
    className="user-menu-item user-menu-item--dark-mode-toggle"
    onClick={(e) => {
      e.stopPropagation();
      onDarkModeChanged(!darkMode);
    }}
  >
    <span className="user-menu-item__icon">
      <span className="material-icons">dark_mode</span>
    </span>{' '}
    <span className="user-menu-item__label">Dark Mode</span>
    <div className={`toggle ${darkMode ? 'selected' : ''}`}>
      <div className="toggle-knob"></div>
    </div>
  </div>
);
