import React from 'react';

import moment from 'moment';
import { ActivatedSubscriptionInfo } from 'src/app/subscription/subscription-types';
import { formatPrice } from 'src/checkout/utils/format-price';
import { Alert } from 'src/common/components/alert/alert';
import { ANNUAL_CADENCE } from 'src/pricing/constants';
import {
  AvailablePlanPrice,
  ChangePlanPreviewInvoice,
} from 'src/pricing/types';

import { isDowngrade } from '../plan/utils';

const UNITS_MULTIPLIER = 100;

const generateRenewalTextForUpgrade = (
  invoice: ChangePlanPreviewInvoice,
  targetProductPrice: AvailablePlanPrice
) => {
  const date = moment.unix(invoice.period_start);
  const onRenew = invoice.amount_on_renew / UNITS_MULTIPLIER;

  if (targetProductPrice.cadence === ANNUAL_CADENCE) {
    const monthlyPrice = onRenew / 12;

    return `This plan will renew every year on ${date.format(
      'MMM Do'
    )} for $${formatPrice(onRenew)} ($${formatPrice(
      monthlyPrice
    )} monthly) until you cancel.`;
  }

  return `This plan will renew every month for $${formatPrice(
    onRenew
  )} until you cancel.`;
};

const UpgradePreviewDescription = ({
  targetProductPrice,
  invoice,
}: {
  targetProductPrice: AvailablePlanPrice;
  invoice: ChangePlanPreviewInvoice;
}) => {
  const date = moment.unix(invoice.period_start);

  if (invoice.trialing) {
    return (
      <div>
        <p>
          Once your trial ends on {date.format('MMM Do')}, you will be charged $
          {invoice.amount_due / UNITS_MULTIPLIER}.
        </p>
        <p>{generateRenewalTextForUpgrade(invoice, targetProductPrice)}</p>
      </div>
    );
  }

  return (
    <div>
      <p>
        Starting today, you will be charged $
        {invoice.amount_due / UNITS_MULTIPLIER} (including a credit from your
        current plan).
      </p>
      <p>{generateRenewalTextForUpgrade(invoice, targetProductPrice)}</p>
    </div>
  );
};

const DowngradePreviewDescription = ({
  invoice,
  currentPlan,
  targetPlanPrice,
}: {
  invoice: ChangePlanPreviewInvoice;
  currentPlan: ActivatedSubscriptionInfo;
  targetPlanPrice: AvailablePlanPrice;
}) => {
  const date = moment.unix(invoice.period_start);

  if (invoice.trialing) {
    return (
      <div>
        <p>
          Once your trial ends on {date.format('MMM Do')}, you will be charged $
          {invoice!.amount_due / UNITS_MULTIPLIER}.
        </p>
        <p>{generateRenewalTextForUpgrade(invoice, targetPlanPrice)}</p>
      </div>
    );
  }

  const currentPlanPrice = `${currentPlan.price.toFixed(2)} ${
    currentPlan.cadence === ANNUAL_CADENCE ? 'yearly' : 'monthly'
  }`;

  const targetPlanCadence =
    targetPlanPrice.cadence === ANNUAL_CADENCE ? 'yearly' : 'monthly';

  const targetPlanCadenceUnit =
    targetPlanPrice.cadence === ANNUAL_CADENCE ? 'year' : 'month';

  return (
    <p>
      Starting on {date.format('MMM Do, YYYY')} (the end of your current plan),
      we will charge you ${invoice.amount_on_renew / UNITS_MULTIPLIER}{' '}
      {targetPlanCadence} instead of ${currentPlanPrice}. This plan will renew
      every {targetPlanCadenceUnit} until you cancel.
    </p>
  );
};

export const PaypalPreviewDescription = () => (
  <Alert level="danger">
    You can’t switch plan because you are on a legacy plan with PayPal billing.
    PayPal is no longer supported for new plans. To change your plan please{' '}
    <a href="/billing?cancel=true">cancel your subscription</a> and resubscribe
    or <a href="https://support.streamable.com/contact-us">contact support</a>{' '}
    for help
  </Alert>
);

export const PreviewDescription = ({
  currentPlan,
  targetProductPrice,
  invoice,
  isOnlySwitchingCadence,
}: {
  currentPlan: ActivatedSubscriptionInfo;
  targetProductPrice: AvailablePlanPrice;
  invoice: ChangePlanPreviewInvoice;
  isOnlySwitchingCadence: boolean;
}) => {
  const isUpgrade = isOnlySwitchingCadence
    ? targetProductPrice.cadence === ANNUAL_CADENCE
    : !isDowngrade(currentPlan, targetProductPrice);

  if (isUpgrade) {
    return (
      <UpgradePreviewDescription
        invoice={invoice}
        targetProductPrice={targetProductPrice}
      />
    );
  } else {
    return (
      <DowngradePreviewDescription
        invoice={invoice}
        currentPlan={currentPlan}
        targetPlanPrice={targetProductPrice}
      />
    );
  }
};
