import { connect } from 'react-redux';

import { pricingActions } from 'src/pricing/state/pricing-actions';
import { selectTargetCadence } from 'src/pricing/state/pricing-selectors';
import type { RootState } from 'src/redux';

import {
  CadenceSwitchComponent,
  CadenceSwitchComponentProps,
} from './cadence-switch-component';

const mapDispatchToProps = {
  changeCadence: pricingActions.cadenceSwitch.changeTargetCadenceToggle.clicked,
};

const mapStateToProps = (
  state: RootState,
  ownProps: Omit<CadenceSwitchComponentProps, 'targetCadence' | 'changeCadence'>
) => ({
  ...ownProps,
  targetCadence: selectTargetCadence(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CadenceSwitchComponent);
