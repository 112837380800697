import React from 'react';

import classNames from 'classnames';
import type { Location } from 'history';
import { ToastManager } from 'src/common/components/toast/toast-manager';
import { getCookie } from 'src/misc/cookies';

import App from '../app/App';
import { Landing as LandingNew } from '../landing-new';
import { ALL_SLUGS } from '../landing-new/slugs';
import { Landing } from '../landing/landing';
import { isUserLoggedIn } from '../utils/is-user-logged-in';

type AppWrapperProps = {
  params: {
    view: string;
  };
  location: Location;
};

const AppWrapper = ({ params, location }: AppWrapperProps) => {
  let view = params.view || null;

  if (view === null) {
    if (isUserLoggedIn() || getCookie('dashboard')) {
      view = 'videos';
    } else {
      return <Landing />;
    }
  }

  const shouldDisplayNewLanding = ALL_SLUGS.includes(location.pathname);

  return (
    <div
      className={classNames({
        s_p_a: !shouldDisplayNewLanding,
      })}
    >
      <ToastManager />
      {shouldDisplayNewLanding && <LandingNew slug={view} />}
      {!shouldDisplayNewLanding && (
        <App
          viewParams={{
            ...params,
            query: { ...location.query },
            view,
          }}
          key="app"
        />
      )}
    </div>
  );
};

export default AppWrapper;
