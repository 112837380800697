import React from 'react';

import classnames from 'classnames';

import './skeleton.css';

type SkeletonProps = Pick<
  React.CSSProperties,
  'marginBottom' | 'marginTop' | 'marginLeft' | 'marginRight' | 'width'
> & {
  animation?: 'wave';
  height: Required<React.CSSProperties>['height'];
  variant?: 'circular' | 'rectangular' | 'text';
  className?: string;
};

export const Skeleton = ({
  animation,
  variant = 'text',
  className,
  ...cssProps
}: SkeletonProps) => (
  <span
    className={classnames('sb-skeleton', `sb-skeleton--${variant}`, className, {
      'sb-skeleton-animated--wave': animation === 'wave',
    })}
    style={cssProps}
  />
);
