import { MEDIA_STATUS } from 'src/common/types/media';

import type { ReplaceVideoPartialResponseMetadata } from './replace-video-api-types';

export const optimisticUploadStartedState = (shortcode: string) => ({
  shortcode,
  status: MEDIA_STATUS.UPLOADING,
  thumbnail_url: null,
  poster_url: null,
  upload_percent: 0,
  percent: 0,
});

export const formatApiMetadataToActionParam = (
  metadata: ReplaceVideoPartialResponseMetadata
) => ({
  ...optimisticUploadStartedState(metadata.shortcode),
  upload_metadata: metadata,
  version: metadata.version,
  max_version: metadata.version,
  status: MEDIA_STATUS.UPLOADING,
});
