import { pipe, prop } from 'ramda';
import type { RootState } from 'src/redux';

const selectAllUpsellState = (state: RootState) => state.upsell;

const selectStorageUpsellModal = pipe(
  selectAllUpsellState,
  prop('storageUpsellModal')
);

export const selectStorageUpsellModalIsOpen = pipe(
  selectStorageUpsellModal,
  prop('isOpen')
);
