import React, {
  type PropsWithChildren,
  useState,
  useRef,
  type ReactNode,
} from 'react';
import { usePopper } from 'react-popper';

import { Popover } from '@headlessui/react';

import { InfoCircle } from '../icons/info-circle';
import './tooltip.css';

type TooltipProps = PropsWithChildren<{
  type?: 'info';
  trigger?: ReactNode;
  interactive?: boolean;
}>;

export const Tooltip = ({
  children,
  type,
  trigger,
  interactive,
}: TooltipProps) => {
  /**
   * The use of useState is intentional for the elements as explained on popper's docs:
   * https://popper.js.org/react-popper/v2/#example -- see the bottom of the example
   * Copying it here just to see the explanation here
   * Note: the usePopper hook intentionally takes the DOM node, not refs,
   * in order to be able to update when the nodes change.
   * A callback ref is used here to permit this behaviour, and
   * useState is an appropriate way to implement this.
   */
  const popoverTriggerRef = useRef<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const { styles, attributes } = usePopper(
    popoverTriggerRef.current,
    popperElement,
    {
      placement: 'top-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 2],
          },
        },
      ],
    }
  );

  return (
    <Popover className="sb-tooltip">
      {({ open, close }) => (
        <>
          <Popover.Button
            className="sb-tooltip__trigger"
            ref={popoverTriggerRef}
            onMouseEnter={() => popoverTriggerRef.current?.click()}
            onMouseLeave={() => {
              if (!interactive) {
                close();
              }
            }}
          >
            {trigger && <>{trigger}</>}
            {type === 'info' && (
              <InfoCircle variant={open ? 'solid' : 'outline'} />
            )}
          </Popover.Button>
          <Popover.Panel
            className="sb-tooltip__panel"
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            {/* @ts-ignore - hopefully will be solved after react-popper + v18 update */}
            {children}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};
