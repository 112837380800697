import { connect } from 'react-redux';

import { embedPreviewActions } from 'src/dashboard/state/embed-preview/embed-preview-actions';
import {
  selectIsEmbedPreviewModalOpen,
  selectEmbedPlayerOptions,
  selectEmbedContainerOptions,
  selectEmbedVideo,
  selectEmbedCodeCopied,
} from 'src/dashboard/state/embed-preview/embed-preview-selectors';
import type { RootState } from 'src/redux';

import { EmbedPreviewModalComponent } from './embed-preview-modal-component';

const mapStateToProps = (state: RootState) => ({
  isShowing: selectIsEmbedPreviewModalOpen(state),
  playerOptions: selectEmbedPlayerOptions(state),
  containerOptions: selectEmbedContainerOptions(state),
  disabled: selectEmbedCodeCopied(state),
  shortcode: selectEmbedVideo(state)?.shortcode,
});

const mapDispatchToProps = {
  changeContainerHeight:
    embedPreviewActions.embedPreviewModal.changeContainerHeight,
  changeContainerWidth:
    embedPreviewActions.embedPreviewModal.changeContainerWidth,
  changeContainerSizeType:
    embedPreviewActions.embedPreviewModal.changeContainerSizeType,
  codeCopied: embedPreviewActions.embedPreviewModal.codeCopied,
  onClose: embedPreviewActions.embedPreviewModal.closed,
  togglePlayerOption: embedPreviewActions.embedPreviewModal.togglePlayerOption,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmbedPreviewModalComponent);
