import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import classNames from 'classnames';
import { CaretDown, List, X } from 'phosphor-react';
import { Button } from 'src/common/components/button/button';
import { Flex } from 'src/common/components/flex/flex';
import { Logo } from 'src/common/components/logo';
import { selectIsLoggedIn } from 'src/common/state/me/me-selectors';
import type { RootState } from 'src/redux';
import { isHamburgerNavigationOn } from 'src/utils/is-hamburger-navigation-on';

import { NAVIGATION_LINKS } from '../../constants';
import { NavigationBarSubMenuSection } from '../navigation-bar-sub-menu-section/navigation-bar-sub-menu-section';
import './navigation-bar.css';

export type NavigationBarVariant = 'navy';

type NavigationBarProps = {
  isLoggedIn: boolean;
  variant?: NavigationBarVariant;
};

const NavigationBarComponent = ({
  isLoggedIn,
  variant,
}: NavigationBarProps) => {
  const [isMobileNavigationOpen, setIsMobileNavigationOpen] = useState(false);
  const [isToolsSubMenuOpen, setIsToolsSubMenuOpen] = useState(false);

  const handleToggleNavigation = useCallback(
    (toBeOpen) => {
      setIsMobileNavigationOpen(toBeOpen);
    },
    [setIsMobileNavigationOpen]
  );

  useEffect(() => {
    const closeNavigationOnMobile = () => {
      if (isHamburgerNavigationOn()) {
        handleToggleNavigation(false);
      }
    };

    window.addEventListener('resize', closeNavigationOnMobile);

    return () => {
      window.removeEventListener('resize', closeNavigationOnMobile);
    };
  }, [handleToggleNavigation]);

  const handleMobileMenuButtonClick = () => {
    handleToggleNavigation(!isMobileNavigationOpen);
    setIsToolsSubMenuOpen(false);
  };

  const handleMenuItemClick = () => {
    handleToggleNavigation(false);
    if (isToolsSubMenuOpen) {
      setIsToolsSubMenuOpen(false);
    }
  };

  const handleToolsMenuBtnClick = () =>
    setIsToolsSubMenuOpen(!isToolsSubMenuOpen);

  const handleToolsSubMenuButtonMouseEnter = useCallback(() => {
    if (!isHamburgerNavigationOn()) {
      setIsToolsSubMenuOpen(true);
    }
  }, [setIsToolsSubMenuOpen]);

  const handleToolsSubMenuButtonMouseLeave = useCallback(() => {
    if (!isHamburgerNavigationOn()) {
      setIsToolsSubMenuOpen(false);
    }
  }, [setIsToolsSubMenuOpen]);

  return (
    <nav
      className={classNames('navigation-bar-v2', {
        'navigation-bar-v2--navy': variant === 'navy',
      })}
    >
      <div
        className={classNames('navigation-bar-v2__content', {
          'navigation-bar-v2__content--open': isMobileNavigationOpen,
        })}
      >
        <div className="navigation-bar-v2__logo-container">
          <Link
            to="/"
            className="navigation-bar-v2__logo-link navigation-bar-v2__logo-link--mobile"
            onClick={handleMenuItemClick}
          >
            <Logo className="navigation-bar-v2__logo" />
          </Link>
        </div>

        <Button
          className="navigation-bar-v2__menu-btn"
          onClick={handleMobileMenuButtonClick}
          variant="icon"
        >
          {isMobileNavigationOpen ? (
            <X size={22} weight="bold" />
          ) : (
            <List size={22} weight="bold" />
          )}
        </Button>

        <ul
          className={classNames('navigation-bar-v2__menu', {
            'navigation-bar-v2__menu--open': isMobileNavigationOpen,
          })}
        >
          <li className="navigation-bar-v2__menu-item navigation-bar-v2__menu-item--logo">
            <Link
              to="/"
              className="navigation-bar-v2__logo-link navigation-bar-v2__logo"
              onClick={handleMenuItemClick}
            >
              <Logo />
            </Link>
          </li>
          <li className="navigation-bar-v2__menu-item">
            <Button
              className={classNames('navigation-bar-v2__sub-menu-btn', {
                'navigation-bar-v2__sub-menu-btn--active': isToolsSubMenuOpen,
              })}
              onClick={handleToolsMenuBtnClick}
              onMouseEnter={handleToolsSubMenuButtonMouseEnter}
              onMouseLeave={handleToolsSubMenuButtonMouseLeave}
              variant="text"
            >
              Tools
              <CaretDown
                className="navigation-bar-v2__sub-menu-btn-icon"
                size={22}
                weight="bold"
              />
            </Button>

            <div
              className={classNames('navigation-bar-v2__sub-menu', {
                'navigation-bar-v2__sub-menu--open': isToolsSubMenuOpen,
              })}
              onMouseEnter={handleToolsSubMenuButtonMouseEnter}
              onMouseLeave={handleToolsSubMenuButtonMouseLeave}
            >
              <Flex className="navigation-bar-v2__sub-menu__content">
                {Object.values(NAVIGATION_LINKS).map((section, i) => (
                  <NavigationBarSubMenuSection
                    key={`navigation-bar-sub-menu-section-${i}`}
                    title={section.title}
                    links={section.links.map((link) => ({
                      ...link,
                      slug: link.href || '',
                    }))}
                    onClick={handleMenuItemClick}
                  />
                ))}
              </Flex>
            </div>
          </li>
          <li className="navigation-bar-v2__menu-item">
            <Link
              to="/pricing?src_internal=landing-navbar-link"
              className="navigation-bar-v2__link"
              onClick={handleMenuItemClick}
            >
              Pricing
            </Link>
          </li>
          <li className="navigation-bar-v2__menu-item">
            <Link
              to="/blog"
              className="navigation-bar-v2__link"
              onClick={handleMenuItemClick}
            >
              Blog
            </Link>
          </li>
          {!isLoggedIn && (
            <li className="navigation-bar-v2__menu-item navigation-bar-v2__menu-item--no-border">
              <Link
                to="/login"
                className="navigation-bar-v2__link"
                onClick={handleMenuItemClick}
              >
                Log in
              </Link>
            </li>
          )}
          <li className="navigation-bar-v2__menu-item navigation-bar-v2__menu-item--no-border">
            {isLoggedIn ? (
              <Link
                to="/"
                className="navigation-bar-v2__link navigation-bar-v2__link--cta"
              >
                Open Streamable
              </Link>
            ) : (
              <Link
                to="/signup?signup_src=landing"
                className="navigation-bar-v2__link navigation-bar-v2__link--cta"
                onClick={handleMenuItemClick}
              >
                Sign up for free
              </Link>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: selectIsLoggedIn(state),
});

export const NavigationBar = connect(mapStateToProps)(NavigationBarComponent);
