import React, { useState } from 'react';
import { FaTrash } from 'react-icons4/fa';
import { Link } from 'react-router';

import {
  faTrash,
  faFilm,
  faTimes,
  faTag,
  faShare,
  faCircleNotch,
  faEllipsis,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { List } from 'immutable';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { Button } from 'src/common/components/button/button';
import { TextWithSplashExpression } from 'src/common/components/text-with-splash-expression/text-with-splash-expression';
import { VideoRepresentation } from 'src/dashboard/types';
import { getShortcodesFromVideosList } from 'src/dashboard/utils/get-shortcodes-from-videos-list';
import DropDown from 'src/misc/dropdown/DropDown';
import { Modal, ModalFooter, ModalBody } from 'src/misc/modals/modals';

import './video-list-header.css';

type VideoListHeaderStateProps = {
  createCollectionIsLoading: boolean;
  isDarkModeEnabled: boolean;
  selectedVideos: List<VideoRepresentation>;
  withSidebar: boolean;
};

type VideoListHeaderDispatchProps = {
  handleBulkEditLabels: (shortcodes: string[]) => void;
  handleCreateCollection: (shorcodes: string[]) => void;
  handleDeselectAll: () => void;
  handleDeleteSelectedVideos: () => void;
};

type VideoListHeaderProps = VideoListHeaderStateProps &
  VideoListHeaderDispatchProps;

export const VideoListHeaderComponent = ({
  createCollectionIsLoading,
  isDarkModeEnabled,
  selectedVideos,
  withSidebar,
  handleBulkEditLabels,
  handleCreateCollection,
  handleDeleteSelectedVideos,
  handleDeselectAll,
}: VideoListHeaderProps) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  const selectedVideosShortcodes = getShortcodesFromVideosList(selectedVideos);

  const selectedVideoCount = selectedVideos.size;
  const isMoreThanOneVideoSelected = selectedVideoCount > 1;
  const videoLabel = `item${isMoreThanOneVideoSelected ? 's' : ''}`;

  return (
    <>
      <Modal
        isOpen={deleteModalOpen}
        toggle={toggleDeleteModal}
        title="Delete Confirmation"
        className="app-modal"
        darkMode={isDarkModeEnabled}
      >
        <ModalBody>
          Are you sure you want to{' '}
          <b style={{ fontWeight: 600 }}>
            delete {selectedVideoCount} {videoLabel}
          </b>
          ?
        </ModalBody>
        <ModalFooter>
          <button
            className="blue-button"
            onClick={() => {
              toggleDeleteModal();
              handleDeleteSelectedVideos();
            }}
          >
            Delete
          </button>
        </ModalFooter>
      </Modal>
      {selectedVideoCount > 0 ? (
        <div className="card video-list-actions">
          <Button
            className="video-list-action close-action leftmost"
            onClick={handleDeselectAll}
            variant="icon"
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <div className="selection-indicator">
            {selectedVideoCount} {videoLabel}{' '}
            <span className="desktop">selected</span>
          </div>
          {withSidebar && selectedVideoCount > 0 && (
            <Button
              className="video-list-action video-list-action--btn"
              onClick={() => {
                handleBulkEditLabels(selectedVideosShortcodes);
              }}
            >
              <FontAwesomeIcon icon={faTag} />
              <span className="tablet">Edit Labels</span>
            </Button>
          )}

          <div className="video-list-actions__merge">
            <Tooltip
              overlay="Select another video to merge"
              placement="bottom"
              trigger={isMoreThanOneVideoSelected ? [] : 'hover'}
            >
              <div>
                <Link
                  className={classNames('video-list-action', {
                    'video-list-action--disabled': !isMoreThanOneVideoSelected,
                  })}
                  aria-disabled={!isMoreThanOneVideoSelected}
                  to="/merge"
                >
                  <FontAwesomeIcon icon={faFilm} />
                  <span className="tablet">Merge</span>
                </Link>
              </div>
            </Tooltip>
          </div>
          <Tooltip
            overlay="Select another video to share"
            placement="bottom"
            trigger={isMoreThanOneVideoSelected ? [] : 'hover'}
          >
            <div>
              <Button
                className={classNames(
                  'video-list-action',
                  'video-list-action--btn',
                  'video-list-action--btn--share',
                  {
                    'video-list-action--disabled': !isMoreThanOneVideoSelected,
                  }
                )}
                onClick={() => handleCreateCollection(selectedVideosShortcodes)}
                disabled={
                  createCollectionIsLoading || !isMoreThanOneVideoSelected
                }
              >
                <TextWithSplashExpression
                  showSplashExpression={isMoreThanOneVideoSelected}
                >
                  {createCollectionIsLoading ? (
                    <FontAwesomeIcon
                      icon={faCircleNotch}
                      className="labels-loader__icon"
                    />
                  ) : (
                    <FontAwesomeIcon icon={faShare} />
                  )}
                  <span className="video-list-action--btn--share__text">
                    Share
                  </span>
                </TextWithSplashExpression>
              </Button>
            </div>
          </Tooltip>
          <div className="video-list-actions__delete">
            <Button
              className="video-list-action video-list-action--btn"
              onClick={toggleDeleteModal}
            >
              <FontAwesomeIcon icon={faTrash} />
              <span className="tablet">Delete</span>
            </Button>
          </div>

          <div className="video-list-actions__overflow">
            <DropDown
              items={[
                {
                  onClick: toggleDeleteModal,
                  title: 'Delete',
                  icon: <FaTrash />,
                },
              ]}
            >
              <FontAwesomeIcon icon={faEllipsis} />
            </DropDown>
          </div>
        </div>
      ) : null}
    </>
  );
};
