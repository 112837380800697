import type { PayloadAction } from '@reduxjs/toolkit';

import { dashboardUpgradeActions } from './upgrade-actions';

export type UpgradeState = {
  upgradeModal: {
    isOpen: boolean;
  };
};

const initialState: UpgradeState = {
  upgradeModal: {
    isOpen: false,
  },
};

export const upgradeReducer = (
  state = initialState,
  action: PayloadAction<any>
): UpgradeState => {
  const { type } = action;
  switch (type) {
    case dashboardUpgradeActions.upgradeModal.triggered.type:
      return {
        ...state,
        upgradeModal: {
          ...state.upgradeModal,
          isOpen: true,
        },
      };

    case dashboardUpgradeActions.upgradeModal.closed.type:
      return {
        ...state,
        upgradeModal: {
          ...state.upgradeModal,
          isOpen: false,
        },
      };

    default:
      return state;
  }
};
