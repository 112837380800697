import React from 'react';
import { Link } from 'react-router';

import { Flex } from 'src/common/components/flex/flex';
import { Logo } from 'src/common/components/logo';

import './checkout-navbar.css';

export const CheckoutNavBar = () => (
  <Flex className="checkout-navbar">
    <Link to="/">
      <Logo type="primary" />
    </Link>
  </Flex>
);
