import type { PayloadAction } from '@reduxjs/toolkit';
import { List, Map } from 'immutable';
import {
  editVideoAction,
  messageAction,
  shortcodeReceivedAction,
  pollingUpdateReceivedAction,
  uploadProgressAction,
  uploadAction,
  fetchVideoAction,
  retranscodeAction,
  clearVideosAction,
} from 'src/actions';
import { dashboardAssignLabelsActions } from 'src/dashboard/state/assign-labels/assign-labels-actions';
import { dashboardSidebarActions } from 'src/dashboard/state/labels/labels-actions';
import { getVideos } from 'src/dashboard/state/videos/videos-selectors';
import type { Label, VideoRepresentation } from 'src/dashboard/types';
import type { RootState } from 'src/redux';
import { PRIVACY_SETTING } from 'src/settings/constants';

import { videoPrivacyActions } from '../video-privacy/video-privacy-actions';
import { videosActions } from './videos-actions';
import {
  replaceVideo,
  setVideo,
  setVideos,
  syncVideos,
  type VideoKey,
} from './videos-helpers';
import type { VideosState, VideoMap } from './videos-types';

const initialState: VideosState = {
  isError: false,
  isLoading: true,
  shortcodes: List(),
  total: 0,
  sort: 'date_added',
  sortd: 'DESC',
  count: 16,
  page: 1,
  search: null,
  group_id: null,
  cache: Map(),
  uploads: List(),
  pending: List(),
  loaded: false,
};

export const videosReducer = (
  state = initialState,
  action: PayloadAction<any> & { error?: string }
): VideosState => {
  const { type, payload, error } = action;

  switch (type) {
    case pollingUpdateReceivedAction.type:
      return {
        ...state,
        cache: setVideos(
          state.cache,
          payload.map((item: VideoRepresentation) =>
            Map(item).delete('title').delete('labels').toObject()
          )
        ),
      };

    case videosActions.upload.createVideo.type:
      const video: VideoRepresentation & { local_id?: string } = payload;
      if (video.local_id) {
        return {
          ...state,
          total: state.total + 1,
          pending: state.pending.unshift(video.local_id),
          cache: replaceVideo(
            state.cache,
            {
              ...video,
              status: video.status || 0,
              date_added: Math.floor(Date.now() / 1000),
              new_upload: true,
            },
            'local_id' as VideoKey
          ),
        };
      } else if (video.shortcode) {
        return {
          ...state,
          uploads: state.uploads.unshift(video.shortcode),
          shortcodes: state.shortcodes.unshift(video.shortcode),
          cache: setVideo(
            state.cache,
            Map<VideoKey, any>(video)
              .set('status', payload.status || 0)
              .set('new_upload', true)
          ),
        };
      } else {
        return state;
      }

    case shortcodeReceivedAction.type: {
      const updatedVideo = Map(payload.video)
        .delete('title')
        .set('shortcode', payload.shortcode)
        .set('upload_metadata', payload)
        .set('local_id', payload.local_id) as VideoMap;

      return {
        ...state,
        uploads: state.uploads.unshift(payload.shortcode),
        shortcodes: state.shortcodes.unshift(payload.shortcode),
        pending: state.pending.delete(state.pending.indexOf(payload.local_id)),
        cache: setVideo(
          state.cache,
          updatedVideo,
          'shortcode',
          'local_id' as VideoKey
        ),
      };
    }
    case clearVideosAction.type:
      return {
        ...state,
        shortcodes: state.shortcodes.clear(),
        loaded: false,
        total: 0,
        sort: 'date_added',
        sortd: 'DESC',
        count: 16,
        page: 1,
        search: null,
        group_id: null,
      };

    case videosActions.dashboard.fetchVideos.initiated.type:
      return {
        ...state,
        ...payload,
        isError: false,
        isLoading: true,
      };

    case videosActions.dashboard.fetchVideos.fulfilled.type: {
      const { total, videos } = payload;
      const newState = syncVideos(state.cache, videos);

      return {
        ...state,
        ...newState,
        isLoading: false,
        loaded: true,
        total,
      };
    }

    case videosActions.dashboard.fetchVideos.failed.type:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };

    case retranscodeAction.type:
      return {
        ...state,
        cache: setVideo(
          state.cache,
          Map(payload).set('files', null) as VideoMap
        ),
      };

    case uploadProgressAction.type:
    case fetchVideoAction.type:
    case uploadAction.type:
      if (!error && payload.shortcode) {
        return {
          ...state,
          cache: setVideo(state.cache, payload),
        };
      }
      return state;

    case editVideoAction.type:
      return {
        ...state,
        cache: payload.shortcode
          ? setVideo(state.cache, payload)
          : setVideo(
              state.cache,
              payload,
              'local_id' as VideoKey,
              'local_id' as VideoKey
            ),
      };

    case videosActions.deleteVideo.type:
      if (action.payload.isDeleting) {
        return state;
      }

      if (action.error) {
        return state;
      }

      let { uploads, pending, shortcodes, cache } = state;
      if (payload.local_id) {
        const uploadIdx = uploads.indexOf(payload.local_id);
        const pendingIdx = pending.indexOf(payload.local_id);
        uploads = uploadIdx >= 0 ? uploads.delete(uploadIdx) : uploads;
        pending = pendingIdx >= 0 ? pending.delete(pendingIdx) : pending;
        cache = cache.delete(payload.local_id);
      }
      if (payload.shortcode) {
        const uploadIdx = uploads.indexOf(payload.shortcode);
        const shortcodeIdx = shortcodes.indexOf(payload.shortcode);
        uploads = uploadIdx >= 0 ? uploads.delete(uploadIdx) : uploads;
        shortcodes =
          shortcodeIdx >= 0 ? shortcodes.delete(shortcodeIdx) : shortcodes;
        cache = cache.delete(payload.shortcode);
      }
      return { ...state, pending, uploads, shortcodes, cache };

    case messageAction.type:
      if (payload.type === 'video') {
        // ignore title changes so we don't overwrite
        delete payload.payload.title;
        // ignore labels changes so we don't overwrite
        delete payload.payload.labels;
        //only do current version
        const video = state.cache.get(payload.payload.shortcode);

        if (video && video.get('max_version') === payload.payload.version) {
          return {
            ...state,
            cache: setVideo(state.cache, payload.payload),
          };
        }
      }

      return state;

    case dashboardSidebarActions.deleteLabelModal.saveLabelFulfilled.type: {
      const videos = getVideos({ videos: state } as RootState);

      const updatedVideos = videos.map((video) => ({
        ...video,
        labels: (video.labels ?? []).filter((label) => label.id !== payload),
      }));

      return {
        ...state,
        cache: setVideos(state.cache, updatedVideos),
      };
    }

    case dashboardAssignLabelsActions.assignLabelsModal.saveAssignmentsFulfilled
      .type: {
      const { shortcode, labelIdsToAdd, labelIdsToRemove } = payload;

      const video = state.cache.get(shortcode);

      if (!video) {
        return state;
      }

      const labels: Array<Label> = video.get('labels') ?? [];
      const updatedLabels: Array<Label> = [
        ...labels.filter((label) => !labelIdsToRemove.includes(label.id)),
        ...labelIdsToAdd.map((labelId: string) => ({ id: labelId })),
      ];

      const updatedVideo = video.set('labels', updatedLabels);

      return {
        ...state,
        cache: setVideo(state.cache, updatedVideo),
      };
    }

    case dashboardAssignLabelsActions.dragAndDropAssign.saveFulfilled.type: {
      const { shortcodes, labelId } = payload as {
        shortcodes: string[];
        labelId: number;
      };

      const cache = shortcodes.reduce((result, shortcode) => {
        const video = state.cache.get(shortcode);

        if (!video) {
          return result;
        }

        const labels: Array<Label> = video.get('labels') ?? [];
        if (labels.some((label) => label.id === labelId)) {
          return result;
        }

        const updatedVideo = video.set('labels', [...labels, { id: labelId }]);

        return setVideo(result, updatedVideo);
      }, state.cache);

      return {
        ...state,
        cache,
      };
    }

    case videoPrivacyActions.videoPrivacyModal.privacySection.visibility.changed
      .type:
    case videoPrivacyActions.videoPrivacyModal.privacySection.visibility.errored
      .type: {
      const { shortcode, visibility } = payload;
      const video = state.cache.get(shortcode);

      if (!video) {
        return state;
      }

      const updatedVideo = video.set('privacy_settings', {
        ...video.get('privacy_settings'),
        visibility,
      });

      return {
        ...state,
        cache: setVideo(state.cache, updatedVideo),
      };
    }

    case videoPrivacyActions.videoPrivacyModal.restrictionSection
      .domainRestrictions.toggled.type:
    case videoPrivacyActions.videoPrivacyModal.restrictionSection
      .domainRestrictions.errored.type: {
      const video = state.cache.get(payload);

      if (!video) {
        return state;
      }

      const privacySettings = video.get('privacy_settings');
      const updatedVideo = video.set('privacy_settings', {
        ...privacySettings,
        domain_restrictions:
          privacySettings?.domain_restrictions === 'allowlist'
            ? 'off'
            : 'allowlist',
      });

      return {
        ...state,
        cache: setVideo(state.cache, updatedVideo),
      };
    }

    case videoPrivacyActions.videoPrivacyModal.restrictionSection
      .allowedDomainInput.changed.type: {
      const { shortcode, allowedDomain } = payload;
      const video = state.cache.get(shortcode);

      if (!video) {
        return state;
      }

      const updatedVideo = video.set('privacy_settings', {
        ...video.get('privacy_settings'),
        allowed_domain: allowedDomain,
      });

      return {
        ...state,
        cache: setVideo(state.cache, updatedVideo),
      };
    }

    case videoPrivacyActions.videoPrivacyModal.restrictionSection
      .passwordProtection.turnedOn.type: {
      const shortcode = payload;
      const video = state.cache.get(shortcode);

      if (!video) {
        return state;
      }

      const updatedVideo = video.set('privacy_settings', {
        ...video.get('privacy_settings'),
        password_protected: true,
      });

      return {
        ...state,
        cache: setVideo(state.cache, updatedVideo),
      };
    }

    case videoPrivacyActions.videoPrivacyModal.restrictionSection
      .passwordProtection.turnedOff.type: {
      const shortcode = payload;
      const video = state.cache.get(shortcode);

      if (!video) {
        return state;
      }

      const updatedVideo = video
        .set('privacy', PRIVACY_SETTING.BY_LINK)
        .set('privacy_settings', {
          ...video.get('privacy_settings'),
          password_protected: false,
        });

      return {
        ...state,
        cache: setVideo(state.cache, updatedVideo),
      };
    }

    case videoPrivacyActions.videoPrivacyModal.playerPreferencesSection
      .allowDownload.toggled.type:
    case videoPrivacyActions.videoPrivacyModal.playerPreferencesSection
      .allowDownload.errored.type: {
      const video = state.cache.get(payload);

      if (!video) {
        return state;
      }

      const privacySettings = video.get('privacy_settings');
      const updatedVideo = video.set('privacy_settings', {
        ...privacySettings,
        allow_download: !privacySettings?.allow_download,
      });

      return {
        ...state,
        cache: setVideo(state.cache, updatedVideo),
      };
    }

    case videoPrivacyActions.videoPrivacyModal.playerPreferencesSection
      .allowSharing.toggled.type:
    case videoPrivacyActions.videoPrivacyModal.playerPreferencesSection
      .allowSharing.errored.type: {
      const video = state.cache.get(payload);

      if (!video) {
        return state;
      }

      const privacySettings = video.get('privacy_settings');
      const updatedVideo = video.set('privacy_settings', {
        ...privacySettings,
        allow_sharing: !privacySettings?.allow_sharing,
      });

      return {
        ...state,
        cache: setVideo(state.cache, updatedVideo),
      };
    }

    case videoPrivacyActions.videoPrivacyModal.playerPreferencesSection
      .hideViewCount.toggled.type:
    case videoPrivacyActions.videoPrivacyModal.playerPreferencesSection
      .hideViewCount.errored.type: {
      const video = state.cache.get(payload);

      if (!video) {
        return state;
      }

      const privacySettings = video.get('privacy_settings');
      const updatedVideo = video.set('privacy_settings', {
        ...privacySettings,
        hide_view_count: !privacySettings?.hide_view_count,
      });

      return {
        ...state,
        cache: setVideo(state.cache, updatedVideo),
      };
    }

    default:
      return state;
  }
};
