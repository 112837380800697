import { browserHistory } from 'react-router';

import { all, call, put, takeEvery } from 'redux-saga/effects';

import { dashboardUpgradeActions } from '../upgrade/upgrade-actions';
import { playerPagesActions } from './player-pages-actions';

export function* handlePlayerPageMessage({
  payload: { type },
}: ReturnType<typeof playerPagesActions.receiveMessage>) {
  switch (type) {
    case 'dashboard_back': {
      yield call(browserHistory.push, '/');
      break;
    }
    case 'trigger_upgrade_modal': {
      yield put(dashboardUpgradeActions.upgradeModal.triggered());
      break;
    }
    default: {
      console.error(
        `Received an unknown message type from the player page: ${type}`
      );
    }
  }
}

export function* watchPlayerPagesSaga() {
  yield all([
    takeEvery(playerPagesActions.receiveMessage, handlePlayerPageMessage),
  ]);
}
