import { connect } from 'react-redux';

import {
  login,
  signup,
  clearVideosAction,
  editUserAction,
  externalConnect,
} from 'src/actions';
import { selectAllMeState } from 'src/common/state/me/me-selectors';
import type { RootState, AppDispatch } from 'src/redux';

import SignIn from './sign-in-widget-component';

const mapStateToProps = (state: RootState) => ({ me: selectAllMeState(state) });

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  reset: () => dispatch(editUserAction({ error: null, message: null })),

  clearVideos: () => dispatch(clearVideosAction()),

  externalConnect: (token: string, email: string, provider: string) =>
    dispatch(externalConnect(token, email, provider)),

  authenticate: (
    isLogin: boolean,
    username: string,
    password: string,
    verificationRedirect: string
  ) => {
    const authAction = isLogin
      ? login(username, password)
      : signup(username, password, username, verificationRedirect);
    return dispatch(authAction);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
