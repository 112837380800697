import type { PayloadAction } from '@reduxjs/toolkit';

import { dashboardSuggestionsActions } from './suggestions-actions';
import { ShowUpsellCardParams } from './suggestions-types';

export type SuggestionsState = {
  suggestions: {
    [key: string]: {
      isDismissed: boolean;
    };
  };
  upsell: ShowUpsellCardParams & {
    isShown: boolean;
  };
};

const initialState: SuggestionsState = {
  suggestions: {},
  upsell: {
    isShown: false,
    title: '',
    callToActionLabel: '',
    text: '',
    dispatchActionTypeOnClick: '',
  },
};

export const suggestionsReducer = (
  state = initialState,
  action: PayloadAction<any>
): SuggestionsState => {
  switch (action.type) {
    case dashboardSuggestionsActions.suggestionCard.shown.type: {
      const payload = action.payload as ShowUpsellCardParams;
      return {
        ...state,
        upsell: {
          ...payload,
          isShown: true,
        },
      };
    }

    case dashboardSuggestionsActions.suggestionCard.closed.type: {
      return {
        ...state,
        upsell: {
          ...initialState.upsell,
          isShown: false,
        },
      };
    }

    case dashboardSuggestionsActions.suggestionCard.dismissButton.clicked
      .type: {
      const payload = action.payload as string;
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          [payload]: {
            isDismissed: true,
          },
        },
      };
    }

    default:
      return state;
  }
};
