import React, { useState } from 'react';

import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert } from 'src/common/components/alert/alert';
import { Button } from 'src/common/components/button/button';
import { getCookie, setCookie } from 'src/misc/cookies';

import './stale-videos-alert.css';

type StaleVideosAlertProps = {
  numberOfStaleVideos: number;
  showPlanPicker: () => void;
};

export const StaleVideosAlert = ({
  numberOfStaleVideos,
  showPlanPicker,
}: StaleVideosAlertProps) => {
  const [isShowing, setIsShowing] = useState(
    !getCookie('hideStaleNotification')
  );

  if (!isShowing) {
    return null;
  }

  const handleDismissClick = () => {
    setIsShowing(false);
    setCookie('hideStaleNotification', 'true', 90000);
  };

  return (
    <Alert
      className="stale-videos-alert"
      level="warning"
      action={{
        label: <FontAwesomeIcon icon={faClose} size="lg" />,
        props: {
          className: 'stale-videos-alert__dismiss-btn',
          color: 'secondary',
          onClick: handleDismissClick,
          title: 'Dismiss',
          variant: 'link',
        },
      }}
    >
      You have {numberOfStaleVideos} video
      {numberOfStaleVideos > 1 ? 's' : ''} that will be deleted soon.{' '}
      <Button variant="link" onClick={showPlanPicker}>
        Upgrade Now
      </Button>
    </Alert>
  );
};
