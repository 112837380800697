import type { PayloadAction } from '@reduxjs/toolkit';
import { indexBy, prop, omit, clone } from 'ramda';
import type { FetchingState } from 'src/common/types/fetching-state';

import type { LabelWithCount } from '../../types';
import { dashboardAssignLabelsActions } from '../assign-labels/assign-labels-actions';
import {
  videosActions,
  type CreateVideoPayload,
  type DeleteVideoPayload,
} from '../videos/videos-actions';
import { dashboardSidebarActions } from './labels-actions';

type Labels = {
  [key: number]: LabelWithCount;
};

type StaticSidebarLabels = 'all-videos' | 'unlabelled-videos';

export type ActiveLabelId = number | StaticSidebarLabels;

export type LabelsState = Pick<
  FetchingState,
  'isError' | 'isInitialFetchDone' | 'isLoading'
> & {
  activeLabelId: ActiveLabelId;
  isSidebarOpenDesktop: boolean;
  isSidebarOpenMobile: boolean;
  isEmptyStateDismissed: boolean;
  labels: Labels;
  createModal: Pick<FetchingState, 'isError' | 'isLoading'> & {
    isShowing: boolean;
    errorMessage?: string;
    labelName: string;
  };
  editModal: Pick<FetchingState, 'isError' | 'isLoading'> & {
    isShowing: boolean;
    errorMessage?: string;
    labelId: number | null;
    newLabelName: string;
  };
  deleteModal: Pick<FetchingState, 'isError' | 'isLoading'> & {
    isShowing: boolean;
    errorMessage?: string;
    labelId: number | null;
  };
};

const getInitialEditModalState = () => ({
  isError: false,
  isLoading: false,
  isShowing: false,
  errorMessage: undefined,
  newLabelName: '',
  labelId: null,
});

const getInitialDeleteModalState = () => ({
  isError: false,
  isLoading: false,
  isShowing: false,
  errorMessage: undefined,
  labelId: null,
});

const searchParams = new URLSearchParams(window.location.search);
const labelIdFromQuery = searchParams.get('labels')
  ? parseInt(searchParams.get('labels')!, 10)
  : undefined;

const isViewingUnlabeledVideos =
  searchParams.get('excludeAnyWithLabels') !== null;

const initialActiveLabelId: ActiveLabelId = labelIdFromQuery
  ? labelIdFromQuery
  : isViewingUnlabeledVideos
    ? 'unlabelled-videos'
    : 'all-videos';

const initialState: LabelsState = {
  activeLabelId: initialActiveLabelId,
  isInitialFetchDone: false,
  isError: false,
  isLoading: false,
  isSidebarOpenDesktop: true,
  isSidebarOpenMobile: false,
  isEmptyStateDismissed: false,
  labels: {},
  createModal: {
    isError: false,
    isLoading: false,
    isShowing: false,
    errorMessage: undefined,
    labelName: '',
  },
  editModal: getInitialEditModalState(),
  deleteModal: getInitialDeleteModalState(),
};

export const labelsReducer = (
  state = initialState,
  action: PayloadAction<any>
): LabelsState => {
  switch (action.type) {
    case dashboardSidebarActions.component.labelsInitialised.type: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case dashboardSidebarActions.tryAgain.buttonClicked.type: {
      return {
        ...state,
        isError: false,
        isLoading: true,
      };
    }

    case dashboardSidebarActions.labelButton.clicked.type: {
      return {
        ...state,
        activeLabelId: action.payload,
        isSidebarOpenMobile: false,
      };
    }

    case dashboardSidebarActions.component.labelsFulfilled.type:
    case dashboardSidebarActions.tryAgain.labelsFulfilled.type: {
      const labelsFromPayload = action.payload as LabelWithCount[];

      return {
        ...state,
        isInitialFetchDone: true,
        isError: false,
        isLoading: false,
        labels: indexBy(prop('id'), labelsFromPayload),
      };
    }

    case dashboardSidebarActions.component.labelsRejected.type:
    case dashboardSidebarActions.tryAgain.labelsRejected.type: {
      return {
        ...state,
        isInitialFetchDone: true,
        isError: true,
        isLoading: false,
      };
    }

    case dashboardSidebarActions.createLabelButton.clicked.type: {
      return {
        ...state,
        createModal: {
          ...state.createModal,
          isError: false,
          isShowing: true,
          labelName: '',
          errorMessage: undefined,
        },
      };
    }

    case dashboardSidebarActions.createLabelModal.cancelled.type: {
      return {
        ...state,
        createModal: {
          ...state.createModal,
          isShowing: false,
        },
      };
    }

    case dashboardSidebarActions.createLabelModal.inputChanged.type: {
      return {
        ...state,
        createModal: {
          ...state.createModal,
          labelName: action.payload,
          isError: false,
          errorMessage: undefined,
        },
      };
    }

    case dashboardSidebarActions.createLabelModal.saveButtonClicked.type: {
      return {
        ...state,
        createModal: {
          ...state.createModal,
          isLoading: true,
        },
      };
    }

    case dashboardAssignLabelsActions.assignLabelsModal.createLabelFulfilled
      .type: {
      const updatedLabels = {
        ...state.labels,
        [action.payload.id]: action.payload,
      };
      return {
        ...state,
        labels: updatedLabels,
      };
    }

    case dashboardSidebarActions.createLabelModal.saveLabelFulfilled.type: {
      const updatedLabels = {
        ...state.labels,
        [action.payload.id]: action.payload,
      };
      return {
        ...state,
        createModal: {
          ...state.createModal,
          isError: false,
          isLoading: false,
          isShowing: false,
          labelName: '',
          errorMessage: undefined,
        },
        labels: updatedLabels,
      };
    }

    case dashboardSidebarActions.createLabelModal.saveLabelRejected.type: {
      return {
        ...state,
        createModal: {
          ...state.createModal,
          isLoading: false,
          isError: true,
          errorMessage: action.payload,
        },
      };
    }

    case dashboardSidebarActions.title.contextMenu.editLabelButton.clicked.type:
    case dashboardSidebarActions.contextMenu.editLabelButton.clicked.type: {
      const labelId = action.payload;
      const newLabelName = state.labels[labelId].name;

      return {
        ...state,
        editModal: {
          ...getInitialEditModalState(),
          isShowing: true,
          newLabelName,
          labelId,
        },
      };
    }

    case dashboardSidebarActions.editLabelModal.cancelled.type: {
      return {
        ...state,
        editModal: {
          ...state.editModal,
          isShowing: false,
        },
      };
    }

    case dashboardSidebarActions.editLabelModal.inputChanged.type: {
      return {
        ...state,
        editModal: {
          ...state.editModal,
          newLabelName: action.payload,
          isError: false,
          errorMessage: undefined,
        },
      };
    }

    case dashboardSidebarActions.editLabelModal.saveButtonClicked.type: {
      return {
        ...state,
        editModal: {
          ...state.editModal,
          isLoading: true,
        },
      };
    }

    case dashboardSidebarActions.editLabelModal.saveLabelFulfilled.type: {
      const updatedLabels = {
        ...state.labels,
        [action.payload.id]: {
          ...state.labels[action.payload.id],
          ...action.payload,
        },
      };

      return {
        ...state,
        editModal: getInitialEditModalState(),
        labels: updatedLabels,
      };
    }

    case dashboardSidebarActions.editLabelModal.saveLabelRejected.type: {
      return {
        ...state,
        editModal: {
          ...state.editModal,
          isLoading: false,
          isError: true,
          errorMessage: action.payload || 'Unknown Error',
        },
      };
    }

    case dashboardSidebarActions.title.contextMenu.deleteLabelButton.clicked
      .type:
    case dashboardSidebarActions.contextMenu.deleteLabelButton.clicked.type: {
      const labelId = action.payload;

      return {
        ...state,
        deleteModal: {
          ...getInitialDeleteModalState(),
          isShowing: true,
          labelId,
        },
      };
    }

    case dashboardSidebarActions.deleteLabelModal.cancelled.type: {
      return {
        ...state,
        deleteModal: {
          ...state.deleteModal,
          isShowing: false,
        },
      };
    }

    case dashboardSidebarActions.deleteLabelModal.saveButtonClicked.type: {
      return {
        ...state,
        deleteModal: {
          ...state.deleteModal,
          isLoading: true,
          isError: false,
          errorMessage: '',
        },
      };
    }

    case dashboardSidebarActions.deleteLabelModal.saveLabelFulfilled.type: {
      const deletedLabelId = action.payload;
      const activeLabelId =
        state.activeLabelId === deletedLabelId
          ? 'all-videos'
          : state.activeLabelId;

      return {
        ...state,
        activeLabelId,
        deleteModal: getInitialDeleteModalState(),
        labels: omit([deletedLabelId], state.labels),
      };
    }

    case dashboardSidebarActions.deleteLabelModal.saveLabelRejected.type: {
      return {
        ...state,
        deleteModal: {
          ...state.deleteModal,
          isLoading: false,
          isError: true,
          errorMessage: action.payload || 'Unknown Error',
        },
      };
    }

    case dashboardSidebarActions.sidebar.desktop.closed.type: {
      return {
        ...state,
        isSidebarOpenDesktop: false,
      };
    }

    case dashboardSidebarActions.sidebar.desktop.toggled.type: {
      return {
        ...state,
        isSidebarOpenDesktop: !state.isSidebarOpenDesktop,
      };
    }

    case dashboardSidebarActions.sidebar.mobile.closed.type: {
      return {
        ...state,
        isSidebarOpenMobile: false,
      };
    }

    case dashboardSidebarActions.sidebar.mobile.toggled.type: {
      return {
        ...state,
        isSidebarOpenMobile: !state.isSidebarOpenMobile,
      };
    }

    case dashboardSidebarActions.sidebar.emptyState.dismissed.type: {
      return {
        ...state,
        isEmptyStateDismissed: true,
      };
    }

    case dashboardAssignLabelsActions.assignLabelsModal.saveAssignmentsFulfilled
      .type: {
      const updatedLabels = clone(state.labels);

      action.payload.labelIdsToAdd.forEach((labelId: number) => {
        updatedLabels[labelId].count++;
      });
      action.payload.labelIdsToRemove.forEach((labelId: number) => {
        updatedLabels[labelId].count--;
      });

      return {
        ...state,
        labels: updatedLabels,
      };
    }

    case videosActions.upload.createVideo.type: {
      const labels = (action.payload as CreateVideoPayload).labels;
      if (!labels || labels.length === 0) {
        return state;
      }

      const labelsCopy = clone(state.labels);

      labels.forEach((label) => {
        if (typeof labelsCopy[label.id] !== 'undefined') {
          labelsCopy[label.id].count++;
        }
      });

      return {
        ...state,
        labels: labelsCopy,
      };
    }

    case videosActions.deleteVideo.type: {
      // TODO: Payload can be error type

      const payload = action.payload as DeleteVideoPayload;

      // if the deleting is still in progress we ignore the action
      // and let the next one update the state when the deletion has been confirmed.
      // This is also necessary because when a video is still being uploaded and is then
      // deleted it does not go through the `isDeleting=true` state.
      if (payload.isDeleting) {
        return state;
      }

      const labels = payload.labels;
      if (!labels || labels.length === 0) {
        return state;
      }

      const labelsCopy = clone(state.labels);

      labels.forEach((label) => {
        if (typeof labelsCopy[label.id] !== 'undefined') {
          labelsCopy[label.id].count--;
        }
      });

      return {
        ...state,
        labels: labelsCopy,
      };
    }

    case dashboardAssignLabelsActions.dragAndDropAssign.saveFulfilled.type: {
      const { labelId, delta } = action.payload;
      const labelsCopy = clone(state.labels);

      if (labelsCopy[labelId]) {
        labelsCopy[labelId].count += delta;
      }

      return {
        ...state,
        labels: labelsCopy,
      };
    }

    default:
      return state;
  }
};
