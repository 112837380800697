import { VideoRepresentation } from '../types';

export const transformVideoAfterFetch = (
  video: VideoRepresentation
): VideoRepresentation => ({
  ...video,
  privacy_settings: video.privacy_settings
    ? {
        ...video.privacy_settings,
        domain_restrictions:
          video.privacy_settings?.allowed_domain &&
          video.privacy_settings?.allowed_domain.length > 0 &&
          video.privacy_settings?.domain_restrictions !== 'off'
            ? 'allowlist'
            : 'off',
      }
    : null,
});
