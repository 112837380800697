import React, {
  useState,
  useEffect,
  type PropsWithChildren,
  type ReactNode,
} from 'react';

import classNames from 'classnames';

import './dropdown.css';

export type DropDownItem = {
  title?: string;
  onClick?: () => void;
  selected?: boolean;
  description?: string;
  icon?: ReactNode;
  separator?: boolean;
};

type DropDownProps = PropsWithChildren<{
  className?: string;
  triggerClassName?: string;
  items: Array<DropDownItem>;
}>;

const DropDown = ({
  items,
  className,
  triggerClassName,
  children,
}: DropDownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = () => setIsOpen((open) => !open);

  useEffect(() => {
    const close = () => isOpen && setIsOpen(false);
    document.addEventListener('click', close);
    return () => {
      document.removeEventListener('click', close);
    };
  }, [isOpen]);

  return (
    <div className={classNames('dropdown', className)}>
      <button data-draggable className={triggerClassName} onClick={onClick}>
        {children}
      </button>
      {isOpen && (
        <div className="actions-menu">
          {items.map((item, i) => (
            <div key={i}>
              {item.separator ? (
                <div className="action-menu-separator" />
              ) : (
                <div
                  className={`action-menu-button ${
                    item.selected ? 'actions-menu-selected' : ''
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    item.onClick?.();
                  }}
                >
                  {item.icon}
                  <div className="action-button-content">
                    {item.title && (
                      <span dangerouslySetInnerHTML={{ __html: item.title }} />
                    )}
                    {item.description && (
                      <div className="action-button-description">
                        {item.description}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropDown;
