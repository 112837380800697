import type { PayloadAction, Reducer } from '@reduxjs/toolkit';

import { dashboardAssignLabelsActions } from './assign-labels-actions';
import type { AssignLabelsState } from './assign-labels-types';

const initialState: AssignLabelsState = {
  assignLabelsModal: {
    isLoading: false,
    isShowing: false,
    isBulk: false,
    shortcodes: [],
    isCreatingLabel: false,
  },
  dragAndDrop: {
    isDragging: false,
    errorAlert: {
      isShowing: false,
    },
  },
};

export const assignLabelsReducer: Reducer<
  AssignLabelsState,
  PayloadAction<any>
> = (state = initialState, action): AssignLabelsState => {
  switch (action.type) {
    case dashboardAssignLabelsActions.videoCard.moreMenu.editLabelsButton
      .clicked.type:
    case dashboardAssignLabelsActions.videoCard.videoLabelsButton.clicked
      .type: {
      return {
        ...state,
        assignLabelsModal: {
          ...state.assignLabelsModal,
          isShowing: true,
          isBulk: false,
          shortcodes: [action.payload],
        },
      };
    }

    case dashboardAssignLabelsActions.actionsBar.editLabelsButton.clicked
      .type: {
      return {
        ...state,
        assignLabelsModal: {
          ...state.assignLabelsModal,
          isShowing: true,
          isBulk: true,
          shortcodes: action.payload.shortcodes,
        },
      };
    }

    case dashboardAssignLabelsActions.assignLabelsModal.cancelled.type: {
      return {
        ...state,
        assignLabelsModal: {
          ...state.assignLabelsModal,
          errorMessage: undefined,
          isShowing: false,
          isBulk: false,
          shortcodes: [],
        },
      };
    }

    case dashboardAssignLabelsActions.assignLabelsModal.close.type: {
      return {
        ...state,
        assignLabelsModal: {
          ...state.assignLabelsModal,
          errorMessage: undefined,
          isLoading: false,
          isShowing: false,
          isBulk: false,
          shortcodes: [],
        },
      };
    }

    case dashboardAssignLabelsActions.assignLabelsModal.saved.type: {
      return {
        ...state,
        assignLabelsModal: {
          ...state.assignLabelsModal,
          isLoading: true,
        },
      };
    }

    case dashboardAssignLabelsActions.assignLabelsModal.saveAssignmentsFulfilled
      .type: {
      // if this fulfill action is a part of bigger set
      // e.g. when multiple videos' labels are being edited
      // just ignore it -- the state will be reset with the
      // `close` action.
      if (action.payload.partiallyFulfilled) {
        return {
          ...state,
          assignLabelsModal: {
            ...state.assignLabelsModal,
            isLoading: false,
          },
        };
      }
      return {
        ...state,
        assignLabelsModal: {
          ...state.assignLabelsModal,
          errorMessage: undefined,
          isLoading: false,
          isShowing: false,
          isBulk: false,
          shortcodes: [],
        },
      };
    }

    case dashboardAssignLabelsActions.assignLabelsModal.saveAssignmentsRejected
      .type: {
      return {
        ...state,
        assignLabelsModal: {
          ...state.assignLabelsModal,
          errorMessage: action.payload,
          isLoading: false,
        },
      };
    }

    case dashboardAssignLabelsActions.assignLabelsModal.labelCheckbox.changed
      .type: {
      return {
        ...state,
        assignLabelsModal: {
          ...state.assignLabelsModal,
          errorMessage: undefined,
        },
      };
    }

    case dashboardAssignLabelsActions.assignLabelsModal.createLabelButtonClicked
      .type: {
      return {
        ...state,
        assignLabelsModal: {
          ...state.assignLabelsModal,
          isCreatingLabel: true,
        },
      };
    }

    case dashboardAssignLabelsActions.assignLabelsModal.createLabelFulfilled
      .type: {
      return {
        ...state,
        assignLabelsModal: {
          ...state.assignLabelsModal,
          isCreatingLabel: false,
        },
      };
    }

    case dashboardAssignLabelsActions.assignLabelsModal.createLabelRejected
      .type: {
      return {
        ...state,
        assignLabelsModal: {
          ...state.assignLabelsModal,
          isCreatingLabel: false,
          errorMessage: action.payload,
        },
      };
    }

    case dashboardAssignLabelsActions.dragAndDropAssign.startDragging.type: {
      return {
        ...state,
        dragAndDrop: {
          ...state.dragAndDrop,
          isDragging: true,
        },
      };
    }

    case dashboardAssignLabelsActions.dragAndDropAssign.stopDragging.type: {
      return {
        ...state,
        dragAndDrop: {
          ...state.dragAndDrop,
          isDragging: false,
        },
      };
    }

    case dashboardAssignLabelsActions.dragAndDropAssign.saveRejected.type: {
      return {
        ...state,
        dragAndDrop: {
          ...state.dragAndDrop,
          errorAlert: {
            errorMessage: action.payload,
            isShowing: true,
          },
        },
      };
    }

    case dashboardAssignLabelsActions.dragAndDropAssign.dismissErrorAlert
      .type: {
      return {
        ...state,
        dragAndDrop: {
          ...state.dragAndDrop,
          errorAlert: {
            errorMessage: undefined,
            isShowing: false,
          },
        },
      };
    }

    default:
      return state;
  }
};
