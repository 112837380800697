import React from 'react';

import { Flex } from 'src/common/components/flex/flex';

import type { PageConfig } from '../../state/landing-types';
import leftVideoExampleSrc from './assets/video-example-left.png';
import rightVideoExampleSrc from './assets/video-example-right.png';
import { ReactComponent as LeftCurve } from './assets/white-curve-left.svg';
import { ReactComponent as MobileCurve } from './assets/white-curve-mobile.svg';
import { ReactComponent as RightCurve } from './assets/white-curve-right.svg';
import './hero.scss';
import { Widget } from './widget/widget';

type HeroProps = {
  page: PageConfig | null;
  isLoading?: boolean;
};

export const Hero = ({ page, isLoading = false }: HeroProps) => {
  return (
    <Flex className="hero" justifyContent="center">
      <Flex className="widget-container" justifyContent="center">
        <MobileCurve className="curve mobile-curve" />
        <RightCurve className="curve right-curve" />
        <LeftCurve className="curve left-curve" />
        <img
          className="video-example left-example"
          src={leftVideoExampleSrc}
          alt="Woman dancing video example"
        />
        <img
          className="video-example right-example"
          src={rightVideoExampleSrc}
          alt="Man in headphones video example"
        />

        <Widget page={page} isLoading={isLoading} />
      </Flex>

      <div className="explanation-note">
        No credit card or account required.{' '}
        <a
          className="explanation-note__link"
          target="_blank"
          rel="noreferrer"
          href="https://streamableapp.zendesk.com/hc/en-us/articles/26732811851409-Recommended-Video-Settings"
        >
          Learn
        </a>{' '}
        about Streamable's upload formats and requirements. By continuing, you
        accept our{' '}
        <a
          className="explanation-note__link"
          href="https://terms.streamable.com/"
          rel="noreferrer"
          target="_blank"
        >
          Terms of Service
        </a>{' '}
        and acknowledge receipt of our{' '}
        <a
          className="explanation-note__link"
          href="https://privacy.streamable.com/"
          rel="noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>
        .
      </div>
    </Flex>
  );
};
