import React, { useRef, useState } from 'react';
import { usePopper } from 'react-popper';

import { Popover } from '@headlessui/react';
import classNames from 'classnames';
import { MaterialIcon } from 'material-icons';
import { Button } from 'src/common/components/button/button';
import { Flex } from 'src/common/components/flex/flex';

import './labels-context-menu.css';

type AvailableOptionsIcons = Extract<MaterialIcon, 'edit' | 'delete'>;

const isSafari = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return userAgent.indexOf('safari') > -1 && userAgent.indexOf('chrome') === -1;
};

export type ContextMenuOptionProps = {
  icon?: AvailableOptionsIcons;
  name: string;
  onClick: () => void;
};

type LabelsContextMenuProps = {
  options: ContextMenuOptionProps[];
};

const TIMEOUT_DURATION = 200;

export const ContextMenu = ({ options }: LabelsContextMenuProps) => {
  /**
   * The use of useState is intentional for the elements as explained on popper's docs:
   * https://popper.js.org/react-popper/v2/#example -- see the bottom of the example
   * Copying it here just to see the explanation here
   * Note: the usePopper hook intentionally takes the DOM node, not refs,
   * in order to be able to update when the nodes change.
   * A callback ref is used here to permit this behaviour, and
   * useState is an appropriate way to implement this.
   */
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 2],
        },
      },
    ],
  });

  // TODO: This is most likely also not very good if we show labels context menu
  //       together with some modal or popover, this is currently not the case
  const closePopover = () =>
    referenceElement?.dispatchEvent(
      new KeyboardEvent('keydown', {
        key: 'Escape',
        bubbles: true,
        cancelable: true,
      })
    );

  const handleMouseEnter = (open: boolean) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (open) {
      return;
    }
    return referenceElement?.click();
  };

  const handleMouseLeave = (open: boolean) => {
    if (!open) {
      return;
    }
    timeoutRef.current = setTimeout(() => closePopover(), TIMEOUT_DURATION);
  };

  return (
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            className="context-menu-container__popover-button"
            ref={setReferenceElement}
            onMouseEnter={() => handleMouseEnter(open)}
            onMouseLeave={() => handleMouseLeave(open)}
          >
            <Flex
              className="context-menu-container__icon"
              alignItems="center"
              justifyContent="center"
            >
              <span className="material-icons">more_horiz</span>
            </Flex>
          </Popover.Button>

          <Popover.Panel
            className="context-menu-container__popover-panel"
            ref={setPopperElement}
            onMouseEnter={() => handleMouseEnter(open)}
            onMouseLeave={() => handleMouseLeave(open)}
            style={styles.popper}
            {...attributes.popper}
          >
            <ul
              className={classNames('context-menu-container__menu', {
                'context-menu-container__menu--open': open,
              })}
            >
              {options.map(({ icon, onClick, name }) => (
                <li className="context-menu-container__menu__item" key={name}>
                  <Button
                    className="context-menu-container__menu__item__button"
                    onClick={(e) => {
                      if (!isSafari()) {
                        e.stopPropagation();
                        onClick();
                      }
                    }}
                    onMouseDown={(e) => {
                      if (isSafari()) {
                        e.stopPropagation();
                        onClick();
                      }
                    }}
                    onKeyDown={(e) => {
                      if (isSafari() && e.key === 'Enter') {
                        e.stopPropagation();
                        onClick();
                      }
                    }}
                    variant="link"
                  >
                    {icon && <span className="material-icons">{icon}</span>}
                    <p className="context-menu-container__menu__item__text">
                      {name}
                    </p>
                  </Button>
                </li>
              ))}
            </ul>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};
