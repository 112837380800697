import React from 'react';

import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from 'src/common/components/text/text';

import './upsell-label.css';

type UpsellLabelProps = {
  enableUpsell: boolean;
  label: string;
  planName: string;
};

export const UpsellLabel = ({
  enableUpsell,
  label,
  planName,
}: UpsellLabelProps) =>
  enableUpsell ? (
    <span className="upsell-label">
      <Text as="span">{label}</Text>{' '}
      <Text as="span" className="upsell-label__container">
        <FontAwesomeIcon
          icon={faStar}
          className="upsell-label__container__icon"
        />
        {planName}
      </Text>
    </span>
  ) : (
    <>{label}</>
  );
