import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { selectCurrentPlansLimits } from 'src/app/subscription/subscription-selectors';
import { Alert } from 'src/common/components/alert/alert';
import { humanizeStorageUsage } from 'src/dashboard/utils';
import type { RootState } from 'src/redux';

export type PlanLimitsAlertsProps = {
  limits: ReturnType<typeof selectCurrentPlansLimits>;
};

export const PlanLimitsAlerts = ({ limits }: PlanLimitsAlertsProps) => {
  if (!limits?.storage?.exceeded || !limits?.storage?.limit) {
    return null;
  }

  return (
    <Alert
      level="warning"
      action={{
        label: 'Upgrade',
        props: {
          onClick: () => {
            browserHistory.push('/pricing');
          },
        },
      }}
    >
      <p>
        You’ve used{' '}
        <a href="/your_usage">
          {humanizeStorageUsage(limits.storage.current, limits.storage.limit)}
        </a>{' '}
        of the{' '}
        {humanizeStorageUsage(limits.storage.limit, limits.storage.limit)}{' '}
        storage included in your plan. Upgrade your account to upload more
        videos.
      </p>
    </Alert>
  );
};

const mapStateToProps = (state: RootState) => ({
  limits: selectCurrentPlansLimits(state),
});

export default connect(mapStateToProps)(PlanLimitsAlerts);
