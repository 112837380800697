import React, { type PropsWithChildren, type ReactNode } from 'react';

import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from '@headlessui/react';
import classnames from 'classnames';
import { Button } from 'src/common/components/button/button';

import { Flex } from '../flex/flex';
import './modal.css';
import {
  closeModalById,
  useModalEscapeHandler,
  type ModalId,
  type CloseTrigger,
} from './use-modal-escape-handler';

// NOTE: Better to keep in sync with z-index value in ./src/misc/modals/modals.scss
//       until the modals are replaced by the component library.
const MODAL_Z_INDEX_BASE = 1100;

export type { CloseTrigger } from './use-modal-escape-handler';

export type ModalProps<WithId = true> = PropsWithChildren<
  (WithId extends true ? { id: ModalId } : {}) & {
    className?: string;
    isShowing: boolean;
    onClose: (trigger?: CloseTrigger) => void;
    title?: ReactNode;
    cancelLabel?: string;
    confirmLabel?: string;
    disableConfirm?: boolean;
    disableClose?: boolean;
    hideCancel?: boolean;
    confirmAutofocus?: boolean;
    withBackdrop?: boolean;
    zIndexOffset?: number;
    variant?: 'primary' | 'danger';
  }
> &
  (
    | { hideConfirm: true; onConfirm?: never }
    | {
        hideConfirm?: false;
        onConfirm: () => void;
      }
  );

export const Modal = ({
  id,
  children,
  className,
  isShowing,
  onClose,
  onConfirm: handleConfirm,
  title,
  cancelLabel = 'Cancel',
  confirmLabel = 'Save',
  disableConfirm = false,
  hideCancel = false,
  hideConfirm = false,
  disableClose = false,
  confirmAutofocus = false,
  withBackdrop = true,
  zIndexOffset = 0,
  variant = 'primary',
}: ModalProps) => {
  const handleClose = (trigger?: CloseTrigger) => {
    if (disableClose) {
      return;
    }
    closeModalById(id);
    onClose(trigger);
  };

  useModalEscapeHandler(id, isShowing, handleClose);

  return (
    <Dialog
      open={isShowing}
      onClose={() => null}
      className={classnames('sb-modal', className)}
      style={{ zIndex: MODAL_Z_INDEX_BASE + zIndexOffset }}
    >
      <Flex
        direction="row"
        alignItems="center"
        justifyContent="center"
        className="sb-modal__container"
      >
        <div
          className={classnames('sb-modal__backdrop', {
            'sb-modal__backdrop--hidden': !withBackdrop,
          })}
          aria-hidden="true"
          onClick={() => handleClose('backdrop')}
          data-testid="sb-modal-backdrop"
        />
        <div className="sb-modal__panel">
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            className="sb-modal__panel__header"
          >
            {title && (
              <Dialog.Title className="sb-modal__panel__header__title">
                {/* @ts-ignore - TODO: Fix it later */}
                {title}
              </Dialog.Title>
            )}

            {!disableClose ? (
              <Button
                className="sb-modal__panel__header__close-button"
                variant="icon"
                onClick={() => handleClose('close-button')}
                title="Close"
                autoFocus={false}
              >
                <FontAwesomeIcon icon={faClose} size="lg" />
              </Button>
            ) : null}
          </Flex>

          <Flex className="sb-modal__panel__content">{children}</Flex>

          {(!hideCancel || !hideConfirm) && (
            <Flex
              direction="row"
              justifyContent="flex-end"
              className="sb-modal__panel__footer"
            >
              {!hideCancel && (
                <Button
                  variant="text"
                  onClick={() => handleClose('footer-button')}
                >
                  {cancelLabel}
                </Button>
              )}
              {!hideConfirm && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleConfirm?.();
                  }}
                  color={variant}
                  disabled={disableConfirm}
                  autoFocus={confirmAutofocus}
                >
                  {confirmLabel}
                </Button>
              )}
            </Flex>
          )}
        </div>
      </Flex>
    </Dialog>
  );
};
