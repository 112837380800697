import type { PayloadAction } from '@reduxjs/toolkit';
import type { FetchingState } from 'src/common/types/fetching-state';

import { landingActions } from './landing-actions';
import type { PageConfig } from './landing-types';

export type LandingState = {
  slug: string;
  page: {
    data: null | PageConfig;
  } & Pick<FetchingState, 'isError' | 'isLoading'>;
};

const initialState: LandingState = {
  slug: '',
  page: {
    data: null,
    isError: false,
    isLoading: true,
  },
};

export const landingReducer = (
  state = initialState,
  action: PayloadAction<any>
): LandingState => {
  const { type, payload } = action;

  switch (type) {
    case landingActions.fetchPage.initiated.type:
      return {
        ...state,
        slug: payload,
        page: {
          ...state.page,
          isLoading: true,
          isError: false,
        },
      };

    case landingActions.fetchPage.fulfilled.type:
      return {
        ...state,
        page: {
          ...state.page,
          data: payload,
          isLoading: false,
        },
      };

    case landingActions.fetchPage.failed.type:
      return {
        ...state,
        page: {
          ...state.page,
          data: null,
          isLoading: false,
          isError: true,
        },
      };

    default:
      return state;
  }
};
