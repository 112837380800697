import React, { useEffect } from 'react';

import { disableDarkMode } from 'src/utils/disable-dark-mode';
import { enableDarkMode } from 'src/utils/enable-dark-mode';
import { isDarkModeEnabled } from 'src/utils/is-dark-mode-enabled';

export const withIsDarkModeSupported =
  (isSupported: boolean = true) =>
  (WrappedComponent: React.FunctionComponent<any>) => {
    return function IsDarkModeSupportedProvider(props: any) {
      useEffect(() => {
        if (!isSupported) {
          disableDarkMode();
        } else if (isDarkModeEnabled()) {
          enableDarkMode();
        }
      }, [isSupported]);

      return <WrappedComponent {...props} />;
    };
  };
