import fetch from 'isomorphic-fetch';
import { env } from 'src/env';

export type ApiRequestParams = {
  path: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  body?: any;
  contentType?: 'json' | 'text';
};

export async function apiRequest<T>({
  path,
  method,
  body,
  contentType = 'json',
}: ApiRequestParams): Promise<T> {
  return fetch(`${env.API_HOST}${path}`, {
    method,
    body: typeof body === 'string' ? body : JSON.stringify(body),
    headers: {
      'Content-Type':
        contentType === 'json' ? 'application/json' : 'text/plain',
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
    },
    credentials: 'include',
  })
    .then(async (res) => {
      if (res.status === 204) {
        return null as T;
      }

      const payload = await (contentType === 'json' ? res.json() : res.text());

      if (!res.ok) {
        throw new Error(payload.message);
      }
      return payload as T;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
}
