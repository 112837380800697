import React, { useState } from 'react';

import { Checkbox } from 'src/common/components/checkbox/checkbox';
import { ErrorMessage } from 'src/common/components/error-message/error-message';
import { Flex } from 'src/common/components/flex/flex';
import { BulkLabelSelectionState } from 'src/dashboard/types';

import './assign-labels-modal-component.css';

export type AssignLabelsModalComponentProps = {
  errorMessage?: string;
  handleChange: (labelId: number) => void;
  isCreatingLabel: boolean;
  isDisabled?: boolean;
  onCreateLabelClick: (labelName: string) => void;
  selectedLabelsState: BulkLabelSelectionState;
};

export function AssignLabelsModalComponent({
  errorMessage,
  handleChange,
  isCreatingLabel,
  isDisabled = false,
  selectedLabelsState,
  onCreateLabelClick,
}: AssignLabelsModalComponentProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const filteredLabels =
    searchTerm === ''
      ? selectedLabelsState
      : selectedLabelsState.filter((label) =>
          label.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
  const showCreateNewLabelButton =
    filteredLabels.length === 0 && searchTerm.trim() !== '';

  return (
    <Flex>
      <Flex className="search-labels-container">
        <input
          aria-label="Search labels"
          className="sb-input"
          disabled={isCreatingLabel}
          onChange={(event) => setSearchTerm(event.currentTarget.value)}
          placeholder="Search for labels"
          value={searchTerm}
        />
      </Flex>

      {showCreateNewLabelButton && (
        <button
          className="create-labels-button"
          onClick={() => {
            onCreateLabelClick(searchTerm);
            // If the user leaves a space in front or after the label, it won't
            // show up because of the filter after it has been created. So we
            // have to trim the white space.
            setSearchTerm(searchTerm.trim());
          }}
        >
          <span className="material-icons">add</span>

          <span className="create-labels-button__text">
            Create new label "{searchTerm}"
          </span>
        </button>
      )}

      {isCreatingLabel && (
        <Flex className="assign-labels-create-message">
          <span className="material-icons assign-labels-create-message__spinner">
            data_usage
          </span>

          <span>Creating label "{searchTerm}"</span>
        </Flex>
      )}

      {filteredLabels.length > 0 && (
        <Flex as="ul" aria-label="Labels list" className="assign-labels-list">
          {filteredLabels.map((label) => {
            const id = `label-checkbox-${label.id}`;

            return (
              <Flex
                as="li"
                direction="row"
                alignItems="center"
                key={label.id}
                className="assign-labels-list__item"
              >
                <span className="material-icons">label</span>

                <label htmlFor={id} className="assign-labels-list__item__label">
                  {label.name}
                </label>

                <Checkbox
                  id={id}
                  aria-label={
                    label.indeterminate
                      ? `Apply ${label.name} from some to all`
                      : label.checked
                        ? `Remove ${label.name} label`
                        : `Apply ${label.name}`
                  }
                  checked={label.checked}
                  indeterminate={label.indeterminate}
                  onChange={() => handleChange(label.id)}
                  disabled={isDisabled || isCreatingLabel}
                />
              </Flex>
            );
          })}
        </Flex>
      )}

      <ErrorMessage
        message={errorMessage}
        className="assign-labels-list__error-message-container"
      />
    </Flex>
  );
}
