import React from 'react';

import { Text } from 'src/common/components/text/text';

import './plan-description.css';

type PlanDescriptionProps = {
  children: React.ReactNode;
};

export const PlanDescription = ({ children }: PlanDescriptionProps) => (
  <Text className="plan-description">{children}</Text>
);
