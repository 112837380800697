import React from 'react';

import { SeparatorLine } from 'src/common/components/separator-line/separator-line';
import { HOME_PAGE_FEATURES } from 'src/landing/constants';

import { FeatureItem } from '../feature-item/feature-item';
import './features.css';

export const Features = () => (
  <section className="features-v2">
    <div className="features-v2__content">
      <FeatureItem {...HOME_PAGE_FEATURES[0]} />

      <div className="features-v2__feature-separator-container">
        <SeparatorLine className="features-v2__feature-separator-container__feature-separator-line" />
      </div>

      <FeatureItem {...HOME_PAGE_FEATURES[1]} />
    </div>
  </section>
);
