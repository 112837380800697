import React from 'react';
import { connect } from 'react-redux';

import { Modal } from 'src/common/components/modal/modal';
import { TextField } from 'src/common/components/text-field/text-field';
import {
  dashboardSidebarActions,
  updateLabel,
} from 'src/dashboard/state/labels/labels-actions';
import {
  selectEditLabelErrorMessage,
  selectEditNewLabelName,
  selectIsLabelEditionLoading,
  selectIsEditLabelModalShowing,
} from 'src/dashboard/state/labels/labels-selectors';
import type { RootState, AppDispatch } from 'src/redux';

type EditLabelModalProps = {
  handleClose: () => void;
  handleInputChange: (newValue: string) => void;
  handleSave: () => void;
  isLoading: boolean;
  isShowing: boolean;
  errorMessage?: string;
  newLabelName: string;
};

const EditLabelModalComponent = ({
  handleClose,
  handleInputChange,
  handleSave,
  isLoading,
  isShowing,
  errorMessage,
  newLabelName,
}: EditLabelModalProps) => {
  const disableConfirm =
    !newLabelName || newLabelName.trim().length === 0 || isLoading;

  return (
    <Modal
      id="edit-label-modal"
      isShowing={isShowing}
      onClose={handleClose}
      onConfirm={handleSave}
      title="Rename label"
      disableConfirm={disableConfirm}
      confirmLabel={isLoading ? 'Saving' : 'Save'}
    >
      <TextField
        label="Label name"
        id="edit-label-input"
        name="editLabelName"
        onChange={handleInputChange}
        value={newLabelName}
        errorMessage={errorMessage}
        disabled={isLoading}
        autoFocus
        autoComplete="off"
        maxLength={50}
        onEnter={disableConfirm ? undefined : handleSave}
      />
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: selectIsLabelEditionLoading(state),
  isShowing: selectIsEditLabelModalShowing(state),
  errorMessage: selectEditLabelErrorMessage(state),
  newLabelName: selectEditNewLabelName(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  handleClose: () =>
    dispatch(dashboardSidebarActions.editLabelModal.cancelled()),
  handleInputChange: (newValue: string) =>
    dispatch(dashboardSidebarActions.editLabelModal.inputChanged(newValue)),
  handleSave: () => dispatch(updateLabel()),
});

export const EditLabelModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditLabelModalComponent);
