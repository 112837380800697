import React from 'react';
import { Link } from 'react-router';

import { Flex } from 'src/common/components/flex/flex';
import { Text } from 'src/common/components/text/text';
import type { PageConfig } from 'src/landing-new/state/landing-types';
import { Markdown } from 'src/misc/markdown';

import './sign-up-cta.css';

type SignUpCTAProps = {
  page: PageConfig;
};

export const SignUp = ({ page }: SignUpCTAProps) => {
  if (!page.widgetAttributes) {
    return null;
  }

  const attributes = page.widgetAttributes;

  return (
    <Flex className="sign-up-cta" alignItems="center" justifyContent="center">
      <Text as="h1" className="sign-up-cta__title">
        {attributes.title || 'Sign Up'}
      </Text>
      <Text as="p">
        <Markdown>{page.description}</Markdown>
      </Text>
      <Link to={page.widgetAttributes.link} className="sign-up-cta__link">
        {page.widgetAttributes.label}
      </Link>
    </Flex>
  );
};
