import React from 'react';
import { Link } from 'react-router';

import { TextWithHighlightStroke } from '../text-with-highlight-stroke/text-with-highlight-stroke';
import './get-started-for-free-banner.css';

export const GetStartedForFreeBanner = () => (
  <section className="get-started-for-free-banner">
    <div className="get-started-for-free-banner__content">
      <h2 className="get-started-for-free-banner__title">
        Get started for <TextWithHighlightStroke>free</TextWithHighlightStroke>
      </h2>
      <p className="get-started-for-free-banner__text">
        Upload and share your first video with Streamable today.
      </p>
      <Link to="/signup" className="get-started-for-free-banner__cta">
        Sign up for free
      </Link>
    </div>
  </section>
);
