import moment from 'moment';

import { ANONYMOUS_USER, FREE_USER, PAID_USER } from '../../selectors/users';

export const EXPIRATION_TIME_BY_PLAN = {
  [PAID_USER]: null,
  [FREE_USER]: 90 * 24,
  [ANONYMOUS_USER]: 48,
};

export const EXPIRATION_WARNING_TIME_BY_PLAN = {
  [PAID_USER]: null,
  [FREE_USER]: 24,
  [ANONYMOUS_USER]: 48,
};

export const isExpired = (dateAdded, userTier) => {
  if (userTier !== ANONYMOUS_USER) {
    return false;
  }

  const expirationPeriod = EXPIRATION_TIME_BY_PLAN[userTier];

  return (
    moment().diff(
      moment(new Date(dateAdded * 1000)).add(expirationPeriod, 'h')
    ) > 0
  );
};

export const willExpireSoon = (dateAdded, userTier) => {
  if (userTier !== ANONYMOUS_USER) {
    return false;
  }

  if (isExpired(dateAdded, userTier)) {
    return false;
  }

  const expirationPeriod = EXPIRATION_TIME_BY_PLAN[userTier];
  const expirationSoonPeriod =
    expirationPeriod - EXPIRATION_WARNING_TIME_BY_PLAN[userTier];

  return (
    moment().diff(
      moment(new Date(dateAdded * 1000)).add(expirationSoonPeriod, 'h')
    ) > 0
  );
};
