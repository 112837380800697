import type { AnyAction } from 'redux';
import { FetchingState } from 'src/common/types/fetching-state';

import { BlogPost } from '../types';
import { blogPageActions } from './blog-actions';

export type BlogState = Pick<
  FetchingState,
  'isInitialFetchDone' | 'isError' | 'isLoading'
> & {
  currentPage: number;
  hasMore: boolean;
  posts: BlogPost[];
};

export const initialState: BlogState = {
  isInitialFetchDone: false,
  isError: false,
  isLoading: false,
  currentPage: 1,
  hasMore: false,
  posts: [],
};

export const blogReducer = (
  state = initialState,
  action: AnyAction
): BlogState => {
  switch (action.type) {
    case blogPageActions.blogPostsFetched.type: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case blogPageActions.blogPostsFulfilled.type: {
      const { hasMore, posts } = action.payload;
      return {
        ...state,
        isInitialFetchDone: true,
        isError: false,
        isLoading: false,
        currentPage: state.currentPage + 1,
        hasMore,
        posts: [...state.posts, ...posts],
      };
    }

    case blogPageActions.blogPostsRejected.type: {
      return {
        ...state,
        isInitialFetchDone: true,
        isError: true,
        isLoading: false,
      };
    }

    default:
      return state;
  }
};
