import React from 'react';
import { connect } from 'react-redux';

import classnames from 'classnames';
import { selectNoTrial } from 'src/common/state/me/me-selectors';
import { Markdown } from 'src/misc/markdown';
import type { RootState } from 'src/redux';
import { getExperiment } from 'src/utils/get-experiment';

import { Accordion } from '../accordion/accordion';
import './faq.css';
import { FAQItem } from './types';

type FAQOwnProps = {
  questions: FAQItem[];
  variant?: 'lavender';
};

type FAQProps = FAQOwnProps & {
  noTrial: boolean;
};

const FAQComponent = ({
  noTrial: noTrialProp,
  questions,
  variant,
}: FAQProps) => {
  const noTrial = getExperiment('noFreeTrials') ? true : noTrialProp;
  const filteredQuestions = questions.filter((faqItem) =>
    noTrial ? !faqItem.isNoTrial : true
  );

  return (
    <section
      className={classnames('faq-section', {
        [`faq-section--${variant}`]: variant !== undefined,
      })}
    >
      <div className="faq-section__content">
        <h2 className="faq-section__content__title">
          Frequently asked questions
        </h2>

        <Accordion>
          {filteredQuestions.map((question, i) => (
            <Accordion.Item key={`faq-item-${i}`} title={question.title}>
              {typeof question.content === 'string' ? (
                <Markdown>{question.content}</Markdown>
              ) : (
                question.content
              )}
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </section>
  );
};

const mapStateToProps = (state: RootState) => ({
  noTrial: selectNoTrial(state),
});

export const FAQ = connect(mapStateToProps)(FAQComponent);
