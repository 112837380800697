import React, { type PropsWithChildren } from 'react';

import classnames from 'classnames';

import { Flex } from '../flex/flex';
import './chip.css';

type ChipProps = PropsWithChildren<{
  className?: string;
  color?: 'primary' | 'secondary';
}>;

export const Chip = ({
  children,
  className,
  color = 'secondary',
}: ChipProps) => (
  <Flex
    direction="row"
    alignItems="center"
    className={classnames('sb-chip', `sb-chip--${color}`, className)}
    inline
  >
    {children}
  </Flex>
);
