import React from 'react';

import { CheckCircle } from 'phosphor-react';
import { Flex } from 'src/common/components/flex/flex';

import './plan-features-list.css';

type PlanFeaturesListProps = {
  children: React.ReactNode;
};

export const PlanFeaturesList = ({ children }: PlanFeaturesListProps) => (
  <Flex as="ul" className="plan-features-list">
    {children}
  </Flex>
);

type PlanFeatureItemProps = {
  children: React.ReactNode;
};

export const PlanFeatureItem = ({ children }: PlanFeatureItemProps) => (
  <Flex as="li" direction="row" className="plan-features-list__item">
    <CheckCircle
      size={24}
      weight="fill"
      className="plan-features-list__item__icon"
    />
    {children}
  </Flex>
);
