import { useEffect, useState } from 'react';

type UseIsThinnerThanBreakpointType = {
  breakpoint: number;
};

export function useIsThinnerThanBreakpoint({
  breakpoint,
}: UseIsThinnerThanBreakpointType) {
  const isBrowser = typeof window !== 'undefined';
  const [screen, setScreen] = useState(isBrowser ? window.innerWidth : 0);

  useEffect(() => {
    const setSideScreen = (): void => {
      setScreen(window.innerWidth);
    };

    if (isBrowser) {
      setSideScreen();
      window.addEventListener('resize', setSideScreen);

      return () => {
        window.removeEventListener('resize', setSideScreen);
      };
    }
  }, [isBrowser]);

  return screen < breakpoint;
}
