import React, { useEffect } from 'react';

import classNames from 'classnames';
import { Button } from 'src/common/components/button/button';
import { Modal } from 'src/common/components/modal/modal';
import { Spinner } from 'src/common/components/spinner/spinner';
import { videoCaptionsActions } from 'src/dashboard/state/video-captions/video-captions-actions';
import {
  selectVideoCaptions,
  selectVideoCaptionsError,
  selectVideoCaptionsLoading,
  selectVideoCaptionsModalIsShowing,
  selectVideoCaptionsShortcode,
} from 'src/dashboard/state/video-captions/video-captions-selectors';
import { selectIsMobile } from 'src/misc/selectors';
import { useAppSelector, useAppDispatch } from 'src/redux';

import './video-captions-modal.css';

const DEFAULT_LABEL = 'Default';

const localeToLanguageName = (
  localeToTranslate: string,
  label?: string,
  targetLocale = 'en'
) => {
  if (label !== DEFAULT_LABEL) {
    return label;
  }
  const languageNames = new Intl.DisplayNames([targetLocale], {
    type: 'language',
  });

  try {
    const languageName = languageNames.of(localeToTranslate);

    if (languageName === localeToTranslate) {
      return label ?? localeToTranslate;
    }

    return languageName;
  } catch (error) {
    console.error(
      `Error translating language name from ${localeToTranslate} to ${targetLocale}`
    );
  }

  return label ?? localeToTranslate;
};

export const VideoCaptionsModal = () => {
  const dispatch = useAppDispatch();

  const isMobile = useAppSelector(selectIsMobile);
  const isShowing = useAppSelector(selectVideoCaptionsModalIsShowing);
  const isLoading = useAppSelector(selectVideoCaptionsLoading);
  const error = useAppSelector(selectVideoCaptionsError);
  const shortcode = useAppSelector(selectVideoCaptionsShortcode);
  const captions = useAppSelector(selectVideoCaptions);

  const onClose = () => dispatch(videoCaptionsActions.captionsModal.closed());
  const onDelete = (captionId: number) =>
    shortcode &&
    dispatch(
      videoCaptionsActions.captionsDeleteModal.opened({
        shortcode,
        id: captionId,
      })
    );

  useEffect(() => {
    if (shortcode) {
      dispatch(videoCaptionsActions.fetch.initiated(shortcode));
    }
  }, [dispatch, shortcode]);

  return (
    <Modal
      id="video-captions-modal"
      className="video-captions-modal"
      isShowing={isShowing}
      onClose={onClose}
      title="Captions"
      hideConfirm={true}
      hideCancel={true}
    >
      {isLoading ? (
        <Spinner />
      ) : error ? (
        <div className="error-container">{error}</div>
      ) : (
        <div>
          {captions.map((caption) => (
            <div
              key={caption.language}
              className="video-captions-modal__caption"
            >
              <span className="video-captions-modal__caption-name">
                {localeToLanguageName(caption.language, caption.label)}
              </span>
              <Button
                variant="icon"
                color="primary"
                size="slim"
                title="Delete"
                className={classNames(
                  'video-captions-modal__caption-delete',
                  isMobile && 'video-captions-modal__caption-delete--mobile'
                )}
                onClick={() => onDelete(caption.id)}
              >
                <span className="material-symbols-outlined">delete</span>
              </Button>
            </div>
          ))}
        </div>
      )}

      <div
        className={classNames(
          'video-captions-modal__actions',
          captions.length === 0 && 'video-captions-modal__actions--empty'
        )}
      >
        <Button color="primary" size="slim">
          Add caption track
        </Button>
      </div>
    </Modal>
  );
};
