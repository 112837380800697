import React from 'react';

import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { Button } from 'src/common/components/button/button';
import { Flex } from 'src/common/components/flex/flex';
import { Text } from 'src/common/components/text/text';

import './dismissible-card.css';

type DismissibleCardProps = {
  className?: string;
  isDismissed?: boolean;
  handleDismiss: () => void;
  title: string;
};

export const DismissibleCard: React.FunctionComponent<
  React.PropsWithChildren<DismissibleCardProps>
> = ({ children, className, isDismissed = false, handleDismiss, title }) => {
  if (isDismissed) {
    return null;
  }

  return (
    <Flex className={classnames('dismissible-card', className)}>
      <Flex as="header" direction="row" alignItems="center">
        <Text as="h3" className="dismissible-card__title">
          {title}
        </Text>
        <Button
          onClick={handleDismiss}
          variant="icon"
          className="dismissible-card__close-btn"
        >
          <FontAwesomeIcon icon={faClose} size="lg" title="Dismiss" />
        </Button>
      </Flex>
      <Flex className="dismissible-card__content">{children}</Flex>
    </Flex>
  );
};
