import React from 'react';
import { ToastType, Toaster, resolveValue, toast } from 'react-hot-toast';

import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';

import { Alert, type AlertLevel } from '../alert/alert';
import './toast.css';

const ALERT_TYPE_TO_TOAST_TYPE: Record<ToastType, AlertLevel> = {
  error: 'danger',
  loading: 'info',
  blank: 'info',
  custom: 'info',
  success: 'success',
};

export const ToastManager = () => (
  <Toaster containerClassName="sb-toast-container" position="bottom-center">
    {(t) => (
      <Transition appear show={t.visible}>
        <Alert
          className={classNames(
            'sb-toast',
            `sb-toast--${ALERT_TYPE_TO_TOAST_TYPE[t.type]}`,
            t.className
          )}
          level={ALERT_TYPE_TO_TOAST_TYPE[t.type]}
          action={{
            label: <FontAwesomeIcon icon={faClose} size="lg" />,
            props: {
              className: 'sb-toast__dismiss-btn',
              color: t.type === 'error' ? 'danger' : 'secondary',
              onClick: () => toast.dismiss(t.id),
              title: 'Dismiss',
              variant: 'link',
            },
          }}
        >
          {resolveValue(t.message, t)}
        </Alert>
      </Transition>
    )}
  </Toaster>
);
