import { toast } from 'react-hot-toast';

import type { PayloadAction } from '@reduxjs/toolkit';
import { all, call, takeEvery, put, spawn } from 'redux-saga/effects';
import { fetchCurrentUserAction } from 'src/actions';
import { logMesssage } from 'src/common/logging/logging-actions';
import { getUser } from 'src/common/state/me/me-api';
import { UserOutput } from 'src/common/types/user';
import { Unwrap } from 'src/utils/types';

import { landingActions } from './landing-actions';
import { getMarketingPageBySlug, trackMarketingPageVisit } from './landing-api';

export function* trackPageVisitAndRefetchUser(slug: string) {
  yield call(trackMarketingPageVisit, slug);
  const user: UserOutput = yield call(getUser);
  yield put(fetchCurrentUserAction({ promos: user.promos }));
}

export function* handlePageDataFetch(action: PayloadAction<string>) {
  try {
    const slug = action.payload;
    const marketingData: Unwrap<typeof getMarketingPageBySlug> = yield call(
      getMarketingPageBySlug,
      slug
    );

    if (marketingData.page && marketingData.page.trackVisit) {
      yield spawn(trackPageVisitAndRefetchUser, slug);
    }

    yield put(landingActions.fetchPage.fulfilled(marketingData.page));
  } catch (error) {
    const { message } = error as Error;

    yield call(toast.error, message);
    yield put(landingActions.fetchPage.failed(message));
    yield put(logMesssage(message));
  }
}

export function* watchLandingSaga() {
  yield all([
    takeEvery(landingActions.fetchPage.initiated, handlePageDataFetch),
  ]);
}
