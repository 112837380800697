import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
// @ts-ignore - TODO: Fix type hoisting issue with redux
import createSentryMiddleware from 'redux-sentry-middleware';
import thunkMiddleware from 'redux-thunk';

import { env } from '../env';
import { extendActionsErrorMiddleware } from '../extend-actions-error.middleware';
import reducer from '../reducers';
import type { RootState } from '../reducers/root';
import rootSaga from '../root-saga';

const sagaMiddleware = createSagaMiddleware();

const getReduxMiddlewares = () => {
  const sentryMiddleware = createSentryMiddleware(Sentry, {
    getUserContext: (state: RootState) => state.me,
  });

  const middlewares = [
    extendActionsErrorMiddleware,
    thunkMiddleware,
    sentryMiddleware,
    sagaMiddleware,
  ];

  if (env.isDev) {
    const { createLogger } = require('redux-logger');
    const logger = createLogger({
      predicate: () => true,
    });
    middlewares.push(logger);
  }

  return middlewares;
};

export const store = configureStore({
  reducer,
  devTools: !env.isProd,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // TODO: We store many non-serializable things in redux
      serializableCheck: false,
    }).concat(getReduxMiddlewares()),
});

if (!env.isProd && module.hot) {
  // @ts-ignore
  module.hot.accept('../reducers', () => store.replaceReducer(reducer));
}

sagaMiddleware.run(rootSaga, store.dispatch);

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
