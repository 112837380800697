import { createAction } from '@reduxjs/toolkit';
import type { PrivacyVisibility } from 'src/common/types/privacy';

export const videoPrivacyActions = {
  videoFooter: {
    privacyItem: {
      clicked: createAction<string>('[Video Footer Privacy Item] clicked'),
    },
  },
  videoPrivacyModal: {
    closed: createAction('[Video Privacy Modal] closed'),
    firstRendered: createAction('[Video Privacy Modal] rendered'),
    triggeredBySearchParams: createAction<string>(
      '[Video Privacy Modal] triggered by search params'
    ),
    privacySection: {
      visibility: {
        changed: createAction<{
          shortcode: string;
          visibility: PrivacyVisibility;
        }>('[Video Privacy Modal] visibility changed'),
        errored: createAction<{
          shortcode: string;
          visibility: PrivacyVisibility;
        }>('[Video Privacy Modal] visibility change errored'),
      },
    },
    restrictionSection: {
      allowedDomainInput: {
        changed: createAction<{ shortcode: string; allowedDomain: string }>(
          '[Video Privacy Modal] allowed domain input changed'
        ),
        saved: createAction('[Video Privacy Modal] allowed domain input saved'),
      },
      domainRestrictions: {
        toggled: createAction<string>(
          '[Video Privacy Modal] domain restrictions toggled'
        ),
        errored: createAction<string>(
          '[Video Privacy Modal] domain restrictions errored'
        ),
        upsellClicked: createAction<string>(
          '[Video Privacy Modal] domain restrictions upsell clicked'
        ),
      },
      passwordProtection: {
        turnedOff: createAction<string>(
          '[Video Privacy Modal] password protection turned off'
        ),
        turnedOn: createAction<string>(
          '[Video Privacy Modal] password protection turned on'
        ),
      },
      setPasswordButton: {
        clicked: createAction<{ shortcode: string; password: string }>(
          '[Video Privacy Modal] set password button clicked'
        ),
      },
    },
    playerPreferencesSection: {
      allowDownload: {
        toggled: createAction<string>(
          '[Video Privacy Modal] allow downloading toggled'
        ),
        errored: createAction<string>(
          '[Video Privacy Modal] allow downloading toggle errored'
        ),
      },
      allowSharing: {
        toggled: createAction<string>(
          '[Video Privacy Modal] allow sharing toggled'
        ),
        errored: createAction<string>(
          '[Video Privacy Modal] allow sharing toggle errored'
        ),
      },
      hideViewCount: {
        toggled: createAction<string>(
          '[Video Privacy Modal] hide view count toggled'
        ),
        errored: createAction<string>(
          '[Video Privacy Modal] hide view count toggle errored'
        ),
      },
    },
    advanced: {
      resetSettingsClicked: createAction(
        '[Video Privacy Modal] reset settings clicked'
      ),
    },
    resetModal: {
      cancelClicked: createAction(
        '[Video Privacy Modal Reset Modal] cancel clicked'
      ),
      confirmClicked: createAction<string>(
        '[Video Privacy Modal Reset Modal] confirm clicked'
      ),
      resetting: createAction(
        '[Video Privacy Modal Reset Modal] privacy resetting'
      ),
      resetSuccess: createAction(
        '[Video Privacy Modal Reset Modal] privacy reset successfully'
      ),
      resetFail: createAction(
        '[Video Privacy Modal Reset Modal] privacy reset failed'
      ),
    },
  },
};
