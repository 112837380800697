import React from 'react';

import classNames from 'classnames';

import { BlackLogo } from './black';
import './logo.css';
import { StandardLogo } from './standard';
import { CommonLogoProps } from './types';

const Logos = {
  standard: StandardLogo,
  primary: StandardLogo,
  black: BlackLogo,
};

type LogoTypes = keyof typeof Logos;

type LogoProps = CommonLogoProps & {
  type?: LogoTypes;
  theme?: 'light' | 'dark';
};

export const Logo = ({
  type = 'standard',
  theme,
  className,
  style,
}: LogoProps) => {
  let LogoComponent = Logos[type];

  return (
    <LogoComponent
      className={classNames(
        className,
        theme,
        `logo-type-${type}`,
        `og-logo-type-${type}`
      )}
      style={style}
    />
  );
};
