import { type PayloadAction } from '@reduxjs/toolkit';

import { subscribeAction, updateCardAction } from '../actions';

export type CheckoutState = {
  purchasing: boolean;
  error: boolean;
  message: string | null;
};

const initialState = {
  purchasing: false,
  error: false,
  message: null,
};

export default function checkout(
  state = initialState,
  action: PayloadAction<any> & { error?: string }
) {
  switch (action.type) {
    case updateCardAction.type:
    case subscribeAction.type:
      if (action.error) {
        return {
          ...state,
          purchasing: false,
          error: true,
          message: action.payload.message,
        };
      }
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export const selectCheckoutState = (state: any) =>
  state.checkout as CheckoutState;
