import React from 'react';

import { Flex } from 'src/common/components/flex/flex';

import { PlanCallToAction } from '../plan-call-to-action/plan-call-to-action';
import { PlanDescription } from '../plan-description/plan-description';
import {
  PlanFeaturesList,
  PlanFeatureItem,
} from '../plan-features-list/plan-features-list';
import { PlanPrice } from '../plan-price/plan-price';
import { PlanTitleContainer } from '../plan-title-container/plan-title-container';
import { PlanTitle } from '../plan-title/plan-title';
import './plan.css';

type PlanProps = {
  children: React.ReactNode;
};

const Plan = ({ children }: PlanProps) => (
  <Flex as="article" className="pricing-plan">
    {children}
  </Flex>
);

Plan.TitleContainer = PlanTitleContainer;
Plan.Title = PlanTitle;
Plan.Price = PlanPrice;
Plan.Description = PlanDescription;
Plan.FeaturesList = PlanFeaturesList;
Plan.FeatureItem = PlanFeatureItem;
Plan.CallToAction = PlanCallToAction;

export { Plan };
