import { createAction } from '@reduxjs/toolkit';
import type { PlanCadence } from 'src/pricing/types';

import type {
  CheckoutQueryParams,
  IntentType,
  ProcessingStep,
} from './checkout-types';

export const checkoutActions = {
  billingDetails: {
    initFromQuery: createAction<CheckoutQueryParams>(
      '[Checkout Billing Details] init from query'
    ),
    cadenceSwitch: {
      clicked: createAction<PlanCadence>(
        '[Checkout Billing Details Selectable Cadence] clicked'
      ),
    },
    next: {
      intentFetch: {
        initiated: createAction<string>(
          '[Checkout Billing Details Next Intent Fetch] initiated'
        ),
        fulfilled: createAction<{ client_secret: string; type: IntentType }>(
          '[Checkout Billing Details Next Intent Fetch] fulfilled'
        ),
        failed: createAction(
          '[Checkout Billing Details Next Intent Fetch] failed'
        ),
      },
      paymentStatus: {
        failed: createAction<string>(
          '[Checkout Billing Details Next Payment Status] failed'
        ),
        processing: createAction(
          '[Checkout Billing Details Next Payment Status] processing'
        ),
      },
      processingOverlay: {
        open: createAction<ProcessingStep>(
          '[Checkout Billing Details Next Processing Overlay] open'
        ),
        close: createAction(
          '[Checkout Billing Details Next Processing Overlay] close'
        ),
      },
    },
  },
};
