import { MEDIA_STATUS } from 'src/common/types/media';
import type { VideoRepresentation } from 'src/dashboard/types';

export type Video = VideoRepresentation & {
  waitingToUpload: boolean;
  upload_percent: number;
  retries: number;
  upload_speed: number;
};

type DynamicSpeedStatus = 'dynamic-speed';
type SpeedStatus =
  | ''
  | 'Upload queued.'
  | 'Queued for processing.'
  | 'Downloading...'
  | 'Uploading...'
  | 'Still working...'
  | 'Processing...'
  | DynamicSpeedStatus;

type VideoStats = {
  percent: number;
  status: SpeedStatus;
  key: 'upload' | 'downloading' | 'processing';
};

const MEBIBYTE = 2 ** 20;
const getMbpsUploadSpeed = (uploadSpeed: number) =>
  ((uploadSpeed / MEBIBYTE) * 8).toFixed(1).toString();

const getUploadSpeedStatus = (uploadSpeed: number) =>
  `Uploading... ${getMbpsUploadSpeed(uploadSpeed)} Mbps` as DynamicSpeedStatus;

export const extractVideoStats = (video: Video): VideoStats => {
  let percent: VideoStats['percent'] = 0;
  let status: VideoStats['status'] = '';
  let key: VideoStats['key'] = 'upload';

  if (video.waitingToUpload) {
    status = 'Upload queued.';
  } else if (video.status === MEDIA_STATUS.UPLOADING) {
    percent = video.upload_percent || 0;
    if (video.retries) {
      status = 'Still working...';
    } else if (isNaN(video.upload_speed)) {
      status = 'Uploading...';
    } else {
      status = getUploadSpeedStatus(video.upload_speed);
    }
  } else if (video.queued) {
    key = 'processing';
    status = 'Queued for processing.';
  } else {
    key = 'processing';
    status = 'Processing...';
    percent = video.percent || 0;
  }
  return { key, status, percent };
};
