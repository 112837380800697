import React from 'react';
import { Provider } from 'react-redux';
import {
  applyRouterMiddleware,
  browserHistory,
  Router,
  createRoutes,
} from 'react-router';
import { useScroll } from 'react-router-scroll';

import * as Sentry from '@sentry/browser';
import { PersistGate } from 'redux-persist/integration/react';
import AppLoadingContainer from 'src/app/app-loading/app-loading-container';
import { setDarkModeCookie } from 'src/utils/set-dark-mode-cookie';

import { pollUploads, fetchCurrentUser, checkTranscodes } from './actions';
import { env } from './env';
import { getCookie, setCookie } from './misc/cookies';
import { persistor, store } from './redux/store';
import { routes } from './routes';

Sentry.init({
  dsn: 'https://e3a99ea6ec894448b4e0504a8fa294ae@o20911.ingest.sentry.io/5192543',
  release: env.VERSION,
});

window._STM_PREDATA = window._STM_PREDATA || new Proxy({}, { get: () => '' });

// We need a Root component for React Hot Loading.
function Root() {
  import('autotrack').then(() => {
    window.ga('create', process.env.REACT_APP_GA_KEY, 'auto');
    window.ga('require', 'eventTracker');
    window.ga('require', 'urlChangeTracker');
    window.ga('require', 'ec');
    window.ga('send', 'pageview');
  });

  if (getCookie('src_external')) {
    setCookie('src_external', document.referrer || 'DIRECT', 90000);
  }

  setDarkModeCookie(store.getState().me);

  Promise.all([
    store.dispatch(fetchCurrentUser()),
    new Promise((resolve) => (window.onload = resolve)),
  ]);

  // start polling
  setInterval(() => {
    store.dispatch(pollUploads());
  }, 5000);
  setInterval(() => {
    store.dispatch(checkTranscodes());
  }, 100);

  window.addEventListener('unload', () => {
    navigator.sendBeacon(`${env.API_HOST}/log`, 'Window closed.');
  });

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <Router
          history={browserHistory}
          routes={createRoutes(routes)}
          render={applyRouterMiddleware(useScroll())}
        />
        <AppLoadingContainer />
      </PersistGate>
    </Provider>
  );
}

export default Root;
