import { Link } from 'react-router';

import './menu-item-component.css';

type MenuItemProps = {
  label: string;
  icon: string;
  onClick?: () => void;
  to?: string;
  target?: '_blank' | '_self';
};

export const MenuItem = ({
  label,
  icon,
  onClick,
  to,
  target = '_self',
}: MenuItemProps) => {
  const Wrapper = to ? Link : 'div';

  return (
    <Wrapper
      className="user-menu-item"
      to={to!}
      onClick={onClick}
      target={target}
    >
      <span className="user-menu-item__icon">
        <span className="material-icons">{icon}</span>
      </span>{' '}
      <span className="user-menu-item__label">{label}</span>
    </Wrapper>
  );
};
