import React from 'react';
import { Link } from 'react-router';

import isEmpty from 'lodash/isEmpty';
import { path } from 'ramda';
import { ActivatedSubscriptionInfo } from 'src/app/subscription/subscription-types';
import { Alert } from 'src/common/components/alert/alert';
import { Button } from 'src/common/components/button/button';
import { Text } from 'src/common/components/text/text';
import { AvailablePlan } from 'src/pricing/types';

import './domain-restrictions-insufficient-plan-upsell.css';

type DomainRestrictionsInsufficientPlanUpsellProps = {
  currentPlan: ActivatedSubscriptionInfo;
  proPlan?: AvailablePlan;
  openChangeCurrentPlanModal: (planId: string) => void;
};

export const DomainRestrictionsInsufficientPlanUpsell = ({
  currentPlan,
  proPlan,
  openChangeCurrentPlanModal,
}: DomainRestrictionsInsufficientPlanUpsellProps) => {
  const planId = path<string>([currentPlan.cadence, 'id'], proPlan) || null;
  if (isEmpty(planId)) {
    return null;
  }

  const triggerUpsell = () => {
    openChangeCurrentPlanModal(planId);
  };

  return (
    <Alert level="info">
      <Text className="domain-restrictions-insufficient-plan-upsell">
        This video uses{' '}
        <Link to="/settings">default domain privacy settings</Link>.{' '}
        <Button
          variant="link"
          onClick={triggerUpsell}
          className="domain-restrictions-insufficient-plan-upsell__cta"
        >
          Upgrade your plan
        </Button>{' '}
        to enable custom domain privacy on individual videos.
      </Text>
    </Alert>
  );
};
