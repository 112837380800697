import {
  EmbedContainerOptions,
  EmbedPlayerOptions,
} from 'src/dashboard/state/embed-preview/embed-preview-types';

const generatePlayerUrl = (
  shortcode: string,
  playerOptions: EmbedPlayerOptions
) => {
  const { autoplay, loop, muted, controls } = playerOptions;

  const searchParams = new URLSearchParams();
  if (autoplay) {
    searchParams.set('autoplay', '1');
  }
  if (!loop) {
    searchParams.set('loop', '0');
  }
  if (muted) {
    searchParams.set('muted', '1');
  }
  if (!controls) {
    searchParams.set('nocontrols', '1');
  }
  const baseUrl =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:5001'
      : `https://${window.location.host}`;

  return `${baseUrl}/e/${shortcode}?${searchParams.toString()}`;
};

const stylePropsToString = (
  styleProps: Record<string, string | number | undefined>
) =>
  Object.entries(styleProps)
    .map(([key, value]) => `${key}:${value}`)
    .join('; ');

const iframeStyleProps = (size: EmbedContainerOptions['size']) => {
  if (size === 'fixed') {
    return {
      border: 'none',
    };
  }

  return {
    border: 'none',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: '0px',
    top: '0px',
    overflow: 'hidden',
  };
};

const generateIframeHtml = (
  shortcode: string,
  containerOptions: EmbedContainerOptions,
  playerOptions: EmbedPlayerOptions
) => {
  const { height, width, size } = containerOptions;
  const iframeWidth = size === 'fixed' ? width : '100%';
  const iframeHeight = size === 'fixed' ? height : '100%';
  const playerUrl = generatePlayerUrl(shortcode, playerOptions);

  const iframeAllowPolicy = ['fullscreen'];
  if (playerOptions.autoplay) {
    iframeAllowPolicy.push('autoplay');
  }

  const iframeProps = {
    allow: iframeAllowPolicy.join(';'),
    allowfullscreen: true,
    height: iframeHeight,
    src: playerUrl,
    width: iframeWidth,
  };
  const iframePropsString = Object.entries(iframeProps)
    .map(([key, value]) => (value === true ? key : `${key}="${value}"`))
    .join(' ');

  const iframeStyleString = stylePropsToString(iframeStyleProps(size));

  return `<iframe ${iframePropsString} style="${iframeStyleString};"></iframe>`;
};

const containerStyleProps = ({
  height,
  size,
  width,
}: EmbedContainerOptions) => {
  if (size === 'fixed') {
    return {};
  }

  const aspectRatio = width && height ? height / width : 0.5625;

  return {
    position: 'relative',
    width: '100%',
    height: '0px',
    'padding-bottom': (aspectRatio * 100).toFixed(3) + '%',
  };
};

export const generateEmbedCode = (
  shortcode: string,
  containerOptions: EmbedContainerOptions,
  playerOptions: EmbedPlayerOptions
) => {
  const iframeHtml = generateIframeHtml(
    shortcode,
    containerOptions,
    playerOptions
  );

  if (containerOptions.size === 'fixed') {
    return iframeHtml;
  }

  const containerStyleString = stylePropsToString(
    containerStyleProps(containerOptions)
  );

  return `<div style="${containerStyleString}">${iframeHtml}</div>`;
};
