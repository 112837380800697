import type { List } from 'immutable';
import type { VideoRepresentation } from 'src/dashboard/types';

export const getShortcodesFromVideosList = (
  videos: List<VideoRepresentation>
) =>
  videos
    .toArray()
    .map((video) => video.shortcode)
    .filter(Boolean) as Array<string>;
