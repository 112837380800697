import React, { useEffect } from 'react';

import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from 'src/common/components/button/button';
import { type WrappedComponentDropzoneProps } from 'src/common/components/dropzone/dropzone';
import { Flex } from 'src/common/components/flex/flex';
import { Text } from 'src/common/components/text/text';

import {
  type ReplaceVideoModalDropzoneError,
  type UpsellReason,
  getErrorMessage,
  errorToUpsellReason,
} from '../replace-video-modal-utils';
import './replace-video-modal-dropzone.css';

type ReplaceVideoModalDropzoneContentOwnProps = {
  showUpsell: (reason: UpsellReason) => void;
};

type ReplaceVideoModalDropzoneContentProps =
  ReplaceVideoModalDropzoneContentOwnProps &
    WrappedComponentDropzoneProps<ReplaceVideoModalDropzoneError>;

export const ReplaceVideoModalDropzoneContent = ({
  showUpsell,
  isDragActive,
  error,
  triggerFileSelect,
}: ReplaceVideoModalDropzoneContentProps) => {
  const errorMessage = getErrorMessage(error);

  useEffect(() => {
    if (error) {
      const reason = errorToUpsellReason(error);
      if (reason) {
        showUpsell(reason);
      }
    }
  }, [error]);

  return (
    <Flex
      className={classNames('replace-video-dropzone', {
        'replace-video-dropzone--dragging': isDragActive,
        'replace-video-dropzone--error': !isDragActive && error,
      })}
      justifyContent="center"
      alignItems="center"
      onClick={triggerFileSelect}
    >
      {isDragActive ? (
        <Text>Drop video here</Text>
      ) : (
        <>
          {errorMessage && (
            <Text className="replace-video-dropzone__error" as="span">
              {errorMessage}
            </Text>
          )}
          <FontAwesomeIcon
            className="replace-video-dropzone__icon"
            icon={faCloudArrowUp}
          />
          <Button className="replace-video-dropzone__button" color="primary">
            Upload a video
          </Button>
          <Text className="replace-video-dropzone__note" as="p">
            Or drop a video here
          </Text>
        </>
      )}
    </Flex>
  );
};
