import React, { Component } from 'react';
import { Beforeunload } from 'react-beforeunload';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { browserHistory, Link } from 'react-router';

import classnames from 'classnames';
import debounce from 'lodash/debounce';
import { compose } from 'redux';
import {
  deselectAction,
  deselectAllAction,
  editUserAction,
  fetchVideos,
  selectAction,
  zoomAction,
} from 'src/actions';
import AnimatedLogo from 'src/assets/logo.gif';
import { Flex } from 'src/common/components/flex/flex';
import { Text } from 'src/common/components/text/text';
import { withIsSlideOver } from 'src/common/hocs/with-is-slide-over';
import {
  selectAllMeState,
  selectIsLoggedIn,
  selectPro,
  selectSelectedVideos,
} from 'src/common/state/me/me-selectors';
import { ContextMenu } from 'src/dashboard/components/labels-context-menu/labels-context-menu';
import { LabelsSidebar } from 'src/dashboard/components/labels-sidebar/labels-sidebar';
import PlanLimitsAlerts from 'src/dashboard/components/plan-limits-alerts/plan-limits-alerts';
import { UpsellMessageContainer } from 'src/dashboard/components/upsell-message/upsell-message';
import { VideoListHeader } from 'src/dashboard/components/video-list-header/video-list-header';
import { VideosSearchAndSort } from 'src/dashboard/components/videos-search-and-sort/videos-search-and-sort';
import { dashboardAssignLabelsActions } from 'src/dashboard/state/assign-labels/assign-labels-actions';
import { dashboardSidebarActions } from 'src/dashboard/state/labels/labels-actions';
import {
  selectActiveLabel,
  selectActiveLabelId,
  selectIsSidebarOpen,
  selectIsThereAnyLabel,
} from 'src/dashboard/state/labels/labels-selectors';
import { dashboardUpgradeActions } from 'src/dashboard/state/upgrade/upgrade-actions';
import {
  getVideosForActiveLabel,
  selectAllVideosState,
  selectVideosIsLoading,
} from 'src/dashboard/state/videos/videos-selectors';
import Pagination from 'src/misc/Pagination';
import { getAllVideos, selectIsIOs, selectIsMobile } from 'src/misc/selectors';
import { processUploadedFiles } from 'src/misc/utilities';
import { shouldRenderSidebar } from 'src/utils/selectors/should-render-sidebar';

import { getUserTier } from '../selectors/users';
import VideoCreator from './VideoCreator';
import ZoomOverlay from './ZoomOverlay';
import { AllVideosEmptyState } from './all-videos-empty-state';
import './dashboard.css';
import { UnlabeledVideosEmptyState } from './unlabeled-empty-state';
import VideoItem from './videoItem/VideoItem';
import './videoList.css';

const mapStateToProps = (state, ownProps) => {
  const { query } = ownProps;

  const videos = selectAllVideosState(state);
  const me = selectAllMeState(state);

  return {
    me,
    query,
    videos,
    userTier: getUserTier(me),
    isIOs: selectIsIOs(state),
    pro: selectPro(state),
    selectedVideos: selectSelectedVideos(state),
    pages: Math.ceil(videos.total / videos.count),
    items: getVideosForActiveLabel(state),
    isFetching: selectVideosIsLoading(state),
    isMobile: selectIsMobile(state),
    activeUploads: getAllVideos(state).filter((v) => !v.status),
    isLoggedIn: selectIsLoggedIn(state),
    isSidebarOpen: selectIsSidebarOpen(ownProps.isSlideOver)(state),
    activeLabel: selectActiveLabel(state),
    withSidebar: shouldRenderSidebar(state),
    isThereAnyLabel: selectIsThereAnyLabel(state),
    activeLabelId: selectActiveLabelId(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  select: (video) => dispatch(selectAction(video)),
  deselect: (video) => dispatch(deselectAction(video)),
  deselectAll: () => dispatch(deselectAllAction()),
  closeZoom: () => dispatch(zoomAction()),
  handleEditLabelClick: (id) =>
    dispatch(
      dashboardSidebarActions.title.contextMenu.editLabelButton.clicked(id)
    ),
  handleDeleteLabelClick: (id) => {
    dispatch(
      dashboardSidebarActions.title.contextMenu.deleteLabelButton.clicked(id)
    );
  },
  handleEditLabelsClick: (shortcode) =>
    dispatch(
      dashboardAssignLabelsActions.videoCard.moreMenu.editLabelsButton.clicked(
        shortcode
      )
    ),
  handleShowPlanPicker: (source) =>
    dispatch(
      dashboardUpgradeActions.upgradeModal.triggered({
        queryParams: {
          from: 'dashboard',
          src_internal: `dashboard-${source}`,
        },
      })
    ),
});

class VideosList extends Component {
  constructor() {
    super();
    this.state = {
      playerMuted: true,
      currentSearch: null,
      canShowWelcome: false,
    };

    this.dispatchFetch = this.dispatchFetch.bind(this);
    this.dispatchFetchDebounced = debounce(this.dispatchFetch, 500, {
      leading: true,
      trailing: true,
    });
  }

  dispatchFetch(options, force) {
    this.setState({ currentSearch: options.search });
    this.props.dispatch(fetchVideos(options, force));
  }

  getQueryArgs() {
    const { query } = this.props;
    return {
      sort: query.sort || 'date_added',
      sortd: query.sortd || 'DESC',
      search: query.search,
      page: parseInt(query.page || 1),
      labels: query.labels,
      excludeAnyWithLabels: query.excludeAnyWithLabels,
    };
  }

  createQueryArgs(options) {
    const newQuery = { ...this.props.query, ...(options || {}) };
    const searchParams = new URLSearchParams(window.location.search);
    for (let q of Object.keys(newQuery)) {
      if (!newQuery[q]) {
        searchParams.delete(q);
      } else {
        searchParams.set(q, newQuery[q]);
      }
    }
    return searchParams;
  }

  fetch(options = {}, force = false, debounced = false) {
    browserHistory.replace('?' + this.createQueryArgs(options).toString());

    const processedOptions = {
      count: 12,
      ...this.getQueryArgs(),
      ...options,
    };

    this.setState(options);
    if (debounced) {
      this.dispatchFetchDebounced(processedOptions, force);
    } else {
      this.dispatchFetch(processedOptions, force);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.me.reloadVideos) {
      this.fetch({}, true);
      this.props.dispatch(editUserAction({ reloadVideos: false }));
    }
    if (prevProps.activeLabelId !== this.props.activeLabelId) {
      // setting page to `undefined` instead of `1` so that the URL looks cleaner
      this.fetch({
        labels: this.props.activeLabel?.id,
        page: undefined,
        search: '',
        excludeAnyWithLabels:
          this.props.activeLabelId === 'unlabelled-videos' ? 'true' : '',
      });
    }
  }

  componentDidMount() {
    this.fetch();

    setTimeout(() => {
      this.setState({ canShowWelcome: true });
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  isSelected(shortcode) {
    return this.props.selectedVideos
      .map(({ shortcode }) => shortcode)
      .contains(shortcode);
  }

  render() {
    const {
      me,
      query,
      dispatch,
      activeUploads,
      videos,
      activeLabel,
      activeLabelId,
      withSidebar,
      isLoggedIn,
      isThereAnyLabel,
      handleEditLabelsClick,
    } = this.props;

    const isVideosListEmpty =
      this.props.isFetching === false && this.props.items.length === 0;
    const isViewingUnlabeledVideos = activeLabelId === 'unlabelled-videos';

    const staticLabelName = isViewingUnlabeledVideos
      ? 'Unlabeled videos'
      : 'All videos';

    const showUnlabeledEmptyState =
      isViewingUnlabeledVideos && isVideosListEmpty;

    return (
      <Beforeunload
        onBeforeunload={(event) => {
          if (activeUploads.length) {
            event.preventDefault();
          }
        }}
      >
        <Helmet>
          <title>Dashboard - Streamable</title>
          <meta
            name="description"
            content="Upload, edit, and manage your video library."
          ></meta>
          <link rel="canonical" href="https://streamable.com/videos" />
        </Helmet>

        {withSidebar ? <LabelsSidebar /> : null}

        <Flex direction="column" style={{ flexGrow: 1 }}>
          <Flex as="header" className="videos-list-header">
            <PlanLimitsAlerts />
            {query.error ? (
              <div className="alert alert-danger">
                {query.error}
                <span
                  onClick={() =>
                    browserHistory.replace(
                      '?' + this.createQueryArgs({ error: null }).toString()
                    )
                  }
                  style={{ float: 'right', cursor: 'pointer' }}
                >
                  Hide
                </span>
              </div>
            ) : (
              ''
            )}
            {query.alert ? (
              query.alert === 'verified' ? (
                <div className="alert alert-info">
                  Your email has been verified.
                  <span
                    onClick={() =>
                      browserHistory.replace(
                        '?' + this.createQueryArgs({ alert: null }).toString()
                      )
                    }
                    style={{ float: 'right', cursor: 'pointer' }}
                  >
                    Hide
                  </span>
                </div>
              ) : (
                <div className="alert alert-info">
                  {query.alert === 'downgrade'
                    ? 'Downgrade successful. '
                    : query.alert === 'upgrade'
                      ? 'Upgrade successful. '
                      : query.alert === 'already-subscribed'
                        ? 'You are already subscribed. '
                        : ''}
                  You're now on the <Link to="/billing">{me.plan_name}</Link>{' '}
                  plan.
                  <span
                    onClick={() =>
                      browserHistory.replace(
                        '?' + this.createQueryArgs({ alert: null }).toString()
                      )
                    }
                    style={{ float: 'right', cursor: 'pointer' }}
                  >
                    Hide
                  </span>
                </div>
              )
            ) : (
              ''
            )}
            <UpsellMessageContainer
              isLoggedIn={isLoggedIn}
              numberOfStaleVideos={me.stale}
              showPlanPicker={this.props.handleShowPlanPicker}
            />

            {this.props.selectedVideos.size === 0 && <VideoCreator />}

            <VideoListHeader
              onChange={() => this.fetch({}, true)}
              {...this.props}
              {...this.getQueryArgs()}
            />
          </Flex>

          <Flex
            className={classnames('sb-dashboard-content', {
              'sb-dashboard-content--full-height':
                this.props.items.length === 0,
            })}
          >
            <div
              className={classnames('videos-list', {
                'videos-list--full-height': this.props.items.length === 0,
              })}
            >
              {withSidebar ? (
                <Flex direction="row" alignItems="center">
                  <Text element="h2" className="videos-list__title">
                    <Flex
                      direction="row"
                      className="videos-list__title-container"
                    >
                      {activeLabel ? activeLabel.name : staticLabelName}
                      {videos.loaded && (
                        <span className="videos-list__title-count">
                          ({videos.total.toLocaleString()})
                        </span>
                      )}
                    </Flex>
                  </Text>
                  {activeLabel && (
                    <Flex className="videos-list__context-menu-container">
                      <ContextMenu
                        options={[
                          {
                            icon: 'edit',
                            name: 'Rename',
                            onClick: () =>
                              this.props.handleEditLabelClick(activeLabel.id),
                          },
                          {
                            icon: 'delete',
                            name: 'Delete',
                            onClick: () =>
                              this.props.handleDeleteLabelClick(activeLabel.id),
                          },
                        ]}
                      />
                    </Flex>
                  )}
                </Flex>
              ) : null}

              {this.state.currentSearch || videos.total > 6 ? (
                <VideosSearchAndSort
                  onSearchChanged={(search) => {
                    this.fetch({ search, page: 1 }, false, true);
                  }}
                  onSortChanged={(sort, sortd) => {
                    this.fetch({ sort, sortd });
                  }}
                  search={this.props.query.search}
                  sortedBy={videos.sort}
                />
              ) : null}
              {this.props.items.length > 0 && (
                <div className="grid row videos-list-items">
                  {this.props.items.map((video) => (
                    <VideoItem
                      userTier={this.props.userTier}
                      me={this.props.me}
                      key={video.shortcode || video.local_id}
                      selectableKey={video.shortcode || video.local_id}
                      type="grid"
                      video={video}
                      selected={this.isSelected(video.shortcode)}
                      onSelect={() => {
                        this.props.select(video);
                      }}
                      onDeselect={() => {
                        this.props.deselect(video);
                      }}
                      isMobile={this.props.isMobile}
                      showWelcome={
                        video.new_upload &&
                        this.props.items.length === 1 &&
                        this.props.me.first_run
                      }
                      handleEditLabelsClick={
                        isThereAnyLabel
                          ? () => handleEditLabelsClick(video.shortcode)
                          : undefined
                      }
                    />
                  ))}
                </div>
              )}
              {isVideosListEmpty ? (
                this.state.currentSearch ? (
                  <div
                    className="video-list-placeholder"
                    style={{ marginTop: '50px' }}
                  >
                    No videos match your search
                  </div>
                ) : this.state.canShowWelcome ? (
                  <div className="row">
                    <div className="video-list-placeholder">
                      <form
                        ref={(r) => {
                          if (r) {
                            this.formRef = r;
                          }
                        }}
                        style={{ display: 'none' }}
                      >
                        <input
                          key="fileInput"
                          type="file"
                          multiple={true}
                          ref="fileInput"
                          onChange={() => {
                            const files = this.refs.fileInput.files;
                            if (!files.length) {
                              return;
                            }
                            processUploadedFiles(
                              dispatch,
                              files,
                              null,
                              me.plan_max_length,
                              me.plan_max_size
                            ).then(() => {
                              this.formRef.reset();
                            });
                          }}
                        />
                      </form>
                      <div
                        className="embed-container"
                        onClick={(e) => {
                          e.preventDefault();
                          this.refs.fileInput.click();
                        }}
                      >
                        {showUnlabeledEmptyState ? (
                          <UnlabeledVideosEmptyState />
                        ) : (
                          <AllVideosEmptyState isMobile={this.props.isMobile} />
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
              {this.props.pages > 1 ? (
                <Pagination
                  page={this.getQueryArgs().page}
                  pages={this.props.pages}
                  onChange={(page) => {
                    this.fetch({ page });
                  }}
                />
              ) : null}
              {this.props.isFetching === true ||
              (this.state.currentSearch || '') !==
                (this.getQueryArgs().search || '') ? (
                <div className="loading-indicator">
                  <img src={AnimatedLogo} alt="presentation" />
                </div>
              ) : (
                ''
              )}
              {this.props.me.zoomed ? (
                <ZoomOverlay
                  me={this.props.me}
                  video={this.props.me.zoomed}
                  onClose={this.props.closeZoom}
                  isMobile={this.props.isMobile}
                />
              ) : null}
            </div>
          </Flex>
        </Flex>
      </Beforeunload>
    );
  }
}

export default compose(
  withIsSlideOver,
  connect(mapStateToProps, mapDispatchToProps)
)(VideosList);
