import { createAction } from '@reduxjs/toolkit';

export type UpgradeQueryParams = {
  from?: string;
  src_internal?: string;
};

export const dashboardUpgradeActions = {
  upgradeModal: {
    triggered: createAction<{ queryParams?: UpgradeQueryParams } | undefined>(
      '[Dashboard Upgrade Modal] triggered'
    ),
    closed: createAction('[Dashboard Upgrade Modal] closed'),
  },
};
