export const ANONYMOUS_USER = 'anonymous';
export const FREE_USER = 'free';
export const PAID_USER = 'paid';

export const getUserTier = (me) => {
  if (me.plan) {
    return PAID_USER;
  }

  if (me.user_name) {
    return FREE_USER;
  }

  return ANONYMOUS_USER;
};
