import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';

import classNames from 'classnames';
import { compose } from 'redux';
import { Flex } from 'src/common/components/flex/flex';
import { withIsSlideOver } from 'src/common/hocs/with-is-slide-over';
import { FetchingState } from 'src/common/types/fetching-state';
import {
  dashboardSidebarActions,
  fetchLabelsOnTryAgain,
} from 'src/dashboard/state/labels/labels-actions';
import { ActiveLabelId } from 'src/dashboard/state/labels/labels-reducer';
import {
  selectActiveLabelId,
  selectIsEmptyStateDismissed,
  selectIsError,
  selectIsInitialFetchDone,
  selectIsLoading,
  selectIsSidebarOpen,
  selectLabelsAsArray,
} from 'src/dashboard/state/labels/labels-selectors';
import { LabelWithCount } from 'src/dashboard/types';
import type { RootState } from 'src/redux';
import { shouldExposeUnlabelledVideosView } from 'src/utils/selectors/should-expose-unlabelled-videos-view';

import { LabelRowButton } from '../label-row-button/label-row-button';
import { LabelsLoader } from '../labels-loader/labels-loader';
import { CreateLabelButton } from './create-label-button';
import { LabelsEmptyState } from './labels-empty-state';
import './labels-sidebar.css';

type LabelsSidebarOwnProps = {
  asSlideOver?: boolean;
  withOverlay?: boolean;
  isSlideOver: boolean;
};

type LabelsSidebarProps = Pick<
  FetchingState,
  'isInitialFetchDone' | 'isError' | 'isLoading'
> &
  LabelsSidebarOwnProps & {
    activeLabelId: ActiveLabelId;
    isEmptyStateDismissed: boolean;
    labels: LabelWithCount[];
    isSidebarOpen: boolean;
    shouldExposeUnlabelledVideos: boolean;
    closeSidebar: () => void;
    handleCreateLabelClick: () => void;
    handleDismissEmptyState: () => void;
    handleEditLabelClick: (labelId: number) => void;
    handleDeleteLabelClick: (labelId: number) => void;
    handleLabelButtonClick: (labelId: ActiveLabelId) => void;
    handleTryAgainClick: () => void;
  };

const LabelsSidebarComponent = ({
  activeLabelId,
  isInitialFetchDone,
  isError,
  isLoading,
  isSidebarOpen,
  isEmptyStateDismissed,
  labels,
  asSlideOver,
  withOverlay,
  shouldExposeUnlabelledVideos,
  closeSidebar,
  handleEditLabelClick,
  handleDeleteLabelClick,
  handleCreateLabelClick,
  handleDismissEmptyState,
  handleLabelButtonClick,
  handleTryAgainClick,
}: LabelsSidebarProps) => {
  const [areLabelsFixed, setAreLabelsFixed] = useState(false);
  const areLabelsEmpty = isInitialFetchDone && !isError && labels.length === 0;
  const showEmptyState = areLabelsEmpty && !isEmptyStateDismissed;
  const showUnlabelledButton = shouldExposeUnlabelledVideos && !areLabelsEmpty;

  const headerHeightPx = useMemo(() => {
    const documentStyles = getComputedStyle(document.documentElement);
    const headerHeightRem = Number(
      documentStyles.getPropertyValue('--navbar-height').replace('rem', '')
    );
    const remBasePx = Number(documentStyles.fontSize.replace('px', ''));
    return headerHeightRem * remBasePx;
  }, []);

  useEffect(() => {
    const onScroll = () => {
      if (!areLabelsFixed && window.scrollY > headerHeightPx) {
        setAreLabelsFixed(true);
      }
      if (areLabelsFixed && window.scrollY < headerHeightPx) {
        setAreLabelsFixed(false);
      }
    };

    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  }, [areLabelsFixed]);

  return (
    <div
      className={classNames('labels-sidebar-container', {
        'labels-sidebar-container--close': !isSidebarOpen,
        'labels-sidebar-container--slide-over': asSlideOver,
        'labels-sidebar-container--inline': !asSlideOver,
      })}
    >
      {withOverlay && isSidebarOpen && (
        <div className="labels-sidebar__overlay" onClick={closeSidebar} />
      )}
      <Flex
        as="aside"
        className={classNames('labels-sidebar', {
          'labels-sidebar--fixed': areLabelsFixed,
        })}
      >
        <Flex
          direction="column"
          className="labels-sidebar__static-labels-container"
        >
          <LabelRowButton
            icon="grid_view"
            iconClassName={
              activeLabelId === 'all-videos'
                ? 'material-icons-round'
                : 'material-icons'
            }
            onClick={() => handleLabelButtonClick('all-videos')}
            label="All videos"
            isActive={activeLabelId === 'all-videos'}
            id="labels-sidebar-all-videos-button"
          />
          {showUnlabelledButton && (
            <LabelRowButton
              icon="label_off"
              iconClassName={
                activeLabelId === 'unlabelled-videos'
                  ? 'material-icons'
                  : 'material-icons-outlined'
              }
              onClick={() => handleLabelButtonClick('unlabelled-videos')}
              label="Unlabeled"
              isActive={activeLabelId === 'unlabelled-videos'}
              id="labels-sidebar-unlabelled-videos-button"
            />
          )}
        </Flex>

        {showEmptyState && (
          <LabelsEmptyState
            handleCreateLabelClick={handleCreateLabelClick}
            handleDismissEmptyState={handleDismissEmptyState}
          />
        )}

        <LabelsLoader isLoading={isLoading} />

        {isError && (
          <LabelRowButton
            icon="refresh"
            onClick={handleTryAgainClick}
            label="Failed to load labels"
            id="labels-sidebar-retry-load"
          />
        )}

        {!showEmptyState && (
          <CreateLabelButton
            onClick={handleCreateLabelClick}
            id="labels-sidebar-create"
          />
        )}

        {isInitialFetchDone && !isError && !showEmptyState && (
          <ul className="labels-sidebar__labels-list">
            {labels.map((label) => (
              <li key={`label-${label.id}`}>
                <LabelRowButton
                  isActive={label.id === activeLabelId}
                  iconClassName={
                    label.id === activeLabelId
                      ? 'material-icons'
                      : 'material-icons-outlined'
                  }
                  icon="label"
                  id={String(label.id)}
                  label={label.name}
                  onClick={() => handleLabelButtonClick(label.id)}
                  contextMenuOptions={[
                    {
                      icon: 'edit',
                      name: 'Rename',
                      onClick: () => handleEditLabelClick(label.id),
                    },
                    {
                      icon: 'delete',
                      name: 'Delete',
                      onClick: () => handleDeleteLabelClick(label.id),
                    },
                  ]}
                  isDropTarget
                />
              </li>
            ))}
          </ul>
        )}
      </Flex>
    </div>
  );
};

const mapStateToProps = (
  state: RootState,
  ownProps: LabelsSidebarOwnProps
) => ({
  activeLabelId: selectActiveLabelId(state),
  isInitialFetchDone: selectIsInitialFetchDone(state),
  isError: selectIsError(state),
  isLoading: selectIsLoading(state),
  isSidebarOpen: selectIsSidebarOpen(ownProps.isSlideOver)(state),
  labels: selectLabelsAsArray(state),
  isEmptyStateDismissed: selectIsEmptyStateDismissed(state),
  shouldExposeUnlabelledVideos: shouldExposeUnlabelledVideosView(state),
});

const mapDispatchToProps = {
  closeDesktopSidebar: dashboardSidebarActions.sidebar.desktop.closed,
  closeMobileSidebar: dashboardSidebarActions.sidebar.mobile.closed,
  handleCreateLabelClick: dashboardSidebarActions.createLabelButton.clicked,
  handleEditLabelClick:
    dashboardSidebarActions.contextMenu.editLabelButton.clicked,
  handleDeleteLabelClick:
    dashboardSidebarActions.contextMenu.deleteLabelButton.clicked,
  handleLabelButtonClick: dashboardSidebarActions.labelButton.clicked,
  handleTryAgainClick: fetchLabelsOnTryAgain,
  handleDismissEmptyState: dashboardSidebarActions.sidebar.emptyState.dismissed,
};

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: LabelsSidebarOwnProps
) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  closeSidebar: ownProps.isSlideOver
    ? dispatchProps.closeMobileSidebar
    : dispatchProps.closeDesktopSidebar,
});

export const LabelsSidebar = compose(
  withIsSlideOver,
  connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(LabelsSidebarComponent);
