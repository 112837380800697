export const calculateRedirectURLAfterSubscription = () => {
  const currentSearchParams = new URLSearchParams(window.location.search);

  /**
   * We use `plan` and `subscribed` to measure conversion rate
   * so DO NOT remove these but include them
   * wherever we want to redirect the user after subscription
   */
  const conversionParams = {
    plan: currentSearchParams.get('plan') || 'basic',
    subscribed: 'true',
  };
  let pageToRedirectTo = '';
  const newSearchParams = new URLSearchParams(conversionParams);
  newSearchParams.append('alert', conversionParams.plan);

  if (currentSearchParams.get('from') === 'settings') {
    pageToRedirectTo = 'settings';
  } else if (currentSearchParams.get('from') === 'appearance') {
    pageToRedirectTo = 'appearance';
  } else if (currentSearchParams.get('from') === 'video') {
    currentSearchParams.delete('from');
    newSearchParams.delete('alert');
    for (const [key, value] of Array.from(currentSearchParams.entries())) {
      newSearchParams.append(key, value);
    }
  }

  const srcInternal = currentSearchParams.get('src_internal');
  if (srcInternal && !newSearchParams.has('src_internal')) {
    newSearchParams.append('src_internal', srcInternal);
  }

  return `/${pageToRedirectTo}?${newSearchParams.toString()}`;
};
