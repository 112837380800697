import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { selectAllMeState } from 'src/common/state/me/me-selectors';

import { fetchExtraUserInfo } from '../../actions';
import './settings.css';

const mapStateToProps = (state, ownProps) => {
  const me = selectAllMeState(state);
  return me;
};

class UsageInfo extends Component {
  componentDidMount() {
    if (!this.props.user_name) {
      browserHistory.push('/login?redirect=%2Fsettings');
    }

    this.props.dispatch(fetchExtraUserInfo('usage'));
  }

  render() {
    return (
      <div className="settings">
        <Helmet>
          <title>Usage Info - Streamable</title>
          <meta
            name="description"
            content="View your bandwidth and storage usage for your Streamable account."
          ></meta>
          <link
            rel="canonical"
            href={`https://streamable.com/change_payment`}
          />
        </Helmet>
        <div className="card change-payment">
          <div className="card-header">Your Usage</div>
          <div className="card-block">
            {this.props.usage ? (
              <div>
                <div className="stat-value">
                  <b>
                    {Math.floor(
                      this.props.usage.bandwidth / 1000
                    ).toLocaleString()}
                    GB
                  </b>{' '}
                  bandwidth used in last 30 days
                </div>
                <div className="stat-value">
                  <b>
                    {Math.floor(this.props.usage.storage).toLocaleString()}GB
                  </b>{' '}
                  in storage used
                </div>
              </div>
            ) : (
              <div className="loading">Loading...</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(UsageInfo);
