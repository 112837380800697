import React, { useEffect } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import classNames from 'classnames';
import PaymentCollection from 'src/checkout/components/payment-collection';
import { calculateAnnualDiscount } from 'src/checkout/utils/calculate-annual-discount';
import { calculateAnnualPrice } from 'src/checkout/utils/calculate-annual-price';
import { calculateMonthlyPrice } from 'src/checkout/utils/calculate-monthly-price';
import { Flex } from 'src/common/components/flex/flex';
import { Skeleton } from 'src/common/components/skeleton/skeleton';
import { Text } from 'src/common/components/text/text';
import { useEffectOnce } from 'src/common/hooks/use-effect-once';
import { ANNUAL_CADENCE, MONTHLY_CADENCE } from 'src/pricing/constants';
import { AvailablePlan, PlanCadence } from 'src/pricing/types';
import { triggerGoogleAnalyticsEvent } from 'src/utils/trigger-google-analytics-event';

import DueToday from '../due-today';
import PaymentCollectionSkeleton from '../payment-collection-skeleton';
import { SelectablePlan } from '../selectable-plan/selectable-plan';
import SmallPrintDetails from '../small-print-details';
import './billing-details.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);

type BillingDetailsComponentOwnProps = {
  chosenPlan: AvailablePlan | null;
  handleSelectCadence: (cadence: PlanCadence) => void;
  selectedCadence: PlanCadence | null;
};

export type BillingDetailsComponentProps = BillingDetailsComponentOwnProps & {
  clientSecret: string | null;
  redirectStatus: 'succeeded' | undefined;
  shouldRenderPlanCadencePicker: boolean;
  fetchIntent: (planId: string) => any;
};

export const BillingDetailsComponent = ({
  clientSecret,
  chosenPlan,
  fetchIntent,
  handleSelectCadence,
  selectedCadence,
  shouldRenderPlanCadencePicker,
}: BillingDetailsComponentProps) => {
  useEffect(() => {
    if (chosenPlan && selectedCadence && !clientSecret) {
      fetchIntent(chosenPlan[selectedCadence].id);
    }
  }, [fetchIntent, clientSecret, chosenPlan, selectedCadence]);

  useEffectOnce(() =>
    triggerGoogleAnalyticsEvent('displayed_payment_element_checkout_form')
  );

  const options = clientSecret
    ? ({
        loader: 'never',
        clientSecret,
        appearance: {
          rules: {
            '.Tab--selected': {
              border: '1px solid #007aff',
              boxShadow: 'none',
            },
            '.Input:focus': {
              border: '1px solid #007aff',
              boxShadow: 'none',
            },
          },
          variables: {
            colorText: '#1C1C1E',
            colorDanger: '#D70015',
            colorPrimary: '#007AFF',
            colorSuccess: '#34C759',
            fontFamily: "'Manrope', sans-serif",
            fontSizeSm: '14px',
          },
        },
        fonts: [
          {
            family: 'Manrope',
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap',
          },
        ],
        locale: 'en',
      } as StripeElementsOptions)
    : null;

  return (
    <Flex className="billing-details-next">
      {shouldRenderPlanCadencePicker && (
        <Flex className="billing-details-next__payment-form">
          <Text className="billing-details-next__heading">Your plan</Text>
          <Flex className="billing-details-next__section">
            {chosenPlan ? (
              <>
                {chosenPlan.monthly && (
                  <SelectablePlan
                    handleSelect={() => handleSelectCadence(MONTHLY_CADENCE)}
                    isSelected={selectedCadence === MONTHLY_CADENCE}
                    value={MONTHLY_CADENCE}
                    name="cadence"
                    title="Monthly"
                    description="Cancel anytime no fee"
                    displayPrice={`$${calculateMonthlyPrice(chosenPlan)}/month`}
                  />
                )}
                {chosenPlan.annual && (
                  <SelectablePlan
                    handleSelect={() => handleSelectCadence(ANNUAL_CADENCE)}
                    isSelected={selectedCadence === ANNUAL_CADENCE}
                    value={ANNUAL_CADENCE}
                    name="cadence"
                    title="Yearly"
                    label="Most popular"
                    displayPrice={`$${calculateAnnualPrice(chosenPlan)}/month`}
                    description={`Pay yearly and save ${calculateAnnualDiscount(
                      chosenPlan
                    )}%`}
                  />
                )}
              </>
            ) : (
              <>
                <Skeleton height="5.3125rem" animation="wave" width="100%" />
                <Skeleton height="5.3125rem" animation="wave" width="100%" />
              </>
            )}
          </Flex>
        </Flex>
      )}
      <Flex className="billing-details-next__payment-form">
        <Text
          className={classNames('billing-details-next__heading', {
            'billing-details-next__heading--centered':
              !shouldRenderPlanCadencePicker,
          })}
        >
          Payment method
        </Text>
        {options ? (
          <Elements stripe={stripePromise} options={options} key={clientSecret}>
            <PaymentCollection>
              <DueToday
                chosenPlan={chosenPlan}
                selectedCadence={selectedCadence}
              />
            </PaymentCollection>
          </Elements>
        ) : (
          <PaymentCollectionSkeleton />
        )}
        <SmallPrintDetails />
      </Flex>
    </Flex>
  );
};
