import React from 'react';

import { Modal } from 'src/common/components/modal/modal';
import { videoCaptionsActions } from 'src/dashboard/state/video-captions/video-captions-actions';
import {
  selectVideoCaptionsDeleting,
  selectVideoCaptionsDeleteModalIsShowing,
  selectVideoCaptionsDeleteError,
  selectVideoCaptionsDeleteId,
} from 'src/dashboard/state/video-captions/video-captions-selectors';
import { useAppSelector, useAppDispatch } from 'src/redux';

import './video-captions-delete-modal.css';

export const VideoCaptionsDeleteModal = () => {
  const dispatch = useAppDispatch();

  const captionId = useAppSelector(selectVideoCaptionsDeleteId);
  const isShowing = useAppSelector(selectVideoCaptionsDeleteModalIsShowing);
  const isDeleting = useAppSelector(selectVideoCaptionsDeleting);

  const deleteError = useAppSelector(selectVideoCaptionsDeleteError);

  const deleteCaption = () =>
    captionId && dispatch(videoCaptionsActions.delete.initiated(captionId));
  const onClose = () =>
    dispatch(videoCaptionsActions.captionsDeleteModal.closed());

  return (
    <Modal
      id="video-captions-delete-modal"
      className="video-captions-delete-modal"
      variant="danger"
      isShowing={isShowing}
      disableConfirm={isDeleting}
      onClose={onClose}
      onConfirm={deleteCaption}
      confirmLabel="Delete captions"
      title="Delete captions"
    >
      <span>
        Are you sure you would like to delete captions for this language?
      </span>

      {deleteError && (
        <span className="delete-error-container">{deleteError}</span>
      )}
    </Modal>
  );
};
