import type { PayloadAction } from '@reduxjs/toolkit';

import {
  fetchVideoAction,
  clearVideoAction,
  retranscodeAction,
} from '../actions';

export type VideoState = {
  isFetching: boolean;
  shortcode: string | null;
};

const initialState: VideoState = {
  isFetching: true,
  shortcode: null,
};

export default function video(
  state = initialState,
  action: PayloadAction<any> & { error?: string }
): VideoState {
  switch (action.type) {
    case clearVideoAction.type:
      return {
        isFetching: false,
        shortcode: null,
      };
    case fetchVideoAction.type:
      if (action.error) {
        return {
          isFetching: false,
          shortcode: null,
        };
      }
      return {
        ...state,
        shortcode: action.payload.shortcode,
      };
    case retranscodeAction.type:
      return { ...state, shortcode: action.payload.shortcode };
    default:
      return state;
  }
}
