import React, { useState } from 'react';
import ClipboardButton from 'react-clipboard.js';

import { faCheck, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from 'src/common/components/chip/chip';
import { Text } from 'src/common/components/text/text';

const VideoUrl = ({ url }) => {
  const [copied, setCopied] = useState(false);

  if (!url) {
    return <div></div>;
  }

  return (
    <div className="video-url">
      <a
        id="video-url-input"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        value={url}
      >
        {url}
      </a>
      <ClipboardButton
        component="button"
        className="video-url__clipboard-button"
        data-clipboard-text={url}
        onSuccess={() => {
          setCopied(true);

          setTimeout(() => {
            setCopied(false);
          }, 3000);
        }}
      >
        {copied ? (
          <Chip className="video-url__clipboard-button__chip" color="primary">
            <FontAwesomeIcon icon={faCheck} /> <Text>Copied!</Text>
          </Chip>
        ) : (
          <Chip className="video-url__clipboard-button__chip">
            <FontAwesomeIcon icon={faLink} flip="both" /> <Text>Copy Link</Text>
          </Chip>
        )}
      </ClipboardButton>
    </div>
  );
};

export default VideoUrl;
