import React, { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

import './button.css';

export type ButtonProps = ComponentPropsWithoutRef<'button'> & {
  color?: 'primary' | 'secondary' | 'danger';
  variant?: 'filled' | 'icon' | 'text' | 'link' | 'outline';
  size?: 'base' | 'slim';
};

export const Button = ({
  children,
  className,
  color = 'secondary',
  type = 'button',
  size = 'base',
  variant = 'filled',
  ...buttonProps
}: ButtonProps) => (
  <button
    className={classNames(
      'sb-button',
      `sb-button--color-${color}`,
      `sb-button--variant-${variant}`,
      `sb-button--size-${size}`,
      className
    )}
    type={type}
    {...buttonProps}
  >
    {children}
  </button>
);
