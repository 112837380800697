import React, {
  type ForwardedRef,
  forwardRef,
  type InputHTMLAttributes,
} from 'react';

import classnames from 'classnames';

import './input.css';

export type InputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChange'
> & {
  hasError?: boolean;
  onChange?: (newValue: string) => void;
  onEnter?: () => void;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { className, hasError = false, onChange, onEnter, type = 'text', ...rest },
    ref
  ) => {
    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event.currentTarget.value);
      }
    };

    return (
      <input
        className={classnames(
          'sb-input',
          { 'sb-input--has-error': hasError },
          className
        )}
        onChange={handleChange}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Enter') {
            onEnter && onEnter();
          }
        }}
        type={type}
        ref={ref}
        {...rest}
      />
    );
  }
) as (
  props: InputProps & { ref?: ForwardedRef<HTMLInputElement> }
) => JSX.Element;
