export const useShouldRestrictVideoPlaybackByOwnerPlan = (
  dateAdded: number,
  flagValue: string | boolean
) => {
  const restrictCreatedFrom =
    flagValue && typeof flagValue === 'string' ? new Date(flagValue) : null;
  const videoDateAdded = new Date(dateAdded * 1000);

  return restrictCreatedFrom ? videoDateAdded >= restrictCreatedFrom : false;
};
