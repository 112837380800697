import React, { useEffect, useState } from 'react';

import jwt_decode from 'jwt-decode';
import { Button } from 'src/common/components/button/button';
import { Flex } from 'src/common/components/flex/flex';
import { Input } from 'src/common/components/input/input';
import { useWaitWindowProperty } from 'src/common/hooks/use-wait-window-property';
import type { MeState } from 'src/common/state/me/me-types';
import type { UserOutput } from 'src/common/types/user';

import '../sign-in.css';
import { FBIcon } from './icons/fb-icon-svg';

const GOOGLE_CLIENT_ID =
  '428206166307-qeosmcg8fbi74og27rm0maeiajnc3e67.apps.googleusercontent.com';

type SignInProps = {
  me: MeState;
  reset: () => void;
  clearVideos: () => void;
  externalConnect: (
    token: string,
    email: string,
    provider: string
  ) => Promise<UserOutput>;
  authenticate: (
    isLogin: boolean,
    username: string,
    password: string,
    verificationRedirect: string
  ) => Promise<UserOutput>;
  action?: 'login' | 'signup';
  buttonText?: string;
  verificationRedirect: string;
  onDone: (user: UserOutput) => void;
};

const SignIn = ({
  me,
  reset,
  clearVideos,
  externalConnect,
  authenticate,
  action = 'login',
  buttonText,
  verificationRedirect,
  onDone,
}: SignInProps) => {
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const isLogin = action === 'login';

  useWaitWindowProperty('google', (google) => {
    google.accounts.id.initialize({
      client_id: GOOGLE_CLIENT_ID,
      callback: async (response: { credential: string }) => {
        setIsAuthenticating(true);
        const responsePayload: { email: string } = jwt_decode(
          response.credential
        );
        const email = responsePayload.email;

        clearVideos();
        const user = await externalConnect(
          response.credential,
          email,
          'google'
        );
        setIsAuthenticating(false);
        onDone(user);
      },
    });
    const signinButton = document.getElementById('my-signin2');
    // NOTE: `renderButton` cannot be configured with dynamic/responsive
    // dimensions, so we have to set them manually.
    google.accounts.id.renderButton(signinButton, {
      width: 280,
      height: 38,
      text: 'continue_with',
    });
    google.accounts.id.disableAutoSelect();
  });

  useEffect(() => {
    setIsAuthenticating(false);
  }, [username, password]);

  return (
    <Flex className="signin-widget">
      <Flex alignItems="center" className="signin-widget__social-buttons">
        <div id="my-signin2"></div>
        <button
          className="btn facebook-button"
          onClick={async () => {
            setIsAuthenticating(true);
            const response = await new Promise<{
              authResponse?: { accessToken: string };
              status: 'connected';
            }>((resolve) =>
              // @ts-ignore
              window.FB.login(resolve, { scope: 'email' })
            );

            if (!response.authResponse || response.status !== 'connected') {
              console.log('User cancelled login or did not fully authorize.');
              return;
            }

            const accessToken = response.authResponse.accessToken;
            const me = await new Promise<{ email: string }>((resolve) =>
              // @ts-ignore
              window.FB.api('/me', { fields: 'email' }, resolve)
            );

            clearVideos();
            const user = await externalConnect(
              accessToken,
              me.email,
              'facebook'
            );
            setIsAuthenticating(false);
            onDone(user);
          }}
        >
          <FBIcon />
          <span className="facebook-text">Continue with Facebook</span>
        </button>
      </Flex>
      <div className="separator">
        <span>or {isLogin ? 'log in' : 'sign up'} with email</span>
      </div>
      <form
        className="signin-widget__form"
        onSubmit={async (e) => {
          e.preventDefault();
          reset();
          clearVideos();
          setIsAuthenticating(true);

          const user = await authenticate(
            isLogin,
            username,
            password,
            verificationRedirect
          );

          setIsAuthenticating(false);
          onDone(user);
        }}
      >
        <div className="signin-widget__form-group">
          <Input
            className="signin-widget__input"
            autoComplete="username"
            value={username}
            onChange={setUsername}
            placeholder={
              isLogin ? 'Email address or username' : 'Email address'
            }
          />
        </div>
        <div className="signin-widget__form-group">
          <Input
            type="password"
            className="signin-widget__input"
            placeholder="Password"
            value={password}
            onChange={setPassword}
            autoComplete={isLogin ? 'current-password' : 'new-password'}
          />
        </div>
        {me.message && (
          <div className={`${me.error ? 'error' : 'info'} signin-alert`}>
            {me.message}
          </div>
        )}
        <Button
          type="submit"
          color="primary"
          disabled={isAuthenticating || !password || !username}
          className="signin-widget__submit-btn"
        >
          {buttonText || (isLogin ? 'Log In' : 'Sign Up')}
        </Button>
      </form>
      <div className="separator"></div>
      <p className="signin-widget__terms">
        By continuing, you accept our{' '}
        <a
          href="https://terms.streamable.com/"
          rel="noreferrer"
          target="_blank"
        >
          Terms of Service
        </a>{' '}
        and acknowledge receipt of our{' '}
        <a
          href="https://privacy.streamable.com/"
          rel="noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>
      </p>
    </Flex>
  );
};

export default SignIn;
