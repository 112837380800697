import React, { useState } from 'react';

import { Button } from 'src/common/components/button/button';
import { Flex } from 'src/common/components/flex/flex';
import { Text } from 'src/common/components/text/text';
import SignInWidget from 'src/user-authentication/sign-in-widget';

import './user-authentication-component.css';

type UserAuthenticationComponentProps = {
  resetUser: () => void;
};

export const UserAuthenticationComponent = ({
  resetUser,
}: UserAuthenticationComponentProps) => {
  const [action, setAction] = useState<'login' | 'signup'>('signup');
  const isSignUp = action === 'signup';

  const handleSwitch = () => {
    resetUser();
    setAction(isSignUp ? 'login' : 'signup');
  };

  return (
    <Flex alignItems="center" className="user-authentication">
      <Flex alignItems="center" className="user-authentication__section">
        <Text as="h3">{isSignUp ? 'Join Streamable' : 'Log in'}</Text>

        <Text className="user-authentication__switcher">
          {isSignUp ? 'Already have an account? ' : "Don't have an account? "}
          <Button onClick={handleSwitch} variant="link" color="primary">
            {isSignUp ? 'Log in' : 'Create account'}
          </Button>
        </Text>
      </Flex>

      <SignInWidget
        action={action}
        buttonText="Continue"
        verificationRedirect="https://streamable.com/upgrade"
        onDone={() => {}}
      />

      <Text className="user-authentication__agreement-copy">
        <a
          target="_blank"
          href="https://terms.streamable.com/"
          rel="noreferrer"
        >
          Terms of Service
        </a>{' '}
        apply and you acknowledge the{' '}
        <a
          target="_blank"
          href="https://privacy.streamable.com/"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </Text>
    </Flex>
  );
};
