import React from 'react';
import { browserHistory } from 'react-router';

import { Plan } from 'src/pricing/components/plan';
import { PLANS } from 'src/pricing/constants';

type FreePlanProps = {
  isGuest: boolean;
  hasPaidPlan: boolean;
};

export const FreePlanComponent = ({ isGuest, hasPaidPlan }: FreePlanProps) => {
  const redirectToCancel = () => {
    browserHistory.push('/billing?cancel=true');
  };

  const redirectToSignup = () => {
    browserHistory.push('/signup?signup_src=pricing');
  };

  return (
    <Plan>
      <Plan.TitleContainer>
        <Plan.Title>{PLANS.free.title}</Plan.Title>
      </Plan.TitleContainer>
      <Plan.Price value={PLANS.free.cost} />
      {isGuest ? (
        <Plan.CallToAction onClick={redirectToSignup}>
          Try for free
        </Plan.CallToAction>
      ) : (
        <Plan.CallToAction
          onClick={hasPaidPlan ? redirectToCancel : undefined}
          disabled={!hasPaidPlan}
        >
          {hasPaidPlan ? 'Downgrade' : 'Current Plan'}
        </Plan.CallToAction>
      )}
      <Plan.FeaturesList>
        {PLANS.free.items.map((item, i) => (
          <Plan.FeatureItem key={`free-plan-feature-item-${i}`}>
            {item.name}
          </Plan.FeatureItem>
        ))}
      </Plan.FeaturesList>
    </Plan>
  );
};
