import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from 'src/common/components/button/button';
import {
  type WrappedComponentDropzoneProps,
  Dropzone,
} from 'src/common/components/dropzone/dropzone';
import { Flex } from 'src/common/components/flex/flex';
import { Text } from 'src/common/components/text/text';
import type { PageConfig } from 'src/landing-new/state/landing-types';
import { setCookie } from 'src/misc/cookies';
import { Markdown } from 'src/misc/markdown';
import { processUploadedFiles } from 'src/misc/utilities';
import type { RootState, AppDispatch } from 'src/redux';

import './uploader.css';

type UploaderOwnProps = {
  page: PageConfig;
};

type UploaderDropzoneContentProps = UploaderOwnProps &
  WrappedComponentDropzoneProps;

const UploaderDropzoneContent = ({
  isDragActive,
  triggerFileSelect,
  page,
}: UploaderDropzoneContentProps) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    className={classNames('uploader', { uploader__drag: isDragActive })}
    onClick={triggerFileSelect}
  >
    <Text as="h1" className="uploader__title">
      {page.title || 'Upload video'}
    </Text>
    <Text as="p" className="uploader__copy">
      <Markdown>
        {page.description ||
          `The upload video tool works with every file format, from MP4 video to MOV
      video, and is free for videos up to 10 min or 250 MB — no gimmicks and no
      watermarks. A free trial is available for larger files.`}
      </Markdown>
    </Text>
    <Button color="primary" className="uploader__cta">
      <FontAwesomeIcon icon={faCloudArrowUp} />
      <span>Upload video</span>
    </Button>
    <Text as="p" className="uploader__copy uploader__copy--hidden-on-small">
      Or drop a video here
    </Text>
  </Flex>
);

type UploaderComponentProps = UploaderOwnProps & {
  me: RootState['me'];
  dispatch: AppDispatch;
};

const UploaderComponent = ({ page, me, dispatch }: UploaderComponentProps) => {
  return (
    <Dropzone
      className="uploader__dropzone"
      noClick={true}
      callback={() => {
        setCookie('dashboard', 'true', 90000);
        browserHistory.push('/');
      }}
      processFiles={async (files) =>
        processUploadedFiles(
          dispatch,
          files,
          null,
          me.plan_max_length,
          me.plan_max_size
        )
      }
    >
      {(dropzoneProps) => (
        <UploaderDropzoneContent {...dropzoneProps} page={page} />
      )}
    </Dropzone>
  );
};

const mapStateToProps = (state: RootState) => ({ me: state.me });
const mapDispatchToProps = (dispatch: AppDispatch) => ({ dispatch });

export const Uploader = connect(
  mapStateToProps,
  mapDispatchToProps
)(UploaderComponent);
