import React, { useRef } from 'react';

import copy from 'copy-to-clipboard';
import { Checkbox } from 'src/common/components/checkbox/checkbox';
import { Flex } from 'src/common/components/flex/flex';
import { Input } from 'src/common/components/input/input';
import { Modal } from 'src/common/components/modal/modal';
import { SelectableOption } from 'src/common/components/selectable-option/selectable-option';
import { Text } from 'src/common/components/text/text';
import { Textarea } from 'src/common/components/textarea/textarea';
import { Tooltip } from 'src/common/components/tooltip/tooltip';
import {
  EmbedContainerOptions,
  EmbedContainerSizeType,
  EmbedPlayerOptionName,
} from 'src/dashboard/state/embed-preview/embed-preview-types';
import { Markdown } from 'src/misc/markdown';

import './embed-preview-modal.css';
import { generateEmbedCode } from './utils';

type EmbedPreviewModalStateProps = {
  isShowing: boolean;
  playerOptions: Record<EmbedPlayerOptionName, boolean>;
  containerOptions: EmbedContainerOptions;
  disabled: boolean;
  shortcode: string | undefined;
};

type EmbedPreviewModalDispatchProps = {
  changeContainerHeight: (height: number) => void;
  changeContainerWidth: (width: number) => void;
  changeContainerSizeType: (sizeType: EmbedContainerSizeType) => void;
  codeCopied: () => void;
  onClose: () => void;
  togglePlayerOption: (option: EmbedPlayerOptionName) => void;
};

type EmbedPreviewModalProps = EmbedPreviewModalStateProps &
  EmbedPreviewModalDispatchProps;

const EMBED_SIZE_OPTIONS: Array<{
  label: string;
  value: EmbedContainerSizeType;
}> = [
  { label: 'Responsive', value: 'responsive' },
  { label: 'Fixed', value: 'fixed' },
];

const EMBED_CONFIG_OPTIONS: Array<{
  label: string;
  queryParam: EmbedPlayerOptionName;
  tooltip?: string;
}> = [
  { label: 'Loop', queryParam: 'loop' },
  {
    label: 'Autoplay',
    queryParam: 'autoplay',
    tooltip:
      'With autoplay, the Streamable player will try to start playback automatically. Some browsers block videos that try to autoplay with sound. For the best experience in all browsers, enable **Autoplay** and **Mute audio**.',
  },
  {
    label: 'Mute audio',
    queryParam: 'muted',
  },
  { label: 'Show player controls', queryParam: 'controls' },
];

export const EmbedPreviewModalComponent = ({
  isShowing,
  playerOptions,
  containerOptions,
  disabled,
  shortcode,
  changeContainerHeight,
  changeContainerWidth,
  changeContainerSizeType,
  codeCopied,
  onClose,
  togglePlayerOption,
}: EmbedPreviewModalProps) => {
  const embedCodeRef = useRef<HTMLTextAreaElement | null>(null);

  const handleCopy = () => {
    if (embedCodeRef?.current) {
      copy(embedCodeRef.current.value);
      codeCopied();
    }
  };

  return (
    <Modal
      id="embed-preview-modal"
      className="embed-preview-modal"
      confirmLabel={disabled ? 'Copied' : 'Copy'}
      title="Embed video"
      isShowing={isShowing}
      onClose={onClose}
      onConfirm={handleCopy}
      hideCancel
      disableConfirm={disabled}
    >
      <Flex className="embed-preview-modal__content">
        <Flex className="embed-preview-modal__section embed-preview-modal__code-wrapper">
          {shortcode && (
            <Textarea
              readOnly
              value={generateEmbedCode(
                shortcode,
                containerOptions,
                playerOptions
              )}
              className="embed-preview-modal__code-wrapper__code"
              ref={embedCodeRef}
            />
          )}
        </Flex>
        <Flex
          direction="row"
          className="embed-preview-modal__section embed-preview-modal__radio-group"
        >
          {EMBED_SIZE_OPTIONS.map((option) => (
            <SelectableOption
              key={option.value}
              label={option.label}
              isSelected={containerOptions.size === option.value}
              name="size"
              value={option.value}
              handleSelect={() => changeContainerSizeType(option.value)}
              size="slim"
            />
          ))}
        </Flex>
        {containerOptions.size === 'fixed' && (
          <Flex
            direction="row"
            alignItems="center"
            className="embed-preview-modal__dimensions"
          >
            <Input
              placeholder="width"
              type="number"
              onChange={(value) => changeContainerWidth(Number(value))}
              value={containerOptions.width!}
            />
            <Text as="span">×</Text>
            <Input
              placeholder="height"
              type="number"
              onChange={(value) => changeContainerHeight(Number(value))}
              value={containerOptions.height!}
            />
            <Text as="span">pixels</Text>
          </Flex>
        )}
        <Flex className="embed-preview-modal__section embed-preview-modal__embed-options">
          <Text className="embed-preview-modal__embed-options-heading">
            Embed options
          </Text>
          {EMBED_CONFIG_OPTIONS.map((option) => (
            <Flex
              key={option.queryParam}
              direction="row"
              alignItems="center"
              className="embed-preview-modal__embed-option"
            >
              <Checkbox
                id={`embed-checkbox-${option.queryParam}`}
                name={option.queryParam}
                checked={playerOptions[option.queryParam]}
                onChange={() => togglePlayerOption(option.queryParam)}
              />
              <Text
                as="label"
                htmlFor={`embed-checkbox-${option.queryParam}`}
                className="embed-preview-modal__embed-option__label"
              >
                {option.label}
              </Text>
              {option.tooltip && (
                <Tooltip type="info">
                  <Flex>
                    <Markdown className="embed-preview-modal__tooltip-text">
                      {option.tooltip}
                    </Markdown>
                  </Flex>
                </Tooltip>
              )}
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Modal>
  );
};
