import Cookies from 'js-cookie';

const isDev = process.env.NODE_ENV === 'development';

export type CookieName =
  | 'dashboard'
  | 'session'
  | 'user_name'
  | 'src_external'
  | 'dark_mode'
  | 'embed_options'
  | 'hideStaleNotification'
  | `hideNotification-${number}`
  | string; // for dynamic cookie names

const domain = window.location.hostname.startsWith('ui-app-web-pr-')
  ? undefined
  : '.streamable.com';

const getCookieOptions = (
  attributes?: Cookies.CookieAttributes,
  defaultOption?: {}
) => {
  const options = {};

  if (attributes) {
    Object.assign(options, attributes);
  }

  if (isDev) {
    return Object.keys(options).length ? options : defaultOption;
  }

  if (domain) {
    Object.assign(options, { domain });
  }

  return Object.keys(options).length ? options : defaultOption;
};

export const setCookie = (
  name: CookieName,
  value: string | undefined,
  expires: number
) => {
  const cookieOptions = getCookieOptions({ expires }, {});
  Cookies.set(name, value ?? '', cookieOptions);
};

export const getCookie = (name: CookieName) => {
  const cookie = Cookies.get(name);
  return cookie;
};

export const removeCookie = (name: CookieName) => {
  const options = getCookieOptions();
  Cookies.remove(name, options);
};
