import React, { useEffect, useRef } from 'react';

import { Logo } from 'src/common/components/logo';
import { Modal } from 'src/common/components/modal/modal';
import { Text } from 'src/common/components/text/text';
import { EMAIL_ADDRESSES } from 'src/config';
import { CadenceSwitch } from 'src/pricing/components/cadence-switch';
import { FreePlan } from 'src/pricing/components/plan/free-plan';
import { SelfServePlan } from 'src/pricing/components/plan/self-serve-plan';
import { ANNUAL_CADENCE, MONTHLY_CADENCE } from 'src/pricing/constants';
import { AvailablePlan } from 'src/pricing/types';

import { UpgradeModalCard } from './upgrade-modal-card';
import './upgrade-modal.scss';

type UpgradeModalComponentStateProps = {
  isShowing: boolean;
  availablePlans: AvailablePlan[] | null;
};

type UpgradeModalComponentDispatchProps = {
  onClose: () => void;
};

type UpgradeModalComponentProps = UpgradeModalComponentStateProps &
  UpgradeModalComponentDispatchProps;

export const UpgradeModalComponent = ({
  isShowing,
  onClose,
  availablePlans,
}: UpgradeModalComponentProps) => {
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const popularPlanRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (carouselRef.current && popularPlanRef.current) {
      const scrollLeft =
        popularPlanRef.current.offsetLeft - window.innerWidth / 2;

      carouselRef.current.scrollLeft = scrollLeft;
    }
  }, [isShowing]);

  return (
    <Modal
      id="upgrade-plan-modal"
      className="upgrade-modal"
      isShowing={isShowing}
      onClose={onClose}
      hideConfirm={true}
      hideCancel={true}
      zIndexOffset={10}
      title={<Logo type="primary" />}
    >
      <div className="upgrade-modal__container">
        <Text as="h2" className="upgrade-modal__title">
          Choose a plan
        </Text>

        <div className="upgrade-modal__cadence-container">
          <div className="upgrade-modal__cadence">
            <CadenceSwitch
              options={[
                {
                  label: 'Monthly',
                  value: MONTHLY_CADENCE,
                },
                {
                  label: (
                    <>
                      Yearly{' '}
                      <span className="upgrade-modal__cadence__savings">
                        Save up to 25%
                      </span>
                    </>
                  ),
                  value: ANNUAL_CADENCE,
                },
              ]}
            />
          </div>
        </div>
        <div className="upgrade-modal__plans-carousel" ref={carouselRef}>
          <div className="upgrade-modal__plans-container">
            <UpgradeModalCard>
              <FreePlan />
            </UpgradeModalCard>
            {availablePlans &&
              availablePlans.map((availablePlan, i) => (
                <UpgradeModalCard
                  ref={
                    availablePlan.name === 'Pro' ? popularPlanRef : undefined
                  }
                  key={`${availablePlan.name}-${i}`}
                  variant={availablePlan.name === 'Pro' ? 'popular' : 'default'}
                >
                  <SelfServePlan plan={availablePlan} />
                </UpgradeModalCard>
              ))}
          </div>
        </div>

        <div className="upgrade-modal__enterprise-footer">
          <Text className="upgrade-modal__enterprise-footer__text">
            <strong>Need a plan without limits?</strong> Our Enterprise plan
            gives you custom bandwidth pricing, high-volume storage, reporting,
            and more.
          </Text>
          <Text className="upgrade-modal__enterprise-footer__text">
            <a href={`mailto:${EMAIL_ADDRESSES.sales}?subject=Enterprise plan`}>
              Contact sales
            </a>
          </Text>
        </div>

        <div className="upgrade-modal__terms">
          <Text>
            By continuing, you accept our{' '}
            <a
              href="https://terms.streamable.com/"
              rel="noreferrer"
              target="_blank"
            >
              Terms of Service
            </a>{' '}
            and acknowledge receipt of our{' '}
            <a
              href="https://privacy.streamable.com/"
              rel="noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>
          </Text>
        </div>
        {/* todo(soutar): add terms notice */}
      </div>
    </Modal>
  );
};
