import React from 'react';
import { connect } from 'react-redux';

import { Modal } from 'src/common/components/modal/modal';
import { TextField } from 'src/common/components/text-field/text-field';
import { Text } from 'src/common/components/text/text';
import {
  dashboardSidebarActions,
  saveLabel,
} from 'src/dashboard/state/labels/labels-actions';
import {
  selectCreateLabelErrorMessage,
  selectCreateLabelName,
  selectIsCreateLabelModalShowing,
  selectIsLabelCreationLoading,
  selectIsNumberOfLabelsExceeded,
} from 'src/dashboard/state/labels/labels-selectors';
import type { RootState, AppDispatch } from 'src/redux';

import './create-label-modal.css';

type CreateLabelModalProps = {
  handleClose: () => void;
  handleInputChange: (newValue: string) => void;
  handleSave: () => void;
  isLoading: boolean;
  isShowing: boolean;
  errorMessage?: string;
  labelName: string;
  isNumberOfLabelsExceeded: boolean;
};

const CreateLabelModalComponent = ({
  handleClose,
  handleInputChange,
  handleSave,
  isLoading,
  isShowing,
  errorMessage,
  labelName,
  isNumberOfLabelsExceeded,
}: CreateLabelModalProps) => {
  const disableConfirm =
    !labelName || labelName.trim().length === 0 || isLoading;
  return (
    <Modal
      id="create-label-modal"
      isShowing={isShowing}
      onClose={handleClose}
      title="Create new label"
      disableConfirm={disableConfirm}
      cancelLabel={isNumberOfLabelsExceeded ? 'Okay' : undefined}
      confirmLabel={isLoading ? 'Saving' : 'Save'}
      {...(isNumberOfLabelsExceeded
        ? { hideConfirm: true }
        : { hideConfirm: false, onConfirm: handleSave })}
    >
      {isNumberOfLabelsExceeded ? (
        <Text>
          You have reached the maximum number of labels allowed for your plan,
          please contact support if you want to add more.
        </Text>
      ) : (
        <>
          <TextField
            label="Label name"
            id="create-label-input"
            name="createLabelName"
            onChange={handleInputChange}
            value={labelName}
            errorMessage={errorMessage}
            disabled={isLoading}
            autoFocus
            autoComplete="off"
            maxLength={50}
            onEnter={disableConfirm ? undefined : handleSave}
          />
        </>
      )}
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoading: selectIsLabelCreationLoading(state),
  isShowing: selectIsCreateLabelModalShowing(state),
  errorMessage: selectCreateLabelErrorMessage(state),
  labelName: selectCreateLabelName(state),
  isNumberOfLabelsExceeded: selectIsNumberOfLabelsExceeded(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  handleClose: () =>
    dispatch(dashboardSidebarActions.createLabelModal.cancelled()),
  handleInputChange: (newValue: string) =>
    dispatch(dashboardSidebarActions.createLabelModal.inputChanged(newValue)),
  handleSave: () => dispatch(saveLabel()),
});

export const CreateLabelModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateLabelModalComponent);
