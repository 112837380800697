import { createSlice } from '@reduxjs/toolkit';

import { appLoadingSlice } from './app-loading-constants';

export * from './app-loading-constants';

const initialState = { appIsLoading: true };

export const {
  actions: { finishedAppLoading },
  reducer,
} = createSlice({
  name: appLoadingSlice,
  initialState,
  reducers: {
    finishedAppLoading: (state) => {
      state.appIsLoading = false;
    },
  },
});

export type AppLoadingState = ReturnType<typeof reducer>;
