import React from 'react';
import { connect } from 'react-redux';

import { Modal } from 'src/common/components/modal/modal';
import { dashboardAssignLabelsActions } from 'src/dashboard/state/assign-labels/assign-labels-actions';
import { selectDragAndDropErrorAlert } from 'src/dashboard/state/assign-labels/assign-labels-selectors';
import type { RootState, AppDispatch } from 'src/redux';

type ErrorAlertProps = {
  errorMessage?: string;
  isShowing: boolean;

  handleClose: () => void;
};

export const ErrorAlertModal = ({
  errorMessage,
  isShowing,
  handleClose,
}: ErrorAlertProps) => {
  return (
    <Modal
      id="error-alert-modal"
      isShowing={isShowing}
      onClose={handleClose}
      onConfirm={handleClose}
      hideCancel
      title="Error moving items"
      confirmLabel="Ok"
    >
      {errorMessage}
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  handleClose: () =>
    dispatch(
      dashboardAssignLabelsActions.dragAndDropAssign.dismissErrorAlert()
    ),
});

const mapStateToProps = (state: RootState) => {
  const alertState = selectDragAndDropErrorAlert(state);
  return {
    isShowing: alertState.isShowing,
    errorMessage: alertState.errorMessage,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorAlertModal);
