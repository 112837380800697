import React from 'react';

import { Text } from 'src/common/components/text/text';
import type { AvailablePlan } from 'src/pricing/types';
import type { AppDispatch } from 'src/redux';

import { SuggestionCardComponent } from '../suggestion-card/suggestion-card-component';

export interface UpsellComponentProps {
  plan?: AvailablePlan;
  callToActionLabel: string;
  text: string;
  title: string;
  dispatch: AppDispatch;
  dispatchActionTypeOnClick: string;
  isShown: boolean;
  dismiss: () => void;
}

export const UpsellComponent = ({
  plan,
  title,
  callToActionLabel,
  text,
  dispatch,
  isShown,
  dismiss,
  dispatchActionTypeOnClick,
}: UpsellComponentProps) => {
  return (
    <SuggestionCardComponent
      title={title}
      isDismissed={!isShown}
      callToActionLabel={callToActionLabel}
      onClick={() => dispatch({ type: dispatchActionTypeOnClick })}
      plan={plan}
      storeKey="saga-powered-upsell"
      withPricingLink={true}
      triggerGtmEventOnMount={false}
      dismiss={dismiss}
    >
      <Text>{text}</Text>
    </SuggestionCardComponent>
  );
};
