import './unlabeled-empty-state.scss';

export const UnlabeledVideosEmptyState = () => (
  <div className="unlabeled-empty-state">
    <span className="unlabeled-empty-state__emoji">👍</span>
    <div className="unlabeled-empty-state__text-container">
      <h1 className="unlabeled-empty-state__heading">
        You have no unlabeled videos
      </h1>
      <span className="unlabeled-empty-state__description">
        Select videos to upload or drag and drop video files
      </span>
    </div>
  </div>
);
