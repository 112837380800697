import { useState } from 'react';

import classNames from 'classnames';
import type { FilePreset, FileRepresentation } from 'src/common/types/file';
import { MEDIA_STATUS } from 'src/common/types/media';
import { copyToClipboard } from 'src/utils/copy-to-clipboard';

import './fileList.css';

const NAMES: Record<FilePreset, string> = {
  'mp4-high': 'Ultra HD',
  mp4: 'HD',
  'mp4-mobile': 'SD',
};

type FileListItemProps = {
  file: FileRepresentation;
  hasPlan: boolean;
  disabled?: boolean;
  shortcode: string;
  showUpgradeModal: () => void;
  handleUpsellClick: (source: string) => void;
};

export const FileListItem = ({
  file,
  hasPlan,
  disabled = false,
  shortcode,
  showUpgradeModal,
  handleUpsellClick,
}: FileListItemProps) => {
  const [copied, setCopied] = useState(false);

  const { width, height = 0, size = 0, url, preset = 'mp4' } = file;

  const isDone = file.status === MEDIA_STATUS.READY;

  let name = NAMES[preset];
  if (preset === 'mp4' && height <= 480) {
    name = NAMES['mp4-mobile'];
  }

  const downloadUrl = isDone && !disabled ? url : null;
  const rawUrl = `https://streamable.com/l/${shortcode}/${preset}.mp4`;
  const description = isDone
    ? `${width} × ${height} / ${(size / 1000000).toFixed(1)}MB`
    : '';

  return (
    <div className="file-list-item">
      <div className="name-container">
        <div className="name">{name}</div>
        {disabled ? (
          <button
            className="upgrade-link"
            onClick={() => handleUpsellClick('file-download-list')}
          >
            <span className="material-icons-outlined icon">star</span>
            <span>BASIC</span>
          </button>
        ) : (
          description && <div className="description">({description})</div>
        )}
      </div>

      {downloadUrl || disabled ? (
        <div className="buttons-container">
          <button
            className="icon-button"
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              if (downloadUrl) {
                const a = document.createElement('a');
                a.href = downloadUrl;
                a.download = downloadUrl.split('/').pop() ?? '';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              }
            }}
          >
            <span className="material-symbols-outlined icon">download</span>
            <span className="button-text">Download</span>
          </button>

          <button
            className={classNames('icon-button', copied && 'copied')}
            disabled={disabled}
            onClick={async () => {
              if (hasPlan) {
                await copyToClipboard(rawUrl);
                setCopied(true);
                setTimeout(() => setCopied(false), 3000);
              } else {
                showUpgradeModal();
              }
            }}
          >
            <span className="material-symbols-outlined icon">link</span>
            <div className="copy button-text">
              <span className={copied ? 'hidden' : ''}>MP4 URL</span>
              <span className={!copied ? 'hidden' : ''}>Copied</span>
            </div>
          </button>
        </div>
      ) : (
        <span className="processing">Processing...</span>
      )}
    </div>
  );
};
