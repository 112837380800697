import { createReducer } from '@reduxjs/toolkit';

import type { Posts } from '../types';
import { mapPostForView } from '../utils/map-post-for-view';
import { blogItemPageActions } from './blog-item-actions';

export type BlogItemState = Posts;

const initialState: BlogItemState = {};

export const blogItemReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(blogItemPageActions.blogItemFetched, (state, { payload }) => {
      if (!state[payload.slug]) {
        // @ts-ignore
        state[payload.slug]! = {};
      }
      state[payload.slug]!.isLoading = true;
      state[payload.slug]!.isError = false;
    })
    .addCase(blogItemPageActions.blogItemFulfilled, (state, { payload }) => {
      state[payload.slug] = {
        isError: false,
        isLoading: false,
        ...mapPostForView(payload),
      };
    })
    .addCase(blogItemPageActions.blogItemRejected, (state, { payload }) => {
      const blogItem = state[payload.slug];
      if (blogItem) {
        blogItem.isLoading = false;
        blogItem.isError = true;
      }
    });
});
