import { createAction } from '@reduxjs/toolkit';
import type { Caption } from 'src/dashboard/types';

export const videoCaptionsActions = {
  captionsModal: {
    opened: createAction<string>('[Video Captions Modal] opened'),
    closed: createAction('[Video Captions Modal] closed'),
  },
  fetch: {
    initiated: createAction<string>('[Video Captions] fetch initiated'),
    fulfilled: createAction<Caption[]>('[Video Captions] fetch fulfilled'),
    failed: createAction<string>('[Video Captions] fetch failed'),
  },
  captionsDeleteModal: {
    opened: createAction<{ shortcode: string; id: number }>(
      '[Video Captions Delete Modal] opened'
    ),
    closed: createAction('[Video Captions Delete Modal] closed'),
  },
  delete: {
    initiated: createAction<number>('[Video Captions] delete initiated'),
    fulfilled: createAction('[Video Captions] delete fulfilled'),
    failed: createAction<string>('[Video Captions] delete failed'),
  },
};
