import React from 'react';

import classNames from 'classnames';

import './hero-text-line-expression.css';

export const HeroTextLineExpression = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="49"
    height="62"
    className={classNames('hero-text-line-expression', className)}
  >
    <path
      d="M 5.619 55.529 C 3.823 49.312 4.873 37.459 23.444 39.78 C 37.124 41.489 42.098 46.411 38.782 49.727 C 35.466 53.042 18.884 49.727 22.201 26.932 C 24.802 9.054 38.367 4.138 45 4.552"
      fill="transparent"
      strokeWidth="4"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
  </svg>
);
