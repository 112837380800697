import React, { useEffect, useState } from 'react';

import { Button } from 'src/common/components/button/button';
import { Flex } from 'src/common/components/flex/flex';
import { TextField } from 'src/common/components/text-field/text-field';
import { Text } from 'src/common/components/text/text';
import { Toggle } from 'src/common/components/toggle/toggle';

import { SettingOption } from '../setting-option/setting-option';
import { UpsellLabel } from '../upsell-label/upsell-label';
import './privacy-and-security-password.css';

type PrivacyAndSecurityPasswordProps = {
  disabled?: boolean;
  isPasswordPresent: boolean;
  handlePasswordSubmit?: (password: string) => void;
  handleTurnOffPasswordProtection?: () => void;
  handleUpsellClick: () => void;
};

export const PrivacyAndSecurityPassword = ({
  disabled = false,
  isPasswordPresent: isPasswordPresentProp,
  handlePasswordSubmit: handlePasswordSubmitProp,
  handleTurnOffPasswordProtection,
  handleUpsellClick,
}: PrivacyAndSecurityPasswordProps) => {
  const [isPasswordPresent, setIsPasswordPresent] = useState(
    isPasswordPresentProp
  );
  const [isPasswordProtectionOn, setIsPasswordProtectionOn] =
    useState(isPasswordPresent);
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (isPasswordPresent !== isPasswordPresentProp) {
      setIsPasswordPresent(isPasswordPresentProp);
    }
    if (isPasswordProtectionOn && !isPasswordPresentProp) {
      setIsPasswordProtectionOn(false);
    }
  }, [isPasswordPresentProp]);

  const handlePasswordSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    setIsPasswordPresent(true);
    handlePasswordSubmitProp?.(password);
  };

  return (
    <>
      <SettingOption
        label={
          <UpsellLabel
            enableUpsell={disabled}
            label="Password protection"
            planName="Basic"
          />
        }
        secondaryLabel={
          <Text variant="secondary">
            Only people with the password can view.
          </Text>
        }
        disabled={false}
        onClick={() => {
          if (disabled) {
            handleUpsellClick();
          } else {
            setIsPasswordProtectionOn(!isPasswordProtectionOn);
            // If password protection is about to change to false, we need to turn off password protection
            if (isPasswordProtectionOn) {
              handleTurnOffPasswordProtection?.();
            } else {
              setPassword('');
            }
          }
        }}
      >
        <Toggle
          name="password_protection"
          checked={isPasswordProtectionOn}
          size="large"
          disabled={disabled}
        />
      </SettingOption>

      {isPasswordProtectionOn && (
        <div className="privacy-and-settings__restrictions__text-field-container">
          {isPasswordPresent ? (
            <Button
              onClick={() => {
                setIsPasswordPresent(false);
                setPassword('');
              }}
              disabled={disabled}
              className="privacy-and-settings__restrictions__text-field-container__change-password-btn"
              variant="outline"
            >
              Change password
            </Button>
          ) : (
            <form onSubmit={handlePasswordSubmit}>
              <Flex
                direction="row"
                alignItems="center"
                className="privacy-and-settings__restrictions__text-field-container__password"
              >
                <TextField
                  placeholder="Create password"
                  onChange={setPassword}
                  value={password}
                  disabled={disabled}
                />
                <Button
                  disabled={!password.length}
                  color="primary"
                  type="submit"
                >
                  Set password
                </Button>
              </Flex>
            </form>
          )}
        </div>
      )}
    </>
  );
};
