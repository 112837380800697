import React from 'react';

import { Button } from 'src/common/components/button/button';
import { Flex } from 'src/common/components/flex/flex';
import { Skeleton } from 'src/common/components/skeleton/skeleton';

import './payment-collection-skeleton.css';

export const PaymentCollectionSkeleton = ({
  noTrial,
}: {
  noTrial: boolean;
}) => (
  <Flex className="payment-collection-skeleton-wrapper">
    <div className="payment-collection-skeleton">
      <Skeleton height="2.5rem" animation="wave" width="100%" variant="text" />

      <div className="payment-collection-skeleton__card-details">
        <Skeleton
          height="2.5rem"
          animation="wave"
          width="100%"
          variant="text"
        />
        <Skeleton
          height="2.5rem"
          animation="wave"
          width="100%"
          variant="text"
        />
      </div>
    </div>
    <Skeleton height="2.5rem" animation="wave" />
    <Skeleton height="2.5rem" animation="wave" />
    <Button
      color="primary"
      className="payment-collection-skeleton__submit-button"
      disabled
    >
      {noTrial ? 'Upgrade Now' : 'Start Free Trial'}
    </Button>
  </Flex>
);
