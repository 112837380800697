import { createSelector } from '@reduxjs/toolkit';
import {
  always,
  both,
  defaultTo,
  find,
  ifElse,
  not,
  pipe,
  prop,
  propOr,
} from 'ramda';
import type { SubscriptionState } from 'src/app/subscription/subscription-types';
import { ELEMENTS_PLAN } from 'src/common/constants';
import { selectIsLoggedIn } from 'src/common/state/me/me-selectors';
import type { RootState } from 'src/redux';

import { DEFAULT_CADENCE } from '../constants';
import type { AvailablePlan, FeatureItem, PlanCadence } from '../types';
import { isUpgrade } from '../utils/is-upgrade';

const selectPricingState = (state: RootState) => state.pricing;

export const selectTargetCadence = pipe(
  selectPricingState,
  prop('targetCadence')
);

export const selectAllChangePlanModalState = pipe(
  selectPricingState,
  prop('changePlanModal')
);

export const selectTargetPlanId = pipe(
  selectAllChangePlanModalState,
  prop('targetPlanId')
);

export const selectAvailablePlans = pipe(
  selectPricingState,
  prop('availablePlans')
);

export const selectPlanByName = (name: string) =>
  pipe(
    selectAvailablePlans,
    defaultTo([] as AvailablePlan[]),
    find((plan) => plan.name === name)
  );

// @ts-expect-error TS can't infer the types correctly.
export const selectFeaturesFromPlanByName: (
  name: string
) => (state: RootState) => FeatureItem[] = (name: string) =>
  pipe(
    selectPlanByName(name),
    propOr(
      [
        { label: '2_tb_storage', description: '2TB cloud storage' },
        { label: 'no_limits', description: 'No limits on duration or size' },
        { label: 'no_ads', description: 'Ad-free video playback' },
      ],
      'features'
    )
  );

export const selectTargetProduct = createSelector(
  [selectTargetPlanId, selectAvailablePlans],
  (targetPlanId, availablePlans) => {
    return targetPlanId && availablePlans
      ? availablePlans.find(
          (plan) =>
            plan.monthly.id === targetPlanId || plan.annual.id === targetPlanId
        )
      : undefined;
  }
);

export const selectTargetProductPrice = createSelector(
  [selectTargetPlanId, selectTargetProduct],
  (targetPlanId, plan) => {
    if (plan) {
      return plan.monthly.id === targetPlanId ? plan.monthly : plan.annual;
    }
  }
);

export const selectPlanByQueryParamAndCadence =
  (planType: string, cadence?: PlanCadence) => (state: RootState) => {
    if (planType === 'elements') {
      return ELEMENTS_PLAN;
    }

    const availablePlans = selectAvailablePlans(state);

    if (!availablePlans) {
      return null;
    }

    const cadenceToUse = cadence || DEFAULT_CADENCE;

    let plan = availablePlans.find((plan) =>
      plan[cadenceToUse].id.includes(planType)
    );

    // TODO: remove the following block once we have then new plans activated
    if (!plan) {
      plan = availablePlans.find((plan) =>
        plan[cadenceToUse].name.toLocaleLowerCase().includes(planType)
      );
    }

    return plan || null;
  };

// Duplicated to avoid circular
const selectCurrentPlan = createSelector(
  (state) => state.subscription,
  (subscription: SubscriptionState) => subscription.currentPlan
);

export const selectPlanOneUpToCurrent = createSelector(
  [selectCurrentPlan, selectAvailablePlans],
  (currentPlan, availablePlans) => {
    if (!availablePlans) {
      return null;
    }

    if (!currentPlan) {
      return availablePlans[0];
    }

    const plan = availablePlans.find((plan) => isUpgrade(currentPlan, plan));
    return plan || null;
  }
);

export const selectChosenPlan =
  (planType?: string, cadence?: PlanCadence) => (state: RootState) =>
    planType
      ? selectPlanByQueryParamAndCadence(planType, cadence)(state)
      : selectPlanOneUpToCurrent(state);

export const selectPlanForUpsell: (state: RootState) => AvailablePlan | null = (
  state
) =>
  ifElse(
    // if the user is logged in and doesn't have a plan
    both(selectIsLoggedIn, pipe(selectCurrentPlan, Boolean, not)),

    // return the first available plan if present or null
    (state) => selectAvailablePlans(state)?.[0] || null,

    // otherwise return null
    always(null)
  )(state);

export const selectStorageUpsellTargetPlanId = createSelector(
  [selectCurrentPlan, selectAvailablePlans],
  (currentPlan, availablePlans) => {
    if (
      !currentPlan?.limits.storage.exceeded ||
      !currentPlan?.limits.storage.upgradeRecommendation ||
      !availablePlans
    ) {
      return null;
    }

    const { storage } = currentPlan.limits;
    const targetProductId = storage.upgradeRecommendation?.plan;
    const targetPlan = availablePlans.find(
      (plan) => plan.productId === targetProductId
    );

    return (targetPlan && targetPlan[currentPlan.cadence].id) || 'custom';
  }
);
