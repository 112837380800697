export const CHECKOUT_BASE_FEATURES = [
  {
    id: 'cancel-anytime',
    title: 'Can I cancel anytime?',
    description:
      'Yup, you can cancel whenever you want from your account settings.',
  },
];

export const CHECKOUT_TRIAL_FEATURES = [
  ...CHECKOUT_BASE_FEATURES,
  {
    id: 'trial-charged-today',
    title: 'Will my card be charged today?',
    description:
      "Nope! You'll only be charged if you don't cancel your trial before it ends.",
  },
];

export const CHECKOUT_NOTRIAL_FEATURES = [
  ...CHECKOUT_BASE_FEATURES,
  {
    id: 'full-refund',
    title: "What if I'm unsatisfied?",
    description:
      "If you're unhappy with your purchase, contact our support team for a full refund.",
  },
];

export const BLACKFRIDAY_FEATURES = [
  ...CHECKOUT_BASE_FEATURES,
  {
    title: 'How much will I save?',
    description:
      "You'll save 30% on our monthly plan for your first three months. Or save an extra 25% on our discounted annual plan for your first year of Streamable Pro.",
  },
];
