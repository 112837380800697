import React from 'react';

import classnames from 'classnames';
import { Flex } from 'src/common/components/flex/flex';
import { Text } from 'src/common/components/text/text';

import './setting-option.css';

type SettingOptionProps = {
  children?: React.ReactNode;
  disabled?: boolean;
  label: React.ReactNode;
  secondaryLabel?: React.ReactNode;
  withSeparator?: boolean;
  onClick?: () => void;
};

export const SettingOption = ({
  children,
  disabled = false,
  label,
  secondaryLabel,
  onClick,
  withSeparator = false,
}: SettingOptionProps) => (
  <Flex
    direction="row"
    alignItems="center"
    onClick={disabled ? undefined : onClick}
    className={classnames('settings-option', {
      'settings-option--with-bottom-border': withSeparator,
      'settings-option--disabled': disabled,
      'settings-option--clickable': !!onClick,
    })}
  >
    <Flex className="settings-option__label-container">
      <Text className="settings-option__label">
        {typeof label === 'string' ? (
          <Text as="span" className="settings-option__label__content">
            {label}
          </Text>
        ) : (
          label
        )}
      </Text>
      {secondaryLabel}
    </Flex>
    <Flex
      direction="row"
      alignItems="center"
      className="settings-option__container"
    >
      {children}
    </Flex>
  </Flex>
);
