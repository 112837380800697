export const EXPERIMENT_VALUES = {};

type Experiments = {
  [key: string]: boolean | string;
};

export const EXPERIMENT_DEFAULT_VALUES: Experiments = {
  noFreeTrials: false,
};

// This need to be in sync with src/common/scss/variables.scss
export const BREAKING_POINTS = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const EMAIL_ADDRESSES = {
  sales: 'sales@streamable.com',
  support: 'support@streamable.com',
};

export const SOCIAL_MEDIA_LINKS = {
  twitter: 'https://twitter.com/streamable',
};
