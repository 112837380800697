import React, { Component } from 'react';
import FaCloudUpload from 'react-icons/lib/fa/cloud-upload';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { selectIsIOs, selectIsMobile } from 'src/misc/selectors';
import { processUploadedFiles } from 'src/misc/utilities';

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    ios: selectIsIOs(state),
    me: state.me,
    isMobile: selectIsMobile(state),
  };
};

class VideoCreator extends Component {
  render() {
    const { dispatch, group, me, ios, isMobile } = this.props;

    if (me.restricted) {
      return (
        <div className="card video-creator">
          <svg
            style={{ marginRight: '10px', marginLeft: '10px' }}
            height="20"
            width="20"
            version="1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            enable-background="new 0 0 48 48"
          >
            <path
              fill="#F44336"
              d="M21.2,44.8l-18-18c-1.6-1.6-1.6-4.1,0-5.7l18-18c1.6-1.6,4.1-1.6,5.7,0l18,18c1.6,1.6,1.6,4.1,0,5.7l-18,18 C25.3,46.4,22.7,46.4,21.2,44.8z"
            />
            <path
              fill="#fff"
              d="M21.6,32.7c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.5-0.4,0.8-0.5s0.6-0.2,1-0.2 s0.7,0.1,1,0.2c0.3,0.1,0.6,0.3,0.8,0.5c0.2,0.2,0.4,0.4,0.5,0.7c0.1,0.3,0.2,0.6,0.2,0.9s-0.1,0.6-0.2,0.9s-0.3,0.5-0.5,0.7 c-0.2,0.2-0.5,0.4-0.8,0.5c-0.3,0.1-0.6,0.2-1,0.2s-0.7-0.1-1-0.2s-0.5-0.3-0.8-0.5c-0.2-0.2-0.4-0.4-0.5-0.7S21.6,33.1,21.6,32.7z M25.8,28.1h-3.6L21.7,13h4.6L25.8,28.1z"
            />
          </svg>
          <span>
            You have exceeded your account limits. Please{' '}
            <a href="mailto:limits@streamable.com?subject=Streamable%20Account%20Limits&body=Please%20lift%20the%20limits%20for%20my%20Streamable%20account.">
              contact us
            </a>{' '}
            to upgrade your account.
          </span>
        </div>
      );
    }

    return (
      <div className="card video-creator">
        <form
          ref={(r) => {
            if (r) {
              this.formRef = r;
            }
          }}
          style={{ display: 'none' }}
        >
          <input
            key="fileInput"
            type="file"
            multiple={!ios}
            ref="fileInput"
            accept={isMobile ? 'video/*' : null}
            onChange={() => {
              const files = this.refs.fileInput.files;
              if (!files.length) {
                return;
              }
              processUploadedFiles(
                dispatch,
                files,
                group,
                me.plan_max_length,
                me.plan_max_size
              )
                .then(() => {
                  this.formRef.reset();
                })
                .catch(() => {
                  // do nothing
                });
            }}
          />
        </form>
        <button
          key="uploadButton"
          className="blue-button video-creator__upload-button"
          onClick={(e) => {
            e.preventDefault();
            this.refs.fileInput.click();
          }}
        >
          <span className="highlight" />
          <span className="text">
            {this.props.isMobile ? (
              ''
            ) : (
              <span>
                <FaCloudUpload /> &nbsp;{' '}
              </span>
            )}
            Upload video
          </span>
        </button>
        <form
          key="clipperForm"
          className="clipper-form"
          onSubmit={(e) => {
            document.activeElement.blur();
            e.preventDefault();
            browserHistory.push(`/clipper?url=${e.target.elements[0].value}`);
          }}
        >
          <input
            type="text"
            className="form-control url-input"
            placeholder={
              this.props.isMobile ? 'Paste URL' : 'Paste a video URL'
            }
            autoComplete="off"
            onPaste={(e) => {
              let targetInput = e.target;
              setTimeout(() => {
                browserHistory.push(`/clipper?url=${targetInput.value}`);
              }, 0);
            }}
          />
        </form>
      </div>
    );
  }
}

export default connect(mapStateToProps)(VideoCreator);
