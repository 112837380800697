import React, { type Ref, forwardRef } from 'react';

import { VideoFooterContainer } from 'src/dashboard/components/video-footer/video-footer-container';
import { VideoInfo } from 'src/dashboard/components/video-info/video-info';
import { VideoRepresentation } from 'src/dashboard/types';

import { isExpired } from '../utils';
import VideoThumbnail from './VideoThumbnail';

export type VideoCardProps = {
  className?: string;
  handleEditLabelsClick: (shortcode: string) => void;
  selected: boolean;
  toggleDeleteModal: () => void;
  toggleEmbedModal: () => void;
  toggleFileListModal: () => void;
  togglePrivacyModal: () => void;
  toggleSelect: () => void;
  userTier: string;
  video: VideoRepresentation;
};

export const VideoCard = forwardRef<HTMLDivElement, VideoCardProps>(
  (
    {
      handleEditLabelsClick,
      selected,
      toggleDeleteModal,
      toggleEmbedModal,
      toggleFileListModal,
      togglePrivacyModal,
      toggleSelect,
      userTier,
      video,
      ...otherProps
    },
    ref
  ) => {
    const expired = isExpired(video.date_added, userTier);

    return (
      <div ref={ref} {...otherProps}>
        <VideoThumbnail
          video={video}
          selected={selected}
          toggleSelect={toggleSelect}
          userTier={userTier}
          expired={expired}
        />
        <VideoInfo video={video} expired={expired} />
        <VideoFooterContainer
          video={video}
          togglePrivacyModal={togglePrivacyModal}
          toggleDeleteModal={toggleDeleteModal}
          toggleEmbedModal={toggleEmbedModal}
          toggleFileListModal={toggleFileListModal}
          handleEditLabelsClick={handleEditLabelsClick}
          expired={expired}
        />
      </div>
    );
  }
) as (props: VideoCardProps & { ref?: Ref<HTMLDivElement> }) => JSX.Element;
