import { connect } from 'react-redux';

import {
  selectIsGuest,
  selectHasPaidPlan,
} from 'src/common/state/me/me-selectors';
import type { RootState } from 'src/redux';

import { FreePlanComponent } from './free-plan-component';

const mapStateToProps = (state: RootState) => ({
  isGuest: selectIsGuest(state),
  hasPaidPlan: selectHasPaidPlan(state),
});

export default connect(mapStateToProps)(FreePlanComponent);
