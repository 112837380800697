import type { PayloadAction } from '@reduxjs/toolkit';
import {
  all,
  select,
  takeEvery,
  call,
  put,
  debounce,
} from 'redux-saga/effects';
import { meActions } from 'src/common/state/me/me-actions';
import {
  selectPlayerColor,
  selectWatermarkLink,
} from 'src/common/state/me/me-selectors';
import { UserOutput } from 'src/common/types/user';
import { settingsActions } from 'src/settings/state/settings-actions';
import { disableDarkMode } from 'src/utils/disable-dark-mode';
import { enableDarkMode } from 'src/utils/enable-dark-mode';
import { setDarkModeCookie } from 'src/utils/set-dark-mode-cookie';

import { updateUser } from './me-api';

const WATERMARK_LINK_DEBOUNCE_DELAY = 1000;

export function* handleSetDarkModeToggle({
  payload: { dark_mode },
}: PayloadAction<{ dark_mode: boolean }>) {
  yield put(meActions.editUser({ dark_mode }));

  yield call(setDarkModeCookie, { dark_mode });

  if (dark_mode) {
    yield call(enableDarkMode);
  } else {
    yield call(disableDarkMode);
  }

  try {
    const user: UserOutput = yield call(updateUser, {
      dark_mode,
    });
    yield put(meActions.editUser(user));
  } catch (error) {
    const { message } = error as Error;

    yield put(meActions.editUser({ dark_mode: !dark_mode }));
    yield call(alert, message);
  }
}

export function* handleColorChange({
  payload: { color },
}: PayloadAction<{ color: string }>) {
  const originalColor: string = yield select(selectPlayerColor);

  yield put(meActions.editUser({ color }));

  try {
    const user: UserOutput = yield call(updateUser, {
      color,
    });
    yield put(meActions.editUser(user));
  } catch (error) {
    const { message } = error as Error;

    yield put(meActions.editUser({ color: originalColor }));
    yield call(alert, message);
  }
}

export function* handleClearWatermark() {
  const originalWatermarkLink: string = yield select(selectWatermarkLink);

  yield put(meActions.editUser({ watermark_link: null }));
  try {
    const user: UserOutput = yield call(updateUser, {
      clear_watermark: true,
    });
    yield put(meActions.editUser(user));
  } catch (error) {
    const { message } = error as Error;

    yield put(meActions.editUser({ watermark_link: originalWatermarkLink }));
    yield call(alert, message);
  }
}

export function* handleRemoveBrandingChanged({
  payload: { remove_branding },
}: PayloadAction<{ remove_branding: boolean }>) {
  yield put(meActions.editUser({ remove_branding }));
  try {
    const user: UserOutput = yield call(updateUser, {
      remove_branding,
    });
    yield put(meActions.editUser(user));
  } catch (error) {
    const { message } = error as Error;

    yield put(meActions.editUser({ remove_branding: !remove_branding }));
    yield call(alert, message);
  }
}

export function* handleWatermarkLinkChanged({
  payload: { watermark_link },
}: PayloadAction<{ watermark_link: string }>) {
  const originalWatermarkLink: string = yield select(selectWatermarkLink);

  try {
    const user: UserOutput = yield call(updateUser, {
      watermark_link,
    });
    yield put(settingsActions.playerAppearance.watermarkLink.linkSaved());
    yield put(meActions.editUser(user));
  } catch (error) {
    const { message } = error as Error;

    yield put(meActions.editUser({ watermark_link: originalWatermarkLink }));
    yield call(alert, message);
  }
}

export function* handleAcceptTerms() {
  try {
    const user: UserOutput = yield call(updateUser, {
      terms_accepted: true,
    });
    yield put(meActions.editUser(user));
  } catch (error) {
    const { message } = error as Error;

    yield call(alert, message);
  }
}

export function* watchMeSaga() {
  yield all([
    takeEvery(meActions.nav.userMenu.darkModeChanged, handleSetDarkModeToggle),
    takeEvery(meActions.profile.darkModeChanged, handleSetDarkModeToggle),
    takeEvery(meActions.playerAppearance.colorChanged, handleColorChange),
    takeEvery(meActions.playerAppearance.clearWatermark, handleClearWatermark),
    takeEvery(
      meActions.playerAppearance.removeBrandingChanged,
      handleRemoveBrandingChanged
    ),
    takeEvery(meActions.acceptTermsModal.accepted, handleAcceptTerms),
    debounce(
      WATERMARK_LINK_DEBOUNCE_DELAY,
      settingsActions.playerAppearance.watermarkLink.keyPressed,
      handleWatermarkLinkChanged
    ),
  ]);
}
