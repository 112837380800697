import React from 'react';

import { pick } from 'ramda';
import { Flex } from 'src/common/components/flex/flex';
import { Text } from 'src/common/components/text/text';
import { XLogo } from 'src/common/components/x-logo/x-logo';

import { FOOTER_LINKS } from '../../constants';
import { FooterSection } from '../footer-section';
import './footer.css';

export const Footer = () => (
  <footer className="footer-v3">
    <Flex className="footer-v3__container">
      <Flex className="footer-v3__container__column">
        <FooterSection
          sections={Object.values(pick(['company', 'product'], FOOTER_LINKS))}
        />
      </Flex>

      <Flex className="footer-v3__container__column">
        <FooterSection
          sections={Object.values(pick(['tools'], FOOTER_LINKS))}
        />
      </Flex>

      <Flex className="footer-v3__container__column">
        <FooterSection
          sections={Object.values(pick(['support'], FOOTER_LINKS))}
        />

        <div>
          <a
            href="https://twitter.com/streamable"
            target="_blank"
            rel="noreferrer"
            className="footer-v3-link"
          >
            <XLogo />
          </a>
        </div>

        <div>
          <Text as="p" className="footer-v3__copyright-text">
            &copy; {new Date().getFullYear()} Streamable Inc.
          </Text>
        </div>
      </Flex>
    </Flex>
  </footer>
);
