import { toast } from 'react-hot-toast';

import { all, call, delay, put, takeEvery } from 'redux-saga/effects';

import { embedPreviewActions } from './embed-preview-actions';

const COPY_DISABLED_TIMEOUT = 3000;

export function* handleEmbedCodeCopy() {
  yield call(toast.success, 'Embed code copied to clipboard', {
    id: 'embed-code-copied',
    duration: COPY_DISABLED_TIMEOUT,
  });
  yield delay(COPY_DISABLED_TIMEOUT);
  yield call(toast.dismiss, 'embed-code-copied');
  yield put(embedPreviewActions.embedPreviewModal.enableCodeCopy());
}

export function* embedPreviewSaga() {
  yield all([
    takeEvery(
      embedPreviewActions.embedPreviewModal.codeCopied,
      handleEmbedCodeCopy
    ),
  ]);
}
