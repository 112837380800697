import { pipe, map, range, join, nth, times } from 'ramda';

const generateASCIICharacters = pipe(map(String.fromCharCode), join(''));
const alpha = generateASCIICharacters(range(65, 91)).concat(
  generateASCIICharacters(range(97, 123))
);
const numeric = generateASCIICharacters(range(48, 58));
const alphanumeric = alpha + numeric;

const characterTypes = {
  alpha: alpha,
  numeric: numeric,
  alphanumeric: alphanumeric,
};

export const generateUniqueId = (
  type: 'alpha' | 'numeric' | 'alphanumeric',
  length: number = 6
): string => {
  const characters = characterTypes[type];
  return times(
    () => nth(Math.floor(Math.random() * characters.length), characters),
    length
  ).join('');
};
