import { defaultTo, pipe, prop } from 'ramda';
import {
  ENTERPRISE_PLAN,
  ENTERPRISE_PLAN_NAME,
} from 'src/dashboard/components/upsell/upsell-constants';
import { selectPlanByName } from 'src/pricing/state/pricing-selectors';
import type { RootState } from 'src/redux';

const selectAllSuggestionsState = (state: RootState) => state.suggestions;

export const selectIsDismissed = (storeKey: string) => (state: RootState) =>
  pipe(
    selectAllSuggestionsState,
    prop('suggestions'),
    prop(storeKey),
    defaultTo({ isDismissed: false }),
    prop('isDismissed')
  )(state);

export const selectUpsellCardParams = pipe(
  selectAllSuggestionsState,
  prop('upsell')
);

export const selectUpsellPlanWithEnterprise = (state: RootState) =>
  pipe(selectUpsellCardParams, prop('planName'), (currentPlanName) =>
    currentPlanName
      ? currentPlanName === ENTERPRISE_PLAN_NAME
        ? ENTERPRISE_PLAN
        : selectPlanByName(currentPlanName)(state)
      : undefined
  )(state) || undefined;
