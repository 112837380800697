import { Button } from 'src/common/components/button/button';
import { Flex } from 'src/common/components/flex/flex';
import { Text } from 'src/common/components/text/text';

import './create-label-button.css';

type CreateLabelButtonProps = {
  onClick: () => void;
  id: string;
};

export const CreateLabelButton = ({ onClick, id }: CreateLabelButtonProps) => (
  <Flex id={id} direction="row" className="create-label-btn">
    <Text as="span" className="create-label-btn__label">
      Labels
    </Text>
    <Button variant="icon" className="create-label-btn__btn" onClick={onClick}>
      <span className="create-label-btn__icon material-icons">add</span>
    </Button>
  </Flex>
);
