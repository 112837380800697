import type { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, spawn, takeEvery } from 'redux-saga/effects';
import { fetchCurrentUserAction } from 'src/actions';
import { logMesssage } from 'src/common/logging/logging-actions';
import {
  selectAllMeState,
  selectUsername,
} from 'src/common/state/me/me-selectors';
import type { UserOutput } from 'src/common/types/user';
import { calculateRedirectURLAfterSubscription } from 'src/dashboard/utils/calculate-redirect-url-after-subscription';
import { pricingActions } from 'src/pricing/state/pricing-actions';
import { confirmPlanChange } from 'src/pricing/state/pricing-api';
import { getErrorMessage } from 'src/utils/get-error-message';
import { navigateTo } from 'src/utils/navigate-to';
import { trackFacebookStartTrialEvent } from 'src/utils/trigger-facebook-event';
import { triggerRedditSignupEvent } from 'src/utils/trigger-reddit-event';
import type { Unwrap } from 'src/utils/types';

import { invoicesActions, subscriptionActions } from './subscription-actions';
import {
  cancelSubscriptionRequest,
  fetchBillingInfoRequest,
  fetchInvoicesRequest,
  fetchSubscriptionInfoRequest,
  reactivateSubscriptionRequest,
  sendCollectedFeedbackRequest,
} from './subscription-api';
import {
  selectCurrentPlan,
  selectCurrentPlansStatus,
  selectCurrentPlansStripeSubscriptionId,
  selectIsPayPalUser,
} from './subscription-selectors';
import type {
  ActivatedSubscriptionInfo,
  SubscriptionStatus,
} from './subscription-types';

export function* handleFetchSubscriptionInfo() {
  const data: Unwrap<typeof fetchSubscriptionInfoRequest> = yield call(
    fetchSubscriptionInfoRequest
  );
  yield put(subscriptionActions.subscriptionInfoFetched(data));
  return data;
}

export function* handleFetchInvoices() {
  try {
    const data: Unwrap<typeof fetchInvoicesRequest> =
      yield call(fetchInvoicesRequest);
    yield put(invoicesActions.invoices.fetchFulfilled(data));
  } catch (error) {
    yield put(invoicesActions.invoices.fetchFailed());
  }
}

export function* handleConfirmedPlanChange() {
  const [billingInfoData, subscriptionInfoData]: [
    Unwrap<typeof fetchBillingInfoRequest>,
    Unwrap<typeof fetchSubscriptionInfoRequest>,
  ] = yield all([
    call(fetchBillingInfoRequest),
    call(fetchSubscriptionInfoRequest),
  ]);
  yield put(fetchCurrentUserAction(billingInfoData));
  yield put(subscriptionActions.subscriptionInfoFetched(subscriptionInfoData));
}

export function* handleConfirmCancelScheduledPlanRequest() {
  yield put(subscriptionActions.cancelScheduledPlanModalBusy());

  const { id: planId }: ActivatedSubscriptionInfo =
    yield select(selectCurrentPlan);

  try {
    yield call(confirmPlanChange, planId);
    yield put(subscriptionActions.cancelSchedulePlanFulfilled());
  } catch (error) {
    if (error instanceof Error) {
      yield put(subscriptionActions.cancelSchedulePlanFailed(error.message));
    } else {
      yield put(
        subscriptionActions.cancelSchedulePlanFailed(
          'Something went wrong. Please try again later.'
        )
      );
    }
  }
}

export function* handleCancelSubscription({
  payload,
}: ReturnType<typeof subscriptionActions.cancelSubscription>) {
  try {
    yield put(subscriptionActions.editUser({ loading_plan: true }));

    const data: Unwrap<typeof cancelSubscriptionRequest> = yield call(
      cancelSubscriptionRequest,
      payload.reason,
      payload.reasonCategory
    );

    yield put(subscriptionActions.editUser({ ...data, loading_plan: false }));
    yield put(subscriptionActions.subscriptionCancelled(data));
    const isPayPal: boolean = yield select(selectIsPayPalUser);
    if (isPayPal) {
      yield call(navigateTo, '/billing');
    }
  } catch (error) {
    const message: string = yield call(getErrorMessage, error);
    yield call(navigateTo, '/billing?error=' + message);
  }
}

export function* handleReactivateSubscription() {
  try {
    const data: Unwrap<typeof reactivateSubscriptionRequest> = yield call(
      reactivateSubscriptionRequest
    );
    yield put(subscriptionActions.subscriptionReactivated(data));
  } catch (error) {
    const message: string = yield call(getErrorMessage, error);
    yield call(navigateTo, '/billing?error=' + message);
  }
}

export function* trackFirstPromoterSignup() {
  const user: UserOutput = yield select(selectAllMeState);

  if (window.fpr && user.email) {
    yield call(window.fpr, 'referral', {
      email: user.email,
    });
    yield put(logMesssage(`[REFERRAL#SENT-IF-ANY] user.email (${user.email})`));
  } else {
    yield put(
      logMesssage(
        `[REFERRAL#ISSUE] either fpr module (${window.fpr}) or user.email (${user.email}) is not defined`
      )
    );
  }
}

export function* trackRedditSignupEvent() {
  const { id, name, cadence }: ActivatedSubscriptionInfo =
    yield select(selectCurrentPlan);
  yield call(triggerRedditSignupEvent, id, name, cadence);
}

export function* trackFacebookSignupEvent() {
  const currentPlanStatus: SubscriptionStatus | null = yield select(
    selectCurrentPlansStatus
  );

  if (currentPlanStatus === 'TRIAL') {
    const stripeSubscriptionId: string | undefined = yield select(
      selectCurrentPlansStripeSubscriptionId
    );

    yield call(
      trackFacebookStartTrialEvent,
      { currency: 'USD', predicted_ltv: '29.00', value: '0.00' },
      { eventID: stripeSubscriptionId || '' }
    );
  }
}

export function* handleTrackingUserSubscribed() {
  yield spawn(trackFirstPromoterSignup);
  yield spawn(trackRedditSignupEvent);
  yield spawn(trackFacebookSignupEvent);
}

export function* handleUserSubscriptionToAPlanFulfilled() {
  yield call(navigateTo, calculateRedirectURLAfterSubscription());
}

export function* handleSubscribeToElementsPlan({
  payload,
}: PayloadAction<{ plan: string }>) {
  try {
    yield call(confirmPlanChange, payload.plan);
    yield put(subscriptionActions.fetchSubscriptionInfo());
    yield put(subscriptionActions.closeCancelPlanModal());
    yield put(subscriptionActions.subscribeToElementsPlan.fulfilled());
  } catch (error) {
    const message: string = yield call(getErrorMessage, error);

    yield put(subscriptionActions.subscribeToElementsPlan.failed({ message }));
    yield put(logMesssage(message));
  }
}

export function* handleSendCancelFeedback({
  payload,
}: PayloadAction<{ feedback: string }>) {
  const emailOrUsername: string | null = yield select(selectUsername);
  const { feedback } = payload;

  if (emailOrUsername && emailOrUsername.includes('@') && feedback) {
    try {
      yield call(sendCollectedFeedbackRequest, feedback, emailOrUsername);
      yield put(subscriptionActions.sendCancelFeedback.fulfilled());
    } catch (error) {
      const message: string = yield call(getErrorMessage, error);
      yield put(
        logMesssage(
          `ERROR! Failed to send feedback to Zappier: ${feedback} from ${emailOrUsername}. Error: ${message}`
        )
      );
    }
  }
}

export function* watchSubscriptionSaga() {
  yield all([
    takeEvery(
      pricingActions.changePlanModal.changeFulfilled,
      handleConfirmedPlanChange
    ),
    takeEvery(
      subscriptionActions.confirmCancelScheduledPlan,
      handleConfirmCancelScheduledPlanRequest
    ),
    takeEvery(
      subscriptionActions.cancelSchedulePlanFulfilled,
      handleFetchSubscriptionInfo
    ),
    takeEvery(
      subscriptionActions.fetchSubscriptionInfo,
      handleFetchSubscriptionInfo
    ),
    takeEvery(subscriptionActions.cancelSubscription, handleCancelSubscription),
    takeEvery(
      subscriptionActions.reactivateSubscription,
      handleReactivateSubscription
    ),
    takeEvery(
      subscriptionActions.tracking.subscribed,
      handleTrackingUserSubscribed
    ),
    takeEvery(
      subscriptionActions.userSubscriptionToAPlan.fulfilled,
      handleUserSubscriptionToAPlanFulfilled
    ),
    takeEvery(
      subscriptionActions.subscribeToElementsPlan.initiated,
      handleSubscribeToElementsPlan
    ),
    takeEvery(
      subscriptionActions.sendCancelFeedback.initiated,
      handleSendCancelFeedback
    ),
  ]);
}

export function* watchInvoicesSaga() {
  yield takeEvery(invoicesActions.invoices.fetchStarted, handleFetchInvoices);
}
