import { and, not, pick, pipe, prop, propOr } from 'ramda';
import { DEFAULT_TRIAL_PERIOD } from 'src/common/constants';
import type { UserPromo } from 'src/common/types/userPromo';
import type { PlanFields } from 'src/pricing/types';
import type { RootState } from 'src/redux';

export const selectAllMeState = (state: RootState) => state.me;

export const selectUserId = pipe(selectAllMeState, prop('id'));

export const selectIsGuest = (state: RootState) => !state.me.user_name;

export const selectHasPaidPlan = (state: RootState) => Boolean(state.me.plan);

export const selectIsMeFetching = pipe(selectAllMeState, prop('isFetching'));

export const selectNoTrial = pipe(
  selectAllMeState,
  (me) => me.no_trial || false
);

export const selectUsername = pipe(selectAllMeState, prop('user_name'));

export const selectIsLoggedIn: (state: RootState) => boolean = pipe(
  selectUsername,
  Boolean
);

export const selectSelectedVideos = pipe(
  selectAllMeState,
  prop('selectedVideos')
);

export const selectPlanFields: (store: RootState) => PlanFields = pipe(
  selectAllMeState,
  pick([
    'plan_name',
    'plan_price',
    'plan_annual',
    'plan_plays',
    'plan_requests',
    'plan_max_length',
    'plan_max_size',
    'plan_hide_branding',
  ])
);

export const selectPro = pipe(selectAllMeState, prop('pro'));

export const selectVersion = pipe(selectAllMeState, prop('version'));

export const selectDarkMode = pipe(selectAllMeState, prop('dark_mode'));
export const selectPlayerColor = pipe(selectAllMeState, prop('color'));
export const selectWatermarkUrl = pipe(selectAllMeState, prop('watermark_url'));
export const selectUserRemoveBranding = pipe(
  selectAllMeState,
  prop('remove_branding')
);
export const selectWatermarkLink = pipe(
  selectAllMeState,
  prop('watermark_link')
);

export const selectLoadingPlan = pipe(selectAllMeState, prop('loading_plan'));

const selectHostingProvider = pipe(selectAllMeState, prop('hosting_provider'));

const selectEmailVerified = pipe(selectAllMeState, prop('email_verified'));

export const selectIsVerificationRequired = (state: RootState) =>
  and(Boolean(selectHostingProvider(state)), not(selectEmailVerified(state)));

export const selectTotalVideos = pipe(
  selectAllMeState,
  propOr(0, 'total_videos')
) as (state: RootState) => number;

export const selectMaxVideoSizeForPlan = pipe(
  selectAllMeState,
  prop('plan_max_size')
);

export const selectMaxVideoLengthForPlan = pipe(
  selectAllMeState,
  prop('plan_max_length')
);

export const selectFreeTrialDuration = (state: RootState) => {
  const meState = selectAllMeState(state);
  const userPromos: UserPromo[] = meState.promos || [];
  const extendedTrialPromo = userPromos.find(
    (promo) => promo.configuration.type === 'extended_trial'
  );
  if (!extendedTrialPromo) {
    return DEFAULT_TRIAL_PERIOD;
  } else {
    return extendedTrialPromo.configuration.trialPeriod || DEFAULT_TRIAL_PERIOD;
  }
};
