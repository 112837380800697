import type { PayloadAction } from '@reduxjs/toolkit';

import { replaceVideoActions } from './replace-video-actions';

export type ReplaceVideoState = {
  replaceVideoModal: {
    isShowing: boolean;
    shortcode: string | null;
  };
};

const initialState: ReplaceVideoState = {
  replaceVideoModal: {
    isShowing: false,
    shortcode: null,
  },
};

export const replaceVideoReducer = (
  state = initialState,
  action: PayloadAction<any>
): ReplaceVideoState => {
  const { type, payload } = action;
  switch (type) {
    case replaceVideoActions.replaceVideoModal.closed.type:
      return {
        ...state,
        replaceVideoModal: {
          isShowing: false,
          shortcode: null,
        },
      };

    case replaceVideoActions.videoFooter.replaceItem.clicked.type:
      return {
        ...state,
        replaceVideoModal: {
          isShowing: true,
          shortcode: payload,
        },
      };

    default:
      return state;
  }
};
